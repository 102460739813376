import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";

interface Props {
  inputElement: HTMLInputElement;
  onClose?: () => void;
  style?: React.CSSProperties;
}

interface State {
  isFocused?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    focused: {
      "& > div > div:after": {
        transform: "scaleX(1)",
      },
    },
    root: {
      backgroundColor: theme.palette.common.white,
      outline: "none",
      position: "absolute",
      right: 32,
    },
  });

export class ToolbarInputWrapper extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  public static className = "input-wrapper";
  private rootElement: HTMLDivElement | null;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { isFocused: false };
  }

  private onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.setState({ isFocused: false });
    }
  };

  private onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.setState({ isFocused: true });
    }
  };

  private onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      // Enter
      if (event.target === this.rootElement) {
        this.props.inputElement.focus();
      } else {
        this.rootElement!.focus();
      }
    }

    if (
      event.keyCode === 27 && // Escape
      event.target !== this.rootElement &&
      this.props.onClose
    ) {
      this.props.onClose();
    }
  };

  public render() {
    return (
      <div
        className={
          `${this.props.classes.root}` +
          ` ${ToolbarInputWrapper.className}` +
          ` ${this.state.isFocused ? this.props.classes.focused : ""}`
        }
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyUp={this.onKeyUp}
        ref={(r) => (this.rootElement = r)}
        style={this.props.style}
        tabIndex={-1}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(ToolbarInputWrapper);
