import ButtonBase from "@material-ui/core/ButtonBase";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import Localization from "../core/Localization";
import { useNavigation } from "../core/Navigation";
import Sys from "../core/Sys";
import { CustomTheme } from "../muiTheme";
import { CheckBoxGroupOption } from "./CheckBoxGroupOption";
import getFieldHelperText from "./FieldHelperText";
import FocusRipple from "./FocusRipple";
import Icon from "./Icon";
import TextField from "./TextField";

type Orientation = "horizontal" | "vertical";

interface Props {
  disabled?: boolean;
  disabledHelpText?: string;
  getErrors?: () => string[];
  helperText: string;
  label: string;
  onOptionChange: (option: CheckBoxGroupOption, checked: boolean) => void;
  options: CheckBoxGroupOption[];
  orientation: Orientation;
  readOnly?: boolean;
  required?: boolean;
  selectedValues: string[];
}

interface OptionProps {
  isActive: boolean;
  isSelected: boolean;
  onChange: (checked: boolean) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  option: CheckBoxGroupOption;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  group: {
    "&:focus": {
      outline: "none",
    },
    "&:focus-visible": {
      // Prevent browser default from briefly flashing while focus is moved to
      // one of the child options.
      outline: "none",
    },
    marginBottom: -8,
  },
}));

const useOptionStyles = makeStyles((theme: CustomTheme) => ({
  checkBoxRoot: {
    fontSize: 24,
    height: 24,
    marginLeft: -1,
    minWidth: 24,
    padding: 8,
    position: "relative",
    width: 24,
  },
  label: {
    marginRight: 16,
    ...theme.overrides!.MuiFormControlLabel!.label,
  },
  root: {
    display: "inline-flex",
    justifyContent: "start",
    ...theme.overrides!.MuiFormControlLabel!.root,
  },
}));

function Option(props: OptionProps): JSX.Element {
  const [isFocusVisible, setIsFocusVisible] = React.useState<boolean>(false);
  const classes = useOptionStyles();

  const onClick = (): void => {
    props.onChange(!props.isSelected);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    switch (event.key) {
      case "Space":
        props.onChange(!props.isSelected);
        break;
      case "Enter":
        // Ensure "Enter" key does nothing so select behaviour is consistent
        // with checkboxes (by default, "Enter" triggers the onClick handler for
        // the button base).
        break;
      default:
        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
        return;
    }

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <ButtonBase
      aria-selected={props.isSelected}
      className={classes.root}
      onBlur={() => setIsFocusVisible(false)}
      onClick={onClick}
      onFocusVisible={() => setIsFocusVisible(true)}
      onKeyDown={onKeyDown}
      role="option"
      tabIndex={props.isActive ? 0 : -1}
    >
      <div className={classes.checkBoxRoot}>
        <FocusRipple visible={isFocusVisible} />
        {props.isSelected ? (
          <Icon icon="fas fa-check-square" />
        ) : (
          <Icon icon="far fa-square" />
        )}
      </div>
      <Typography className={classes.label} component="span">
        {props.option.display}
      </Typography>
    </ButtonBase>
  );
}

export function CheckBoxGroup(props: Props): JSX.Element {
  const classes = useStyles();
  const groupRef = React.useRef<HTMLDivElement>();
  const [navigationState, navigate] = useNavigation(props.options.length);

  const componentId = React.useRef<string>(`check-box-group-${Sys.nextId}`);

  React.useEffect(() => {
    if (!groupRef.current) {
      return;
    }

    if (
      navigationState.activeIndex !== null &&
      document.hasFocus() &&
      document.activeElement !== null &&
      groupRef.current.contains(document.activeElement)
    ) {
      const activeElement = groupRef.current.querySelector('[tabindex="0"]');
      (activeElement as HTMLElement).focus();
    }
  }, [navigationState.activeIndex]);

  React.useEffect(() => {
    navigate({ count: props.options.length, type: "set count" });
  }, [props.options.length]);

  const onGroupBlur = (
    event: React.FocusEvent<HTMLElement>,
    errors: string[]
  ): void => {
    if (
      event.relatedTarget instanceof Element &&
      event.currentTarget.contains(event.relatedTarget)
    ) {
      // Focus is moving within the group
      return;
    }

    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }

    // Focus is moving away from the group
    navigate({ activeIndex: null, type: "set active index" });
  };

  const onGroupFocus = (event: React.FocusEvent<HTMLElement>): void => {
    if (
      event.relatedTarget instanceof Element &&
      event.currentTarget.contains(event.relatedTarget)
    ) {
      // Focus is moving within the group
      return;
    }

    // Focus is moving into the group
    let initialIndex: number | null = null;

    if (props.options.length > 0) {
      const selectedIndex = props.options.findIndex((option) =>
        props.selectedValues.includes(option.value)
      );
      initialIndex = Math.max(selectedIndex, 0);
    }

    navigate({ activeIndex: initialIndex, type: "set active index" });
  };

  const onOptionKeyDown = (
    optionIndex: number,
    event: React.KeyboardEvent<HTMLElement>
  ): void => {
    switch (event.key) {
      case "ArrowDown":
        navigate({ type: "next" });
        break;
      case "ArrowLeft":
        navigate({ type: "previous" });
        break;
      case "ArrowRight":
        navigate({ type: "next" });
        break;
      case "ArrowUp":
        navigate({ type: "previous" });
        break;
      case "End":
        navigate({ type: "last" });
        break;
      case "Home":
        navigate({ type: "first" });
        break;
      default:
        return;
    }
    event.stopPropagation();
    event.preventDefault();
  };

  if (props.disabled) {
    return (
      <TextField
        disabled={true}
        disabledHelpText={props.disabledHelpText}
        id={componentId.current}
        label={props.label}
        variant="filled"
      />
    );
  }

  if (props.readOnly) {
    const optionValues: string[] = props.options
      .filter((option) => props.selectedValues.includes(option.value))
      .map((option) => option.display);
    const delimiter: string = props.orientation === "horizontal" ? ", " : "\n";

    return (
      <TextField
        id={componentId.current}
        label={props.label}
        multiline={props.orientation === "vertical"}
        readOnly={true}
        value={optionValues.join(delimiter)}
        variant="filled"
      />
    );
  }

  if (props.options.length === 0) {
    return (
      <TextField
        disabled={true}
        disabledHelpText={Localization.getBuiltInMessage(
          "CheckBoxGroup.NoOptionsAvailable"
        )}
        id={componentId.current}
        label={props.label}
        variant="filled"
      />
    );
  }

  const helperTextId = `${componentId.current}-helper-text`;
  const labelId = `${componentId.current}-label`;

  const fieldHelperText = getFieldHelperText({
    getErrors: props.getErrors,
    helperText: props.helperText,
  });

  return (
    <div>
      <FormLabel
        component="span"
        disabled={props.disabled}
        error={fieldHelperText.hasErrors}
        id={labelId}
        required={props.required}
      >
        {props.label}
      </FormLabel>
      <div className="screenReaderOnly" id={helperTextId}>
        {fieldHelperText.helperText}
      </div>
      <FormGroup
        aria-describedby={helperTextId}
        aria-labelledby={labelId}
        aria-multiselectable={true}
        aria-orientation={props.orientation}
        aria-required={props.required}
        className={classes.group}
        onBlur={(event: React.FocusEvent<HTMLElement>) => {
          onGroupBlur(event, fieldHelperText.errors);
        }}
        onFocus={onGroupFocus}
        ref={groupRef}
        role="listbox"
        row={props.orientation === "horizontal"}
        tabIndex={navigationState.activeIndex === null ? 0 : -1}
      >
        {props.options.map((option, index) => (
          <Option
            isActive={index === navigationState.activeIndex}
            isSelected={props.selectedValues.includes(option.value)}
            key={option.value}
            onChange={(checked) => props.onOptionChange(option, checked)}
            onKeyDown={(event) => onOptionKeyDown(index, event)}
            option={option}
          />
        ))}
      </FormGroup>
      <FormHelperText aria-hidden={true} error={fieldHelperText.hasErrors}>
        {fieldHelperText.helperText}
      </FormHelperText>
    </div>
  );
}
