import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import PaneRow from "../models/PaneRow";
import { ValueByBreakpoint } from "../mustangui/Api";
import {
  Layout,
  LayoutChildProps,
  LayoutConfig,
  Props as LayoutProps,
} from "./Layout";

export interface DialogChildProps extends LayoutChildProps {
  parentDialog?: {
    rowKey: string | null;
  };
}

export interface DialogLayoutConfig extends LayoutConfig {
  titleHeadings: HeadingInfo[];
}

interface Props extends LayoutProps {
  config: DialogLayoutConfig;
  labelledById: string;
  propagated?: DialogChildProps;
}

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

@observer
export class Dialog extends React.Component<Props & WithWidth> {
  private headingsByBreakPoint: ValueByBreakpoint<HeadingInfo | undefined>;

  public constructor(props: Props & WithWidth) {
    super(props);

    this.headingsByBreakPoint = {
      lg: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      md: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "md"
      ),
      sm: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "sm"
      ),
      // We display the configuration for Large when the BreakPoint is XtraLarge.
      xl: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      xs: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "xs"
      ),
    };
  }

  public render(): React.ReactNode {
    const headingInfo: HeadingInfo | undefined = this.headingsByBreakPoint[
      this.props.width
    ];
    let headingText: string | undefined = undefined;

    if (headingInfo) {
      const runtimeProperties = PaneRow.getWidgetProperties(
        headingInfo.dataId,
        headingInfo.name
      );
      headingText = runtimeProperties.headingText;
    }

    return (
      <form>
        <div id={this.props.labelledById} style={{ display: "none" }}>
          {headingText}
        </div>
        <Layout config={this.props.config} propagated={this.props.propagated} />
      </form>
    );
  }
}

export default withWidth()(Dialog);
