import * as React from "react";
import Localization from "../core/Localization";
import Icon from "../coreui/Icon";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";

interface Props {
  data: PaneRow;
  style?: React.CSSProperties;
}

export default function ProjectCurrentJobIndicator(
  props: Props
): JSX.Element | null {
  const row = props.data as PaneRow;

  let iconDisplayStyle: string = "none";
  let hierarchyLevelLabel: string;
  if (row.isCurrentJob) {
    iconDisplayStyle = "inherit";
    hierarchyLevelLabel = Localization.getBuiltInMessage(
      "DataTable.projectGridHierarchyCurrentJob"
    );
  } else if (row.currentJobLevel! > row.hierarchyLevel!) {
    hierarchyLevelLabel = Localization.getBuiltInMessage(
      "DataTable.projectGridHierarchyLevelUp",
      { levelDifference: row.currentJobLevel! - row.hierarchyLevel! }
    );
  } else if (row.currentJobLevel! < row.hierarchyLevel!) {
    hierarchyLevelLabel = Localization.getBuiltInMessage(
      "DataTable.projectGridHierarchyLevelDown",
      { levelDifference: row.hierarchyLevel! - row.currentJobLevel! }
    );
  } else {
    hierarchyLevelLabel = Localization.getBuiltInMessage(
      "DataTable.projectGridHierarchySameLevel"
    );
  }

  // The class of screenReaderOnly is needed here instead of aria-label so that
  // both the vertical hierachy and a grid with VoiceOver will read the text
  return (
    <div
      style={{
        alignItems: "left",
        display: "flex",
        width: "16px",
        ...(props.style ? props.style : {}),
      }}
    >
      <div className="screenReaderOnly">{hierarchyLevelLabel}</div>
      <Icon
        icon="fas fa-eye"
        style={{
          display: iconDisplayStyle,
          fontSize: 16,
          height: "auto",
        }}
      />
    </div>
  );
}
