import * as muiGrid from "@material-ui/core/Grid";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";

interface Props extends muiGrid.GridProps {}

const styles = (theme: Theme) =>
  createStyles({
    item: {},
  });

export class GridItem extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const _props = { ...this.props };

    return <muiGrid.default {..._props} item />;
  }
}

export default withStyles(styles)(GridItem);
