import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTextField from "@material-ui/core/TextField";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import getFieldHelperText from "../coreui/FieldHelperText";
import Presentation from "../coreui/Presentation";
import TextField from "../coreui/TextField";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface Props {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  increment: number | null;
  justification: "Left" | "Right";
  label: string;
  maximum: number | null;
  maximumError: string | null;
  minimum: number | null;
  minimumError: string | null;
  name: string;
  scale: number | null;
  scaleError: string | null;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    inputLabelRoot: {
      width: "calc(100% - 33px)",
    },
    inputLabelShrink: {
      width: "calc((100% - 33px) * 1.333)",
    },
    labelRootWithIncrementors: {
      width: "calc(100% - 102px)",
    },
    labelShrinkWithIncrementors: {
      width: "calc((100% - 102px) * 1.333)",
    },
    root: {},
  });

@observer
export class NumericEdit extends React.Component<
  Props & WithStyles<typeof styles>
> {
  public static readonly systemDecimalCharacter = ".";

  @observable private isFocused = false;
  private isFocusing = false;
  private muiProps: muiTextField.TextFieldProps;
  private updateValueInterval: number;

  private static addThousandsSeparators(value: string) {
    const parts = value.split(Sys.settings.decimalSeparator);
    let part1 = parts[0];
    const part2 =
      parts.length > 1 ? Sys.settings.decimalSeparator + parts[1] : "";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(part1)) {
      part1 = part1.replace(rgx, `$1${Sys.settings.thousandsSeparator}$2`);
    }

    return part1 + part2;
  }

  private static round(value: number, decimals: number): string {
    // Use exponential notation to avoid rounding issues
    // https://stackoverflow.com/a/32178833
    const rounded = Math.round(Number(`${value}e${decimals}`));

    return Number(`${rounded}e-${decimals}`).toFixed(decimals);
  }

  public static formatNumericValue(
    value: number | null,
    userFormatted: boolean,
    scale: number | null
  ) {
    if (value === null) {
      return null;
    }

    let formattedValue =
      scale !== null ? NumericEdit.round(value, scale) : value.toString();

    if (userFormatted) {
      formattedValue = formattedValue.replace(
        NumericEdit.systemDecimalCharacter,
        Sys.settings.decimalSeparator
      );
      formattedValue = NumericEdit.addThousandsSeparators(formattedValue);
    }

    return formattedValue;
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.muiProps = {
      autoFocus: props["autoFocus"],
      fullWidth: true,
      id: `numeric-edit-${Sys.nextId}`,
      InputProps: props["InputProps"] || {},
      label: props.label,
      name: props.name,
      variant: "filled",
    };

    this.muiProps.inputProps = {
      max: props.maximum,
      min: props.minimum,
    };

    this.muiProps.onChange = (e) => this.setValue(e);
    this.muiProps.onKeyDown = (e) => this.validateKeyPress(e);
    this.muiProps.onKeyUp = (e) => this.onKeyUp(e);
    this.muiProps.onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (this.isFocusing) {
        return;
      }

      const target = e.target;
      this.isFocused = true;
      this.isFocusing = true;

      // Firefox loses focus when re-rendering element, so re-focus it
      setTimeout(() => {
        if (document.activeElement !== target) {
          target.focus();
          target.select();
        }
        this.isFocusing = false;
      }, 100);
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private formatValue(value: number | null, userFormatted: boolean) {
    const scale: number = this.props.scale === null ? 0 : this.props.scale;
    return NumericEdit.formatNumericValue(value, userFormatted, scale);
  }

  private getCurrentValueForEdit(): string {
    return Presentation.getValue(this.props).replace(
      NumericEdit.systemDecimalCharacter,
      Sys.settings.decimalSeparator
    );
  }

  private getCurrentValueParsed() {
    let value = Presentation.getValue(this.props);
    let parsed: number | null = null;

    if (value) {
      switch (typeof value) {
        case "string":
          value = value.replace(
            Sys.settings.decimalSeparator,
            NumericEdit.systemDecimalCharacter
          );

          const regex = new RegExp(`\\${Sys.settings.thousandsSeparator}`, "g");
          parsed = parseFloat(value.replace(regex, ""));
          if (isNaN(parsed)) {
            parsed = null;
          }
          break;
        case "number":
          parsed = value as number;
          break;
        default:
          throw new Error(
            "Unexpected numeric edit value type " + `${typeof value}`
          );
      }
    }

    return parsed;
  }

  private getErrors(): string[] {
    const currentValue = this.getCurrentValueParsed();
    const widgetProperties = Api.getWidgetProperties(this.props);

    if (!widgetProperties) {
      return [];
    }

    const businessErrors = widgetProperties["businessErrors"];
    const result: string[] = [...businessErrors];

    let index = result.indexOf(this.props.minimumError!);
    if (
      this.props.minimum !== null &&
      currentValue !== null &&
      currentValue < this.props.minimum
    ) {
      if (index < 0) {
        result.push(this.props.minimumError!);
      }
    } else if (index >= 0) {
      result.splice(index, 1);
    }

    index = result.indexOf(this.props.maximumError!);
    if (
      this.props.maximum !== null &&
      currentValue !== null &&
      currentValue > this.props.maximum
    ) {
      if (index < 0) {
        result.push(this.props.maximumError!);
      }
    } else if (index >= 0) {
      result.splice(index, 1);
    }

    index = result.indexOf(this.props.scaleError!);
    if (this.props.increment !== null) {
      const scale =
        this.props.scale !== null ? Math.pow(10, this.props.scale) : 1;

      if (
        currentValue !== null &&
        (Math.round(currentValue * scale) % (this.props.increment * scale)) /
          scale !==
          0
      ) {
        if (index < 0) {
          result.push(this.props.scaleError!);
        }
      } else if (index >= 0) {
        result.splice(index, 1);
      }
    }

    return result;
  }

  private increaseOrDecreaseValue(increase: boolean) {
    if (this.props.increment === null) {
      return;
    }

    let currentValue = this.getCurrentValueParsed();

    if (
      this.props.minimum !== null &&
      (currentValue === null || currentValue < this.props.minimum)
    ) {
      currentValue = this.props.minimum;
    } else if (
      this.props.maximum !== null &&
      (currentValue === null || currentValue > this.props.maximum)
    ) {
      currentValue = this.props.maximum;
    } else {
      currentValue = currentValue === null ? 0 : currentValue;
      currentValue = currentValue + this.props.increment * (increase ? 1 : -1);
    }

    if (
      (increase ||
        this.props.minimum === null ||
        currentValue >= this.props.minimum) &&
      (!increase ||
        this.props.maximum === null ||
        currentValue <= this.props.maximum)
    ) {
      this.setFormattedValue(currentValue);
    }
  }

  private onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!event.key || !this.props.increment) {
      return;
    }

    if (event.key === "ArrowUp") {
      this.increaseOrDecreaseValue(true);
    }

    if (event.key === "ArrowDown") {
      this.increaseOrDecreaseValue(false);
    }
  };

  private setFormattedValue(value: number | null) {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, this.formatValue(value, false));
  }

  private setValue(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, event.target.value);
  }

  private startIncreaseOrDecreaseValue(increase: boolean) {
    document.addEventListener("mouseup", this.stopIncreaseOrDecreaseValue);

    this.updateValueInterval = window.setInterval(
      () => this.increaseOrDecreaseValue(increase),
      250
    );
  }

  private stopIncreaseOrDecreaseValue = () => {
    document.removeEventListener("mouseup", this.stopIncreaseOrDecreaseValue);
    clearInterval(this.updateValueInterval);
  };

  private validateKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (!event.key || (event.target as HTMLElement).tagName !== "INPUT") {
      return;
    }

    const validCharacters = [
      Sys.settings.thousandsSeparator,
      Sys.settings.decimalSeparator,
      "-",
    ];
    for (let i = 0; i <= 9; i++) {
      validCharacters.push(i.toString());
    }

    const isControlChar = event.key.length > 1;
    if (
      !event.ctrlKey &&
      !isControlChar &&
      validCharacters.indexOf(event.key) < 0
    ) {
      event.preventDefault();
    }
  }

  public render() {
    const _props = { ...this.props };
    const widgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    this.muiProps.InputProps!.endAdornment = undefined;
    this.muiProps.required = false;
    this.muiProps.style = undefined;

    // Number type doesn't support international number characters (space
    // and comma), tel type iphone keypad doesn't support the decimal character
    this.muiProps.type = "text";

    this.muiProps.inputProps!.style = {
      textAlign: Api.getAlignment(_props.justification),
    };

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widgetProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widgetProperties.accessLevel === AccessLevel.readOnly) {
      const value = this.formatValue(this.getCurrentValueParsed(), true);

      return (
        <TextField
          label={_props.label}
          name={_props.name}
          readOnly={true}
          value={value ? value : "-"}
          variant="filled"
        />
      );
    }

    const parsedValue = this.getCurrentValueParsed();

    if (_props.increment !== null) {
      const canDecrement =
        parsedValue === null ||
        _props.minimum === null ||
        parsedValue - _props.increment >= _props.minimum;
      const canIncrement =
        parsedValue === null ||
        _props.maximum === null ||
        parsedValue + _props.increment <= _props.maximum;

      this.muiProps.InputProps!.endAdornment = (
        <muiInputAdornment.default position="end" style={{ marginTop: -4 }}>
          <React.Fragment>
            <Button
              aria-label={Localization.getBuiltInMessage("decrement")}
              disabled={!canDecrement}
              icon="fas fa-minus"
              onClick={() => {
                this.increaseOrDecreaseValue(false);
                Sys.debounceMethod(
                  () => {
                    Sys.announce(this.getCurrentValueForEdit());
                  },
                  "NumericEditUpdated",
                  300
                );
              }}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(false)}
              size="small"
              tabIndex={-1}
            />
            <Button
              aria-label={Localization.getBuiltInMessage("increment")}
              disabled={!canIncrement}
              icon="fas fa-plus"
              onClick={() => {
                this.increaseOrDecreaseValue(true);
                Sys.debounceMethod(
                  () => {
                    Sys.announce(this.getCurrentValueForEdit());
                  },
                  "NumericEditUpdated",
                  300
                );
              }}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(true)}
              size="small"
              style={{ marginLeft: 8 }}
              tabIndex={-1}
            />
          </React.Fragment>
        </muiInputAdornment.default>
      );
    }

    this.muiProps.required = widgetProperties.showAsMandatory;

    const fieldHelperText = getFieldHelperText({
      getErrors: () => this.getErrors(),
      helperText: this.props.helperText,
    });

    let formatted: string | null;
    if (this.isFocused) {
      formatted = this.getCurrentValueForEdit();
    } else {
      formatted = this.formatValue(parsedValue, true);
    }
    this.muiProps.value = formatted === null ? "" : formatted;

    return (
      <muiTextField.default
        {...this.muiProps}
        error={fieldHelperText.hasErrors}
        FormHelperTextProps={{
          "aria-hidden": true,
          ...fieldHelperText.formHelperTextProps,
        }}
        helperText={fieldHelperText.helperText}
        InputLabelProps={{
          classes: {
            root: this.props.increment
              ? this.props.classes.labelRootWithIncrementors
              : this.props.classes.inputLabelRoot,

            shrink: this.props.increment
              ? this.props.classes.labelShrinkWithIncrementors
              : this.props.classes.inputLabelShrink,
          },
        }}
        onBlur={() => {
          if (!this.isFocusing) {
            this.isFocused = false;
            this.setFormattedValue(this.getCurrentValueParsed());
          }
          this.announceErrors(fieldHelperText.errors);
        }}
      />
    );
  }
}

export default withStyles(styles)(NumericEdit);
