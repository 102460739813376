import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import Dialog from "../coreui/Dialog";
import DialogActions from "../coreui/DialogActions";
import DialogContent from "../coreui/DialogContent";
import Icon from "../coreui/Icon";
import Typography from "../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import AddressSearchCriteria from "../mustangui/AddressSearchCriteria";
import Api, { ValueByBreakpoint } from "../mustangui/Api";
import { DateRangeCriteria } from "../mustangui/DateRangeCriteria";
import { DomainCheckBoxCriteria } from "../mustangui/DomainCheckBoxCriteria";
import Grid from "../mustangui/Grid";
import GridItem from "../mustangui/GridItem";
import SearchService, {
  ExecuteSearchResponse,
} from "../services/SearchService";
import ErrorsStore from "../stores/ErrorsStore";
import RequestsStore from "../stores/RequestsStore";
import { Layout, LayoutChildProps, LayoutConfig } from "./Layout";

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

export interface SearchPresentationConfig extends LayoutConfig {
  criteriaPaneDataId: string;
  criteriaWidgetNames: string[];
  description: string;
  titleHeadings: Array<HeadingInfo>;
}

export interface SearchChildProps extends LayoutChildProps {
  parentSearch: {
    clear: () => void;
    resultsCountMessage: string | null;
    search: () => void;
    succeeded: boolean;
  };
}

export interface SiteSearchInfo {
  description: string;
  helperText: string;
  isDefault: boolean;
  mandatory: boolean;
  url: string;
}

interface Props {
  autoExecute: boolean;
  config: SearchPresentationConfig;
  propagated?: LayoutChildProps;
  queryStringValues: string | null;
}

interface State {
  exceededRowLimit: boolean;
  resultsCountMessage: string | null;
  succeeded: boolean;
  timedOut: boolean;
}

export class SearchPresentation extends React.Component<
  Props & WithWidth,
  State
> {
  private readonly formRef = React.createRef<HTMLFormElement>();
  private headingsByBreakPoint: ValueByBreakpoint<HeadingInfo | undefined>;

  public constructor(props: Props & WithWidth) {
    super(props);

    this.state = {
      exceededRowLimit: false,
      resultsCountMessage: null,
      succeeded: false,
      timedOut: false,
    };

    this.headingsByBreakPoint = {
      lg: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      md: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "md"
      ),
      sm: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "sm"
      ),
      // We display the configuration for Large when the BreakPoint is XtraLarge.
      xl: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      xs: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "xs"
      ),
    };
  }

  private focusFirstHeading(): void {
    if (this.formRef.current === null) {
      // This should never happen, but if it does it is not worth having the
      // application crash over it.
      console.warn(
        `No form element existed when attempting to focus the page heading`
      );
    } else {
      FocusManager.grabFocusForChild(
        this.formRef.current,
        [
          'h1[tabindex="-1"]',
          'h2[tabindex="-1"]',
          'h3[tabindex="-1"]',
          'h4[tabindex="-1"]',
          'h5[tabindex="-1"]',
          'h6[tabindex="-1"]',
        ].join(", ")
      );
    }
  }

  private closeDialog = () => {
    this.setState({
      exceededRowLimit: false,
      timedOut: false,
    });
  };

  // FUTURE: This dialog is nearly identical to the refineCriteriaDialog.
  // It could be factored into a common ancestor.
  private timedOutDialog = (): React.ReactNode => {
    return (
      <Dialog
        disableEscapeKeyDown={true}
        fullScreen={false}
        open={this.state.exceededRowLimit}
        maxWidth="sm"
      >
        <DialogContent>
          <Grid grouping="Closely Related" xs={1}>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography variant="h3">
                <div
                  className="fa-layers"
                  style={{
                    marginRight: ".4em",
                    width: "1.2em",
                  }}
                >
                  <Icon
                    fixedWidth
                    icon="far fa-triangle"
                    style={{
                      color: Api.getSystemColor("warning"),
                    }}
                  />
                  <Icon
                    fixedWidth
                    icon="fas fa-exclamation"
                    style={{
                      bottom: "0",
                      fontSize: ".6em",
                      left: "0",
                      margin: "0 auto",
                      position: "absolute",
                      right: "0",
                      top: "2px",
                    }}
                  />
                </div>
                {Localization.getBuiltInMessage("refineCriteriaTitle")}
              </Typography>
            </GridItem>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography>
                {Localization.getBuiltInMessage("refineCriteriaText")}
              </Typography>
            </GridItem>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} style={{ marginLeft: 40 }}>
            {Localization.getBuiltInMessage("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  private clear = (): void => {
    ErrorsStore.clearErrors();
    const row: PaneRow = PaneRow.get(this.props.config.criteriaPaneDataId)!;

    for (const widgetName of this.props.config.criteriaWidgetNames) {
      const widget: RuntimeWidget = row.getWidget(widgetName)!;

      // FUTURE: It would be better to have the widgetType defined in an
      // enumeration. Once that is done widgets can control their data
      // interface directly.
      switch (widget.widgetTypeId) {
        case AddressSearchCriteria.widgetTypeId:
          AddressSearchCriteria.clear(widgetName, row);
          break;
        case DateRangeCriteria.widgetTypeId:
          DateRangeCriteria.clear(widgetName, row);
          break;
        case DomainCheckBoxCriteria.widgetTypeId:
          DomainCheckBoxCriteria.clear(widgetName, row);
          break;
        default:
          row.setProperty(widgetName, null);
      }
    }
  };

  private search = (): void => {
    ErrorsStore.clearErrors();

    RequestsStore.instance.processingStarted();
    SearchService.executeSearch(this.props.config.layoutId)
      .then(
        (response: ExecuteSearchResponse) => {
          this.setState({
            exceededRowLimit: response.exceededRowLimit,
            resultsCountMessage: response.resultsCountMessage,
            succeeded: response.succeeded,
            timedOut: response.timedOut,
          });
        },
        () => {
          // Do nothing on error.
        }
      )
      .finally(() => {
        RequestsStore.instance.processingStopped();
      });
  };

  // FUTURE: This dialog is nearly identical to the refineCriteriaDialog.
  // It could be factored into a common ancestor.
  private refineCriteriaDialog = (): React.ReactNode => {
    return (
      <Dialog
        disableEscapeKeyDown={true}
        fullScreen={false}
        open={this.state.timedOut}
        maxWidth="sm"
      >
        <DialogContent>
          <Grid grouping="Closely Related" xs={1}>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography variant="h3">
                <div
                  className="fa-layers"
                  style={{
                    marginRight: ".4em",
                    width: "1.2em",
                  }}
                >
                  <Icon
                    fixedWidth
                    icon="far fa-triangle"
                    style={{
                      color: Api.getSystemColor("warning"),
                    }}
                  />
                  <Icon
                    fixedWidth
                    icon="fas fa-exclamation"
                    style={{
                      bottom: "0",
                      fontSize: ".6em",
                      left: "0",
                      margin: "0 auto",
                      position: "absolute",
                      right: "0",
                      top: "2px",
                    }}
                  />
                </div>
                {Localization.getBuiltInMessage("searchTimedOutTitle")}
              </Typography>
            </GridItem>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography>
                {Localization.getBuiltInMessage("searchTimedOutText")}
              </Typography>
            </GridItem>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} style={{ marginLeft: 40 }}>
            {Localization.getBuiltInMessage("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  public componentDidMount(): void {
    if (this.props.autoExecute) {
      this.search();
    }
    // The setTimeout appears to be necessary to ensure the runtime data is
    // loaded and everything can render appropriately. Perhaps the mobx
    // observables need another frame to catch up?
    setTimeout(() => this.focusFirstHeading());
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      this.props.queryStringValues !== prevProps.queryStringValues ||
      this.props.autoExecute !== prevProps.autoExecute
    ) {
      if (this.props.autoExecute) {
        this.search();
      }
    }
  }

  public render(): React.ReactNode {
    const propagated: SearchChildProps = {
      parentSearch: {
        clear: this.clear,
        resultsCountMessage: this.state.resultsCountMessage,
        search: this.search,
        succeeded: this.state.succeeded,
      },
    };

    let headingText: string | undefined = undefined;
    const headingInfo: HeadingInfo | undefined = this.headingsByBreakPoint[
      this.props.width
    ];

    if (headingInfo) {
      const runtimeProperties = PaneRow.getWidgetProperties(
        headingInfo.dataId,
        headingInfo.name
      );
      headingText = runtimeProperties.headingText;
    }

    return (
      <div>
        {this.refineCriteriaDialog()}
        {this.timedOutDialog()}
        <form aria-label={headingText} ref={this.formRef} role="search">
          <Layout config={this.props.config} propagated={propagated} />
        </form>
      </div>
    );
  }
}

export default withWidth()(SearchPresentation);
