import { action, observable } from "mobx";
import Localization from "../core/Localization";
import PaneRow from "../models/PaneRow";

export interface BusinessError {
  message: string;
  rows: BusinessErrorRow[];
  widgets: BusinessErrorWidget[];
}

interface BusinessErrorRow {
  dataId: string;
  parentGridDescription: string;
  parentGridKey: string;
  rowKey: string;
  topPaneUseKey?: string;
}

export interface BusinessErrorWidget {
  dataId: string;
  isColumnWidget: boolean;
  parentGridDescription: string | null;
  parentGridKey: string | null;
  rowKey: string;
  topPaneUseKey?: string;
  widgetName: string;
}

export default class ErrorsStore {
  @observable private static businessErrors: BusinessError[] = [];

  private static clearWidgetErrors(
    dataId: string,
    name: string,
    rowKey: string
  ): void {
    const row = PaneRow.get(dataId, rowKey);

    if (!row) {
      return;
    }

    const widget = row.getWidget(name);
    widget.properties.businessErrors.length = 0;
  }

  private static createBusinessError(message: string): BusinessError {
    const businessError = {
      message,
      rows: [],
      widgets: [],
    };

    return businessError;
  }

  /** @deprecated Use clearBusinessErrorsForX methods instead */
  @action
  public static clearBusinessErrors(
    dataId?: string,
    name?: string,
    rowKey?: string
  ) {
    if (dataId && name && rowKey) {
      ErrorsStore.clearBusinessErrorsForTableCell(dataId, name, rowKey);
    } else if (dataId && name) {
      ErrorsStore.clearBusinessErrorsForWidget(dataId, name);
    } else if (dataId && rowKey) {
      ErrorsStore.clearBusinessErrorsForTableRow(dataId, rowKey);
    } else {
      ErrorsStore.clearErrors();
    }
  }

  @action
  public static clearBusinessErrorsForTableCell(
    dataId: string,
    name: string,
    rowKey: string
  ) {
    // Need to clone the array so we can delete items.
    const businessErrors: BusinessError[] = [...ErrorsStore.businessErrors];
    let deleteCount: number = 0;

    businessErrors.forEach((businessError: BusinessError, index: number) => {
      if (
        businessError.widgets.some(
          (widget) =>
            widget.dataId === dataId &&
            widget.widgetName === name &&
            widget.rowKey === rowKey
        )
      ) {
        businessError.widgets.forEach((widget) => {
          ErrorsStore.clearWidgetErrors(
            widget.dataId,
            widget.widgetName,
            widget.rowKey
          );
        });

        ErrorsStore.businessErrors.splice(index - deleteCount, 1);
        deleteCount++;
      }
    });

    // When clearing the errors for a cell, clear for the corresponding row as well.
    ErrorsStore.clearBusinessErrorsForTableRow(dataId, rowKey);
  }

  @action
  public static clearBusinessErrorsForTableRow(dataId: string, rowKey: string) {
    // Need to clone the array so we can delete items.
    const businessErrors: BusinessError[] = [...ErrorsStore.businessErrors];
    let deleteCount: number = 0;

    businessErrors.forEach((businessError: BusinessError, index: number) => {
      if (
        businessError.widgets.some(
          (widget) => widget.dataId === dataId && widget.rowKey === rowKey
        )
      ) {
        businessError.widgets.forEach((widget) => {
          ErrorsStore.clearWidgetErrors(
            widget.dataId,
            widget.widgetName,
            widget.rowKey
          );
        });

        ErrorsStore.businessErrors.splice(index - deleteCount, 1);
        deleteCount++;
      } else if (
        businessError.rows.some(
          (row) => row.dataId === dataId && row.rowKey === rowKey
        )
      ) {
        ErrorsStore.businessErrors.splice(index - deleteCount, 1);
        deleteCount++;
      }
    });
  }

  @action
  public static clearBusinessErrorsForWidget(dataId: string, name: string) {
    // Need to clone the array so we can delete items.
    const businessErrors: BusinessError[] = [...ErrorsStore.businessErrors];
    let deleteCount: number = 0;

    businessErrors.forEach((businessError: BusinessError, index: number) => {
      if (
        businessError.widgets.some(
          (widget) => widget.dataId === dataId && widget.widgetName === name
        )
      ) {
        businessError.widgets.forEach((widget) => {
          ErrorsStore.clearWidgetErrors(
            widget.dataId,
            widget.widgetName,
            widget.rowKey
          );
        });

        ErrorsStore.businessErrors.splice(index - deleteCount, 1);
        deleteCount++;
      }
    });
  }

  @action
  public static clearErrors(): void {
    for (const error of ErrorsStore.businessErrors) {
      for (const widget of error.widgets) {
        ErrorsStore.clearWidgetErrors(
          widget.dataId,
          widget.widgetName,
          widget.rowKey
        );
      }
    }

    ErrorsStore.businessErrors.length = 0;
  }

  public static getBusinessErrorsCountForPane(topPaneUseKey: string): number {
    let paneWidgetErrors: BusinessErrorWidget[] = [];
    let paneRowErrors: BusinessErrorRow[] = [];
    let paneErrorsLength: number = 0;

    for (const businessError of ErrorsStore.businessErrors) {
      paneWidgetErrors = businessError.widgets.filter(
        (widget) => widget.topPaneUseKey === topPaneUseKey
      );
      paneErrorsLength += paneWidgetErrors.length;
    }

    for (const businessError of ErrorsStore.businessErrors) {
      paneRowErrors = businessError.rows.filter(
        (row) => row.topPaneUseKey === topPaneUseKey
      );
      paneErrorsLength += paneRowErrors.length;
    }

    return paneErrorsLength;
  }

  public static getErrorMessages(): string[] {
    if (ErrorsStore.businessErrors.length === 0) {
      return [];
    }

    const messages: string[] = [];
    const gridErrorCountByKey: {
      [key: string]: { count: number; description: string };
    } = {};

    for (const error of ErrorsStore.businessErrors) {
      const gridErrors: {
        description: string;
        key: string;
      }[] = [];

      for (const widget of error.widgets) {
        if (widget.isColumnWidget) {
          gridErrors.push({
            description: widget.parentGridDescription!,
            key: widget.parentGridKey!,
          });
        }
      }

      for (const row of error.rows) {
        gridErrors.push({
          description: row.parentGridDescription,
          key: row.parentGridKey,
        });
      }

      for (const gridError of gridErrors) {
        if (gridError.key in gridErrorCountByKey) {
          gridErrorCountByKey[gridError.key].count++;
        } else {
          gridErrorCountByKey[gridError.key] = {
            count: 1,
            description: gridError.description,
          };
        }
      }

      if (gridErrors.length === 0) {
        messages.push(error.message);
      }
    }

    for (const key in gridErrorCountByKey) {
      if (!gridErrorCountByKey.hasOwnProperty(key)) {
        continue;
      }

      const builtInMessageTranslationArgs = {
        count: gridErrorCountByKey[key].count,
        description: gridErrorCountByKey[key].description,
      };

      const message = Localization.getBuiltInMessage(
        builtInMessageTranslationArgs.count === 1
          ? "errorSnackbar"
          : "errorsSnackbar",
        builtInMessageTranslationArgs
      );

      messages.push(message);
    }

    return messages;
  }

  public static getTableErrorCount(contentDataId: string): number {
    let result: number = 0;

    for (const businessError of ErrorsStore.businessErrors) {
      result += businessError.widgets.filter(
        (widget) => widget.dataId === contentDataId
      ).length;

      result += businessError.rows.filter((row) => row.dataId === contentDataId)
        .length;
    }

    return result;
  }

  public static getTableRowErrors(
    contentDataId: string,
    isColumnVisible?: (widget: BusinessErrorWidget) => boolean
  ): Map<string, string[]> {
    const rowErrors: Map<string, string[]> = new Map<string, string[]>();

    for (const businessError of ErrorsStore.businessErrors) {
      if (isColumnVisible !== undefined) {
        businessError.widgets
          .filter((widget) => widget.dataId === contentDataId)
          .forEach((widget) => {
            if (!isColumnVisible(widget)) {
              const rowKey: string = widget.rowKey;

              if (rowErrors.has(rowKey)) {
                rowErrors.get(rowKey)!.push(businessError.message);
              } else {
                rowErrors.set(rowKey, [businessError.message]);
              }
            }
          });
      }

      businessError.rows
        .filter((row) => row.dataId === contentDataId)
        .forEach((row) => {
          const rowKey: string = row.rowKey;

          if (rowErrors.has(rowKey)) {
            rowErrors.get(rowKey)!.push(businessError.message);
          } else {
            rowErrors.set(rowKey, [businessError.message]);
          }
        });
    }

    return rowErrors;
  }

  public static getTableWidgetErrors(
    contentDataId: string
  ): BusinessErrorWidget[] {
    const widgets: BusinessErrorWidget[] = [];

    for (const businessError of ErrorsStore.businessErrors) {
      widgets.push(
        ...businessError.widgets.filter(
          (widget) => widget.dataId === contentDataId
        )
      );
    }

    return widgets;
  }

  @action
  public static pushErrorsToWidgets() {
    ErrorsStore.businessErrors.forEach((businessError: BusinessError) => {
      businessError.widgets.forEach((widget) => {
        const row = PaneRow.get(widget.dataId, widget.rowKey);

        if (row) {
          const widgetErrors = row.getWidget(widget.widgetName!).properties
            .businessErrors;

          if (widgetErrors.indexOf(businessError.message) === -1) {
            widgetErrors.push(businessError.message);
          }
        }
      });
    });
  }

  @action
  public static setBusinessErrors(
    businessErrors: BusinessError[] = [],
    clearMessages: boolean = true
  ): boolean {
    let result: boolean = false;
    if (businessErrors.length > 0) {
      result = true;

      if (clearMessages) {
        // Must clear all existing messages because the round trip may
        // not refresh all the data.
        ErrorsStore.clearErrors();
      }

      ErrorsStore.businessErrors.push(...businessErrors);
    } else {
      // FUTURE: Tab controls and grids (AsyncData) should call this
      // directly rather than using a special case for setBusinessErrors.
      ErrorsStore.pushErrorsToWidgets();
    }

    return result;
  }

  @action
  public static showErrors(messages: string[] | null) {
    if (!messages) {
      return;
    }

    if (messages.length === 0) {
      return;
    }

    const businessErrors: BusinessError[] = [];
    for (const message of messages) {
      // FUTURE: The messages list should never have null messages in it, this
      // doesn't make any sense. One known example of this happening is in
      // UserService.ts when an invalid username or password is provided.
      if (message !== null) {
        businessErrors.push(ErrorsStore.createBusinessError(message));
      }
    }
    ErrorsStore.businessErrors.unshift(...businessErrors);
  }
}
