import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import { AddOnHost, RuntimeProperties } from "../config/AddOnHost";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./Api";

export interface ConfigProps {
  breakPoints: BreakPoints;
  dataId: string;
  hostId: string;
  name: string;
}

interface BreakPoints {
  lg: { height: number; visible: boolean };
  md: { height: number; visible: boolean };
  sm: { height: number; visible: boolean };
  xl: { height: number; visible: boolean };
  xs: { height: number; visible: boolean };
}

interface State {
  height: number | null;
}

@observer
export class EmbeddedAddOn extends React.Component<
  ConfigProps & WithWidth,
  State
> {
  public target: React.RefObject<HTMLDivElement>;

  /**
   * [DEPRECATED] Use AddOnHost.clearRoundTrip() instead
   */
  public static clearRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.clearRoundTrip()
    AddOnHost.clearRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.rejectRoundTrip() instead
   */
  public static rejectRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.rejectRoundTrip()
    AddOnHost.rejectRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.resolveRoundTrip() instead
   */
  public static resolveRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.resolveRoundTrip()
    AddOnHost.resolveRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.roundTripStarting() instead
   */
  public static roundTripStarting(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.roundTripStarting()
    AddOnHost.roundTripStarting();
  }

  public constructor(props: ConfigProps & WithWidth) {
    super(props);
    this.state = { height: null };
    this.target = React.createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    AddOnHost.getAddOn(this.props.hostId).registerEmbeddedAddOn(this);
  }

  public componentDidUpdate(prevProps: ConfigProps & WithWidth): void {
    if (prevProps.width !== this.props.width) {
      AddOnHost.getAddOn(this.props.hostId).embeddedAddOnChanged();
    }
  }

  public componentWillUnmount(): void {
    // The add-on host may already be unmounted if the whole presentation is
    // being unmounted.
    const addOn: AddOnHost | null = AddOnHost.tryGetAddOn(this.props.hostId);
    if (addOn) {
      addOn.unregisterEmbeddedAddOn(this);
    }
  }

  public render(): React.ReactNode {
    const row: PaneRow | null = PaneRow.get(this.props.dataId);

    if (!row) {
      return null;
    }

    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    if (runtimeProps.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.props.breakPoints[this.props.width].visible) {
      return null;
    }

    const height =
      this.state.height || this.props.breakPoints[this.props.width].height;

    return (
      <div
        aria-owns={this.props.hostId}
        ref={this.target}
        role="presentation"
        style={{ height, transition: "height 500ms" }}
      />
    );
  }

  public setHeight(height: number | null) {
    this.setState({ height });
  }
}

export default withWidth()(EmbeddedAddOn);
