import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import KeyboardNavigationGroup from "../coreui/KeyboardNavigationGroup";
import Step from "../coreui/Step";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./Api";
import {
  WidgetProperties,
  WizardControl as WizardControlBase,
} from "./WizardControl";
import { StepProps } from "./WizardStepPane";

interface Props extends WithWidth {}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
  });

@observer
export class WizardStepsDisplay extends React.Component<
  Props & WithWidth & WithStyles<typeof styles>
> {
  private renderStepButton(
    step: StepProps,
    index: number,
    widgetProperties: WidgetProperties
  ): React.ReactNode {
    const stepWidgetProps = widgetProperties.steps.find(
      (s) => s.paneUseKey === step.paneUseKey
    )!;

    if (!stepWidgetProps) {
      return null;
    }

    if (stepWidgetProps.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <Step
        complete={stepWidgetProps.stepComplete}
        disabled={stepWidgetProps.accessLevel === AccessLevel.disabled}
        businessErrorsCount={ErrorsStore.getBusinessErrorsCountForPane(
          step.paneUseKey
        )}
        key={step.paneUseKey}
        label={step.label}
        last={index + 1 === widgetProperties.steps.length}
        onClick={() => {
          WizardControlBase.gotoStep(step.paneUseKey);
        }}
        selected={step.paneUseKey === widgetProperties.selectedPaneUseKey}
        step={WizardControlBase.getStepNumber(index)}
        stepCount={WizardControlBase.getStepCount()}
        tabIndex={-1}
        value={step.paneUseKey}
      />
    );
  }

  public render(): React.ReactNode {
    const widgetProperties = WizardControlBase.getWidgetProperties();
    const steps = WizardControlBase.getSteps();

    if (!widgetProperties || !steps) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <KeyboardNavigationGroup
        childSelector={'[role="tab"]'}
        className={this.props.classes.root}
        role="tablist"
        upDown={this.props.width === "xs"}
      >
        {steps.map((step, index) =>
          this.renderStepButton(step, index, widgetProperties)
        )}
      </KeyboardNavigationGroup>
    );
  }
}

export default withStyles(styles)(withWidth()(WizardStepsDisplay));
