import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import PaneRow from "../models/PaneRow";
import { ValueByBreakpoint } from "../mustangui/Api";
import { Layout, LayoutConfig, Props as LayoutProps } from "./Layout";

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

export interface PresentationConfig extends LayoutConfig {
  objectDefDescription: string;
  paperWidth: ValueByBreakpoint<4 | 6 | null>;
  titleHeadings: Array<HeadingInfo>;
}

interface Props extends LayoutProps {
  config: PresentationConfig;
  isHome?: boolean;
}

@observer
export class Presentation extends React.Component<Props & WithWidth> {
  private readonly formRef = React.createRef<HTMLFormElement>();
  private headingsByBreakPoint: ValueByBreakpoint<HeadingInfo | undefined>;

  public constructor(props: Props & WithWidth) {
    super(props);

    this.headingsByBreakPoint = {
      lg: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      md: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "md"
      ),
      sm: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "sm"
      ),
      // We display the configuration for Large when the BreakPoint is XtraLarge.
      xl: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "lg"
      ),
      xs: props.config.titleHeadings.find(
        (headingInfo) => headingInfo.breakPoint === "xs"
      ),
    };
  }

  private focusFirstHeading(): void {
    if (this.formRef.current === null) {
      // This should never happen, but if it does it is not worth having the
      // application crash over it.
      console.warn(
        `No form element existed when attempting to focus the page heading`
      );
    } else {
      FocusManager.grabFocusForChild(
        this.formRef.current,
        [
          'h1[tabindex="-1"]',
          'h2[tabindex="-1"]',
          'h3[tabindex="-1"]',
          'h4[tabindex="-1"]',
          'h5[tabindex="-1"]',
          'h6[tabindex="-1"]',
        ].join(", ")
      );
    }
  }

  public componentDidMount(): void {
    if (!this.props.isHome) {
      // The setTimeout appears to be necessary to ensure the runtime data is
      // loaded and everything can render appropriately. Perhaps the mobx
      // observables need another frame to catch up?
      setTimeout(() => this.focusFirstHeading());
    }
  }

  public render(): React.ReactNode {
    let headingText: string | undefined = undefined;
    const headingInfo: HeadingInfo | undefined = this.headingsByBreakPoint[
      this.props.width
    ];

    if (headingInfo) {
      const runtimeProperties = PaneRow.getWidgetProperties(
        headingInfo.dataId,
        headingInfo.name
      );
      headingText = runtimeProperties.headingText;
    }

    return (
      <form aria-label={headingText} ref={this.formRef}>
        <Layout config={this.props.config} />
      </form>
    );
  }
}

export default withWidth()(Presentation);
