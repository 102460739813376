import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Hidden from "../../coreui/Hidden";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import { AccessLevel } from "../Api";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { WizardControl as WizardControlBase } from "../WizardControl";

interface Props extends ActionButtonProps {}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class WizardPreviousButton extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private static onClick(): void {
    WizardControlBase.gotoPreviousStep();
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        WizardPreviousButton.onClick();
        configProps.propagated.onItemClicked!();
      }
    };

    return (
      <MenuItem
        disabled={props.runtime.accessLevel === AccessLevel.disabled}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    );
  }

  private onClick = () => {
    WizardPreviousButton.onClick();
  };

  public render() {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;
    const widgetProperties = WizardControlBase.getWidgetProperties();

    if (!widgetProperties) {
      return null;
    }

    const selectedStep: number = widgetProperties.steps.findIndex(
      (s) => s.paneUseKey === widgetProperties.selectedPaneUseKey
    )!;
    const step: number = WizardControlBase.getStepNumber(selectedStep);

    return (
      <React.Fragment>
        <Hidden xsDown>
          <ApiButton
            alternateText={runtimeProperties.alternateText}
            buttonColor={this.props.buttonColor}
            dataId={this.props.dataId}
            disabled={step === 1}
            disabledHelpText={this.props.disabledHelpText}
            iconName="fas fa-arrow-left"
            isIconOnly={false}
            label={runtimeProperties.label}
            name={this.props.name}
            onClick={this.onClick}
            size={this.props.size}
          />
        </Hidden>
        <Hidden smUp>
          <ApiButton
            alternateText={runtimeProperties.alternateText}
            buttonColor={this.props.buttonColor}
            dataId={this.props.dataId}
            disabled={step === 1}
            disabledHelpText={this.props.disabledHelpText}
            iconName="fas fa-arrow-left"
            isIconOnly={true}
            label={runtimeProperties.label}
            name={this.props.name}
            onClick={this.onClick}
            size={this.props.size}
          />
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WizardPreviousButton);
