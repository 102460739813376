import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiAutocomplete from "@material-ui/lab/Autocomplete";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import * as React from "react";
import TextField from "./TextField";

export interface AutocompleteProps {
  autoComplete?: boolean;
  autoSelect?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  filterOptions?: (
    options: object[],
    state: FilterOptionsState<object>
  ) => object[];
  forcePopupIcon?: boolean;
  freeSolo?: boolean;
  getErrors?: (value: string) => string[];
  getOptionLabel?: (option: object) => string;
  getOptionSelected?: (option: object, value: object) => boolean;
  helperText?: string;
  icon?: string;
  inputValue?: string;
  label: string;
  name: string;
  noOptionsText?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputChange?: (
    value: string,
    reason: muiAutocomplete.AutocompleteInputChangeReason
  ) => void;
  onValueChange?: (option: object | null) => void;
  options: object[];
  readOnly?: boolean;
  renderOption?: (
    option: object,
    state: muiAutocomplete.AutocompleteRenderOptionState
  ) => React.ReactNode;
  required?: boolean;
  role?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    endAdornment: {
      right: "14px !important",
    },
  });

interface State {
  didInputChange: boolean;
}

export class Autocomplete extends React.Component<
  AutocompleteProps & WithStyles<typeof styles>,
  State
> {
  public constructor(props: AutocompleteProps & WithStyles<typeof styles>) {
    super(props);

    this.state = { didInputChange: false };
  }

  private onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    reason: muiAutocomplete.AutocompleteInputChangeReason
  ) => {
    this.setState({ didInputChange: true });

    if (this.props.onInputChange) {
      this.props.onInputChange(value, reason);
    }
  };

  private onValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: object | null
  ) => {
    this.setState({ didInputChange: false });

    if (this.props.onValueChange) {
      this.props.onValueChange(newValue);
    }
  };

  public render() {
    const {
      autoComplete,
      autoSelect,
      disableClearable,
      disabled,
      endAdornment,
      filterOptions,
      forcePopupIcon,
      freeSolo,
      getErrors,
      getOptionLabel,
      getOptionSelected,
      helperText,
      icon,
      inputValue,
      label,
      name,
      noOptionsText,
      onKeyPress,
      options,
      readOnly,
      renderOption,
      required,
      role,
    } = this.props;

    return (
      <muiAutocomplete.default
        autoComplete={autoComplete}
        autoHighlight={autoSelect}
        autoSelect={autoSelect && !!inputValue && this.state.didInputChange}
        classes={this.props.classes}
        disableClearable={disableClearable}
        disabled={disabled}
        filterOptions={filterOptions}
        forcePopupIcon={forcePopupIcon}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        handleHomeEndKeys={false}
        options={options}
        inputValue={inputValue}
        onChange={this.onValueChange}
        onInputChange={this.onInputChange}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
        renderInput={(params) => {
          if (params.InputProps["endadornment"]) {
            params.InputProps[
              "endadornment"
            ].props.className = this.props.classes.endAdornment;
          }
          params.inputProps["autoComplete"] = "off";
          params.InputProps["style"] = { paddingLeft: 12, paddingTop: 0 };
          params.inputProps["style"] = { paddingLeft: 4, paddingTop: 24 };
          params.inputProps["type"] = "search";

          return (
            <TextField
              {...params}
              endAdornment={endAdornment}
              getErrors={getErrors}
              helperText={helperText}
              icon={icon}
              label={label}
              name={name}
              onKeyPress={onKeyPress}
              readOnly={readOnly}
              required={required}
              role={role}
              value={inputValue}
              variant="filled"
            />
          );
        }}
      />
    );
  }
}

export default withStyles(styles)(Autocomplete);
