import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import withMobileDialog, {
  WithMobileDialog,
} from "@material-ui/core/withMobileDialog";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Layout, LayoutChildProps, LayoutConfig } from "../config/Layout";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Dialog, { BreakPointColumn } from "../coreui/Dialog";
import DialogActions from "../coreui/DialogActions";
import DialogContent from "../coreui/DialogContent";
import Presentation from "../coreui/Presentation";
import ProcessingMask from "../coreui/ProcessingMask";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import RoundTripService from "../services/RoundTripService";
import SelectControlService, {
  GetSelectDialogConfigResponse,
  OnDialogCloseResponse,
  OnDialogOpenResponse,
  OnSearchResponse,
} from "../services/SelectControlService";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import Api, { AccessLevel } from "./Api";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "./EmbeddedAddOn";
import Grid from "./Grid";
import GridItem from "./GridItem";
import Panel from "./Panel";

interface ConfigProperties extends WithMobileDialog {
  criteriaPane: object;
  dataId: string;
  dialogResultsDescription: string;
  dialogTitle: string;
  disabledHelpText: string;
  isSingleSelect: boolean;
  name: string;
  selectDialogId: number;
  selectedDataId: string;
  selectedGrid: object;
}

export interface SelectChildProps extends LayoutChildProps {
  dialogResultsDescription: string;
  parentRowObjectHandle?: string;
  parentSelect: {
    configProps: {
      dataId: string;
      disabledHelpText: string;
      isSingleSelect: boolean;
      name: string;
    };
    getRuntimeProps: () => RuntimeProperties;
    getSelectedRowObjectHandles: () => string[];
    isDialogClosing: boolean;
    isDialogOpen: boolean;
    onIsLoadingChanged: (isLoading: boolean) => void;
    onRowSelected: (rowKey: string) => void;
    onRowsRemoved: (rowKeys: string[]) => void;
    onRowUnselected: (rowKey: string) => void;
    search: (criteriaWidgetName: string | null) => void;
    selectedDataId: string;
  };
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  canAddRemoveRows: boolean;
  showDisabledHelp: boolean;
}

interface State {
  breakPointColumns?: BreakPointColumn[];
  isDialogClosing?: boolean;
  isDialogLoading?: boolean;
  isDialogOpen?: boolean;
  isSelectedGridLoading?: boolean;
}

const styles = (theme: CustomTheme) => {
  const dialogWidths = {};
  for (const breakPoint of theme.spacingBreakPoints.filter((b) => b !== "xs")) {
    const maxWidth = theme.panel.maxWidths[breakPoint];

    dialogWidths[`dialog-${breakPoint}`] = {
      [theme.breakpoints.up(breakPoint)]: {
        width: maxWidth,
      },
    };
  }

  return createStyles({
    ...dialogWidths,
    errorText: {
      color: Api.getSystemColor("danger"),
    },
  });
};

@observer
export class SelectControl extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  @observable private dialogLayoutConfig: LayoutConfig | null = null;
  private childSelector: string = "button, input";
  private componentId: string = `select-control-${Sys.nextId}`;
  private criteriaRef: React.RefObject<HTMLDivElement>;
  private dialogId: string = `${this.componentId}-dialog`;
  private dialogLabelId: string = `${this.dialogId}-labelled-by`;
  private propagated: SelectChildProps;
  private lastElementFocused: HTMLElement | null = null;
  private selectionChanges: {
    addedRowKeys: string[];
    removedRowKeys: string[];
  };

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      isDialogClosing: false,
      isDialogLoading: false,
      isDialogOpen: false,
      isSelectedGridLoading: false,
    };

    this.selectionChanges = { addedRowKeys: [], removedRowKeys: [] };

    this.propagated = {
      dialogResultsDescription: props.dialogResultsDescription,
      parentSelect: {
        configProps: {
          dataId: props.dataId,
          disabledHelpText: props.disabledHelpText,
          isSingleSelect: props.isSingleSelect,
          name: props.name,
        },
        getRuntimeProps: this.getRuntimeProps,
        getSelectedRowObjectHandles: this.getCurrentlySelectedRowObjectHandles,
        isDialogClosing: false,
        isDialogOpen: false,
        onIsLoadingChanged: this.onSelectedGridIsLoadingChanged,
        onRowSelected: this.onRowSelected,
        onRowsRemoved: this.onRowsRemoved,
        onRowUnselected: this.onRowUnselected,
        search: this.search,
        selectedDataId: props.selectedDataId,
      },
    };

    this.criteriaRef = React.createRef<HTMLDivElement>();
  }

  private acceptDialog = (): void => {
    if (
      this.selectionChanges.addedRowKeys.length <= 0 &&
      this.selectionChanges.removedRowKeys.length <= 0
    ) {
      this.cancelDialog();

      return;
    }

    if (this.state.isDialogClosing) {
      return;
    }

    this.setState({
      isDialogClosing: true,
      isDialogLoading: true,
    });
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    SelectControlService.onDialogClose(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      this.selectionChanges.addedRowKeys,
      this.selectionChanges.removedRowKeys
    )
      .then((response: OnDialogCloseResponse) => {
        if (response.businessErrors.length > 0) {
          ErrorsStore.clearBusinessErrors();
          ErrorsStore.setBusinessErrors(response.businessErrors, false);

          return;
        }

        AppServer.clearStateRecoveryPoint();
        AppServer.setState(response.appServerState);

        // FUTURE
        // This loadResponse() call causes the data on the results grid in the
        // dialog to be populated again. This is neither efficient nor rational
        // and should be fixed.
        PaneDataStore.loadResponse(response.paneDataByDataId);
        EmbeddedAddOnBase.resolveRoundTrip();
        this.setState({ isDialogOpen: false });
      })
      .finally(() => {
        this.setState({ isDialogLoading: false });
      });
  };

  private cancelDialog = (): void => {
    if (this.state.isDialogClosing) {
      return;
    }

    AppServer.recoverStateFromPoint();
    EmbeddedAddOnBase.rejectRoundTrip();
    this.setState({
      isDialogClosing: true,
      isDialogOpen: false,
    });
  };

  private getCurrentlySelectedRowObjectHandles = (): string[] => {
    let selectedObjectHandles: string[] = this.getInitiallySelectedRowObjectHandles();

    selectedObjectHandles = selectedObjectHandles.filter(
      (h) => !this.selectionChanges.removedRowKeys.includes(h)
    );

    selectedObjectHandles = selectedObjectHandles.concat(
      this.selectionChanges.addedRowKeys
    );

    return selectedObjectHandles;
  };

  private getInitiallySelectedRowObjectHandles = (): string[] => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.selectedDataId
    );

    const selectedObjectHandles: string[] = [];
    for (const row of rows) {
      selectedObjectHandles.push(row.objectHandle);
    }

    return selectedObjectHandles;
  };

  private getRuntimeProps = (): RuntimeProperties => {
    return Api.getWidgetProperties(this.props) as RuntimeProperties;
  };

  private onDialogClose = (event: object) => {
    if (event["forced"]) {
      this.setState({
        isDialogClosing: true,
        isDialogOpen: false,
      });
    } else {
      this.cancelDialog();
    }
  };
  private onDialogExited = (): void => {
    this.dialogLayoutConfig = null;

    // Manually focus the element since the processing animation interferes
    // with the dialog returning the focus
    this.lastElementFocused!.focus();
  };

  private onDialogEntered = (node: HTMLElement, isAppearing: boolean) => {
    const firstFocusable: HTMLElement | null = node.querySelector(
      this.childSelector
    );
    if (firstFocusable) {
      firstFocusable.focus();
    }
  };

  private onRowSelected = (rowKey: string): void => {
    const index = this.selectionChanges.removedRowKeys.indexOf(rowKey);
    if (index >= 0) {
      this.selectionChanges.removedRowKeys.splice(index, 1);
    } else if (this.selectionChanges.addedRowKeys.indexOf(rowKey) < 0) {
      if (this.props.isSingleSelect) {
        this.selectionChanges.addedRowKeys = [rowKey];

        const selectedRowKeys: string[] = this.getInitiallySelectedRowObjectHandles();
        if (
          selectedRowKeys.length > 0 &&
          !this.selectionChanges.removedRowKeys.includes(selectedRowKeys[0])
        ) {
          this.selectionChanges.removedRowKeys.push(selectedRowKeys[0]);
        }
      } else {
        this.selectionChanges.addedRowKeys.push(rowKey);
      }
    }
  };

  private onRowsRemoved = (rowKeys: string[]): void => {
    RoundTripService.standardRoundTrip(
      "SelectControl/OnRemoveRows",
      this.props,
      { removedObjectHandles: rowKeys }
    ).catch((reason) => {
      if (reason) {
        throw reason;
      }
    });

    if (
      this.props.criteriaPane &&
      this.criteriaRef.current &&
      this.getCurrentlySelectedRowObjectHandles().length === rowKeys.length
    ) {
      const element: HTMLElement | null = this.criteriaRef.current.querySelector(
        this.childSelector
      );

      element?.focus();
    }
  };

  private onRowUnselected = (rowKey: string): void => {
    const index = this.selectionChanges.addedRowKeys.indexOf(rowKey);
    if (index >= 0) {
      this.selectionChanges.addedRowKeys.splice(index, 1);
    } else if (this.selectionChanges.removedRowKeys.indexOf(rowKey) < 0) {
      this.selectionChanges.removedRowKeys.push(rowKey);
    }
  };

  private onSelectedGridIsLoadingChanged = (isLoading: boolean): void => {
    this.setState({ isSelectedGridLoading: isLoading });
  };

  private openDialog(criteriaWidgetName: string | null): void {
    this.lastElementFocused = document.activeElement as HTMLElement;
    RequestsStore.instance.processingStarted();
    EmbeddedAddOnBase.roundTripStarting();
    ErrorsStore.clearErrors();

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const dialogOpenRequest = SelectControlService.onDialogOpen(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      criteriaWidgetName
    );

    const configRequest = SelectControlService.getSelectDialogConfig(
      this.props.selectDialogId
    );

    AppServer.createStateRecoveryPoint();
    this.selectionChanges.addedRowKeys = [];
    this.selectionChanges.removedRowKeys = [];

    Promise.all([dialogOpenRequest, configRequest])
      .then(
        ([dialogOpenResponse, configResponse]: [
          OnDialogOpenResponse,
          GetSelectDialogConfigResponse
        ]) => {
          if (dialogOpenResponse.validationErrors.length > 0) {
            ErrorsStore.clearErrors();
            ErrorsStore.showErrors(dialogOpenResponse.validationErrors);
            AppServer.clearStateRecoveryPoint();
            EmbeddedAddOnBase.rejectRoundTrip();

            return;
          }

          if (dialogOpenResponse.dataChangesBusinessErrors.length > 0) {
            ErrorsStore.clearBusinessErrors();
            ErrorsStore.setBusinessErrors(
              dialogOpenResponse.dataChangesBusinessErrors,
              false
            );
            ErrorsStore.pushErrorsToWidgets();
            AppServer.clearStateRecoveryPoint();
            EmbeddedAddOnBase.rejectRoundTrip();

            return;
          }

          if (dialogOpenResponse.criteriaBusinessErrors.length > 0) {
            ErrorsStore.setBusinessErrors(
              dialogOpenResponse.criteriaBusinessErrors,
              true
            );
          }

          AppServer.setState(dialogOpenResponse.appServerState);
          PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

          this.dialogLayoutConfig = configResponse.dialogLayout;

          this.setState({
            breakPointColumns: configResponse.breakPoints,
            isDialogClosing: false,
            isDialogOpen: true,
          });
        }
      )
      .finally(() => RequestsStore.instance.processingStopped());
  }

  private search = (criteriaWidgetName: string | null): void => {
    if (!this.state.isDialogOpen) {
      this.openDialog(criteriaWidgetName);

      return;
    }

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    SelectControlService.onSearch(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      criteriaWidgetName
    )
      .then((response: OnSearchResponse) => {
        if (response.businessErrors.length > 0) {
          ErrorsStore.setBusinessErrors(response.businessErrors, true);
        }

        AppServer.setState(response.appServerState);
        PaneDataStore.loadResponse(response.paneDataByDataId);
      })
      .finally(() => this.setState({ isDialogLoading: false }));
  };

  public render(): React.ReactNode {
    const runtimeProperties: RuntimeProperties = this.getRuntimeProps();

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    let criteriaPane = null;
    if (this.props.criteriaPane) {
      criteriaPane = Presentation.create(
        this.props.criteriaPane,
        this.propagated
      );
    }

    const selectedGrid = Presentation.create(
      this.props.selectedGrid,
      this.propagated
    );

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return <div>{criteriaPane === null ? selectedGrid : criteriaPane}</div>;
    }

    const selectedRows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.selectedDataId
    );
    const noRowsSelected: boolean = selectedRows.length === 0;
    this.propagated.parentSelect.isDialogClosing = !!this.state.isDialogClosing;
    this.propagated.parentSelect.isDialogOpen = !!this.state.isDialogOpen;

    // The wrapping divs are used to prevent the Grid margin from overriding
    // the parent GridItem margin
    return (
      <div>
        <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
          {criteriaPane !== null ? (
            <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
              <div ref={this.criteriaRef} style={{ position: "relative" }}>
                {criteriaPane}
                <ProcessingMask
                  isOpen={noRowsSelected && this.state.isSelectedGridLoading!}
                />
              </div>
            </GridItem>
          ) : null}
          <GridItem
            md={1}
            lg={1}
            sm={1}
            style={{
              display:
                noRowsSelected && criteriaPane !== null ? "none" : undefined,
            }}
            xl={1}
            xs={1}
          >
            {selectedGrid}
          </GridItem>
        </Grid>
        <Dialog
          aria-labelledby={this.dialogLabelId}
          breakPointColumns={this.state.breakPointColumns}
          onClose={this.onDialogClose}
          onEntered={this.onDialogEntered}
          onExited={this.onDialogExited}
          open={this.state.isDialogOpen!}
        >
          <ProcessingMask
            isOpen={this.state.isDialogLoading!}
            trapFocus={true}
          />
          <div id={this.dialogLabelId} className="screenReaderOnly">
            {this.props.dialogTitle}
          </div>
          <DialogContent>
            <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
              <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
                <Typography variant="h3">{this.props.dialogTitle}</Typography>
              </GridItem>
              <GridItem lg={1} md={1} sm={1} xl={1} xs={1}>
                {this.dialogLayoutConfig !== null ? (
                  <Panel presentationId={this.dialogLayoutConfig.layoutId}>
                    <Layout
                      config={this.dialogLayoutConfig}
                      propagated={this.propagated}
                    />
                  </Panel>
                ) : null}
              </GridItem>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.acceptDialog}>
              {Localization.getBuiltInMessage("select")}
            </Button>
            <Button onClick={this.cancelDialog} style={{ marginLeft: 40 }}>
              {Localization.getBuiltInMessage("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog<ConfigProperties>({ breakpoint: "xs" })(
  withStyles(styles)(SelectControl)
);
