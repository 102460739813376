import * as muiCheckbox from "@material-ui/core/Checkbox";
import { IHeaderParams } from "ag-grid-community";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import FormControlLabel from "../FormControlLabel";
import Icon from "../Icon";
import { TableChildProps } from "../Table";

interface Props extends IHeaderParams {
  propagated: TableChildProps;
}

class SelectionHeader extends React.Component<Props> {
  private readonly componentId: string;
  private readonly describedById: string;
  protected muiProps: muiCheckbox.CheckboxProps;

  public constructor(props: Props) {
    super(props);

    this.muiProps = {
      checkedIcon: <Icon icon="fas fa-check-square" />,
      color: "default",
      onChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => {
        this.props.propagated.parentTable.selection.setAllSelected(checked);
        this.props.propagated.parentTable.getTable().focus();
      },
      tabIndex: -1,
    };

    this.componentId = `selection-header-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;

    props.eGridHeader.addEventListener("keydown", this.onHeaderKeyDown);
  }

  private onHeaderKeyDown = (event: KeyboardEvent): void => {
    if (!this.props.propagated.parentTable.selection.isSelectAllEnabled) {
      return;
    }

    if (event.key === " " || event.key === "Enter") {
      const selection = this.props.propagated.parentTable.selection;
      const api = this.props.api;

      event.preventDefault();
      event.stopPropagation();

      selection.setAllSelected(
        selection.getSelectedCount() !==
          api.getModel().getRowCount() + api.getPinnedTopRowCount()
      );
      this.props.propagated.parentTable.getTable().focus();
    }
  };

  private updateGridHeaderDescribedBy(): void {
    const { api, eGridHeader } = this.props;
    const rowCount = api.getModel().getRowCount() + api.getPinnedTopRowCount();

    if (rowCount <= 0) {
      eGridHeader.removeAttribute("aria-describedby");
    } else {
      eGridHeader.setAttribute("aria-describedby", this.describedById);
    }
  }

  public componentDidMount(): void {
    this.updateGridHeaderDescribedBy();
  }

  public componentDidUpdate(): void {
    this.updateGridHeaderDescribedBy();
  }

  public componentWillUnmount(): void {
    this.props.eGridHeader.removeEventListener("keydown", this.onHeaderKeyDown);
  }

  public render(): React.ReactNode {
    if (!this.props.propagated.parentTable.selection.isSelectAllEnabled) {
      return null;
    }

    const selectedCount = this.props.propagated.parentTable.selection.getSelectedCount();
    const icon = selectedCount === 0 ? "far fa-square" : "fas fa-minus-square";
    const { api } = this.props;
    const rowCount = api.getModel().getRowCount() + api.getPinnedTopRowCount();

    if (rowCount <= 0) {
      return (
        <div
          aria-label={Localization.getBuiltInMessage(
            "DataTable.selectRowColumnHeaderLabel"
          )}
        />
      );
    }

    this.muiProps["aria-label"] = Localization.getBuiltInMessage(
      "DataTable.selectRowColumnHeaderLabel"
    );

    this.muiProps.checked = selectedCount === rowCount;
    this.muiProps.icon = <Icon icon={icon} />;

    return (
      <div
        aria-describedby={this.describedById}
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
        }}
      >
        <FormControlLabel
          control={<muiCheckbox.default {...this.muiProps} />}
          label=""
          style={{ maxWidth: "none" }}
        />
        <div aria-hidden className="screenReaderOnly" id={this.describedById}>
          {Localization.getBuiltInMessage("DataTable.selectAllInstructions")}
        </div>
      </div>
    );
  }
}

export default SelectionHeader;
