import * as muiFormControl from "@material-ui/core/FormControl";
import * as React from "react";
import Sys from "../core/Sys";
import FormHelperText from "./FormHelperText";

export interface FormControlProps extends muiFormControl.FormControlProps {
  FormHelperTextProps?: object;
  helperText?: React.ReactNode;
  helperTextId?: string;
  refSetter?: (element: HTMLDivElement) => void;
}

export class FormControl extends React.PureComponent<FormControlProps> {
  private readonly componentId: string;
  private readonly helperTextId: string;

  public constructor(props: FormControlProps) {
    super(props);

    this.componentId = `form-control-${Sys.nextId}`;
    if (this.props.helperTextId === undefined) {
      this.helperTextId = `${this.componentId}-helper-text`;
    } else {
      this.helperTextId = this.props.helperTextId;
    }
  }

  public render(): React.ReactNode {
    const {
      children,
      FormHelperTextProps,
      helperText,
      refSetter,
      helperTextId,
      ...otherProps
    } = this.props;

    return (
      <muiFormControl.default ref={refSetter} {...otherProps}>
        {helperText ? (
          <React.Fragment>
            {children}
            <FormHelperText
              aria-hidden={true}
              id={this.helperTextId}
              {...FormHelperTextProps}
            >
              {helperText}
            </FormHelperText>
          </React.Fragment>
        ) : (
          children
        )}
      </muiFormControl.default>
    );
  }
}

// VERSION_WARNING Material-UI 4.9.14
// React / Typescript throw errors complaining about an incompatible ref if this
// cast is not in place. It seems to be related to something in Material-UI, so
// hopefully it will be fixed in a future version?
// See this stack overflow post for more information:
// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/56566434/muiv4-upgrade-w-typescript-cannot-spread-props-to-textfield-properly
export default FormControl as React.ComponentType<FormControlProps>;
