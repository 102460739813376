import { darken, makeStyles, Theme, withTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Icon from "../coreui/Icon";
import Menu from "../coreui/Menu";
import MenuItemDisplay from "../coreui/MenuItemDisplay";
import Typography from "../coreui/Typography";
import { ButtonSize } from "./ActionButton";
import Api, { AccessLevel } from "./Api";
import ApiButton from "./ApiButton";
import MenuItem, { MenuItemConfig, MenuItemProps } from "./MenuItem";
import { ToolbarChildProps } from "./Toolbar";
import { ToolbarOverflowChild } from "./ToolbarContainerOverflowSection";

interface ConfigProperties {
  dataId: string;
  iconName?: string;
  menuPane: {
    props: {
      menuItems: {
        props: MenuItemConfig;
        type: string;
      }[];
    };
    type: string;
  };
  name: string;
  propagated: { indent?: number } & ToolbarChildProps & object;
  renderAsLink: boolean;
  size: ButtonSize;
}

export interface MenuChild {
  onItemClicked?: () => void;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  label: string;
}

interface State {
  buttonElement?: HTMLElement;
}

const menuStyles = makeStyles((theme: Theme) => ({
  menuItemContent: {
    display: "flex",
    width: "100%",
  },
  menuItemTypography: {
    color: theme.palette.grey[800],
  },
  openMenu: {
    "&:hover": {
      backgroundColor: darken(theme.palette.grey[800], 0.1),
    },
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
  },
  openMenuTypography: {
    color: theme.palette.common.white,
  },
  subMenuHeader: (props: { isDisabled: boolean }) => ({
    color: theme.palette.grey[props.isDisabled ? 300 : 800],
    cursor: "default",
  }),
}));

@observer
export class MenuButton extends React.Component<ConfigProperties, State> {
  private readonly componentId: string;
  private readonly menuId: string;

  public static renderFlatMenu(props: {
    children: React.ReactNode;
    disabled: boolean;
    header: string;
    iconName: string | undefined;
    id: string;
    indent: number;
    otherProps?: object;
  }) {
    const classes = menuStyles({ isDisabled: props.disabled });
    const labelId = `${props.id}-label`;

    return (
      <React.Fragment>
        <MenuItemDisplay
          aria-label={`${props.header}: ${Localization.getBuiltInMessage(
            "Toolbar.menuSection"
          )}`}
          disabled={props.disabled}
          indent={props.indent}
          {...props.otherProps}
        >
          <Typography
            aria-hidden={true}
            classes={{ root: classes.subMenuHeader }}
            ellipsis
            id={labelId}
            variant="body2"
          >
            {props.iconName ? (
              <Icon
                fixedWidth
                icon={props.iconName}
                style={{ marginRight: ".4em" }}
              />
            ) : null}
            {props.header}
          </Typography>
        </MenuItemDisplay>
        {!props.disabled ? props.children : null}
      </React.Fragment>
    );
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;

    const configProps = (config as unknown) as ConfigProperties;
    const runtimeProps = runtime as RuntimeProperties;
    const propagated = (configProps.propagated as unknown) as ToolbarOverflowChild & {
      indent?: number;
    } & MenuChild;

    const indent = propagated?.indent ? propagated.indent : 0;
    const gridSize = Sys.settings.baselineGridSize;
    const isDisabled: boolean =
      runtimeProps.accessLevel === AccessLevel.disabled;

    function onItemClicked(): void {
      propagated.onItemClicked!();
    }

    return (
      <MenuButton.renderFlatMenu
        disabled={isDisabled}
        header={runtimeProps.label}
        iconName={configProps.iconName}
        indent={indent}
        id={`menu-${config.dataId}-${config.name}`}
        otherProps={otherProps}
      >
        {configProps.menuPane.props.menuItems.map((item) => (
          <MenuItem
            key={item.props.name}
            child={item}
            // Even hidden items must be disabled for the MenuList to ignore
            disabled={!MenuItem.isActionable(item.props)}
            propagated={{
              ...propagated,
              indent: indent + gridSize * 6,
              onItemClicked,
            }}
          />
        ))}
      </MenuButton.renderFlatMenu>
    );
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.componentId = `menu-button-${Sys.nextId}`;
    this.menuId = `${this.componentId}-menu`;

    this.state = {};
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        buttonElement: prevState.buttonElement ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ buttonElement: undefined });
  };

  private onItemClicked = (): void => {
    this.setState({ buttonElement: undefined });
  };

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    let ariaLabel: string | undefined = undefined;
    let iconName: string | undefined = this.props.iconName;
    let endIcon: string | undefined = "fas fa-caret-down";

    if (runtimeProperties.label === null) {
      ariaLabel = Localization.getBuiltInMessage("menu");
      iconName = "fas fa-caret-down";
      endIcon = undefined;
    }

    const isIconOnly = !runtimeProperties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("Button.customActionLabel")
      : runtimeProperties.label;

    return (
      <React.Fragment>
        <ApiButton
          alternateText={ariaLabel}
          aria-controls={this.menuId}
          aria-expanded={!!this.state.buttonElement}
          aria-haspopup={true}
          buttonColor="Default"
          dataId={this.props.dataId}
          disabledHelpText=""
          id={this.componentId}
          label={label}
          endIcon={endIcon}
          iconName={iconName}
          isIconOnly={isIconOnly}
          name={this.props.name}
          onClick={this.onClick}
          renderAsLink={this.props.renderAsLink}
          size={this.props.size}
          tabIndex={this.props.propagated?.hasParentToolbar ? -1 : 0}
        />
        <Menu
          anchorEl={this.state.buttonElement}
          labelId={this.componentId}
          menuId={this.menuId}
          offset={runtimeProperties.label ? undefined : 8}
          onClose={this.onCloseMenu}
          variant="menu"
        >
          {this.props.menuPane.props.menuItems.map((item) => (
            <MenuItem
              key={item.props.name}
              child={item}
              // Even hidden items must be disabled for the MenuList to ignore
              disabled={!MenuItem.isActionable(item.props)}
              propagated={{
                ...this.props.propagated,
                onItemClicked: this.onItemClicked,
              }}
            />
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withTheme(MenuButton);
