import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import Button from "../../coreui/Button";
import Dialog, { BreakPointColumn } from "../../coreui/Dialog";
import DialogActions from "../../coreui/DialogActions";
import DialogContent from "../../coreui/DialogContent";
import RequestsStore from "../../stores/RequestsStore";

interface ConfigProperties {
  children: React.ReactNode;
  labelledById: string;
  onClose: () => void;
  onExited?: () => void;
  onOpen: (parentRowKey: string) => Promise<BreakPointColumn[]>;
  parentRowKey?: string;
}

interface State {
  breakPointColumns?: BreakPointColumn[];
  isDialogOpen?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      "& > div": {
        [theme.breakpoints.only("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  });

@observer
export class TableDisplayDialog extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private isDialogClosing: boolean = false;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = { isDialogOpen: false };
  }

  private onClose = () => {
    if (this.isDialogClosing) {
      return;
    }
    this.isDialogClosing = true;

    this.setState({ isDialogOpen: false });
    this.props.onClose();
  };

  private onEntered = (node: HTMLElement, isAppearing: boolean) => {
    FocusManager.grabFocusForChild(node, FocusManager.selectors.focusable);
  };

  private openDialog = () => {
    RequestsStore.instance.processingStarted();
    this.props
      .onOpen(this.props.parentRowKey!)
      .then((breakPointColumns: BreakPointColumn[]) => {
        this.setState({
          breakPointColumns,
          isDialogOpen: true,
        });

        this.isDialogClosing = false;
      })
      .catch(() => this.props.onClose())
      .finally(() => RequestsStore.instance.processingStopped());
  };

  public componentDidUpdate(prevProps: ConfigProperties): void {
    if (
      !this.state.isDialogOpen &&
      this.props.parentRowKey &&
      this.props.parentRowKey !== prevProps.parentRowKey
    ) {
      this.openDialog();
    }
  }

  public render(): React.ReactNode {
    return (
      <Dialog
        aria-labelledby={this.props.labelledById}
        breakPointColumns={this.state.breakPointColumns}
        disableBackdropClick={true}
        open={this.state.isDialogOpen! && !!this.props.parentRowKey}
        onClose={this.onClose}
        onEntered={this.onEntered}
        onExited={this.props.onExited}
      >
        <DialogContent classes={{ root: this.props.classes.dialogContent }}>
          {this.props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>
            {Localization.getBuiltInMessage("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TableDisplayDialog);
