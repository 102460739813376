import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { FilterChangedEvent, GridApi } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import { TableChildProps } from "../coreui/Table";
import Typography from "../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import { CheckBoxColumn } from "./Columns/CheckBoxColumn";
import { ComponentTypeDisplayColumn } from "./Columns/ComponentTypeDisplayColumn";
import { DataImageDisplayColumn } from "./Columns/DataImageDisplayColumn";
import { DataLinkColumn } from "./Columns/DataLinkColumn";
import { DateEditColumn } from "./Columns/DateEditColumn";
import { DomainComboBoxColumnDisplay } from "./Columns/DomainComboBoxColumnDisplay";
import { GridColumnConfigProperties } from "./Columns/GridColumn";
import { IconDisplayColumn } from "./Columns/IconDisplayColumn";
import { ManualLinkColumn } from "./Columns/ManualLinkColumn";
import { NumericEditColumn } from "./Columns/NumericEditColumn";
import { RelationshipComboBoxColumnDisplay } from "./Columns/RelationshipComboBoxColumnDisplay";
import { TextColumn } from "./Columns/TextColumn";

interface ConfigProperties {
  columnWidgetName: string;
  dataId: string;
  functionName: FunctionName;
  label: string;
  name: string;
  propagated: TableChildProps;
}

export type FunctionName = "Average" | "Count" | "Maximum" | "Minimum" | "Sum";

interface State {
  visibleRowKeys?: string[];
}

const styles = (theme: Theme) => createStyles({});

@observer
export class TableSummary extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private readonly columnConfigProperties: GridColumnConfigProperties;
  private readonly gridApi: GridApi;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = {};

    for (const column of this.props.propagated.parentTable.columns) {
      const gridColumn = column as GridColumnConfigProperties;
      if (gridColumn.name === this.props.columnWidgetName) {
        this.columnConfigProperties = gridColumn;
        break;
      }
    }

    if (!this.columnConfigProperties) {
      throw new Error(`Column ${this.props.columnWidgetName} not found`);
    }

    this.gridApi = props.propagated.parentTable.getApi();

    this.gridApi.addEventListener("filterChanged", this.onGridFilterChanged);
  }

  private onGridFilterChanged = (event: FilterChangedEvent) => {
    if (!event.api.isAnyFilterPresent()) {
      this.setState({ visibleRowKeys: undefined });

      return;
    }

    const visibleRowKeys: string[] = [];
    event.api.forEachNodeAfterFilter((row, index) => {
      visibleRowKeys.push(row.id!);
    });

    this.setState({ visibleRowKeys });
  };

  public componentWillUnmount() {
    this.gridApi.removeEventListener("filterChanged", this.onGridFilterChanged);
  }

  public render() {
    const contentDataId: string = this.props.propagated.parentTable.configProps
      .contentDataId;

    const parentGridRows: PaneRow[] = PaneDataStore.getPaneCollection(
      contentDataId
    );

    const runtimeData: RuntimeWidget[] = [];
    for (const row of parentGridRows) {
      const rowKey: string = row.getPrimaryKey();
      if (
        !this.state.visibleRowKeys ||
        this.state.visibleRowKeys.some((k) => k === rowKey)
      ) {
        runtimeData.push(row.getWidget(this.props.columnWidgetName));
      }
    }

    let getSummaryValue: (
      runtimeData: RuntimeWidget[],
      configProperties: object,
      functionName: FunctionName
    ) => string | null;

    const widgetType: string = this.columnConfigProperties.widgetType;
    switch (widgetType) {
      case "CheckBoxColumn":
        getSummaryValue = CheckBoxColumn.getSummaryValue;
        break;
      case "ComponentTypeDisplayColumn":
        getSummaryValue = ComponentTypeDisplayColumn.getSummaryValue;
        break;
      case "DataImageDisplayColumn":
        getSummaryValue = DataImageDisplayColumn.getSummaryValue;
        break;
      case "DataLinkColumn":
        getSummaryValue = DataLinkColumn.getSummaryValue;
        break;
      case "DateEditColumn":
        getSummaryValue = DateEditColumn.getSummaryValue;
        break;
      case "DomainComboBoxColumn":
        getSummaryValue = DomainComboBoxColumnDisplay.getSummaryValue;
        break;
      case "IconDisplayColumn":
        getSummaryValue = IconDisplayColumn.getSummaryValue;
        break;
      case "ManualLinkColumn":
        getSummaryValue = ManualLinkColumn.getSummaryValue;
        break;
      case "MLTextEditColumn":
        getSummaryValue = TextColumn.getSummaryValue;
        break;
      case "NumericEditColumn":
        getSummaryValue = NumericEditColumn.getSummaryValue;
        break;
      case "RelationshipComboBoxColumn":
        getSummaryValue = RelationshipComboBoxColumnDisplay.getSummaryValue;
        break;
      case "SLTextEditColumn":
        getSummaryValue = TextColumn.getSummaryValue;
        break;
      case "TextDisplayColumn":
        getSummaryValue = TextColumn.getSummaryValue;
        break;
      default:
        throw new Error(`Unknown summary widget type ${widgetType}`);
    }

    const value: string | null = getSummaryValue(
      runtimeData,
      this.columnConfigProperties.widgetProps,
      this.props.functionName
    );

    const summaryText = `${this.props.label}: ${value === null ? "" : value}`;
    return <Typography style={{ fontSize: 14 }}>{summaryText}</Typography>;
  }
}

export default withStyles(styles)(TableSummary);
