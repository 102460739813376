import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { CustomTheme } from "../muiTheme";

interface Props {
  visible: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  "@keyframes ripple": {
    from: {
      transform: "scale(1)",
    },
    to: {
      transform: "scale(0.92)",
    },
  },
  "@keyframes rippleEnter": {
    from: {
      opacity: "0.1",
      transform: "scale(0)",
    },
    to: {
      opacity: "0.3",
      transform: "scale(1)",
    },
  },
  ripple: {
    animation: "$rippleEnter 200ms, $ripple 1250ms alternate",
    animationDelay: "0ms, 200ms",
    animationIterationCount: "1, infinite",
    animationTimingFunction: "ease-in-out",
    backgroundColor: theme.palette.grey[800],
    borderRadius: "50%",
    bottom: 0,
    left: 0,
    opacity: 0.3,
    overflow: "visible",
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export default function FocusRipple(props: Props): JSX.Element | null {
  const classes = useStyles();

  return props.visible ? <span className={classes.ripple} /> : null;
}
