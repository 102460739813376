import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";
import { AddOnHost, AddOnHostProps } from "../config/AddOnHost";
import { CustomTheme } from "../muiTheme";

interface Props {
  addOnHosts?: AddOnHostProps[];
  children: React.ReactNode;
  lg: number;
  md: number;
  sm: number;
  style?: React.CSSProperties | null;
  xl: number;
  xs: number;
}

const styles = (theme: CustomTheme) => {
  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    for (let size = 1; size <= 12; size++) {
      visibilityStyles[`griditem-${breakPoint}-${size}`] = {
        [theme.breakpoints.up(breakPoint)]: {
          gridColumnEnd: `span ${size}`,
        },
      };
    }

    // Allow 0 to indicate hidden.
    visibilityStyles[`griditem-${breakPoint}-0`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "none !important",
      },
    };
  }

  const result = {
    container: {
      minWidth: 0,
    },
    ...visibilityStyles,
  };

  return createStyles(result);
};

export class GridItem extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private static onResize: ResizeObserver | ResizeObserverPolyfill;

  public item: React.RefObject<HTMLDivElement>;

  static initialize() {
    if (window["ResizeObserver"]) {
      GridItem.onResize = new ResizeObserver(() => {
        AddOnHost.onGridItemResize();
      });
    } else {
      GridItem.onResize = new ResizeObserverPolyfill(() => {
        AddOnHost.onGridItemResize();
      });
    }
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);
    this.item = React.createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    // FUTURE
    // This is only required if an add-on exists on the page, however, this is
    // somewhat difficult to tell because the add-on may get mounted after some
    // grid items get mounted, so only adding the observer based on if an add-on
    // was mounted means that at least one GridItem will not be observed by the
    // ResizeObserver (at least the GridItem that contains the add-on).
    //
    // Probably the simplest way to work around this would be to send a flag to
    // the layout config from the Presentation Server based on if any add-ons
    // are configured on the layout. Then that flag can be passed along via the
    // propagated props and picked up here. This was not done at the moment to
    // contain scope and because we do not yet know if the optimization with the
    // ResizeObserver is necessary. i.e. does this approach as-is represent a
    // performance problem?
    GridItem.onResize.observe(this.item.current!);
  }

  public render() {
    const classes: string[] = [this.props.classes.container];

    classes.push(this.props.classes[`griditem-xs-${this.props.xs}`]);
    classes.push(this.props.classes[`griditem-sm-${this.props.sm}`]);
    classes.push(this.props.classes[`griditem-md-${this.props.md}`]);
    classes.push(this.props.classes[`griditem-lg-${this.props.lg}`]);
    classes.push(this.props.classes[`griditem-xl-${this.props.xl}`]);

    return (
      <div
        className={classes.join(" ")}
        ref={this.item}
        style={this.props.style ? this.props.style : undefined}
      >
        {this.props.children}
        {this.props.addOnHosts
          ? this.props.addOnHosts.map((addOnHost) => (
              <AddOnHost key={addOnHost.hostId} {...addOnHost} />
            ))
          : null}
      </div>
    );
  }
}

export default withStyles(styles)(GridItem);
