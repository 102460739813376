import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import { CheckBoxGroup } from "../coreui/CheckBoxGroup";
import { CheckBoxGroupOption } from "../coreui/CheckBoxGroupOption";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel, ValueByBreakpoint } from "./Api";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  name: string;
  options: CheckBoxGroupOption[];
  orientationByBreakPoint: ValueByBreakpoint<"horizontal" | "vertical">;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

@observer
export class DomainCheckBoxCriteria extends React.Component<
  ConfigProperties & WithWidth
> {
  public static readonly widgetTypeId: number = 90;

  public static clear(widgetName: string, row: PaneRow): void {
    row.setProperty(widgetName, []);
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget = row.getWidgetT<string[], RuntimeProperties>(widgetName);
    return widget.value.length > 0;
  }

  private onOptionChange = (
    option: CheckBoxGroupOption,
    checked: boolean
  ): void => {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return;
    }

    const oldValues = Presentation.getValue(this.props);
    const values = [...(oldValues as string[])];
    if (checked) {
      values.push(option.value);
    } else {
      const index: number = values.indexOf(option.value);
      values.splice(index, 1);
    }

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);

    Presentation.setValue(this.props, values);
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string[], RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <CheckBoxGroup
        getErrors={() => widget.properties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onOptionChange={this.onOptionChange}
        options={this.props.options}
        orientation={this.props.orientationByBreakPoint[this.props.width]}
        selectedValues={widget.value}
      />
    );
  }
}

export default withWidth()(DomainCheckBoxCriteria);
