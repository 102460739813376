import * as muiFormHelperText from "@material-ui/core/FormHelperText";
import * as React from "react";

interface Props extends muiFormHelperText.FormHelperTextProps {}

export class FormHelperText extends React.PureComponent<Props> {
  public render() {
    return <muiFormHelperText.default {...this.props} component="div" />;
  }
}

export default FormHelperText;
