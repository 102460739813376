import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Hidden from "../../coreui/Hidden";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import { AccessLevel } from "../Api";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { WizardControl as WizardControlBase } from "../WizardControl";
import { StepProps } from "../WizardStepPane";

interface Props extends ActionButtonProps {}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class WizardNextButton extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private static onClick() {
    WizardControlBase.gotoNextStep();
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        WizardNextButton.onClick();
        configProps.propagated.onItemClicked!();
      }
    };

    return (
      <MenuItem
        disabled={props.runtime.accessLevel === AccessLevel.disabled}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    );
  }

  private onClick = () => {
    WizardNextButton.onClick();
  };

  public render() {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;
    const widgetProperties = WizardControlBase.getWidgetProperties();

    if (!widgetProperties) {
      return null;
    }

    const count: number = WizardControlBase.getStepCount();
    const last: number = widgetProperties.steps.length - 1;
    const selectedStep: number = widgetProperties.steps.findIndex(
      (s) => s.paneUseKey === widgetProperties.selectedPaneUseKey
    )!;
    const step: number = WizardControlBase.getStepNumber(selectedStep);
    const steps: StepProps[] | null = WizardControlBase.getSteps();
    let result: React.ReactNode = null;

    if (selectedStep < last) {
      let label: string = runtimeProperties.label;
      let iconName: string = "fas fa-arrow-right";

      if (step === count && steps) {
        label = steps[last].label;
        iconName = "fas fa-check";
      }

      result = (
        <React.Fragment>
          <Hidden xsDown>
            <ApiButton
              alternateText={runtimeProperties.alternateText}
              buttonColor={this.props.buttonColor}
              endIcon={iconName}
              dataId={this.props.dataId}
              disabledHelpText={this.props.disabledHelpText}
              isIconOnly={false}
              label={label}
              name={this.props.name}
              onClick={this.onClick}
              size={this.props.size}
            />
          </Hidden>
          <Hidden smUp>
            <ApiButton
              alternateText={runtimeProperties.alternateText}
              buttonColor={this.props.buttonColor}
              dataId={this.props.dataId}
              disabledHelpText={this.props.disabledHelpText}
              // Always use an arrow icon for the XS break-point
              // because there is no label to indicate that the
              // button will navigate to the finish step.
              iconName="fas fa-arrow-right"
              isIconOnly={true}
              label={label}
              name={this.props.name}
              onClick={this.onClick}
              size={this.props.size}
            />
          </Hidden>
        </React.Fragment>
      );
    }

    return result;
  }
}

export default withStyles(styles)(WizardNextButton);
