import { TextFieldProps as muiTextFieldProps } from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Logging from "../core/Logging";
import Routing from "../core/Routing";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel, { ITrackable } from "../core/TrackableModel";
import muiTheme from "../muiTheme";
import ActionButton from "../mustangui/ActionButton";
import AddressSearch from "../mustangui/AddressSearch";
import AddressSearchCriteria from "../mustangui/AddressSearchCriteria";
import AdvancedSearch from "../mustangui/AdvancedSearch";
import BooleanCriteria from "../mustangui/BooleanCriteria";
import BrandingImageDisplay from "../mustangui/BrandingImageDisplay";
import CaptchaControl from "../mustangui/CaptchaControl";
import CardControl from "../mustangui/CardControl";
import Checkbox, { Checkbox as CheckboxBase } from "../mustangui/Checkbox";
import CheckBoxGroup from "../mustangui/CheckBoxGroup";
import ComplexGridControl from "../mustangui/ComplexGridControl";
import ComplexResultsGridControl from "../mustangui/ComplexResultsGridControl";
import ComponentTest from "../mustangui/ComponentTest";
import ComponentTypeDisplay from "../mustangui/ComponentTypeDisplay";
import DashboardCriteria from "../mustangui/DashboardCriteria";
import DashboardGridControl from "../mustangui/DashboardGridControl";
import DataImageDisplay from "../mustangui/DataImageDisplay";
import DataLink from "../mustangui/DataLink";
import DateEdit from "../mustangui/DateEdit";
import DateRangeCriteria from "../mustangui/DateRangeCriteria";
import DividerDisplay from "../mustangui/DividerDisplay";
import DocumentEdit from "../mustangui/DocumentEdit";
import DomainCheckBoxCriteria from "../mustangui/DomainCheckBoxCriteria";
import DomainComboBox from "../mustangui/DomainComboBox";
import DomainComboBoxCriteria from "../mustangui/DomainComboBoxCriteria";
import EmbeddedAddOn from "../mustangui/EmbeddedAddOn";
import Freeflow from "../mustangui/Freeflow";
import Grid from "../mustangui/Grid";
import GridFilter from "../mustangui/GridFilter";
import GridItem from "../mustangui/GridItem";
import GridNewRowChip from "../mustangui/GridNewRowChip";
import GridPager from "../mustangui/GridPager";
import GridPrint from "../mustangui/GridPrint";
import GridRelatedEditButton from "../mustangui/GridRelatedEditButton";
import GridSelectedCount from "../mustangui/GridSelectedCount";
import GroupHeading from "../mustangui/GroupHeading";
import IconDisplay from "../mustangui/IconDisplay";
import IconDisplayVerticalLayout from "../mustangui/IconDisplayVerticalLayout";
import LanguageSelect from "../mustangui/LanguageSelect";
import ManualLink from "../mustangui/ManualLink";
import Media from "../mustangui/Media";
import MenuButton from "../mustangui/MenuButton";
import MenuItem from "../mustangui/MenuItem";
import MLTextEdit from "../mustangui/MLTextEdit";
import NumericEdit from "../mustangui/NumericEdit";
import Panel from "../mustangui/Panel";
import PresentationButton from "../mustangui/PresentationButton";
import ProjectGrid from "../mustangui/ProjectGrid";
import ProjectGridContainer from "../mustangui/ProjectGridContainer";
import ProjectGridControl from "../mustangui/ProjectGridControl";
import RadioSelect from "../mustangui/RadioSelect";
import RelationshipComboBox from "../mustangui/RelationshipComboBox";
import ResponsiveGrid from "../mustangui/ResponsiveGrid";
import ResultsCountDisplay from "../mustangui/ResultsCountDisplay";
import SelectControl from "../mustangui/SelectControl";
import SelectControlSelectedGrid from "../mustangui/SelectControlSelectedGrid";
import SelectDialogResultsGrid from "../mustangui/SelectDialogResultsGrid";
import SimpleGridControl from "../mustangui/SimpleGridControl";
import SimpleResultsGridControl from "../mustangui/SimpleResultsGridControl";
import SiteCriteria from "../mustangui/SiteCriteria";
import SiteCriteriaLink from "../mustangui/SiteCriteriaLink";
import SLTextEdit from "../mustangui/SLTextEdit";
import SubPaneControl from "../mustangui/SubPaneControl";
import TabControl from "../mustangui/TabControl";
import TableExport from "../mustangui/TableExport";
import TablePrint from "../mustangui/TablePrint";
import TableSort from "../mustangui/TableSort";
import TableSummary from "../mustangui/TableSummary";
import TableSummarySection from "../mustangui/TableSummarySection";
import TextCriteria from "../mustangui/TextCriteria";
import TextDisplay from "../mustangui/TextDisplay";
import ThumbnailDisplay from "../mustangui/ThumbnailDisplay";
import Toolbar from "../mustangui/Toolbar";
import ToolbarContainerGroup from "../mustangui/ToolbarContainerGroup";
import ToolbarContainerItem from "../mustangui/ToolbarContainerItem";
import ToolbarContainerOverflowSection from "../mustangui/ToolbarContainerOverflowSection";
import ToolbarContainerSection from "../mustangui/ToolbarContainerSection";
import WizardControl from "../mustangui/WizardControl";
import WizardStepNumberDisplay from "../mustangui/WizardStepNumberDisplay";
import WizardStepsDisplay from "../mustangui/WizardStepsDisplay";
import ErrorsStore from "../stores/ErrorsStore";
import ThemeWrapper from "../templates/components/ThemeWrapper";
import Button from "./Button";
import Chip from "./Chip";
import ErrorTooltip from "./ErrorTooltip";
import FormControl from "./FormControl";
import FormControlLabel from "./FormControlLabel";
import FormData from "./FormData";
import Grow from "./Grow";
import Hidden from "./Hidden";
import Icon from "./Icon";
import IconButton from "./IconButton";
import InputAdornment from "./InputAdornment";
import Paper from "./Paper";
import TextField from "./TextField";
import Tooltip from "./Tooltip";
import Typography from "./Typography";

export type Colors =
  | "action"
  | "default"
  | "disabled"
  | "error"
  | "inherit"
  | "primary"
  | "secondary";

export type CreatedReactElement = React.DetailedReactHTMLElement<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface TextFieldProps {
  autoFocus?: boolean;
  dataCase?: "lower" | "UPPER" | "Any";
  dataId?: string;
  endIcon?: string;
  endIconColor?: Colors;
  icon?: string;
  iconColor?: Colors;
  label?: string;
  name?: string;
  type?: string;
}

export default class Presentation {
  private static canvas: HTMLCanvasElement | null = null;
  public static currentComponent: React.Component | undefined;
  public static currentPresentationId: number | undefined;
  public static objectDefDescription: string | null = null;
  public static objectTitle: string | null = null;

  public static create(
    config: object | undefined,
    propagatedProps: {} | null = null
  ): CreatedReactElement | null {
    if (!config) {
      return null;
    }

    let props: object | undefined = undefined;
    if ("props" in config) {
      props = { ...config["props"] };
      if (propagatedProps) {
        props!["propagated"] = propagatedProps;
      }
    } else if (propagatedProps) {
      props = { propagated: propagatedProps };
    }

    let result: CreatedReactElement | null = null;
    if ("children" in config) {
      // A list of child configurations or components.
      if (config["children"] instanceof Array) {
        const children: (CreatedReactElement | string | null)[] = [];

        (config["children"] as object[]).forEach((child) => {
          if (typeof child === "string") {
            children.push(child);
          } else {
            children.push(Presentation.create(child, propagatedProps));
          }
        });

        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          ...children
        );
      }
      // A single child configuration.
      else if (config["children"] instanceof Object) {
        const child = Presentation.create(config["children"], propagatedProps);

        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          child
        );
      }
      // A literal child such as text for a button.
      else {
        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          config["children"]
        );
      }
    }
    // Configuration without children.
    else {
      result = React.createElement(
        Presentation.getComponent(config["type"]),
        props
      );
    }

    return result;
  }

  // Wrap the specified config in the default theme.
  public static createWithTheme(
    config: object,
    baseForegroundColor: "grey" | "white",
    isHeaderOrFooter: boolean
  ): React.ReactElement {
    const isElement = React.isValidElement(config);
    const children = isElement ? config : Presentation.create(config);

    return (
      <ThemeProvider theme={muiTheme(baseForegroundColor, isHeaderOrFooter)}>
        {children}
      </ThemeProvider>
    );
  }

  // Return the react component identified by the specified type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getComponent(type: string | React.ReactNode): any {
    if (typeof type !== "string") {
      return type;
    }

    let result: string | React.ReactNode = null;

    switch (type) {
      case "ActionButton":
        result = ActionButton;
        break;
      case "AddressSearch":
        result = AddressSearch;
        break;
      case "AddressSearchCriteria":
        result = AddressSearchCriteria;
        break;
      case "AdvancedSearch":
        result = AdvancedSearch;
        break;
      case "BooleanCriteria":
        result = BooleanCriteria;
        break;
      case "BrandingImageDisplay":
        result = BrandingImageDisplay;
        break;
      case "Button":
        result = Button;
        break;
      case "CaptchaControl":
        result = CaptchaControl;
        break;
      case "CardControl":
        result = CardControl;
        break;
      case "CheckBox":
        result = Checkbox;
        break;
      case "CheckBoxGroup":
        result = CheckBoxGroup;
        break;
      case "Chip":
        result = Chip;
        break;
      case "ComplexGridControl":
        result = ComplexGridControl;
        break;
      case "ComplexResultsGridControl":
        result = ComplexResultsGridControl;
        break;
      case "ComponentTest":
        result = ComponentTest;
        break;
      case "ComponentTypeDisplay":
        result = ComponentTypeDisplay;
        break;
      case "DashboardCriteria":
        result = DashboardCriteria;
        break;
      case "DashboardGridControl":
        result = DashboardGridControl;
        break;
      case "DataImageDisplay":
        result = DataImageDisplay;
        break;
      case "DataLink":
        result = DataLink;
        break;
      case "DateEdit":
        result = DateEdit;
        break;
      case "DateRangeCriteria":
        result = DateRangeCriteria;
        break;
      case "div":
        result = "div";
        break;
      case "DividerDisplay":
        result = DividerDisplay;
        break;
      case "DocumentEdit":
        result = DocumentEdit;
        break;
      case "DomainCheckBoxCriteria":
        result = DomainCheckBoxCriteria;
        break;
      case "DomainComboBox":
        result = DomainComboBox;
        break;
      case "DomainComboBoxCriteria":
        result = DomainComboBoxCriteria;
        break;
      case "EmbeddedAddOn":
        result = EmbeddedAddOn;
        break;
      case "ErrorTooltip":
        result = ErrorTooltip;
        break;
      case "FormControl":
        result = FormControl;
        break;
      case "FormControlLabel":
        result = FormControlLabel;
        break;
      case "FormData":
        result = FormData;
        break;
      case "Fragment":
        result = React.Fragment;
        break;
      case "Freeflow":
        result = Freeflow;
        break;
      case "Grid":
        result = Grid;
        break;
      case "GridFilter":
        result = GridFilter;
        break;
      case "GridItem":
        result = GridItem;
        break;
      case "GridNewRowChip":
        result = GridNewRowChip;
        break;
      case "GridPager":
        result = GridPager;
        break;
      case "GridPrint":
        result = GridPrint;
        break;
      case "GridRelatedEditButton":
        result = GridRelatedEditButton;
        break;
      case "GridSelectedCount":
        result = GridSelectedCount;
        break;
      case "Grow":
        result = Grow;
        break;
      case "GroupHeading":
        result = GroupHeading;
        break;
      case "Hidden":
        result = Hidden;
        break;
      case "Icon":
        result = Icon;
        break;
      case "IconDisplay":
        result = IconDisplay;
        break;
      case "IconDisplayVerticalLayout":
        result = IconDisplayVerticalLayout;
        break;
      case "IconButton":
        result = IconButton;
        break;
      case "InputAdornment":
        result = InputAdornment;
        break;
      case "LanguageSelect":
        result = LanguageSelect;
        break;
      case "ManualLink":
        result = ManualLink;
        break;
      case "Media":
        result = Media;
        break;
      case "MenuButton":
        result = MenuButton;
        break;
      case "MenuItem":
        result = MenuItem;
        break;
      case "MLTextEdit":
        result = MLTextEdit;
        break;
      case "NumericEdit":
        result = NumericEdit;
        break;
      case "Panel":
        result = Panel;
        break;
      case "Paper":
        result = Paper;
        break;
      case "PresentationButton":
        result = PresentationButton;
        break;
      case "ProjectGrid":
        result = ProjectGrid;
        break;
      case "ProjectGridContainer":
        result = ProjectGridContainer;
        break;
      case "ProjectGridControl":
        result = ProjectGridControl;
        break;
      case "RadioSelect":
        result = RadioSelect;
        break;
      case "RelationshipComboBox":
        result = RelationshipComboBox;
        break;
      case "ResponsiveGrid":
        result = ResponsiveGrid;
        break;
      case "ResultsCountDisplay":
        result = ResultsCountDisplay;
        break;
      case "SelectDialogResultsGrid":
        result = SelectDialogResultsGrid;
        break;
      case "SelectControl":
        result = SelectControl;
        break;
      case "SelectControlSelectedGrid":
        result = SelectControlSelectedGrid;
        break;
      case "SimpleGridControl":
        result = SimpleGridControl;
        break;
      case "SimpleResultsGridControl":
        result = SimpleResultsGridControl;
        break;
      case "SiteCriteria":
        result = SiteCriteria;
        break;
      case "SiteCriteriaLink":
        result = SiteCriteriaLink;
        break;
      case "SLTextEdit":
        result = SLTextEdit;
        break;
      case "SubPaneControl":
        result = SubPaneControl;
        break;
      case "TableSummarySection":
        result = TableSummarySection;
        break;
      case "TabControl":
        result = TabControl;
        break;
      case "TableExport":
        result = TableExport;
        break;
      case "TablePrint":
        result = TablePrint;
        break;
      case "TableSort":
        result = TableSort;
        break;
      case "TableSummary":
        result = TableSummary;
        break;
      case "TextCriteria":
        result = TextCriteria;
        break;
      case "TextDisplay":
        result = TextDisplay;
        break;
      case "TextField":
        result = TextField;
        break;
      case "ThumbnailDisplay":
        result = ThumbnailDisplay;
        break;
      case "Toolbar":
        result = Toolbar;
        break;
      case "ToolbarContainerGroup":
        result = ToolbarContainerGroup;
        break;
      case "ToolbarContainerItem":
        result = ToolbarContainerItem;
        break;
      case "ToolbarContainerOverflowSection":
        result = ToolbarContainerOverflowSection;
        break;
      case "ToolbarContainerSection":
        result = ToolbarContainerSection;
        break;
      case "Tooltip":
        result = Tooltip;
        break;
      case "Typography":
        result = Typography;
        break;
      case "WizardControl":
        result = WizardControl;
        break;
      case "WizardStepNumberDisplay":
        result = WizardStepNumberDisplay;
        break;
      case "WizardStepsDisplay":
        result = WizardStepsDisplay;
        break;
      default:
        throw new Error(`Unknown widget type ${type}`);
    }

    return result;
  }

  // Get the specified observable.
  public static getObservable(props: object): object | null {
    if (props["node"]) {
      return props["node"]["data"];
    }

    const trackable: ITrackable | undefined = Presentation.getTrackable(props);

    if (!trackable) {
      return null;
    }

    if (trackable instanceof TrackableCollection) {
      const collection = Presentation.getObservableCollection(
        props["dataId"],
        trackable
      );

      if (props["propagated"] && props["propagated"]["rowKey"]) {
        // FUTURE
        // This code is safe because of the way the row key is
        // structured - in that the section passed down through the
        // propagated props will always be a sub-set of any row-keys on
        // a sub-pane in a card.
        //
        // The problem is that the browser is now depending on the
        // structure of the row key (something defined by the
        // AppServer). If at all possible, we should try to devise a
        // way in which everything in the layout knows exactly which
        // row key it should use. At that point, we can compare row
        // keys with === and the browser will therefore be unaware of
        // the row key structure.
        return collection.find((o) =>
          o["rowKey"].startsWith(props["propagated"]["rowKey"])
        )!;
      }

      if (collection.length > 1) {
        throw new Error(
          "TrackableCollection has more than one item, " +
            "a rowKey is required"
        );
      }

      return collection[0];
    }

    return trackable;
  }

  // Obsolete, replaced by PaneDataStore.getPaneCollection
  public static getObservableCollection(
    dataId: string,
    trackableCollection?: TrackableCollection
  ): TrackableModel[] {
    let result: TrackableModel[];
    let collection: TrackableCollection | undefined = trackableCollection;

    if (!collection) {
      if (TrackableModel.models.has(dataId)) {
        collection = TrackableModel.models.get(dataId) as TrackableCollection;
      }
    }

    if (collection) {
      if (collection.observableCollection) {
        result = collection.observableCollection;
      } else {
        result = collection;
      }
    } else {
      result = [];
    }

    return result;
  }

  // Get the specified trackable.
  public static getTrackable(props: object): ITrackable | undefined {
    let result: ITrackable | undefined = undefined;

    if (
      props &&
      props["dataId"] &&
      TrackableModel.models.has(props["dataId"])
    ) {
      result = TrackableModel.models.get(props["dataId"]);
    }

    return result;
  }

  // Get the current value from the specified model property.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getValue(props: object, defaultValue: any = ""): any {
    const observable: object | null = Presentation.getObservable(props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = defaultValue;

    if (observable) {
      const row = observable as TrackableModel;
      result = row.getPropertyValue(props["name"]) || defaultValue;
    }

    return result;
  }

  public static measureText(
    text: string,
    font: string = "14px roboto"
  ): number {
    let result: number = 0;

    if (text) {
      Presentation.canvas =
        Presentation.canvas || document.createElement("canvas");

      const context = Presentation.canvas.getContext("2d");

      if (context) {
        context.font = font;

        const textMetrics = context.measureText(text);

        result = textMetrics.width;
      }
    }

    return result;
  }

  // Create and inject the specified configuration into the given
  // container element.
  public static render(
    config: object,
    container: string | HTMLElement | null = "root"
  ) {
    try {
      let injectContainer = container;
      let containerName: string = "root";
      if (typeof injectContainer === "string") {
        containerName = injectContainer;
        injectContainer = document.getElementById(injectContainer);
      }

      if (injectContainer && config) {
        ReactDOM.render(
          <ThemeWrapper config={config} containerName={containerName} />,
          injectContainer
        );
      }
    } catch (error) {
      if (process.env.NODE_ENV === "production") {
        const message: string =
          error instanceof String ? error : error["message"];

        Routing.goToErrorPage(message);
      }

      Logging.error(error);
    }
  }

  // Establish two way binding on the specified component with the given props.
  public static setBinding(component: React.Component, props: object): void {
    if (component.props["dataId"] && component.props["name"]) {
      if (component instanceof CheckboxBase) {
        props["onChange"] = (
          event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          ErrorsStore.clearBusinessErrors(
            component.props["dataId"],
            component.props["name"]
          );
          Presentation.setValue(component.props, checked);
        };

        props["checked"] = Presentation.getValue(component.props, false);
      } else {
        props["onChange"] = (event: React.ChangeEvent<HTMLInputElement>) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let value: any;

          value = event.target.value;

          if ("dataCase" in component.props) {
            switch (component.props["dataCase"]) {
              case "lower":
                value = value.toLowerCase();
                break;
              case "UPPER":
                value = value.toUpperCase();
                break;
              case "Any":
                break;
              default:
                break;
            }
          }

          ErrorsStore.clearBusinessErrors(
            component.props["dataId"],
            component.props["name"]
          );
          Presentation.setValue(component.props, value);
        };

        props["value"] = Presentation.getValue(component.props);
      }
    }
  }

  // Set common field properties on the specified component with the given props.
  public static setFieldProperties(
    component: React.Component,
    muiProps: muiTextFieldProps
  ): void {
    const props: TextFieldProps = component.props as TextFieldProps;

    muiProps.autoFocus = props.autoFocus;
    muiProps.fullWidth = true;
    muiProps.label = props.label;
    muiProps.name = props.name;
    muiProps.type = props.type;

    if ("icon" in props) {
      if (!("InputProps" in props)) {
        muiProps.InputProps = {};
      }

      muiProps.InputProps!.startAdornment = (
        <InputAdornment
          position="start"
          icon={props.icon}
          iconColor={props.iconColor}
        />
      );
    }

    if ("endIcon" in props) {
      if (!("InputProps" in props)) {
        muiProps.InputProps = {};
      }

      muiProps.InputProps!.endAdornment = (
        <InputAdornment
          position="end"
          icon={props.endIcon}
          iconColor={props.endIconColor}
        />
      );
    }
  }

  // Set the specified model property to the given value.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static setValue(props: object, value: any): void {
    let trackable: ITrackable | undefined;
    let cleanValue = value;

    if (props["node"]) {
      trackable = props["node"]["data"];
    } else {
      trackable = Presentation.getTrackable(props);
    }

    if (!trackable) {
      return;
    }

    if (cleanValue === "") {
      cleanValue = null;
    }

    if (trackable instanceof TrackableCollection) {
      let model: TrackableModel;

      if (props["propagated"] && props["propagated"]["rowKey"]) {
        // FUTURE
        // This code is safe because of the way the row key is
        // structured - in that the section passed down through the
        // propagated props will always be a sub-set of any row-keys on
        // a sub-pane in a card.
        //
        // The problem is that the browser is now depending on the
        // structure of the row key (something defined by the
        // AppServer). If at all possible, we should try to devise a
        // way in which everything in the layout knows exactly which
        // row key it should use. At that point, we can compare row
        // keys with === and the browser will therefore be unaware of
        // the row key structure.
        model = trackable.find((o) =>
          o["rowKey"].startsWith(props["propagated"]["rowKey"])
        )!;
      } else {
        model = trackable[0];
      }

      const row = model as TrackableModel;
      row.setProperty(props["name"], cleanValue);
    } else {
      const row = trackable as TrackableModel;
      row.setProperty(props["name"], cleanValue);
    }
  }

  // Force the specified component to be removed from memory.
  public static unmountComponent(component: React.Component | undefined) {
    try {
      if (component) {
        const node = ReactDOM.findDOMNode(component);

        if (node && node.parentElement) {
          ReactDOM.unmountComponentAtNode(node.parentElement);
        }
      }
    } catch (exception) {
      Logging.error(exception);
    }
  }
}
