import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Autocomplete from "../coreui/Autocomplete";
import Icon from "../coreui/Icon";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import AddressSearchService from "../services/AddressSearchService";
import BaseService from "../services/BaseService";
import Api, { AccessLevel } from "./Api";

export interface Props {
  dataId: string;
  geocodeApiUrlFindAddressCandidates: string;
  geocodeApiUrlSuggest: string;
  helperText: string;
  label: string;
  name: string;
  noResultsMessage: string;
  propagated?: object;
}

interface Option {
  isCollection?: boolean;
  magicKey: string;
  text: string;
}

interface State {
  options: Option[];
  value: string;
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  category: string;
  countryCode: string;
  location: number[];
  searchExtent: number[];
  showAsMandatory: boolean;
}

@observer
export class AddressSearch extends React.PureComponent<Props, State> {
  private accessToken: string | null = null;

  public constructor(props: Props) {
    super(props);

    this.state = { options: [], value: "" };
  }

  private async getCandidate(option: Option): Promise<void> {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    const esriResponse = await AddressSearchService.findAddressCandidates(
      this.props.geocodeApiUrlFindAddressCandidates,
      runtimeProps.category,
      runtimeProps.location,
      option.magicKey,
      runtimeProps.searchExtent,
      option.text,
      runtimeProps.countryCode,
      this.accessToken
    );

    if (esriResponse.candidates) {
      await AddressSearchService.onSelect(
        row.rowKey,
        this.props.dataId,
        this.props.name,
        esriResponse.candidates[0]
      );
      Sys.announce(
        Localization.getBuiltInMessage("AddressSearch.succeeded", {
          label: this.props.label,
        }),
        true
      );
    } else if (esriResponse.error.code === 498) {
      await this.getToken();
      return this.getCandidate(option);
    }
  }

  private getSuggestions(value: string) {
    // FUTURE
    // Move the actual request to a static method in AddressSearchService, like
    // getCandidates(). It isn't done right now because the current work doesn't
    // touch anything in this method.
    const options: Option[] = [];
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;
    const queryArgs = {
      category: runtimeProperties.category,
      countryCode: runtimeProperties.countryCode,
      f: "json",
      location: runtimeProperties.location
        ? runtimeProperties.location.join(",")
        : "",
      maxSuggestions: 8,
      searchExtent: runtimeProperties.searchExtent
        ? runtimeProperties.searchExtent.join(",")
        : "",
      text: value,
      token: this.accessToken,
    };
    const serviceUrl: string = `${
      this.props.geocodeApiUrlSuggest
    }?${Sys.objectToQueryString(queryArgs)}`;

    this.setState({ value });

    BaseService.requestObject<object>(serviceUrl, {}, {}, {}, "POST", false)
      .then((response) => {
        if (response["suggestions"]) {
          for (const suggestion of response["suggestions"]) {
            if (!suggestion.isCollection) {
              options.push({
                magicKey: suggestion.magicKey,
                text: suggestion.text,
              });
            }
          }

          this.setState({ options });
        } else if (response["error"].code === 498) {
          this.setState({ options });
          // If the token is invalid get a new one.
          this.getToken().then(() => this.getSuggestions(value));
        }
      })
      .catch(() => this.setState({ options }));
  }

  private async getToken(): Promise<void> {
    const row = PaneRow.get(this.props.dataId)!;

    this.accessToken = await AddressSearchService.getToken(
      row.rowKey,
      this.props.dataId,
      this.props.name
    );
  }

  private onInputChange = (value: string, reason: string) => {
    if (reason === "input") {
      this.getSuggestions(value);
    } else {
      // The input is being set based on a selected value or because focus is
      // leaving the field. In either case, the input must be cleared because if
      // a value is selected the round trip script will set the data value and
      // if a value is not selected but focus is leaving the field, the criteria
      // entered in the field should not stick around as it is not "data" that
      // is saved with the rest of the form.
      this.setState({ options: [], value: "" });
    }
  };

  private onValueChange = (option: Option | null) => {
    if (option) {
      this.getCandidate(option);
    }
  };

  public render() {
    const { helperText, label, name, noResultsMessage } = this.props;
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.accessToken) {
      this.getToken();
    }

    return (
      <Autocomplete
        autoSelect
        filterOptions={(options: Option[]) => options}
        forcePopupIcon={false}
        getOptionLabel={(option: Option) => option.text}
        getOptionSelected={(option: Option, value: Option) =>
          option.text === value.text
        }
        helperText={helperText}
        icon="fas fa-search"
        inputValue={this.state.value}
        label={label}
        name={name}
        noOptionsText={noResultsMessage}
        onInputChange={this.onInputChange}
        onValueChange={this.onValueChange}
        options={this.state.options}
        renderOption={(option: Option) => {
          return (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                minHeight: 40,
                width: "100%",
              }}
            >
              <Icon
                icon="fas fa-map-marker-alt"
                style={{ marginRight: ".4em" }}
              />
              <Typography ellipsis>{option.text}</Typography>
            </div>
          );
        }}
        required={runtimeProperties.showAsMandatory}
      />
    );
  }
}

export default AddressSearch;
