import ButtonBase from "@material-ui/core/ButtonBase";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import DisabledHelpBadge from "../coreui/DisabledHelpBadge";
import Icon from "../coreui/Icon";
import Api, { AccessLevel, ValueByBreakpoint } from "./Api";

interface Props {
  alternateText?: string;
  "aria-controls"?: React.AriaAttributes["aria-controls"];
  "aria-expanded"?: React.AriaAttributes["aria-expanded"];
  "aria-haspopup"?: React.AriaAttributes["aria-haspopup"];
  buttonColor: "Danger" | "Dark" | "Default" | "Success";
  children?: null;
  dataId: string;
  disabled?: boolean;
  disabledHelpText: string;
  endIcon?: string;
  href?: string;
  iconName?: string;
  id?: string;
  isIconOnly: boolean;
  label: string;
  name: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  renderAsLink?: boolean;
  size:
    | "large"
    | "medium"
    | "small"
    | undefined
    | ValueByBreakpoint<"large" | "medium" | "small">;
  tabIndex?: number;
  target?: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  showDisabledHelp: boolean;
}

interface State {
  isDisabledHelpOpen: boolean;
  isFocusRippleVisible: boolean;
}

@observer
export class ApiButton extends React.Component<Props & WithWidth, State> {
  private readonly componentId: string;

  public constructor(configProperties: Props & WithWidth) {
    super(configProperties);

    this.componentId = `action-button-${Sys.nextId}`;

    this.state = { isDisabledHelpOpen: false, isFocusRippleVisible: false };
  }

  public render(): React.ReactNode {
    const componentId = this.props.id || this.componentId;
    const describedById = `${componentId}-described-by`;

    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const isDisabled: boolean =
      runtimeProperties.accessLevel === AccessLevel.disabled ||
      (this.props.disabled !== undefined && this.props.disabled);

    const isDisabledHelpVisible: boolean =
      isDisabled && runtimeProperties.showDisabledHelp;

    let content: React.ReactNode;

    let ariaLabel = undefined;
    let label = undefined;
    if (this.props.isIconOnly) {
      ariaLabel = this.props.label;
    } else {
      label = this.props.label;
    }

    if (this.props.renderAsLink) {
      content = (
        <ButtonLink
          aria-controls={this.props["aria-controls"]}
          aria-describedby={describedById}
          aria-expanded={this.props["aria-expanded"]}
          aria-haspopup={this.props["aria-haspopup"]}
          aria-label={ariaLabel}
          disabled={isDisabled}
          href={this.props.href}
          iconName={this.props.iconName}
          id={componentId}
          onClick={this.props.onClick}
          tabIndex={this.props.tabIndex}
          target="_self"
        >
          <span>{label}</span>
          {this.props.endIcon ? (
            <Icon icon={this.props.endIcon} style={{ marginLeft: ".4em" }} />
          ) : null}
          <div id={describedById} style={{ display: "none" }}>
            {isDisabledHelpVisible
              ? this.props.disabledHelpText
              : this.props.alternateText}
          </div>
        </ButtonLink>
      );
    } else {
      let size: "large" | "medium" | "small" | undefined = undefined;
      if (!this.props.size || typeof this.props.size === "string") {
        size = this.props.size;
      } else if (this.props.width in this.props.size) {
        size = this.props.size[this.props.width];
      }

      content = (
        <React.Fragment>
          <Button
            aria-controls={this.props["aria-controls"]}
            aria-describedby={describedById}
            aria-expanded={this.props["aria-expanded"]}
            aria-haspopup={this.props["aria-haspopup"]}
            aria-label={ariaLabel}
            color={
              this.props.buttonColor !== "Default"
                ? this.props.buttonColor.toLowerCase()
                : undefined
            }
            component="button"
            disabled={isDisabled}
            endIcon={this.props.endIcon}
            href={this.props.href}
            icon={this.props.iconName}
            id={componentId}
            label={label}
            fullWidth={true}
            onClick={this.props.onClick}
            size={size}
            tabIndex={this.props.tabIndex}
            target={this.props.target}
          />
          <div id={describedById} style={{ display: "none" }}>
            {isDisabledHelpVisible
              ? this.props.disabledHelpText
              : this.props.alternateText}
          </div>
        </React.Fragment>
      );
    }

    if (isDisabledHelpVisible) {
      return (
        <DisabledHelpBadge
          helpText={this.props.disabledHelpText}
          isFocusRippleVisible={this.state.isFocusRippleVisible}
          isHelpOpen={this.state.isDisabledHelpOpen}
          onHelpOpenChange={(isHelpOpen: boolean): void =>
            this.setState({ isDisabledHelpOpen: isHelpOpen })
          }
        >
          <ButtonBase
            aria-activedescendant={componentId}
            component="div"
            onBlur={() =>
              this.setState({
                isFocusRippleVisible: false,
              })
            }
            onClick={() =>
              this.setState({
                isDisabledHelpOpen: !this.state.isDisabledHelpOpen,
              })
            }
            onFocusVisible={() =>
              this.setState({
                isFocusRippleVisible: true,
              })
            }
            role={undefined} // Override default "button" role
            style={{ width: "100%" }}
            tabIndex={0}
          >
            {content}
          </ButtonBase>
        </DisabledHelpBadge>
      );
    }

    return content;
  }
}

export default withWidth()(ApiButton);
