import * as muiButtonBase from "@material-ui/core/ButtonBase";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import { CustomTheme } from "../../muiTheme";

interface Props {
  href?: string;
  imageSrc: string;
  label: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    ripple: {
      height: "calc(100% + 30px)",
      margin: "-15px",
      width: "calc(100% + 30px)",
    },
    root: {
      "& > img": {
        height: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
      },
      "&:focus-visible": {
        outline: "2px solid",
        outlineColor: theme.links.color!, // Set when in a header or footer
        outlineOffset: "4px",
      },
      [theme.breakpoints.up("xs")]: {
        height: 40,
        marginRight: 24,
      },
      [theme.breakpoints.up("md")]: {
        height: 60,
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: 40,
      },
      alignItems: "center",
      display: "flex",
      maxWidth: "25%",
      outline: "none",
      overflow: "hidden",
    },
  });

export class Logo extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private static readonly componentId: string = "header-logo";

  public static grabFocus(): void {
    const headerLogo = document.getElementById(Logo.componentId)!;
    FocusManager.grabFocus(headerLogo);
  }

  public render() {
    let ariaLabel = "";

    let nonProdEnvironmentClause: string = "";
    if (Sys.settings.nonProdEnvironment) {
      nonProdEnvironmentClause = `${Sys.settings.nonProdEnvironment} - `;
    }

    if (this.props.label && this.props.label !== "") {
      ariaLabel = `${nonProdEnvironmentClause}${this.props.label}`;
    } else {
      ariaLabel = `${nonProdEnvironmentClause}${Localization.getBuiltInMessage(
        "home"
      )}`;
    }

    return (
      <muiButtonBase.default
        aria-label={ariaLabel}
        className={this.props.classes.root}
        href={this.props.href!}
        id={Logo.componentId}
        onClick={this.props.onClick}
        TouchRippleProps={{ className: this.props.classes.ripple }}
      >
        <img aria-hidden={true} src={this.props.imageSrc} />
      </muiButtonBase.default>
    );
  }
}

export default withStyles(styles)(Logo);
