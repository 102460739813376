import {
  createStyles,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { observer } from "mobx-react";
import * as React from "react";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import {
  CustomTheme,
  getPanelMeasurements,
  PanelMeasurements,
} from "../muiTheme";
import { AccessLevel, ValueByBreakpoint } from "../mustangui/Api";

export interface ConfigProperties {
  childWidget: object & { type: string };
  childWidgetName: string;
  isLayoutOnlyWidget: boolean;
  isVisible: ValueByBreakpoint<boolean>;
}

interface Props extends ConfigProperties {
  dataId: string;
  propagated: object;
}

interface RuntimeProperties {
  accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) => {
  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`root-visible-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "block",
      },
    };
  }

  const itemSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const itemSpacing = theme.freeflow.item.spacing[breakPoint];

    itemSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      paddingBottom: itemSpacing.vertical * 0.5,
      paddingLeft: itemSpacing.horizontal * 0.5,
      paddingRight: itemSpacing.horizontal * 0.5,
      paddingTop: itemSpacing.vertical * 0.5,
    };
  }

  const measurementsByBreakpoint: {
    [breakPoint in Breakpoint]: PanelMeasurements;
  } = {
    lg: getPanelMeasurements(theme, "lg"),
    md: getPanelMeasurements(theme, "md"),
    sm: getPanelMeasurements(theme, "sm"),
    xl: { columnWidth: 0, gutterWidth: 0 },
    xs: { columnWidth: 0, gutterWidth: 0 },
  };

  const result = {
    fieldWidget: {
      [theme.breakpoints.up("lg")]: {
        width:
          measurementsByBreakpoint.lg.columnWidth * 3 +
          measurementsByBreakpoint.lg.gutterWidth * 2,
      },
      [theme.breakpoints.only("md")]: {
        width:
          measurementsByBreakpoint.md.columnWidth * 3 +
          measurementsByBreakpoint.md.gutterWidth * 2,
      },
      [theme.breakpoints.only("sm")]: {
        width:
          measurementsByBreakpoint.sm.columnWidth * 4 +
          measurementsByBreakpoint.sm.gutterWidth * 3,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    otherWidget: {
      width: "auto",
    },
    root: {
      "& .helper-text": {
        [theme.breakpoints.up("lg")]: {
          maxWidth:
            measurementsByBreakpoint.lg.columnWidth * 3 +
            measurementsByBreakpoint.lg.gutterWidth * 2,
        },
        [theme.breakpoints.only("md")]: {
          maxWidth:
            measurementsByBreakpoint.md.columnWidth * 3 +
            measurementsByBreakpoint.md.gutterWidth * 2,
        },
        [theme.breakpoints.only("sm")]: {
          maxWidth:
            measurementsByBreakpoint.sm.columnWidth * 4 +
            measurementsByBreakpoint.sm.gutterWidth * 3,
        },
        [theme.breakpoints.only("xs")]: {
          maxWidth: "100%",
        },
      },
      display: "none",
      minWidth: 0,
      ...itemSpaceStyle,
    } as CSSProperties,
    ...visibilityStyles,
  };

  return createStyles(result);
};

@observer
export class FreeflowContainerItem extends React.Component<
  Props & WithStyles<typeof styles> & WithTheme
> {
  private static fieldWidgetTypes: string[] = [
    "DateEdit",
    "DocumentEdit",
    "DomainComboBox",
    "NumericEdit",
    "RelationshipComboBox",
    "SLTextEdit",
  ];

  public static isVisible(
    dataId: string,
    item: ConfigProperties,
    breakPoint: Breakpoint
  ): boolean {
    if (!item.isVisible[breakPoint]) {
      return false;
    }

    if (item.isLayoutOnlyWidget) {
      return true;
    }

    const runtimeProperties = PaneRow.getWidgetProperties(
      dataId,
      item.childWidgetName
    ) as RuntimeProperties;

    if (runtimeProperties.accessLevel === undefined) {
      return true;
    }

    return runtimeProperties.accessLevel >= AccessLevel.disabled;
  }

  public render(): React.ReactNode {
    const classes: string[] = [this.props.classes.root];
    const theme = this.props.theme as CustomTheme;

    for (const breakPoint of theme.visibilityBreakPoints) {
      if (
        FreeflowContainerItem.isVisible(
          this.props.dataId,
          this.props,
          breakPoint
        )
      ) {
        const className = `root-visible-${breakPoint}`;
        classes.push(this.props.classes[className]);
      }
    }

    if (this.props.isLayoutOnlyWidget) {
      classes.push(this.props.classes.otherWidget);
    } else {
      const runtimeProperties = PaneRow.getWidgetProperties(
        this.props.dataId,
        this.props.childWidgetName
      ) as RuntimeProperties;

      if (
        (runtimeProperties?.accessLevel === AccessLevel.enterable ||
          runtimeProperties?.accessLevel === AccessLevel.disabled) &&
        FreeflowContainerItem.fieldWidgetTypes.includes(
          this.props.childWidget.type
        )
      ) {
        classes.push(this.props.classes.fieldWidget);
      } else {
        classes.push(this.props.classes.otherWidget);
      }
    }

    return (
      <div className={classes.join(" ")}>
        {Presentation.create(this.props.childWidget, this.props.propagated)}
      </div>
    );
  }
}

export default withStyles(styles)(withTheme(FreeflowContainerItem));
