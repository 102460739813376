import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import ComboBoxOption from "../../coreui/ComboBoxOption";
import ErrorBadge from "../../coreui/ErrorBadge";
import Select from "../../coreui/Select";
import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import Api, { AccessLevel } from "../Api";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  selectedDisplayValue: string;
  selectedValue: string | null;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      right: 19,
    },
  });

@observer
export class RelationshipComboBoxColumnEdit extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private value: string | null | undefined;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.value = props.value;
  }

  private onChange = (value: ComboBoxOption | null) => {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.value = value?.value;
  };

  private onClose = () => {
    // Must be deferred to run after focus.
    setTimeout(() => {
      const focusedCell = this.props.api!.getFocusedCell();

      this.props.api!.stopEditing();

      if (focusedCell) {
        this.props.api!.setFocusedCell(
          focusedCell.rowIndex,
          focusedCell.column,
          focusedCell.rowPinned || undefined
        );
      }
    }, 500);
  };

  private onFocus = () => {
    const parentTable = this.props.propagated.parentTable;
    parentTable.setStopEditingWhenGridLosesFocus(false);
  };

  public componentWillUnmount(): void {
    this.props.propagated.parentTable.setStopEditingWhenGridLosesFocus(true);
  }

  public getValue(): string | null {
    return this.value === "" || this.value === undefined ? null : this.value;
  }

  public render(): React.ReactNode {
    const row = this.props.node.data as PaneRow | null;
    if (!row) {
      return null;
    }

    const column: GridColumnConfigProperties | undefined = (this.props
      .propagated.parentTable.columns as GridColumnConfigProperties[]).find(
      (_column) => _column.name === this.props.colDef.colId
    );
    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    const selectedOption: ComboBoxOption = {
      display: runtimeProps.selectedDisplayValue,
      value: runtimeProps.selectedValue,
    };

    const table = this.props.propagated.parentTable;
    const options = table.getRelationshipComboBoxOptions(
      this.props.name,
      selectedOption
    );

    const errors: string[] = row.hasChanges(this.props.name)
      ? []
      : [...runtimeProps.businessErrors];

    return (
      <ErrorBadge
        isShort={row.isNew && table.isDocumentGrid}
        message={Api.getErrorMessages(errors)}
      >
        <Select
          aria-label={column?.header}
          disableUnderline={true}
          getErrors={() => errors}
          iconClassName={this.props.classes.icon}
          inputBaseStyle={{
            cursor: "pointer",
            display: "flex",
            height: "calc(100% + 2px)",
          }}
          onClose={this.onClose}
          onFocus={this.onFocus}
          onValueChange={this.onChange}
          openOnMount
          options={options}
          selectDisplayStyle={{
            lineHeight: `${this.props.node.rowHeight}px`,
            paddingBottom: 0,
            paddingLeft: 24,
            paddingTop: 0,
          }}
          showAsMandatory={column?.required}
          value={this.value}
        />
      </ErrorBadge>
    );
  }
}

export default withStyles(styles)(RelationshipComboBoxColumnEdit);
