import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../Api";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface RuntimeProperties {
  accessLevel: AccessLevel;
  bodyText: string[];
}

export class TextDisplayColumn {
  public static readonly widgetType: string = "TextDisplayColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidget(column.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    return runtimeProps.bodyText ? runtimeProps.bodyText.join(" ") : "";
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidget(props.column.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    if (!runtimeProps.bodyText) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {runtimeProps.bodyText.join(" ")}
      </Typography>
    );
  }
}
