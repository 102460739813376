import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "./Button";
import Dialog from "./Dialog";
import DialogActions from "./DialogActions";
import DialogContent from "./DialogContent";
import TextField from "./TextField";

interface Props {
  getErrors?: (value: string) => string[];
  helperText?: React.ReactNode;
  label: React.ReactNode;
  onAccept?: (value: string) => void;
  onCancel: () => void;
  onExited?: () => void;
  open: boolean;
  readOnly?: boolean;
  value: string;
}

interface State {
  value: string;
}

const styles = (theme: Theme) =>
  createStyles({
    contentRoot: {
      display: "flex",
      // Set flex such that it agressively allocates vertical space, but
      // then shrinks down to what is available.
      flex: "0 1 100vh",
    },
    contentRootReadOnly: {
      display: "flex",
      overflow: "hidden",
    },
    contentWrapper: {
      display: "flex",
      flex: "1 1 auto",
      width: 600,
    },
    contentWrapperReadOnly: {
      width: 600,
    },
    inputElement: {
      height: "100%",
      marginLeft: -16,
      marginRight: -16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    inputRoot: {
      flex: "1 1 auto",
      paddingBottom: 22,
    },
    readOnlyContent: {
      overflow: "auto",
    },
    readOnlyRoot: {
      height: "100%",
    },
  });

@observer
export class MultilineTextFieldDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private inputRef = React.createRef<HTMLElement>();
  private readonly labelledById: string;
  private readOnlyDialogContent: HTMLDivElement;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `multiline-textField-dialog-${Sys.nextId}`;
    this.labelledById = `${this.componentId}-labelled-by`;

    this.state = { value: props.value };
  }

  private onAccept = () => {
    if (this.props.onAccept) {
      this.props.onAccept(this.state.value);
    }
  };

  private onCancel = () => {
    this.props.onCancel();
  };

  private onEntered = (node: HTMLElement, isAppearing: boolean) => {
    if (!this.props.readOnly) {
      return;
    }

    if (this.inputRef.current === null) {
      console.warn(
        "No input element exists when attempt to focus the multiline text field"
      );
    } else {
      FocusManager.grabFocus(this.inputRef.current);
    }
  };

  private onExited = () => {
    if (this.props.onExited) {
      this.props.onExited();
    }
  };

  private onValueChange = (value: string) => {
    this.setState({ value });
  };

  private setReadOnlyDialogContent = (element: HTMLDivElement) => {
    this.readOnlyDialogContent = element;
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.open && this.props.open) {
      this.setState({ value: this.props.value });
    }
  }

  public render() {
    const contentRootClassName = this.props.readOnly
      ? this.props.classes.contentRootReadOnly
      : this.props.classes.contentRoot;

    const contentWrapperClassName = this.props.readOnly
      ? this.props.classes.contentWrapperReadOnly
      : this.props.classes.contentWrapper;

    return (
      <Dialog
        aria-labelledby={this.labelledById}
        disableBackdropClick={true}
        onClose={this.onCancel}
        onEntered={this.onEntered}
        onExited={this.onExited}
        open={this.props.open}
      >
        {/*
         * This label exists only to label the dialog via aria-labelledby.
         * Aria-label is not recognized on the dialog.
         */}
        <div className="screenReaderOnly" id={this.labelledById}>
          {this.props.label}
        </div>
        <DialogContent className={contentRootClassName}>
          <div className={contentWrapperClassName}>
            <TextField
              autoFocus={!this.props.readOnly}
              fullWidth={true}
              getErrors={this.props.getErrors}
              helperText={this.props.helperText}
              InputProps={{
                classes: {
                  input: this.props.classes.inputElement,
                  root: this.props.classes.inputRoot,
                },
              }}
              inputRef={this.inputRef}
              label={this.props.label}
              multiline={true}
              onValueChange={this.onValueChange}
              readOnly={this.props.readOnly}
              readOnlyProps={{
                classes: {
                  content: this.props.classes.readOnlyContent,
                  root: this.props.classes.readOnlyRoot,
                },
              }}
              rows={1}
              value={this.state.value}
              variant="filled"
            />
          </div>
        </DialogContent>
        <DialogActions>
          {!this.props.readOnly ? (
            <Button onClick={this.onAccept}>
              {Localization.getBuiltInMessage("ok")}
            </Button>
          ) : null}
          <Button onClick={this.onCancel} style={{ marginLeft: 40 }}>
            {this.props.readOnly
              ? Localization.getBuiltInMessage("close")
              : Localization.getBuiltInMessage("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MultilineTextFieldDialog);
