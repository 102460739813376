import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Api, { ConfigProps } from "./Api";

interface ConfigProperties extends ConfigProps {}

const styles = (theme: Theme) =>
  createStyles({
    blockHeader: {
      fontSize: "14px",
      fontWeight: 900,
    },
    propertyBlock: {
      backgroundColor: theme.palette.grey[100],
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[800],
      fontSize: "12px",
      padding: "8px",
      whiteSpace: "pre-wrap",
    },
    root: {
      borderColor: theme.palette.common.black,
      fontFamily: "monospace",
      padding: "16px",
    },
  });

export class ComponentTest extends React.PureComponent<
  ConfigProperties & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    let runtimePropsStr: string = "Component does not define dataId and name.";

    if (this.props["dataId"] && this.props["name"]) {
      const widgetProperties = Api.getWidgetProperties(this.props);
      if (widgetProperties) {
        runtimePropsStr = JSON.stringify(widgetProperties, null, 2);
      } else {
        runtimePropsStr = "No widget properties exist for component.";
      }
    }

    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.blockHeader}>
          Configured Props (Props)
        </div>
        <div className={this.props.classes.propertyBlock}>
          {JSON.stringify(this.props, null, 2)}
        </div>
        <div className={this.props.classes.blockHeader}>
          Runtime Properties (WidgetProperties)
        </div>
        <div className={this.props.classes.propertyBlock}>
          {runtimePropsStr}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ComponentTest);
