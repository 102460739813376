import Localization, { Language } from "../core/Localization";
import Sys from "../core/Sys";
import {
  AuthenticatedPage,
  AuthenticatedPageConfig,
} from "../pages/AuthenticatedPage";
import { ErrorPage, ErrorPageConfig } from "../pages/ErrorPage";
import { GuestPage, GuestPageConfig } from "../pages/GuestPage";
import { LandingPage, LandingPageConfig } from "../pages/LandingPage";
import CustomIconStore from "../stores/CustomIconStore";
import BaseService from "./BaseService";

export interface SystemConfig {
  ajaxTimeoutMilliSeconds: number;
  authenticatedPage: AuthenticatedPageConfig;
  availableLanguages: Language[];
  bodyIntegrationBlock: string | null;
  colorPalette: {
    danger: string;
    information: string;
    primary: string;
    secondary: string;
    success: string;
    warning: string;
  };
  currentLanguageCode: string;
  customIconsByName: { [name: string]: string };
  dateFormat: string;
  dayAbbreviations: string[];
  days: string[];
  decimalSeparator: string;
  enableEmailAuthentication: boolean;
  environmentBannerColor: string | null;
  errorPage: ErrorPageConfig;
  favIconUrl: string;
  guestPage: GuestPageConfig;
  headerIntegrationBlock: string | null;
  landingPage: LandingPageConfig;
  monthAbbreviations: string[];
  months: string[];
  nonProdEnvironment: string;
  reCaptchaBaseUrl: string;
  reCaptchaSiteKey: string;
  rootUrl: string;
  signOutUrl: string;
  siteName: string;
  thousandsSeparator: string;
  builtInMessages: object;
  useConfiguredAuthentication: boolean;
}

export default class SystemConfigService {
  private static async getSystemConfig(
    languageCode: string | null
  ): Promise<SystemConfig> {
    let requestUrl = "SystemConfig";
    if (languageCode) {
      requestUrl += `/${languageCode}`;
    }

    return BaseService.requestObject(
      requestUrl,
      null,
      null,
      null,
      "GET",
      false
    );
  }

  private static async getMinimalSystemConfig(
    languageCode: string | null
  ): Promise<SystemConfig> {
    let requestUrl = "SystemConfig/Minimal";
    if (languageCode) {
      requestUrl += `/${languageCode}`;
    }

    return BaseService.requestObject(requestUrl, null, null, null, "GET");
  }

  private static setFavIcon(favIconUrl: string): void {
    const favIcon = document.getElementById("favIcon") as HTMLLinkElement;

    if (favIcon) {
      favIcon.href = favIconUrl;
    }
  }

  private static setLanguage(code: string): void {
    const html: HTMLElement = document.documentElement;

    if (html) {
      html.lang = code;
    }
  }

  public static async loadConfig(
    languageCode: string | null
  ): Promise<boolean> {
    let config: SystemConfig;

    try {
      config = await SystemConfigService.getSystemConfig(languageCode);
    } catch (request) {
      config = await SystemConfigService.getMinimalSystemConfig(languageCode);

      BaseService.requestTimeoutMilliseconds = config.ajaxTimeoutMilliSeconds;
      SystemConfigService.setFavIcon(config.favIconUrl);
      ErrorPage.setConfig(config.errorPage);

      Sys.settings.baselineGridSize = 4;
      Sys.settings.colorPalette = config.colorPalette;
      // FUTURE: Why is current language code required for the minimum
      // config? Is a language select widget being rendered in this case?
      Sys.settings.currentLanguageCode = config.currentLanguageCode;
      Sys.settings.environmentBannerColor = config.environmentBannerColor;
      Sys.settings.nonProdEnvironment = config.nonProdEnvironment;
      Sys.settings.rootUrl = config.rootUrl;
      Sys.settings.signOutUrl = config.signOutUrl;
      Sys.settings.siteName = config.siteName;

      BaseService.handleRequestException(request);

      return false;
    }

    SystemConfigService.setFavIcon(config.favIconUrl);
    SystemConfigService.setLanguage(config.currentLanguageCode);
    AuthenticatedPage.setConfig(config.authenticatedPage);
    BaseService.requestTimeoutMilliseconds = config.ajaxTimeoutMilliSeconds;
    CustomIconStore.load(config.customIconsByName);
    ErrorPage.setConfig(config.errorPage);

    if (config.useConfiguredAuthentication) {
      // Guest/Unauthenticated pages are only available if Use Configured
      // Authentication is enabled.
      GuestPage.setConfig(config.guestPage);
      LandingPage.setConfig(config.landingPage);
    }

    Localization.initializeLanguage(
      config.currentLanguageCode,
      config.availableLanguages,
      config.builtInMessages
    );

    Localization.initializeDateFormatter(
      config.dateFormat,
      config.days,
      config.dayAbbreviations,
      config.months,
      config.monthAbbreviations
    );

    Localization.initializeNumberFormatter(
      config.decimalSeparator,
      config.thousandsSeparator
    );

    // FUTURE
    // Move these items out of a generic settings collection and into
    // specialized services / stores. That way the information can be
    // organized according to business domain.
    //
    // Heading comments are provided as an indication of some preliminary
    // thinking about where these settings can go.

    // Authentication
    Sys.settings.enableEmailAuthentication = config.enableEmailAuthentication;
    Sys.settings.useConfiguredAuthentication =
      config.useConfiguredAuthentication;

    // Site
    Sys.settings.environmentBannerColor = config.environmentBannerColor;
    Sys.settings.nonProdEnvironment = config.nonProdEnvironment;
    Sys.settings.reCaptchaBaseUrl = config.reCaptchaBaseUrl;
    Sys.settings.reCaptchaSiteKey = config.reCaptchaSiteKey;
    Sys.settings.rootUrl = config.rootUrl;
    Sys.settings.signOutUrl = config.signOutUrl;
    Sys.settings.siteName = config.siteName;
    Sys.settings.headerIntegrationBlock = config.headerIntegrationBlock;
    Sys.settings.bodyIntegrationBlock = config.bodyIntegrationBlock;

    // Theme
    Sys.settings.baselineGridSize = 4;
    Sys.settings.colorPalette = config.colorPalette;

    return true;
  }
}
