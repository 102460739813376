import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTabs from "@material-ui/core/Tabs";
import * as React from "react";
import TabScrollButton from "./TabScrollButton";

interface Props extends muiTabs.TabsProps {}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      outline: "none",
    },
  });

export class Tabs extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const { classes, ...other } = this.props;

    return (
      <muiTabs.default
        classes={{ flexContainer: classes.flexContainer }}
        ScrollButtonComponent={TabScrollButton}
        scrollButtons="auto"
        variant="scrollable"
        {...other}
      />
    );
  }
}

export default withStyles(styles)(Tabs);
