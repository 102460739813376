import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Button from "../coreui/Button";
import { TableChildProps } from "../coreui/Table";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export class TableExport extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    return (
      <Button
        color="default"
        icon="fas fa-file-export"
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          this.props.propagated.parentTable.getApi().exportDataAsCsv();
        }}
      />
    );
  }
}

export default withStyles(styles)(TableExport);
