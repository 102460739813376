import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Grid from "../coreui/Grid";
import GridItem from "../coreui/GridItem";
import Hidden from "../coreui/Hidden";
import Typography from "../coreui/Typography";
import { CustomTheme } from "../muiTheme";
import Api from "../mustangui/Api";
import EnvironmentBanner from "./components/EnvironmentBanner";
import Logo from "./components/Logo";
import CenteredContentClasses from "./styles/CenteredContentClasses";

interface FooterConfig {
  backgroundColor: string;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  logoUrl: string;
  logoLabel: string;
}

export interface ErrorPageTemplateConfig {
  footer: FooterConfig;
  header: HeaderConfig;
}

interface Props {
  backgroundImage?: string;
  configMessage: string;
  errorCodes?: object;
  footer?: FooterConfig;
  header?: HeaderConfig;
  message?: string | null;
  solutionsTitle?: string;
  solutions?: string[];
  status: number;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    backgroundImage: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      position: "fixed",
      width: "100%",
      zIndex: -1,
    },
    footer: {
      [theme.breakpoints.up("xs")]: {
        height: 68,
      },
      [theme.breakpoints.up("lg")]: {
        height: 100,
      },
      "@media print": {
        display: "none",
      },
    },
    header: {
      [theme.breakpoints.up("xs")]: {
        height: 72,
      },
      [theme.breakpoints.up("sm")]: {
        height: 88,
      },
      [theme.breakpoints.up("md")]: {
        height: 108,
      },
      [theme.breakpoints.up("lg")]: {
        height: 140,
      },
      "@media print": {
        display: "none",
      },
      borderBottomStyle: "solid",
      borderBottomWidth: 4,
    },
    headerContent: {
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      height: "100%",
    },
    main: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    mainContent: {
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.container.verticalSpacing.lg,
        marginTop: theme.container.verticalSpacing.lg,
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: theme.container.verticalSpacing.md,
        marginTop: theme.container.verticalSpacing.md,
      },
      [theme.breakpoints.only("sm")]: {
        marginBottom: theme.container.verticalSpacing.sm,
        marginTop: theme.container.verticalSpacing.sm,
      },
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.container.verticalSpacing.xs,
        marginTop: theme.container.verticalSpacing.xs,
      },
      flexGrow: 1,
      width: "initial",
    },
    mainGrid: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    status: {
      [theme.breakpoints.only("xs")]: { fontSize: 45, lineHeight: "60px" },
      [theme.breakpoints.up("sm")]: { fontSize: 56, lineHeight: "72px" },
      [theme.breakpoints.up("lg")]: { fontSize: 112, lineHeight: "148px" },
      fontWeight: 700,
    },
    ...CenteredContentClasses.create(theme),
  });

class ErrorPageTemplate extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private goHome = () => {
    Sys.setHash("", false, true);
    window.location.assign(Sys.settings.rootUrl);
  };

  public componentDidMount(): void {
    const message = Localization.getBuiltInMessage("GeneralError.errorPrefix");
    Sys.announce(message);

    const mainContent = document.querySelector(
      "#mainContent"
    )! as HTMLDivElement;
    mainContent.focus();
  }

  public render(): React.ReactNode {
    let backgroundImage: string | undefined = this.props.backgroundImage;

    if (
      this.props.errorCodes &&
      this.props.errorCodes[this.props.status] &&
      this.props.errorCodes[this.props.status].backgroundImage
    ) {
      backgroundImage =
        this.props.errorCodes[this.props.status].backgroundImage;
    }

    let backgroundImageElement: React.ReactNode = null;

    if (backgroundImage) {
      backgroundImageElement = (
        <div
          className={this.props.classes.backgroundImage}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
      );
    }

    let footer: React.ReactNode = null;
    if (this.props.footer) {
      footer = (
        <footer
          className={this.props.classes.footer}
          style={{
            backgroundColor: this.props.footer.backgroundColor,
          }}
        />
      );
    }

    let header: React.ReactNode = null;
    if (this.props.header) {
      const headerContentClasses = [
        this.props.classes.headerContent,
        CenteredContentClasses.get(null, this.props.classes),
      ];

      header = (
        <header
          className={this.props.classes.header}
          style={{
            backgroundColor: this.props.header.backgroundColor,
            borderColor: this.props.header.bottomBorderColor,
          }}
        >
          <div className={headerContentClasses.join(" ")}>
            <Logo
              imageSrc={this.props.header.logoUrl}
              label={this.props.header.logoLabel}
              onClick={this.goHome}
              style={{ cursor: "pointer" }}
            />
          </div>
        </header>
      );
    }

    let configMessage: string = this.props.configMessage;

    if (this.props.errorCodes && this.props.errorCodes[this.props.status]) {
      configMessage = this.props.errorCodes[this.props.status].message;
    }

    let solutions: string[] | undefined = this.props.solutions;

    if (this.props.errorCodes && this.props.errorCodes[this.props.status]) {
      solutions = this.props.errorCodes[this.props.status].solutions;
    }

    let solutionsElement: React.ReactNode = null;

    if (!!solutions?.length) {
      solutionsElement = solutions.map((solution: string, index: number) => (
        <li key={index} style={{ marginBottom: 24 }}>
          {solution}
        </li>
      ));
    }

    const mainContentClasses = [
      this.props.classes.mainContent,
      CenteredContentClasses.get(null, this.props.classes),
    ];

    const backgroundColor = this.props.header
      ? `${this.props.header.bottomBorderColor}CC`
      : "#b71c1cCC";
    const color = Api.getContrastColor(backgroundColor);
    let message: React.ReactNode = null;

    if (this.props.message && this.props.status >= 500) {
      message = this.props.message.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));

      message = (
        <Typography paragraph={true} style={{ color }}>
          {message}
        </Typography>
      );
    }

    return (
      <div className={this.props.classes.root}>
        {backgroundImageElement}
        <EnvironmentBanner />
        {header}
        <main
          className={this.props.classes.main}
          id="mainContent"
          style={{ backgroundColor }}
          tabIndex={0}
        >
          <Grid className={mainContentClasses.join(" ")}>
            <GridItem className={this.props.classes.mainGrid} xs={12} md={6}>
              <Typography
                variant="h1"
                className={this.props.classes.status}
                style={{ color }}
              >
                {this.props.status}
              </Typography>
              <Typography variant="h3" paragraph={true} style={{ color }}>
                {configMessage}
              </Typography>
              {message}
              <Typography paragraph={true} style={{ color }}>
                {this.props.solutionsTitle}
              </Typography>
              <ul>
                <Typography style={{ color }}>{solutionsElement}</Typography>
              </ul>
              <Grid>
                <GridItem>
                  <Hidden xsDown={true}>
                    <Button icon="fas fa-home" onClick={this.goHome}>
                      {Localization.getBuiltInMessage("home")}
                    </Button>
                  </Hidden>
                  <Hidden smUp={true}>
                    <Button fab icon="fas fa-home" onClick={this.goHome} />
                  </Hidden>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </main>
        {footer}
      </div>
    );
  }
}

export default withStyles(styles)(ErrorPageTemplate);
