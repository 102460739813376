import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { autorun, IReactionDisposer } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Collapse from "../coreui/Collapse";
import Fade from "../coreui/Fade";
import Paper from "../coreui/Paper";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import Api, { AccessLevel } from "./Api";
import ErrorBoundary from "./ErrorBoundary";

interface ConfigProperties {
  cardDepth: number;
  containerStyle: "Blended" | "Visual";
  controlKey: string | null;
  controlType: "Custom" | "Expansion" | "List" | "Static";
  dataId: string;
  dateEditWidgetsInfo: WidgetInfo[];
  name: string;
  propagated?: object;
  subPanes: SubPaneProps[];
}

interface WidgetInfo {
  dataId: string;
  name: string;
}

interface SubPaneProps {
  dataId: string;
  paneUse: object;
  paneKey: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  visiblePaneName: string | null;
}

interface State {
  currentPaneKey?: string;
  lastPaneKey?: string;
  paneChanged: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      "&:focus": {
        outline: "none",
      },
    },
  });

@observer
export class SubPaneControl extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private autorunDisposer: IReactionDisposer | null = null;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      paneChanged: false,
    };
  }

  private clearDateEditErrors = (): void => {
    for (const info of this.props.dateEditWidgetsInfo) {
      const row = PaneRow.get(info.dataId);
      if (row === null) {
        continue;
      }

      const widget = row.getWidget(info.name)!;
      if (widget.properties.businessErrors.length === 0) {
        continue;
      }

      row.revertValue(info.name);
      widget.properties.businessErrors.length = 0;
    }
  };

  private renderFadePane = (subPane: SubPaneProps): React.ReactNode => {
    const currentPaneKey: string | undefined = this.state.currentPaneKey;
    const lastPaneKey: string | undefined = this.state.lastPaneKey;
    const isSubPaneVisible: boolean =
      subPane.paneKey === currentPaneKey ||
      (!currentPaneKey && subPane.paneKey === lastPaneKey);

    return (
      <Fade
        in={isSubPaneVisible}
        key={subPane.paneKey}
        style={{ display: isSubPaneVisible ? undefined : "none" }}
        timeout={
          this.state.lastPaneKey && subPane.paneKey === currentPaneKey ? 500 : 0
        }
      >
        <div>{this.renderPane(subPane.paneKey)}</div>
      </Fade>
    );
  };

  private renderPane(paneKey: string): React.ReactNode {
    const subPane: SubPaneProps | undefined = this.props.subPanes.find(
      (s) => s.paneKey === paneKey
    );

    if (!subPane) {
      throw new Error(`No sub pane found for ${paneKey}`);
    }

    return (
      <ErrorBoundary title={this.props.name}>
        <Paper
          blended={this.props.containerStyle === "Blended"}
          card={this.props.containerStyle === "Visual"}
          cardDepth={this.props.cardDepth}
          className={this.props.classes.paper}
          elevation={0}
          margin={this.props.containerStyle === "Visual"}
          style={{
            display:
              this.props.containerStyle === "Blended" ? "block" : "inherit",
            flexGrow: 1,
          }}
        >
          {Presentation.create(subPane.paneUse, this.props.propagated)}
        </Paper>
      </ErrorBoundary>
    );
  }

  private setCurrentPaneKey = (): void => {
    const runtimeProperties: RuntimeProperties | null = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties | null;

    if (!runtimeProperties) {
      return;
    }

    let currentPaneKey: string | undefined = undefined;

    if (this.props.controlType === "Custom") {
      currentPaneKey = runtimeProperties.visiblePaneName
        ? runtimeProperties.visiblePaneName
        : undefined;

      if (currentPaneKey !== this.state.currentPaneKey) {
        this.clearDateEditErrors();
      }

      this.setState(
        (prevState) => {
          return {
            currentPaneKey,
            lastPaneKey: prevState.currentPaneKey,
            paneChanged: currentPaneKey !== prevState.currentPaneKey,
          };
        },
        () => {
          this.setState({ paneChanged: false });
        }
      );

      return;
    }

    if (this.props.controlKey) {
      const propagated: object | undefined = this.props.propagated;
      const rowKey: string =
        propagated && propagated["rowKey"] ? propagated["rowKey"] : "";

      currentPaneKey = SubPaneControlStore.getCurrentPaneKeyForControlKey(
        `${this.props.controlKey}_${rowKey}`
      );
    } else {
      throw new Error(
        "No controlling method found for Sub Pane Control " +
          `${this.props.name}`
      );
    }

    if (currentPaneKey !== this.state.currentPaneKey) {
      this.clearDateEditErrors();
    }

    this.setState((prevState) => {
      return {
        currentPaneKey,
        lastPaneKey: prevState.currentPaneKey,
      };
    });
  };

  public componentDidMount(): void {
    this.autorunDisposer = autorun(this.setCurrentPaneKey);
  }

  public componentDidUpdate(prevProps: ConfigProperties): void {
    if (this.props.name !== prevProps.name) {
      if (this.autorunDisposer) {
        this.autorunDisposer();
      }
      this.autorunDisposer = autorun(this.setCurrentPaneKey);
    }
  }

  public componentWillUnmount(): void {
    if (this.autorunDisposer) {
      this.autorunDisposer();
    }
  }

  public render(): React.ReactNode {
    const runtimeProperties: RuntimeProperties | null = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties | null;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    switch (this.props.controlType) {
      case "Custom":
        if (this.state.paneChanged) {
          return null;
        }

        return this.state.currentPaneKey
          ? this.renderPane(this.state.currentPaneKey)
          : null;

      case "Expansion":
      case "List":
        return (
          <Collapse in={this.state.currentPaneKey !== undefined}>
            {this.props.subPanes.map(this.renderFadePane)}
          </Collapse>
        );

      case "Static":
        return this.renderPane(this.props.subPanes[0].paneKey);

      default:
        throw new Error(`Unknown control type ${this.props.controlType}`);
    }
  }
}

export default withStyles(styles)(SubPaneControl);
