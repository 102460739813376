import lightGreen from "@material-ui/core/colors/lightGreen";
import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import * as muiLinearProgress from "@material-ui/core/LinearProgress";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { autorun, IReactionDisposer } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import { IMaskInput } from "react-imask";
import { DialogChildProps } from "../config/Dialog";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import getFieldHelperText from "../coreui/FieldHelperText";
import FocusRipple from "../coreui/FocusRipple";
import FormHelperText from "../coreui/FormHelperText";
import Icon from "../coreui/Icon";
import IconWithLink from "../coreui/IconWithLink";
import ImageWithLink from "../coreui/ImageWithLink";
import Presentation from "../coreui/Presentation";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import DocumentEditService, {
  CompleteDocumentUploadResponse,
} from "../services/DocumentEditService";
import DocumentService, {
  DocumentUploadResponse,
} from "../services/DocumentService";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  maxFileBytes: number | null;
  maxFileBytesError: string;
  name: string;
  nameRequiredError: string;
  propagated: DialogChildProps | null;
  validExtensions: string[];
  validExtensionsError: string;
}

interface State {
  isDocumentUploadFinished?: boolean;
  isDocumentUploading?: boolean;
  isDragging?: boolean;
  isFocused?: boolean;
  isUploadButtonFocused: boolean;
  localErrors?: string[];
  newDocumentExtension?: string;
  newDocumentFileName?: string;
  uploadPercentComplete?: number;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  canDelete: boolean;
  documentExtension: string | null;
  documentHandle: string | null;
  downloadToken: string;
  extensionIconName: string | null;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
  thumbnailType: "Medium" | "ExtensionIcon" | null;
}

const styles = (theme: Theme) =>
  createStyles({
    cellLeft: {
      width: 64,
    },
    cellRight: {
      flex: 1,
      minWidth: 0,
    },
    dropArea: {
      alignItems: "center",
    },
    editableRoot: {
      outline: "none",
      position: "relative",
      width: "100%",
    },
    fileInput: {
      cursor: "pointer",
      height: "100%",
      left: 0,
      opacity: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
    input: {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
    inputLabelRoot: {
      width: "calc(100% - 72px)",
    },
    inputLabelShrink: {
      width: "calc((100% - 72px) * 1.333)",
    },
    label: {
      width: "calc(100% - 72px)",
    },
    previewButton: {
      marginRight: 16,
    },
    progressBar: {
      backgroundColor: lightGreen[100],
      bottom: 0,
      height: 4,
      position: "absolute",
      width: "100%",
    },
    progressBarFilled: {
      backgroundColor: lightGreen[500],
      transitionDuration: "0.1s",
    },
    progressBarFilledSlow: {
      backgroundColor: lightGreen[500],
      transitionDuration: "5s",
    },
    readOnlyRoot: {
      display: "flex",
    },
    row: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    uploadCompleteIcon: {
      bottom: -24,
      fontSize: 16,
      right: 0,
    },
  });

@observer
export class DocumentEdit extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private customInputRef: { maskValue: string };
  private dragEnterStack = 0;
  private readonly helperTextId: string;
  private lastDocumentHandle: string | null;
  private rootRef = React.createRef<HTMLDivElement>();
  private saveMonitorDisposer: IReactionDisposer;
  private readonly validExtensions: string;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `document-edit-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;

    this.state = {
      isDocumentUploadFinished: false,
      isDocumentUploading: false,
      isFocused: false,
      isUploadButtonFocused: false,
      localErrors: [],
      uploadPercentComplete: 0,
    };

    this.validExtensions = this.props.validExtensions
      .map((extension) => `.${extension}`)
      .join(",");
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private completeDocumentUpload(
    uploadResponse: DocumentUploadResponse,
    file: File
  ): Promise<void> {
    RequestsStore.instance.processingStarted(
      Localization.getBuiltInMessage("uploadDone")
    );

    this.setState({ uploadPercentComplete: 100 });

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    return DocumentEditService.completeDocumentUpload(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      this.props.propagated?.parentDialog?.rowKey,
      file,
      uploadResponse.pendingDocumentId,
      uploadResponse.pendingThumbnailId
    )
      .then((response: CompleteDocumentUploadResponse) => {
        AppServer.setState(response.appServerState);
        PaneDataStore.loadResponse(response.paneDataByDataId);

        if (response.widgetData) {
          PaneDataStore.loadWidgetData(this.props.dataId, response.widgetData);
        }

        if (response.uploadErrors.length > 0) {
          this.uploadError(file, response.uploadErrors);
          return;
        }

        Presentation.setValue(this.props, this.state.newDocumentFileName);
        this.setState({
          isDocumentUploadFinished: true,
          isDocumentUploading: false,
          uploadPercentComplete: 100,
        });
      })
      .finally(() => RequestsStore.instance.processingStopped());
  }

  private createCustomInput = (props: InputBaseComponentProps): JSX.Element => {
    const { extension, onChange, ...other } = props;

    const showExtension: boolean =
      !!extension && (!!props.value || this.state.isFocused!);

    return (
      <IMaskInput
        {...other}
        blocks={{ "|": { mask: /.*/ } }}
        lazy={false}
        mask={showExtension ? `|.${extension}` : "|"}
        // The onChange event doesn't fire when lazy == true and the
        // onAccept event fires when the value is programatically
        // changed, so we use onInput
        onInput={onChange}
        placeholderChar=" "
        ref={(ref: { maskValue: string }) => {
          this.customInputRef = ref;
        }}
        unmask={true}
      />
    );
  };

  private deleteDocument = () => {
    this.setState({ isDocumentUploadFinished: false });

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    row.revertValue(this.props.name); // Reset any changes to the file name
    DocumentEditService.deleteDocument(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      this.props.propagated?.parentDialog?.rowKey
    )
      .then(() => {
        this.setLocalErrors();
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });
  };

  private getCurrentValue(): string | null {
    let documentBaseName = Presentation.getValue(this.props);
    if (this.state.isDocumentUploading) {
      documentBaseName = this.state.newDocumentFileName!;
    }

    return documentBaseName;
  }

  private getErrors(businessErrors: string[]): string[] {
    let result: string[] = [...businessErrors];

    result = result.concat(this.state.localErrors!);

    return result;
  }

  private isDocumentSelected(runtimeProperties: RuntimeProperties): boolean {
    return !!runtimeProperties.documentHandle;
  }

  private onChange = () => {
    const value: string = this.customInputRef.maskValue;

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    this.setState({ localErrors: [] });

    if (this.state.isDocumentUploading) {
      this.setState({ newDocumentFileName: value });
    } else {
      Presentation.setValue(this.props, value);
    }
  };

  private onClickFileUpload = () => {
    this.setState({
      isDocumentUploadFinished: false,
      uploadPercentComplete: 0,
    });
  };

  private onDragEnterIntoDocument = () => {
    this.dragEnterStack++;

    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (
      runtimeProperties.accessLevel >= AccessLevel.enterable &&
      !runtimeProperties.canDelete &&
      !this.state.isDocumentUploading
    ) {
      this.setState({ isDragging: true });
    }
  };

  private onDragLeaveOutOfDocument = () => {
    this.dragEnterStack--;

    if (this.dragEnterStack < 1) {
      this.setState({ isDragging: false });
    }
  };

  private onDragOverIntoWidget = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  private onDropIntoDocument = (event: DragEvent) => {
    // Delay so the widget drop event fires (delay the re-render)
    setTimeout(() => {
      this.dragEnterStack = 0;
      this.setState({
        isDocumentUploadFinished: false,
        isDragging: false,
        uploadPercentComplete: 0,
      });
    });

    event.preventDefault();

    return false;
  };

  private onDropIntoWidget = (event: React.DragEvent<HTMLDivElement>) => {
    this.uploadFile(event.dataTransfer.files);

    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  private onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;

    if (!input.files || input.files.length <= 0) {
      return;
    }

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    this.uploadFile(input.files);
  };

  private setLocalErrors() {
    const localErrors: string[] = [];
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (
      !this.getCurrentValue() &&
      (this.isDocumentSelected(runtimeProperties) ||
        this.state.isDocumentUploading)
    ) {
      localErrors.push(this.props.nameRequiredError);
    }

    this.setState({ localErrors });
  }

  private updateProgress = (file: File, event: ProgressEvent) => {
    // Only show 90% complete until the upload has finished
    const percent = Math.round((event.loaded / event.total) * 90);
    this.setState((prevState) => {
      if (event.loaded / event.total >= 1) {
        return { uploadPercentComplete: 98 };
      }

      if (percent > prevState.uploadPercentComplete! && percent <= 90) {
        return { uploadPercentComplete: percent };
      }

      return {};
    });
  };

  private uploadError = (file: File | null, errors: string[]) => {
    if (file) {
      ErrorsStore.showErrors(errors.map((error) => `${file.name} - ${error}`));
    } else {
      ErrorsStore.showErrors(errors);
    }
    this.setState({ isDocumentUploading: false });
  };

  private uploadFile(files: FileList) {
    if (files.length !== 1) {
      this.uploadError(null, [Localization.getBuiltInMessage("singleUpload")]);

      return;
    }

    const file = files[0];
    const fullFileName = file.name;
    const index = fullFileName.lastIndexOf(".");
    const extension = index > 0 ? fullFileName.substring(index + 1) : "";

    if (
      this.props.validExtensions.length > 0 &&
      this.props.validExtensions.indexOf(extension.toLowerCase()) < 0
    ) {
      const error = this.props.validExtensionsError.replace(
        "{extension}",
        extension
      );
      this.uploadError(file, [error]);

      return;
    }

    if (
      this.props.maxFileBytes !== null &&
      file.size > this.props.maxFileBytes
    ) {
      this.uploadError(file, [this.props.maxFileBytesError]);

      return;
    }

    this.setState(
      {
        isDocumentUploading: true,
        newDocumentExtension: extension,
        newDocumentFileName:
          index > 0 ? fullFileName.substring(0, index) : fullFileName,
      },
      () => {
        this.setLocalErrors();
        this.rootRef.current!.focus();
      }
    );

    const batch = DocumentService.uploadFiles(
      this.props.dataId,
      this.props.name,
      [file],
      this.updateProgress,
      this.uploadError
    );

    Promise.all(batch).then((responses: DocumentUploadResponse[]) => {
      if (responses[0].uploadErrors.length <= 0) {
        this.completeDocumentUpload(responses[0], file);
      }
    });
  }

  public componentDidMount() {
    document.body.addEventListener("dragenter", this.onDragEnterIntoDocument);
    document.body.addEventListener("dragleave", this.onDragLeaveOutOfDocument);
    document.body.addEventListener("drop", this.onDropIntoDocument);

    // Monitor for when the presentation is saved after a document upload
    this.saveMonitorDisposer = autorun(() => {
      const runtimeProperties = Api.getWidgetProperties(
        this.props
      ) as RuntimeProperties;

      if (
        runtimeProperties &&
        this.lastDocumentHandle !== runtimeProperties.documentHandle
      ) {
        this.lastDocumentHandle = runtimeProperties.documentHandle;
        this.setState({ isDocumentUploadFinished: false });
      }
    });
  }

  public componentWillUnmount() {
    document.body.removeEventListener(
      "dragenter",
      this.onDragEnterIntoDocument
    );
    document.body.removeEventListener(
      "dragleave",
      this.onDragLeaveOutOfDocument
    );
    document.body.removeEventListener("drop", this.onDropIntoDocument);

    this.saveMonitorDisposer();
  }

  public render() {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            runtimeProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    let documentExtension = runtimeProperties.documentExtension;
    if (this.state.isDocumentUploading) {
      documentExtension = this.state.newDocumentExtension!;
    }
    const documentBaseName = this.getCurrentValue();

    const documentUrl = DocumentService.getDocumentUrl(
      runtimeProperties.documentHandle,
      runtimeProperties.pendingDocumentId,
      documentBaseName ? `${documentBaseName}.${documentExtension}` : "",
      runtimeProperties.downloadToken
    );

    let previewButton: React.ReactNode | null = null;

    switch (runtimeProperties.thumbnailType) {
      case "Medium":
        previewButton = (
          <ImageWithLink
            alternateText={Localization.getBuiltInMessage("viewDocument")}
            disableFocusRipple={true}
            fit="cover"
            height={48}
            href={documentUrl}
            imgSrc={DocumentService.getThumbnailUrl(
              runtimeProperties.documentHandle,
              runtimeProperties.pendingDocumentId,
              runtimeProperties.pendingThumbnailId,
              "Medium Thumbnail",
              runtimeProperties.downloadToken
            )}
            width={48}
          />
        );
        break;
      case "ExtensionIcon":
        previewButton = (
          <IconWithLink
            alternateText={Localization.getBuiltInMessage("viewDocument")}
            disableFocusRipple={true}
            height={48}
            href={documentUrl}
            icon={runtimeProperties.extensionIconName!}
            width={48}
          />
        );
        break;
      default:
    }

    const { classes, ..._props } = this.props;

    if (runtimeProperties.accessLevel === AccessLevel.readOnly) {
      const formattedDocumentName: string | null = documentBaseName
        ? `${documentBaseName}.${documentExtension}`
        : "-";

      return (
        <div className={classes.readOnlyRoot}>
          <div className={classes.row}>
            <div className={classes.cellLeft}>{previewButton}</div>
            <div className={classes.cellRight}>
              <TextField
                label={_props.label}
                name={_props.name}
                readOnly={true}
                value={formattedDocumentName}
                variant="filled"
              />
            </div>
          </div>
        </div>
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: () => this.getErrors(runtimeProperties.businessErrors),
      helperText: this.props.helperText,
    });

    const helperTextId: string | undefined = fieldHelperText.helperText
      ? this.helperTextId
      : undefined;

    let actionButton: React.ReactNode;
    if (runtimeProperties.canDelete) {
      actionButton = (
        <Button
          aria-label={Localization.getBuiltInMessage("deleteFile")}
          color="danger"
          disabled={this.state.isDocumentUploading}
          icon="fas fa-trash"
          onClick={this.deleteDocument}
          size="small"
        />
      );
    } else {
      // VERSION_WARNING Material-UI 4.9.14
      // aria-labelledby value is assuming that the id of the label
      // element will continue to following the existing format.
      actionButton = (
        <React.Fragment>
          <label htmlFor={`${this.componentId}-file-upload`}>
            <Button
              aria-hidden
              disabled={this.state.isDocumentUploading}
              fab
              size="small"
              tabIndex={-1}
            >
              <FocusRipple visible={this.state.isUploadButtonFocused} />
              <Icon icon="fas fa-upload" />
            </Button>
          </label>
          <input
            accept={this.validExtensions}
            aria-describedby={helperTextId}
            aria-invalid={fieldHelperText.hasErrors}
            aria-labelledby={`${this.componentId}-label`}
            className={classes.fileInput}
            id={`${this.componentId}-file-upload`}
            multiple={false}
            onBlur={() => {
              this.announceErrors(fieldHelperText.errors);
              this.setState({ isUploadButtonFocused: false });
            }}
            onFocus={() => {
              this.setState({ isUploadButtonFocused: true });
            }}
            onChange={this.onFileSelected}
            onMouseDown={this.onClickFileUpload}
            required={runtimeProperties.showAsMandatory}
            title={Localization.getBuiltInMessage("chooseFile")}
            type="file"
          />
        </React.Fragment>
      );
    }

    const showProgress: boolean =
      this.state.isDocumentUploading || this.state.isDocumentUploadFinished!;
    const uploadPercentComplete = this.state.uploadPercentComplete!;
    const progressBarStyle: string =
      uploadPercentComplete > 90 && uploadPercentComplete < 100
        ? classes.progressBarFilledSlow
        : classes.progressBarFilled;

    const textField = (
      <div style={{ position: "relative" }}>
        <TextField
          disabled={
            !this.isDocumentSelected(runtimeProperties) &&
            !this.state.isDocumentUploading
          }
          error={fieldHelperText.hasErrors}
          id={this.componentId}
          InputLabelProps={{
            classes: {
              root: classes.inputLabelRoot,
              shrink: classes.inputLabelShrink,
            },
            disabled: false,
          }}
          InputProps={{
            "aria-describedby": helperTextId,
            classes: { root: classes.input },
            endAdornment: (
              <muiInputAdornment.default
                position="end"
                style={{ marginTop: -4 }}
              >
                {actionButton}
              </muiInputAdornment.default>
            ),
            inputComponent: this.createCustomInput,
            inputProps: { extension: documentExtension },
          }}
          label={this.props.label}
          name={this.props.name}
          onBlur={() => {
            this.setLocalErrors();
            this.announceErrors(fieldHelperText.errors);
            this.setState({ isFocused: false });
          }}
          onChange={this.onChange}
          onFocus={() => {
            this.setState({ isFocused: true });
          }}
          required={runtimeProperties.showAsMandatory}
          value={documentBaseName || ""}
          variant="filled"
        />
        {showProgress ? (
          <React.Fragment>
            <muiLinearProgress.default
              aria-hidden={true}
              classes={{
                barColorPrimary: progressBarStyle,
                root: classes.progressBar,
              }}
              variant="determinate"
              value={uploadPercentComplete}
            />
            <Icon
              classes={{ root: classes.uploadCompleteIcon }}
              className="cx-progress-complete"
              icon="far fa-check-circle"
              style={{
                opacity: this.state.isDocumentUploadFinished ? 1 : 0,
              }}
            />
          </React.Fragment>
        ) : null}
        {this.state.isDragging ? (
          <div
            className={`${classes.dropArea} cx-drop-mask`}
            onDragOver={this.onDragOverIntoWidget}
            onDrop={this.onDropIntoWidget}
          >
            <Typography>
              <Icon icon="fas fa-cloud-upload" fixedWidth />
            </Typography>
            <Typography
              style={{ fontSize: 12, marginLeft: ".4em" }}
              variant="body2"
            >
              {Localization.getBuiltInMessage("drop")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              &nbsp;{Localization.getBuiltInMessage("uploadFile")}
            </Typography>
          </div>
        ) : null}
      </div>
    );

    return (
      <div
        className={classes.editableRoot}
        ref={this.rootRef}
        tabIndex={this.state.isDocumentUploading ? 0 : -1}
      >
        <div className={classes.row}>
          {previewButton && !this.state.isDocumentUploading ? (
            <div className={classes.cellLeft}>{previewButton}</div>
          ) : null}
          <div className={classes.cellRight}>{textField}</div>
        </div>
        {fieldHelperText.helperText ? (
          <div className={classes.row}>
            {previewButton && !this.state.isDocumentUploading ? (
              <div className={classes.cellLeft} />
            ) : null}
            <div className={classes.cellRight}>
              <FormHelperText
                aria-hidden="true"
                children={fieldHelperText.helperText}
                id={helperTextId}
                error={fieldHelperText.hasErrors}
                style={{
                  marginLeft: 16,
                  marginRight: this.state.isDocumentUploadFinished ? 20 : 0,
                }}
                variant="filled"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(DocumentEdit);
