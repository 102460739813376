import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Icon from "../coreui/Icon";
import ImageWithLink from "../coreui/ImageWithLink";
import Thumbnail from "../coreui/Thumbnail";
import Api, { AccessLevel } from "./Api";

interface SizeByBreakpoint {
  lg: number | undefined;
  md: number | undefined;
  sm: number | undefined;
  xs: number | undefined;
}

interface Props {
  dataId: string;
  imageHeight: SizeByBreakpoint;
  imageRender: "Fit to Size" | "Mask to Size";
  imageType:
    | "Download Link Only"
    | "Full Size"
    | "Large Thumbnail"
    | "Medium Thumbnail";
  imageWidth: SizeByBreakpoint;
  name: string;
  suppressDownloadLink: boolean;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  documentHandle: string | null;
  downloadToken: string;
  fileName: string;
  iconName: string;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
}

const styles = (theme: Theme) =>
  createStyles({
    image: {
      overflow: "hidden",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
    noImage: {
      "& > svg": {
        height: "100% !important",
        width: "100% !important",
      },
      color: theme.palette.grey[500],
      height: "75% !important",
      left: "50%",
      maxHeight: "96px",
      maxWidth: "96px",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "75% !important",
    },
  });

@observer
export class DataImageDisplay extends React.Component<
  Props & WithWidth & WithTheme & WithStyles<typeof styles>
> {
  private static getUrl(
    documentHandle: string | null,
    pendingDocumentId: number | null,
    pendingThumbnailId: number | null,
    thumbnailType: string,
    documentName: string,
    downloadToken: string
  ): string {
    // DocumentName is only passed to make the url more human readable
    return (
      "dynamic/DataImageDisplay/GetImage" +
      `/${documentHandle}` +
      `/${pendingDocumentId}/${pendingThumbnailId}` +
      `/${thumbnailType}` +
      `/${documentName}` +
      `?downloadToken=${downloadToken}`
    );
  }

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const widgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    let height: number | undefined = undefined;
    if (this.props.width in this.props.imageHeight) {
      height = this.props.imageHeight[this.props.width];
    }
    let width: number | undefined = undefined;
    if (this.props.width in this.props.imageWidth) {
      width = this.props.imageWidth[this.props.width];
    }

    if (!width || !height) {
      return null;
    }

    if (
      !widgetProperties.documentHandle &&
      !widgetProperties.pendingDocumentId
    ) {
      if (_props.imageType === "Download Link Only") {
        return null;
      }

      const background = this.props.theme.palette.grey[200];
      // If there *could be* an image (rel with cardinality
      // of 0) but there isn't an image, display the 'no image'
      // default, as per the ux standards
      return (
        <div
          style={{
            background,
            height,
            position: "relative",
            width,
          }}
        >
          <Icon
            aria-label={Localization.getBuiltInMessage("noImage")}
            className={_props.classes.noImage}
            fixedWidth
            icon="far fa-image"
          />
        </div>
      );
    }

    const imgSrc: string = DataImageDisplay.getUrl(
      widgetProperties.documentHandle,
      widgetProperties.pendingDocumentId,
      widgetProperties.pendingThumbnailId,
      _props.imageType,
      widgetProperties.fileName,
      widgetProperties.downloadToken
    );

    const downloadUrl: string = DataImageDisplay.getUrl(
      widgetProperties.documentHandle,
      widgetProperties.pendingDocumentId,
      widgetProperties.pendingThumbnailId,
      "Full Size",
      widgetProperties.fileName,
      widgetProperties.downloadToken
    );

    if (_props.imageType === "Full Size") {
      const fit = _props.imageRender === "Mask to Size" ? "cover" : "contain";

      if (!this.props.suppressDownloadLink) {
        return (
          <ImageWithLink
            alternateText={widgetProperties.alternateText}
            fit={fit}
            height={height}
            href={downloadUrl}
            imgSrc={imgSrc}
            width={width}
          />
        );
      }

      return (
        <div style={{ overflow: "hidden" }}>
          <div
            className={_props.classes.image}
            style={{
              height,
            }}
          >
            <img
              aria-label={widgetProperties.alternateText}
              src={imgSrc}
              style={{
                height: "100%",
                maxWidth: "100%",
                objectFit: fit,
                width,
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <Thumbnail
        alternateText={widgetProperties.alternateText}
        downloadUrl={downloadUrl}
        hasImage={true}
        iconName={widgetProperties.iconName}
        imageHeight={height}
        imageWidth={width}
        imgSrc={imgSrc}
        thumbnailType={_props.imageType}
      />
    );
  }
}

export default withStyles(styles)(withWidth()(withTheme(DataImageDisplay)));
