import {
  createStyles,
  Theme,
  TypographyVariant,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTypography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Grid from "../coreui/Grid";
import GridItem from "../coreui/GridItem";
import Icon from "../coreui/Icon";
import Api, { AccessLevel } from "./Api";
import { MediaChild } from "./Media";

interface ConfigProperties {
  dataId: string;
  headingSize:
    | "Level 1"
    | "Level 2"
    | "Level 3"
    | "Level 4"
    | "Level 5"
    | "Level 6";
  iconName: string;
  name: string;
  propagated: MediaChild;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  headingText: string;
  helperText: string;
  showAsMandatory: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    goToTopBtn: {
      backgroundColor: theme.palette.common.white,
      marginRight: 8,

      [theme.breakpoints.up("xs")]: {
        marginLeft: Sys.settings.baselineGridSize * 4,
        marginTop: Sys.settings.baselineGridSize * 1,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: Sys.settings.baselineGridSize * 6,
        marginTop: Sys.settings.baselineGridSize * 2,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: Sys.settings.baselineGridSize * 10,
        marginTop: Sys.settings.baselineGridSize * 4,
      },
    },
    helperText: {
      marginTop: 8,
      maxWidth: 580,
    },
    root: {
      "&:focus": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    topMediaContainerChild: {
      textAlign: "center",
    },
  });

@observer
export class GroupHeading extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private readonly helperTextId: string;
  private readonly mandatoryIndicatorId: string;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `group-heading-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.mandatoryIndicatorId = `${this.componentId}-mandatory-indicator`;
  }

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    let variant: TypographyVariant | undefined = undefined;
    if ("headingSize" in this.props) {
      variant = Api.getTextStyle(this.props.headingSize);
    }

    let icon: React.ReactNode = null;
    if ("iconName" in this.props && this.props.iconName) {
      icon = (
        <GridItem>
          <muiTypography.default variant={variant}>
            <Icon
              fixedWidth
              icon={this.props.iconName}
              style={{ marginRight: ".4em" }}
            />
          </muiTypography.default>
        </GridItem>
      );
    }

    const classes: string[] = [this.props.classes.root];
    const helperClasses: string[] = [this.props.classes.helperText];

    const mandatoryIndicator: string = Localization.getBuiltInMessage(
      "Heading.mandatoryIndicator"
    );

    const ariaDescribedByIds = [];
    if (runtimeProperties.showAsMandatory) {
      ariaDescribedByIds.push(this.mandatoryIndicatorId);
    }
    if (runtimeProperties.helperText) {
      ariaDescribedByIds.push(this.helperTextId);
    }

    const ariaDescribedBy: string | undefined =
      ariaDescribedByIds.length > 0 ? ariaDescribedByIds.join(" ") : undefined;

    if (this.props.propagated?.parentMediaOrientation === "Top") {
      classes.push(this.props.classes.topMediaContainerChild);
      helperClasses.push(this.props.classes.topMediaContainerChild);
    }

    return (
      <div>
        <Grid wrap="nowrap">
          {icon}
          <GridItem style={{ flex: "1 1 auto" }}>
            <muiTypography.default
              aria-describedby={ariaDescribedBy}
              className={classes.join(" ")}
              id={this.componentId}
              tabIndex={-1}
              variant={variant}
            >
              <span>{runtimeProperties.headingText}</span>
              {runtimeProperties.showAsMandatory ? (
                <span
                  aria-hidden={true}
                  style={{ color: Api.getSystemColor("danger") }}
                >
                  &nbsp;*
                </span>
              ) : null}
            </muiTypography.default>
          </GridItem>
        </Grid>
        {runtimeProperties.helperText ? (
          <muiTypography.default
            className={helperClasses.join(" ")}
            id={this.helperTextId}
          >
            {runtimeProperties.helperText}
          </muiTypography.default>
        ) : null}
        {runtimeProperties.showAsMandatory ? (
          <div id={this.mandatoryIndicatorId} style={{ display: "none" }}>
            {mandatoryIndicator}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(GroupHeading);
