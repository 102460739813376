import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTypography from "@material-ui/core/Typography";
import * as React from "react";

// VERSION_WARNING Material-UI 4.9.1
// MaterialUI's new "generic" component prop injection infrastructure seems to
// break typescript. Specifically, it does not appear to exist on the Props
// exposed by MaterialUI even though TypeScript thinks the prop is valid when
// using the raw MUI component. The work around to this is to simply re-expose
// the component prop here and then manually pass it on to the MUI component.
interface Props extends muiTypography.TypographyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ElementType<any>;
  ellipsis?: boolean;
  flex?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
    flex: {
      flex: "auto",
    },
    root: {},
  });

export class Typography extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const {
      classes,
      className,
      component,
      ellipsis,
      flex,
      ...otherProps
    } = this.props;

    const {
      ellipsis: ellipsisClass,
      flex: flexClass,
      root: rootClass,
      ...otherClasses
    } = classes;

    const classNames = [rootClass];

    if (ellipsis) {
      classNames.push(ellipsisClass);
    }
    if (flex) {
      classNames.push(flexClass);
    }
    if (className) {
      classNames.push(className);
    }

    return (
      <muiTypography.default
        classes={otherClasses}
        className={classNames.join(" ")}
        component={component!}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(Typography);
