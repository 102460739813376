import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import PaneRow from "../../models/PaneRow";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";

interface Props extends ActionButtonProps {}

@observer
export class ClearCriteriaButton extends React.Component<Props> {
  private getLabel = (): string => {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;

    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("SearchButton.clearCriteriaLabel");

    return label;
  };

  private onClick = (): void => {
    this.props.propagated.parentSearch.clear();
    Sys.announce(
      Localization.getBuiltInMessage("Button.succeeded", {
        label: this.getLabel(),
      })
    );
  };

  public render(): React.ReactNode {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;

    if (runtimeProperties.accessLevel === undefined) {
      return null;
    }

    return (
      <ApiButton
        alternateText={runtimeProperties.alternateText}
        buttonColor={this.props.buttonColor}
        dataId={this.props.dataId}
        disabledHelpText={this.props.disabledHelpText}
        iconName={this.props.iconName}
        isIconOnly={!runtimeProperties.label}
        label={this.getLabel()}
        name={this.props.name}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default ClearCriteriaButton;
