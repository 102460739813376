import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Typography from "../coreui/Typography";
import Api from "./Api";

interface Props {
  title?: string;
}

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.error.main,
      borderStyle: "solid",
      borderWidth: 1,
      padding: 8,
    },
  });

export class ErrorBoundary extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  private childrenWithError: React.ReactNode;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { error: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: any, errorInfo: any) {
    this.childrenWithError = this.props.children;
  }

  public componentDidUpdate() {
    if (this.props.children !== this.childrenWithError) {
      this.childrenWithError = null;
      this.setState({ error: null });
    }
  }

  public render() {
    const _props = { ...this.props };
    let result: React.ReactNode = _props.children;
    let title: React.ReactNode = null;

    if (!!this.state.error) {
      let message: string = "An unexpected error occurred";

      if (process.env.NODE_ENV !== "production") {
        if (_props.title) {
          title = (
            <Typography style={{ marginBottom: 8 }}>{_props.title}</Typography>
          );
        }

        message =
          this.state.error instanceof Error
            ? this.state.error.message
            : this.state.error.toString();
      }

      result = (
        <div className={_props.classes.root}>
          {title}
          <Typography color="error" component="div">
            {Api.getErrorMessage(message)}
          </Typography>
        </div>
      );
    }

    return result;
  }
}

export default withStyles(styles)(ErrorBoundary);
