import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import PaneRow from "../../models/PaneRow";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";

interface Props extends ActionButtonProps {}

@observer
export class ExecuteSearchButton extends React.Component<Props> {
  private onClick = (): void => {
    this.props.propagated.parentSearch.search();
  };

  public render(): React.ReactNode {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;

    if (runtimeProperties.accessLevel === undefined) {
      return null;
    }

    const isIconOnly = !runtimeProperties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("SearchButton.executeSearchLabel")
      : runtimeProperties.label;

    return (
      <ApiButton
        alternateText={runtimeProperties.alternateText}
        buttonColor={this.props.buttonColor}
        dataId={this.props.dataId}
        disabledHelpText={this.props.disabledHelpText}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        name={this.props.name}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default ExecuteSearchButton;
