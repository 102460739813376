import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import Thumbnail, { ThumbnailTypes } from "../../coreui/Thumbnail";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import DocumentService from "../../services/DocumentService";
import Api, { AccessLevel } from "../Api";
import { FunctionName } from "../TableSummary";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellRendererParams {
  dataId: string;
  imageHeight: number;
  imageWidth: number;
  name: string;
  thumbnailType: ThumbnailTypes;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  documentHandle: string | null;
  downloadToken: string;
  fileName: string;
  hasThumbnailImage: boolean;
  iconName: string;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      marginLeft: -1,
      width: "100%",
    },
  });

export class DataImageDisplayColumn extends React.PureComponent<
  ConfigProperties & WithStyles<typeof styles>
> {
  public static readonly widgetType: string = "DataImageDisplayColumn";

  private linkRef: HTMLElement | null;

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    return "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      const runtimeProperties = data.properties as RuntimeProperties;

      if (
        runtimeProperties.accessLevel >= AccessLevel.readOnly &&
        (runtimeProperties.documentHandle ||
          runtimeProperties.pendingDocumentId)
      ) {
        result += 1;
      }
    }

    return result.toString();
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    CellUtil.setReadOnlyAttribute(props.eGridCell, true);
    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
    props.eGridCell.addEventListener("focus", this.onCellFocus);
  }

  private onCellFocus = (): void => {
    if (this.linkRef) {
      this.linkRef.focus();
    }
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("focus", this.onCellFocus);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props,
      this.props.data
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      !runtimeProperties.documentHandle &&
      !runtimeProperties.pendingDocumentId
    ) {
      return null;
    }

    const imgSrc: string = DocumentService.getThumbnailUrl(
      runtimeProperties.documentHandle,
      runtimeProperties.pendingDocumentId,
      runtimeProperties.pendingThumbnailId,
      this.props.thumbnailType,
      runtimeProperties.downloadToken
    );

    const downloadUrl: string = DocumentService.getDocumentUrl(
      runtimeProperties.documentHandle,
      runtimeProperties.pendingDocumentId,
      runtimeProperties.fileName,
      runtimeProperties.downloadToken
    );

    return (
      <div className={this.props.classes.root}>
        <Thumbnail
          alternateText={runtimeProperties.alternateText}
          disableFocusRipple={true}
          downloadUrl={downloadUrl}
          hasImage={runtimeProperties.hasThumbnailImage}
          iconName={runtimeProperties.iconName}
          imageHeight={this.props.imageHeight}
          imageWidth={this.props.imageWidth}
          imgSrc={imgSrc}
          inGrid={true}
          linkRef={(r) => (this.linkRef = r)}
          tabIndex={-1}
          thumbnailType={this.props.thumbnailType}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DataImageDisplayColumn);
