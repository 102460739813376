import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../../core/Localization";
import { CustomTheme } from "../../muiTheme";
import { ValueByBreakpoint } from "../../mustangui/Api";

interface Props {
  url: string;
  visible?: boolean | ValueByBreakpoint<boolean>;
}

const styles = (theme: CustomTheme) => {
  const visibleStyle = {
    display: "block",
  };

  return createStyles({
    content: {
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    image: {
      objectFit: "cover",
      objectPosition: "0 86%",
    },
    mask: {
      background:
        "linear-gradient(to right, rgba(33,33,33,0.9) 0%, " +
        "rgba(33,33,33,0.5) 50%, rgba(33,33,33,0.9) 100%)",
    },
    root: {
      bottom: 0,
      display: "none",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: -1,
    },
    "visible-all": visibleStyle,
    "visible-lg": { [theme.breakpoints.up("lg")]: visibleStyle },
    "visible-md": { [theme.breakpoints.only("md")]: visibleStyle },
    "visible-sm": { [theme.breakpoints.only("sm")]: visibleStyle },
    "visible-xs": { [theme.breakpoints.only("xs")]: visibleStyle },
  });
};

class BackgroundImage extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const imageClasses = [this.props.classes.content, this.props.classes.image];

    const maskClasses = [this.props.classes.content, this.props.classes.mask];

    const visible =
      this.props.visible !== undefined ? this.props.visible : true;

    const rootClasses = [this.props.classes.root];
    if (typeof visible === "boolean") {
      if (visible) {
        rootClasses.push(this.props.classes["visible-all"]);
      }
    } else {
      for (const breakPoint of ["lg", "md", "sm", "xs"]) {
        if (visible[breakPoint]) {
          rootClasses.push(this.props.classes[`visible-${breakPoint}`]);
        }
      }
    }

    return (
      <div className={rootClasses.join(" ")}>
        <img
          alt={Localization.getBuiltInMessage("background")}
          aria-hidden={true}
          className={imageClasses.join(" ")}
          src={this.props.url}
        />
        <div className={maskClasses.join(" ")} />
      </div>
    );
  }
}

export default withStyles(styles)(BackgroundImage);
