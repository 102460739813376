import { observable } from "mobx";

export interface SessionMessage {
  message: string;
  messageId: number;
  messageType: "Caution" | "Danger" | "Info" | "Success";
}

export default class SessionMessagesStore {
  @observable private static sessionMessages: SessionMessage[] = [];

  public static get messages(): SessionMessage[] {
    return this.sessionMessages;
  }

  public static addMessages(messages: SessionMessage[]): void {
    this.sessionMessages.push(...messages);
  }

  public static removeMessage(message: SessionMessage): void {
    const index = this.sessionMessages.indexOf(message);

    if (index > -1) {
      this.sessionMessages.splice(index, 1);
    }
  }

  public static setMessages(messages: SessionMessage[]): void {
    this.sessionMessages = messages;
  }
}
