import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { CustomTheme } from "../../muiTheme";

export default class CenteredContentClasses {
  public static create(theme: CustomTheme): {} {
    return {
      "container-all": {
        [theme.breakpoints.up("lg")]: {
          width: theme.container.maxWidth.lg,
        },
        [theme.breakpoints.only("md")]: {
          width: theme.container.maxWidth.md,
        },
        [theme.breakpoints.up("sm")]: {
          marginLeft: "auto",
          marginRight: "auto",
        },
        [theme.breakpoints.only("sm")]: {
          width: theme.container.maxWidth.sm,
        },
        [theme.breakpoints.only("xs")]: {
          marginLeft: theme.horizontalSpacing.related.xs,
          marginRight: theme.horizontalSpacing.related.xs,
        },
      },
      "container-lg": {
        [theme.breakpoints.up("lg")]: {
          marginLeft: "auto",
          marginRight: "auto",
          width: theme.container.maxWidth.lg,
        },
      },
      "container-md": {
        [theme.breakpoints.only("md")]: {
          marginLeft: "auto",
          marginRight: "auto",
          width: theme.container.maxWidth.md,
        },
      },
      "container-sm": {
        [theme.breakpoints.only("sm")]: {
          marginLeft: "auto",
          marginRight: "auto",
          width: theme.container.maxWidth.sm,
        },
      },
      "container-xs": {
        [theme.breakpoints.only("xs")]: {
          marginLeft: theme.horizontalSpacing.related.xs,
          marginRight: theme.horizontalSpacing.related.xs,
        },
      },
    };
  }

  public static get(breakPoint: Breakpoint | null, classes: {}): string {
    if (breakPoint === null) {
      return classes["container-all"];
    }

    if (breakPoint === "xl") {
      return classes["container-lg"];
    }

    return classes[`container-${breakPoint}`];
  }
}
