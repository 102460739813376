import Badge from "@material-ui/core/Badge";
import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";
import { CustomTheme } from "../muiTheme";
import Api from "../mustangui/Api";
import FocusRipple from "./FocusRipple";
import Icon from "./Icon";

const BadgeButton = withStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      backgroundColor: Api.getSystemColor("information"),
      borderRadius: "50%",
      color: theme.palette.common.white,
      fontSize: 9,
      height: 16,
      minWidth: 16,
      width: 16,
    },
  })
)(ButtonBase);

const StyledBadge = withStyles((theme: CustomTheme) =>
  createStyles({
    badge: {
      height: 32,
      padding: 8,
    },
    root: {
      cursor: "pointer",
      width: "100%",
    },
  })
)(Badge);

interface Props {
  classes?: { badge?: string; root?: string };
  children: React.ReactNode;
  isFocusRippleVisible: boolean;
  isHelpOpen: boolean;
  helpText: string;
  onHelpOpenChange: (isHelpOpen: boolean) => void;
}

export default function DisabledHelpBadge(props: Props): JSX.Element {
  const onBlur = (event: React.FocusEvent<HTMLElement>): void => {
    props.onHelpOpenChange(false);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    props.onHelpOpenChange(!props.isHelpOpen);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === "Escape") {
      props.onHelpOpenChange(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <StyledBadge
      badgeContent={
        <Tooltip
          disableHoverListener
          enterTouchDelay={0}
          open={props.isHelpOpen}
          title={props.helpText}
        >
          <div>
            <BadgeButton onClick={onClick} tabIndex={-1}>
              <Icon icon="fas fa-question" style={{ height: 9 }} />
            </BadgeButton>
            <FocusRipple visible={props.isFocusRippleVisible} />
          </div>
        </Tooltip>
      }
      classes={{
        badge: props.classes?.badge,
        root: props.classes?.root,
      }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      {props.children}
    </StyledBadge>
  );
}
