import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useObserver } from "mobx-react-lite";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import { ContentsExistBadge } from "../coreui/ContentsExistBadge";
import multiClassName from "../coreui/MultiClassName";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import { AddressSearchCriteria } from "./AddressSearchCriteria";
import { ValueByBreakpoint } from "./Api";
import { DateRangeCriteria } from "./DateRangeCriteria";
import { DomainCheckBoxCriteria } from "./DomainCheckBoxCriteria";

interface ConfigProperties {
  childWidgetNames: string[];
  children?: React.ReactNode;
  dataId: string;
  isVisible: ValueByBreakpoint<boolean>;
}

const useStyles = makeStyles((theme: CustomTheme) => {
  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`visible-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "block",
      },
    };
  }

  const result = {
    buttonContainer: {},
    contentHeading: {
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.verticalSpacing.closelyRelated.lg,
        marginTop: theme.verticalSpacing.related.lg,
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: theme.verticalSpacing.closelyRelated.md,
        marginTop: theme.verticalSpacing.related.md,
      },
      [theme.breakpoints.only("sm")]: {
        marginBottom: theme.verticalSpacing.closelyRelated.sm,
        marginTop: theme.verticalSpacing.related.sm,
      },
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.verticalSpacing.closelyRelated.xs,
        marginTop: theme.verticalSpacing.related.xs,
      },
    },
    root: {
      display: "none",
    },
    ...visibilityStyles,
  };

  return result;
});

export default function AdvancedSearch(
  props: ConfigProperties
): JSX.Element | null {
  const styles = useStyles();

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const onButtonClick = (): void => {
    setIsExpanded(!isExpanded);
  };

  return useObserver(() => {
    const row: PaneRow | null = PaneRow.get(props.dataId);
    if (row === null) {
      return null;
    }

    let enteredCriteriaWidgets: number = 0;
    for (const widgetName of props.childWidgetNames) {
      const widget: RuntimeWidget = row.getWidget(widgetName)!;

      switch (widget.widgetTypeId) {
        case AddressSearchCriteria.widgetTypeId:
          if (AddressSearchCriteria.isEntered(widgetName, row)) {
            enteredCriteriaWidgets += 1;
          }
          break;
        case DateRangeCriteria.widgetTypeId:
          if (DateRangeCriteria.isEntered(widgetName, row)) {
            enteredCriteriaWidgets += 1;
          }
          break;
        case DomainCheckBoxCriteria.widgetTypeId:
          if (DomainCheckBoxCriteria.isEntered(widgetName, row)) {
            enteredCriteriaWidgets += 1;
          }
          break;
        default:
          if (widget.value !== null) {
            enteredCriteriaWidgets += 1;
          }
      }
    }

    const label: string = Localization.getBuiltInMessage("advancedSearch");

    return (
      <div
        className={multiClassName(
          styles.root,
          props.isVisible.lg ? styles["visible-lg"] : null,
          props.isVisible.md ? styles["visible-md"] : null,
          props.isVisible.sm ? styles["visible-sm"] : null,
          props.isVisible.xl ? styles["visible-xl"] : null,
          props.isVisible.xs ? styles["visible-xs"] : null
        )}
      >
        <ContentsExistBadge badgeContent={enteredCriteriaWidgets}>
          <Button
            aria-expanded={isExpanded}
            aria-label={
              !!enteredCriteriaWidgets
                ? `${label} ${Localization.getBuiltInMessage(
                    "SearchPresentation.advancedSearchHasContents"
                  )}`
                : label
            }
            endIcon={isExpanded ? "fas fa-minus" : "fas fa-plus"}
            label={label}
            onClick={onButtonClick}
            size="small"
          />
        </ContentsExistBadge>
        <Collapse in={isExpanded}>
          <Typography className={styles.contentHeading} variant="h3">
            {label}
          </Typography>
          {props.children}
        </Collapse>
      </div>
    );
  });
}
