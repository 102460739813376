import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import Icon from "../coreui/Icon";
import Menu from "../coreui/Menu";
import MenuItem from "../coreui/MenuItem";
import { CustomTheme } from "../muiTheme";
import BaseService from "../services/BaseService";
import { MenuButton } from "./MenuButton";
import { MenuItemProps } from "./MenuItem";
import { ToolbarOverflowChild } from "./ToolbarContainerOverflowSection";

interface ConfigProperties {
  dataId: string;
  name: string;
  propagated: { indent?: number } & ToolbarOverflowChild;
}

interface State {
  buttonElement?: HTMLElement;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    largeVersion: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    smallVersion: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
    },
  });

@observer
export class LanguageSelect extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private currentLanguageCode: string;
  private readonly labelId: string;

  private static changeLanguage = (languageCode: string) => {
    BaseService.request(`LanguageSelect/OnChange/${languageCode}`).then(() => {
      window.location.reload();
    });
  };

  public static renderMenuItem(props: MenuItemProps): JSX.Element | null {
    if (!LanguageSelect.shouldRender()) {
      return null;
    }

    const configProps = props.config as ConfigProperties;

    const indent: number =
      configProps.propagated && configProps.propagated.indent
        ? configProps.propagated.indent
        : 0;
    const gridSize: number = Sys.settings.baselineGridSize;

    return (
      <MenuButton.renderFlatMenu
        header={Localization.getBuiltInMessage("language")}
        disabled={false}
        iconName={
          configProps.propagated.hideIcons ? undefined : "fas fa-language"
        }
        id={`menu-${configProps.dataId}-${configProps.name}`}
        indent={indent}
      >
        {Sys.settings.availableLanguages.map((l) => (
          <MenuItem
            children={l.description}
            indent={indent + gridSize * 6}
            key={l.code}
            onClick={() => LanguageSelect.changeLanguage(l.code)}
          />
        ))}
      </MenuButton.renderFlatMenu>
    );
  }

  public static shouldRender(): boolean {
    return Sys.settings.availableLanguages.length > 1;
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = {};

    this.componentId = `language-select-${Sys.nextId}`;
    this.currentLanguageCode = Sys.settings.currentLanguageCode;
    this.labelId = `${this.componentId}-label`;
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        buttonElement: prevState.buttonElement ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ buttonElement: undefined });
  };

  public render() {
    if (!LanguageSelect.shouldRender()) {
      return null;
    }

    const languages = Sys.settings.availableLanguages;

    const currentLanguage = languages.find(
      (l) => l.code === this.currentLanguageCode
    );
    const changeLanguageMessage = Localization.getBuiltInMessage(
      "changeLanguage"
    );
    const ariaLabel = `${changeLanguageMessage}: ${currentLanguage?.description}`;

    return (
      <div>
        <div className={this.props.classes.largeVersion}>
          <ButtonLink
            aria-expanded={!!this.state.buttonElement}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            onClick={this.onClick}
            tabIndex={-1}
          >
            <Icon
              icon="fas fa-language"
              style={{ fontSize: "24px", marginRight: ".4em" }}
            />
            {currentLanguage?.description}
            <Icon icon="fas fa-caret-down" style={{ marginLeft: ".4em" }} />
          </ButtonLink>
        </div>
        <div className={this.props.classes.smallVersion}>
          <Button
            aria-expanded={!!this.state.buttonElement}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            fab
            onClick={this.onClick}
            size="large"
            tabIndex={-1}
          >
            <Icon icon="fas fa-language" />
          </Button>
        </div>
        <Menu
          anchorEl={this.state.buttonElement}
          labelId={this.labelId}
          onClose={this.onCloseMenu}
          variant="selectedMenu"
        >
          {languages.map((l) => (
            <MenuItem
              aria-selected={l.code === this.currentLanguageCode}
              children={l.description}
              key={l.code}
              onClick={() => LanguageSelect.changeLanguage(l.code)}
              role="option"
              selected={l.code === this.currentLanguageCode}
            />
          ))}
        </Menu>
        <div id={this.labelId} style={{ display: "none" }}>
          {changeLanguageMessage}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LanguageSelect);
