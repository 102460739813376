import * as muiRadio from "@material-ui/core/Radio";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import Sys from "../core/Sys";
import FormControlLabel from "../coreui/FormControlLabel";
import Icon from "../coreui/Icon";
import Presentation from "../coreui/Presentation";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";

interface Props {
  controlKey: string | null;
  controlledPaneKeysByValue: object | null;
  dataId: string;
  isPaneController: boolean;
  label: string;
  margin: boolean;
  name: string;
  propagated?: DialogChildProps;
  roundTripOnChange: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      // Makes the icon square
      marginRight: -1.1,
    },
    ripple: {
      overflow: "visible",
    },
    root: {},
  });

@observer
export class ApiRadio extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private formLabelControlElement: HTMLDivElement;
  private readonly labelId: string;

  protected muiProps: muiRadio.RadioProps;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `radio-${Sys.nextId}`;
    this.labelId = `${this.componentId}-labelled-by`;

    this.muiProps = {
      checkedIcon: (
        <Icon icon="far fa-dot-circle" className={props.classes.icon} />
      ),
      color: "default",
      icon: <Icon icon="far fa-circle" className={props.classes.icon} />,
      TouchRippleProps: { className: props.classes.ripple },
    };
  }

  private setBinding(): void {
    this.muiProps.onChange = () => {
      ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
      Presentation.setValue(this.props, this.props.value);
    };

    this.muiProps.checked =
      Presentation.getValue(this.props) === (this.props.value || "");

    this.muiProps.value = Presentation.getValue(this.props);
  }

  public render(): React.ReactNode {
    this.muiProps.className = `${this.props.classes.root} `;

    this.muiProps.disabled = false;
    this.setBinding();

    if (this.props.roundTripOnChange) {
      this.muiProps.onChange = () => {
        const value = Presentation.getValue(this.props);

        ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, this.props.value);

        RoundTripService.standardRoundTrip("RadioSelect/OnChange", this.props, {
          dialogRowKey: this.props.propagated?.parentDialog?.rowKey,
        }).catch((reason) => {
          if (reason) {
            throw reason;
          } else {
            // If the round trip fails, undo the value change.
            Presentation.setValue(this.props, value);
          }
        });
        if (Sys.isSafari) {
          this.formLabelControlElement.focus();
        }
      };
    }

    // VERSION_WARNING iOS 15 / Safari 15 (Mac OS)
    // There is a bug in Safari where input elements that are nested in a label
    // get trapped in voiceover navigation after a roundtrip if focus returns
    // back to the input element. When this happens, focus will not proceed in
    // iOS while focus will be sent to the first element on the page in Safari
    // on MacOS.
    // The work-around implemented here is to force voice-over's focus to be
    // on the element that contains both the input and the label, and to make
    // the browser's focus be on that same element after roundtrip.
    // This condition seems to circumvent the bug.
    let labelId: string | undefined;
    let label: React.ReactNode;
    if (Sys.isSafari) {
      labelId = this.labelId;
      label = (
        <span aria-hidden="true" id={this.labelId} tabIndex={-1}>
          {this.props.label}
        </span>
      );
    } else {
      labelId = undefined;
      label = this.props.label;
    }

    return (
      <FormControlLabel
        aria-labelledby={labelId}
        control={<muiRadio.default {...this.muiProps} />}
        id={this.componentId}
        label={label}
        refSetter={(element) => (this.formLabelControlElement = element)}
        style={this.props.margin ? { marginRight: 24 } : undefined}
      />
    );
  }
}

export default withStyles(styles)(ApiRadio);
