import ButtonBase from "@material-ui/core/ButtonBase";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTextField from "@material-ui/core/TextField";
import * as muiTypography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../core/Sys";
import DisabledHelpBadge from "./DisabledHelpBadge";
import getFieldHelperText from "./FieldHelperText";
import FormControl from "./FormControl";
import Icon from "./Icon";
import InputAdornment from "./InputAdornment";
import multiClassName from "./MultiClassName";
import Typography from "./Typography";

export interface TextFieldProps extends muiTextField.FilledTextFieldProps {
  disabledHelpText?: string;
  endAdornment?: React.ReactNode;
  getErrors?: (value: string) => string[];
  icon?: string;
  onValueChange?: (value: string) => void;
  readOnly?: boolean;
  readOnlyProps?: {
    classes?: {
      content?: string;
      root?: string;
    };
    contentRef?: React.Ref<HTMLDivElement> | React.RefObject<HTMLDivElement>;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    disabledButton: {
      textAlign: "left",
      width: "100%",
    },
    inputLabelRoot: {
      width: "calc(100% - 33px)",
    },
    inputLabelRootEndAdornment: {
      width: "calc(100% - 67px)",
    },
    inputLabelRootIcon: {
      width: "calc(100% - 55px)",
    },
    inputLabelRootIconEndAdornment: {
      width: "calc(100% - 89px)",
    },
    inputLabelShrink: {
      width: "calc((100% - 33px) * 1.333)",
    },
    inputLabelShrinkEndAdornment: {
      width: "calc((100% - 67px) * 1.333)",
    },
    inputLabelShrinkIcon: {
      width: "calc((100% - 55px) * 1.333)",
    },
    inputLabelShrinkIconEndAdornment: {
      width: "calc((100% - 89px) * 1.333)",
    },
    readOnlyContent: {
      "&:focus-visible": {
        outline: "1px solid",
        outlineColor: theme.palette.grey[800],
        outlineOffset: "2px",
      },
    },
    readOnlyContentMultiline: {
      whiteSpace: "pre-wrap",
    },
    readOnlyLabel: {
      color: theme.palette.grey[700],
      flexShrink: 0,
      marginBottom: 8,
    },
    readOnlyRoot: {
      "&:hover": { backgroundColor: "transparent" },
      backgroundColor: "transparent",
    },
    root: {},
    rootWithStartIcon: {
      "& label, & input": {
        marginLeft: 22,
      },
    },
    startIcon: {
      color: theme.palette.text.primary,
      left: 16,
      position: "absolute",
    },
  });

interface State {
  isDisabledHelpOpen: boolean;
  isFocusRippleVisible: boolean;
}

@observer
export class TextField extends React.Component<
  TextFieldProps & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;

  public constructor(props: TextFieldProps & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `text-field-${Sys.nextId}`;

    this.state = { isDisabledHelpOpen: false, isFocusRippleVisible: false };
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.onValueChange) {
      return;
    }

    this.props.onValueChange(event.target.value as string);
  };

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  public render(): React.ReactNode {
    const {
      classes,
      className,
      disabled,
      disabledHelpText,
      endAdornment,
      error,
      getErrors,
      helperText,
      icon,
      id,
      inputProps,
      InputProps,
      onValueChange,
      readOnly,
      readOnlyProps: readOnlyPropOverrides,
      variant,
      ...otherProps
    } = this.props;

    const componentId = id !== undefined ? id : this.componentId;
    const disabledHelpTextId = `${componentId}-disabled-help-text`;
    const labelId = `${this.componentId}-label`;

    if (readOnly) {
      const { inputRef, label, multiline, value } = otherProps;

      const readOnlyProps = {
        classes: {
          content: "",
          root: "",
        },
        contentRef: undefined,
        ...readOnlyPropOverrides,
      };

      const readOnlyClassNames: string[] = [
        readOnlyProps.classes.root || "",
        classes.readOnlyRoot,
      ];

      return (
        <FormControl className={readOnlyClassNames.join(" ")} fullWidth>
          <Typography
            aria-hidden={true}
            className={classes.readOnlyLabel}
            ellipsis
            id={labelId}
            variant="caption"
          >
            {label}
          </Typography>
          <muiTypography.default
            aria-labelledby={labelId}
            aria-readonly={true}
            className={multiClassName(
              classes.readOnlyContent,
              readOnlyProps.classes.content,
              multiline ? classes.readOnlyContentMultiline : ""
            )}
            innerRef={inputRef}
            ref={readOnlyProps.contentRef}
            role="textbox"
            tabIndex={0}
          >
            {(value as string) || "-"}
          </muiTypography.default>
        </FormControl>
      );
    }

    const classNames: string[] = [className ? className : "", classes.root];

    const inputLabelClasses = {
      root: classes.inputLabelRoot,
      shrink: classes.inputLabelShrink,
    };

    if (icon !== undefined && endAdornment === undefined) {
      inputLabelClasses.root = classes.inputLabelRootIcon;
      inputLabelClasses.shrink = classes.inputLabelShrinkIcon;
    } else if (icon === undefined && endAdornment !== undefined) {
      inputLabelClasses.root = classes.inputLabelRootEndAdornment;
      inputLabelClasses.shrink = classes.inputLabelShrinkEndAdornment;
    } else if (icon !== undefined && endAdornment !== undefined) {
      inputLabelClasses.root = classes.inputLabelRootIconEndAdornment;
      inputLabelClasses.shrink = classes.inputLabelShrinkIconEndAdornment;
    }

    if (icon !== undefined) {
      classNames.push(classes.rootWithStartIcon);
    }

    let adornment: React.ReactNode = null;

    if (icon !== undefined || endAdornment !== undefined) {
      // End adornment is used instead of start since when start is set
      // the field moves the label above the input
      adornment = (
        <InputAdornment position="end">
          {icon !== undefined ? (
            <Icon classes={{ root: classes.startIcon }} icon={icon} />
          ) : null}
          {endAdornment}
        </InputAdornment>
      );
    }

    if (adornment && InputProps?.endAdornment) {
      adornment = (
        <React.Fragment>
          {InputProps.endAdornment}
          {adornment}
        </React.Fragment>
      );

      // Creating a local clone of the InputProps causes rendering issues
      // so we are manipulating the provided properties.
      delete InputProps.endAdornment;
    }

    if (disabled) {
      if (disabledHelpText === undefined) {
        return (
          <muiTextField.default
            className={classNames.join(" ")}
            disabled={true}
            fullWidth={true}
            id={componentId}
            InputLabelProps={{ classes: inputLabelClasses }}
            inputProps={inputProps}
            InputProps={{
              endAdornment: adornment,
              ...InputProps,
            }}
            onChange={this.onChange}
            type="text"
            variant="filled"
            {...otherProps}
          />
        );
      }

      return (
        <DisabledHelpBadge
          helpText={disabledHelpText}
          isFocusRippleVisible={this.state.isFocusRippleVisible}
          isHelpOpen={this.state.isDisabledHelpOpen}
          onHelpOpenChange={(isHelpOpen: boolean): void =>
            this.setState({ isDisabledHelpOpen: isHelpOpen })
          }
        >
          <ButtonBase
            aria-activedescendant={componentId}
            className={classes.disabledButton}
            component="div"
            onBlur={() =>
              this.setState({
                isFocusRippleVisible: false,
              })
            }
            onClick={() =>
              this.setState({
                isDisabledHelpOpen: !this.state.isDisabledHelpOpen,
              })
            }
            onFocusVisible={() =>
              this.setState({
                isFocusRippleVisible: true,
              })
            }
            role={undefined} // Override default "button" role
            tabIndex={0}
          >
            <muiTextField.default
              className={classNames.join(" ")}
              disabled={true}
              fullWidth={true}
              id={componentId}
              InputLabelProps={{
                "aria-hidden": true,
                classes: inputLabelClasses,
                id: labelId,
              }}
              inputProps={{
                "aria-describedby": disabledHelpTextId,
                "aria-labelledby": labelId,
                ...inputProps,
              }}
              InputProps={{
                endAdornment: adornment,
                ...InputProps,
              }}
              onChange={this.onChange}
              type="text"
              variant="filled"
              {...otherProps}
            />
            <span id={disabledHelpTextId} style={{ display: "none" }}>
              {disabledHelpText}
            </span>
          </ButtonBase>
        </DisabledHelpBadge>
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors,
      helperText,
      value: otherProps.value as string,
    });

    return (
      <muiTextField.default
        className={classNames.join(" ")}
        error={fieldHelperText.hasErrors || error}
        FormHelperTextProps={{
          "aria-hidden": true,
          ...fieldHelperText.formHelperTextProps,
        }}
        fullWidth={true}
        helperText={fieldHelperText.helperText}
        id={componentId}
        InputLabelProps={{ classes: inputLabelClasses }}
        inputProps={inputProps}
        InputProps={{
          endAdornment: adornment,
          ...InputProps,
        }}
        onBlur={() => {
          this.announceErrors(fieldHelperText.errors);
        }}
        onChange={this.onChange}
        type="text"
        variant="filled"
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(TextField);
