import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import { SiteSearchInfo } from "../../config/SearchPresentation";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import ComboBoxOption from "../../coreui/ComboBoxOption";
import Icon from "../../coreui/Icon";
import Select, { Select as SelectBase } from "../../coreui/Select";
import TextField from "../../coreui/TextField";

interface CollapseButtonProps {
  onCollapse: () => void;
}

interface SearchCriteriaProps {
  helperText?: string;
  mandatory?: boolean;
  onCriteriaChange: (value: string) => void;
  onSearch: () => void;
  value: string;
}

interface SearchSelectProps {
  onSelect: (search: SiteSearchInfo | null) => void;
  selectedSearch: SiteSearchInfo | null;
  siteSearches: SiteSearchInfo[];
}

const useFieldStyles = makeStyles((theme: Theme) => ({
  withHelperText: {
    [theme.breakpoints.up("md")]: {
      marginBottom: -24,
    },
  },
}));

export function CollapseButton(props: CollapseButtonProps): JSX.Element {
  return (
    <Button
      aria-label={Localization.getBuiltInMessage("siteCriteriaCollapseSearch")}
      fab
      onClick={() => props.onCollapse()}
      size="small"
    >
      <Icon icon="fas fa-times" />
    </Button>
  );
}

export function SearchCriteriaField(props: SearchCriteriaProps): JSX.Element {
  const classes = useFieldStyles();

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    props.onSearch();
  };

  const onValueChange = (value: string): void => {
    props.onCriteriaChange(value);
  };

  return (
    <TextField
      className={!!props.helperText ? classes.withHelperText : undefined}
      InputProps={{
        endAdornment: (
          <muiInputAdornment.default position="end" style={{ marginTop: -4 }}>
            <Button
              aria-label={Localization.getBuiltInMessage("search")}
              color="dark"
              icon="fas fa-search"
              onClick={() => props.onSearch()}
              size="small"
            />
          </muiInputAdornment.default>
        ),
      }}
      helperText={props.helperText}
      label={Localization.getBuiltInMessage("search")}
      name="SiteCriteria-Search"
      onKeyPress={onKeyPress}
      onValueChange={onValueChange}
      required={props.mandatory}
      role="group"
      value={props.value}
      variant="filled"
    />
  );
}

export function SearchSelect(props: SearchSelectProps): JSX.Element {
  const selectRef = React.useRef<SelectBase>();

  React.useEffect(() => {
    setTimeout(() => selectRef.current!.focus());
  }, []);

  const onValueChange = (option: ComboBoxOption) => {
    const selectedSearches: SiteSearchInfo[] = props.siteSearches.filter(
      (s) => s.url === option.value
    );
    if (selectedSearches.length === 0) {
      props.onSelect(null);
    } else {
      props.onSelect(selectedSearches[0]);
    }
  };

  const options: ComboBoxOption[] = props.siteSearches.map((s) => ({
    display: s.description,
    value: s.url,
  }));

  return (
    <Select
      innerRef={selectRef}
      label={Localization.getBuiltInMessage("siteCriteriaSearchFor")}
      onValueChange={onValueChange}
      options={options}
      value={props.selectedSearch !== null ? props.selectedSearch.url : null}
    />
  );
}
