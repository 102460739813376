import * as muiButton from "@material-ui/core/Button";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import * as React from "react";
import { CustomTheme } from "../muiTheme";
import Api from "../mustangui/Api";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";

interface Props extends muiButton.ButtonProps {
  component?: string;
  iconName?: string;
  rel?: string;
  target?: string;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    ripple: {
      "& > span > span": {
        backgroundColor: theme.links.rippleColor,
      },
      height: "calc(100% + 10px)",
      margin: "-5px -20px",
      width: "calc(100% + 40px)",
    },
    root: {
      "& span": {
        overflow: "hidden",
        textDecoration: theme.links.textDecoration,
        textOverflow: "ellipsis",
      },
      "&:disabled": {
        color: theme.links.disabledColor,
      },
      "&:hover": {
        "& > span": {
          textDecoration: theme.links.hoverTextDecoration,
        },
        backgroundColor: "transparent",
        color: theme.links.hoverColor
          ? theme.links.hoverColor
          : darken(Api.getSystemColor("information"), 0.1),
      },
      color: theme.links.color
        ? theme.links.color
        : Api.getSystemColor("information"),
      fontSize: 16,
      fontWeight: theme.links.fontWeight,
      justifyContent: "left",
      letterSpacing: "normal",
      lineHeight: "20px",
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      textTransform: "none",
    },
    rootHref: {
      userSelect: "auto",
    },
  });

export class ButtonLink extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    const {
      children,
      className,
      classes,
      iconName,
      ...buttonProps
    } = this.props;

    let content: React.ReactNode = children;
    if (typeof content === "string") {
      content = <span>{children}</span>;
    }

    if (iconName) {
      content = (
        <React.Fragment>
          <Icon
            icon={iconName}
            style={{
              display: "inline-block",
              marginRight: content ? ".4em" : 0,
              verticalAlign: "-.15em",
            }}
          />
          {content}
        </React.Fragment>
      );
    }

    return (
      <muiButton.default
        className={multiClassName(
          className,
          classes.root,
          this.props.href ? classes.rootHref : ""
        )}
        TouchRippleProps={{ className: classes.ripple }}
        {...buttonProps}
      >
        {content}
      </muiButton.default>
    );
  }
}

export default withStyles(styles)(ButtonLink);
