import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import { CheckBoxGroup as CoreUiCheckBoxGroup } from "../coreui/CheckBoxGroup";
import { CheckBoxGroupOption } from "../coreui/CheckBoxGroupOption";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel, ValueByBreakpoint } from "./Api";

interface ConfigProperties {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  name: string;
  orientationByBreakPoint: ValueByBreakpoint<"horizontal" | "vertical">;
  propagated: DialogChildProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  options: CheckBoxGroupOption[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

@observer
export class CheckBoxGroup extends React.Component<
  ConfigProperties & WithWidth
> {
  private onOptionChange = (
    option: CheckBoxGroupOption,
    checked: boolean
  ): void => {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return;
    }

    const oldValues = Presentation.getValue(this.props);
    const values = [...(oldValues as string[])];
    if (checked) {
      values.push(option.value);
    } else {
      const index: number = values.indexOf(option.value);
      values.splice(index, 1);
    }

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);

    Presentation.setValue(this.props, values);

    if (!this.props.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip("CheckBoxGroup/OnChange", this.props, {
      dialogRowKey: this.props.propagated?.parentDialog?.rowKey,
    }).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round-trip fails, undo the value change.
        Presentation.setValue(this.props, oldValues);
      }
    });
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string[], RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <CoreUiCheckBoxGroup
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={
          widget.properties.showDisabledHelp
            ? this.props.disabledHelpText
            : undefined
        }
        getErrors={() => widget.properties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onOptionChange={this.onOptionChange}
        options={widget.properties.options}
        orientation={this.props.orientationByBreakPoint[this.props.width]}
        readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
        required={widget.properties.showAsMandatory}
        selectedValues={widget.value}
      />
    );
  }
}

export default withWidth()(CheckBoxGroup);
