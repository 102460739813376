import { useObserver } from "mobx-react-lite";
import * as React from "react";
import Presentation from "../coreui/Presentation";

export interface PaneUseConfig {
  component: object & { type: string };
  paneUseKey: string;
}

interface Props {
  config: PaneUseConfig;
  propagated: {};
}

export function PaneUse(props: Props): JSX.Element {
  return useObserver(() => (
    <React.Fragment>
      {Presentation.create(props.config.component, props.propagated)}
    </React.Fragment>
  ));
}
