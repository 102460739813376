import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { ColDef } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import RequestPromise from "../core/RequestPromise";
import Sys from "../core/Sys";
import TrackableModel from "../core/TrackableModel";
import ComboBoxOption from "../coreui/ComboBoxOption";
import FocusTracker from "../coreui/FocusTracker";
import Presentation from "../coreui/Presentation";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import PaneDataStore from "../stores/PaneDataStore";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import ErrorBoundary from "./ErrorBoundary";
import { SelectChildProps } from "./SelectControl";

interface ConfigProperties extends WithWidth {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  footerToolbar?: object;
  headerToolbar?: object;
  name: string;
  propagated?: SelectChildProps;
  selectionToolbar?: object;
  tableKey: string;
  verticalLayout?: object;
}

@observer
export class SelectControlSelectedGrid extends React.Component<
  ConfigProperties & WithWidth
> {
  private asyncDataRef: React.RefObject<AsyncData>;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private populate: ((rows: TrackableModel[]) => void) | null = null;
  private propagated: TableChildProps & SelectChildProps;

  public constructor(props: ConfigProperties & WithWidth) {
    super(props);

    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    this.propagated = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
        },
        description: props.description,
        getRelationshipComboBoxOptions: this.getRelationshipComboBoxOptions,
        isDocumentGrid: false,
        populateData: () => this.populateData(),
      },
      ...props.propagated,
    } as TableChildProps & SelectChildProps;
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.description,
      }),
      true
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private getData = (): RequestPromise<GetDataResponse> => {
    return RoundTripService.partialDataRetrevial<GetDataResponse>(
      `SelectControl/GetRowsData/${this.getRowKey()}` +
        `/${this.props.dataId}/${this.props.name}`
    );
  };

  private getRelationshipComboBoxOptions = (
    widgetName: string,
    selectedOption: ComboBoxOption
  ): ComboBoxOption[] => {
    // Relationship combo boxes are always read-only on select controls,
    // since the only details that can be maintained are relationship
    // details, which are never mainted with a relationship combo box.
    return [selectedOption];
  };

  private getRowKey(): string {
    const observable = Presentation.getObservable(this.props)! as PaneRow;
    return observable.rowKey;
  }

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState = this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    this.props.propagated!.parentSelect.onIsLoadingChanged(isLoadingData);

    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private populateData = (): void => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.contentDataId
    );

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  public componentDidUpdate(prevProps: ConfigProperties): void {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public render(): React.ReactNode {
    const tableColumns: ColDef[] = [];
    for (const column of this.props.columns) {
      tableColumns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    const tableProps: TableProps = {
      "aria-label": this.props.description,
      cardDepth: this.props.cardDepth,
      cellEdit: true,
      columns: tableColumns,
      contentDataId: this.props.contentDataId,
      dataId: this.props.dataId,
      footerToolbarChild: this.props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: this.props.headerToolbar,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(this.props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(this.props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(this.props.columns),
      name: this.props.name,
      propagated: this.propagated,
      rowSelection: "multiple",
      selectToolbarChild: this.props.selectionToolbar,
      setPopulate: (populate) => (this.populate = populate),
    };

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <div style={{ position: "relative" }}>
            <AsyncData
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              getData={this.getData}
              onIsLoadingChanged={this.onIsLoadingChanged}
              populateData={this.populateData}
              ref={this.asyncDataRef}
            />
            <Table
              {...tableProps}
              fullWidthChild={this.props.verticalLayout}
              tableKey={this.props.tableKey}
            />
          </div>
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

export default withWidth()(SelectControlSelectedGrid);
