import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import Dialog from "../../coreui/Dialog";
import DialogActions from "../../coreui/DialogActions";
import DialogContent from "../../coreui/DialogContent";
import Icon from "../../coreui/Icon";
import Typography from "../../coreui/Typography";
import Api from "../../mustangui/Api";
import Grid from "../../mustangui/Grid";
import GridItem from "../../mustangui/GridItem";
import ConfirmContinueStore from "../../stores/ConfirmContinueStore";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class ConfirmContinueDialog extends React.Component<
  WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private readonly messageId: string;
  private readonly titleId: string;

  public constructor(props: WithStyles<typeof styles>) {
    super(props);

    this.componentId = `confirmation-continue-dialog-${Sys.nextId}`;
    this.messageId = `${this.componentId}-message`;
    this.titleId = `${this.componentId}-title`;
  }
  private cancel = () => {
    ConfirmContinueStore.instance.closeDialog(false);
  };

  private continue = () => {
    ConfirmContinueStore.instance.closeDialog(true);
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "Enter":
        if (
          !("nodeName" in event.target) ||
          event.target["nodeName"] !== "BUTTON"
        ) {
          this.continue();
        }
        break;
      case "Escape":
        this.cancel();
        break;
      default:
    }
  };

  public render() {
    const isOpen: boolean = ConfirmContinueStore.instance.isDialogOpen;

    const message = Localization.getBuiltInMessage("unsavedChangesText");

    return (
      <Dialog
        aria-describedby={this.messageId}
        aria-labelledby={this.titleId}
        fullScreen={false}
        id={this.componentId}
        maxWidth="sm"
        onClose={this.cancel}
        onKeyDown={this.onKeyDown}
        open={isOpen}
        role="alertdialog"
      >
        <DialogContent>
          <Grid grouping="Closely Related" xs={1}>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography
                id={this.titleId}
                style={{ color: Api.getSystemColor("danger") }}
                variant="h3"
              >
                <div className="fa-layers" style={{ marginRight: ".4em" }}>
                  <Icon
                    fixedWidth
                    icon="fas fa-exclamation"
                    style={{ fontSize: ".6em" }}
                  />
                  <Icon fixedWidth icon="far fa-octagon" />
                </div>
                {Localization.getBuiltInMessage("unsavedChangesTitle")}
              </Typography>
            </GridItem>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography id={this.messageId}>{message}</Typography>
            </GridItem>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="danger" onClick={this.continue}>
            {Localization.getBuiltInMessage("leave")}
          </Button>
          <Button onClick={this.cancel} style={{ marginLeft: 40 }}>
            {Localization.getBuiltInMessage("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmContinueDialog);
