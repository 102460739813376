import { Layout } from "../config/Layout";
import { SearchPresentationConfig } from "../config/SearchPresentation";
import AppServer, { State as AppServerState } from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import PaneDataStore, {
  PaneData,
  PaneDataByDataId,
} from "../stores/PaneDataStore";
import BaseService from "./BaseService";

export interface ExecuteSearchResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  exceededRowLimit: boolean;
  paneDataByDataId: PaneDataByDataId;
  resultsCountMessage: string | null;
  succeeded: boolean;
  timedOut: boolean;
  validationErrors?: string[];
}

export interface SearchPresentationDataResponse {
  appServerState: AppServerState;
  criteriaPaneData: PaneData;
  paneDataByDataId: PaneDataByDataId;
}

export default class SearchService {
  public static executeSearch(
    layoutId: number
  ): RequestPromise<ExecuteSearchResponse> {
    const request: RequestPromise<ExecuteSearchResponse> = BaseService.requestObject(
      `Search/ExecuteSearch/${layoutId}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
      },
      "POST"
    );

    return request.then((response: ExecuteSearchResponse) => {
      if (response.validationErrors && response.validationErrors.length > 0) {
        ErrorsStore.clearErrors();
        ErrorsStore.showErrors(response.validationErrors);

        return Promise.reject("Validation errors exist");
      }

      if (response.businessErrors.length > 0) {
        ErrorsStore.clearBusinessErrors();
      }

      if (ErrorsStore.setBusinessErrors(response.businessErrors, false)) {
        ErrorsStore.pushErrorsToWidgets();
        return Promise.reject("Business errors exist");
      }

      AppServer.setState(response.appServerState);
      PaneDataStore.loadResponse(response.paneDataByDataId);

      return response;
    });
  }

  public static getConfig(
    layoutId: number
  ): RequestPromise<SearchPresentationConfig> {
    const result = BaseService.requestObject<SearchPresentationConfig>(
      `Search/Config/${layoutId}`,
      null,
      null,
      null,
      "GET"
    );

    return result.then((config: SearchPresentationConfig) => {
      Layout.initialize(config);

      return config;
    });
  }

  public static getData(
    layoutId: number,
    queryStringValues: string | null = null
  ): RequestPromise<SearchPresentationDataResponse> {
    return BaseService.requestObject<SearchPresentationDataResponse>(
      `Search/Data/${layoutId}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        queryStringValues,
      },
      "POST"
    );
  }

  public static getPresentationIdForName(
    presentationName: string
  ): RequestPromise<number> {
    return BaseService.requestObject(
      `Search/PresentationIdForName/${presentationName}`,
      null,
      null,
      null,
      "GET"
    );
  }
}
