import Divider from "@material-ui/core/Divider";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../core/AppServer";
import FocusManager from "../core/FocusManager";
import Localization from "../core/Localization";
import Routing from "../core/Routing";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import CircularProgress from "../coreui/CircularProgress";
import PasswordField from "../coreui/PasswordField";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import Grid from "../mustangui/Grid";
import GridItem from "../mustangui/GridItem";
import UserService, { ExternalAuthenticator } from "../services/UserService";
import ErrorsStore from "../stores/ErrorsStore";
import RequestsStore from "../stores/RequestsStore";

interface Props {
  autoFocus?: true;
  forgotPasswordUrl: string | null;
  title: string;
}

interface State {
  externalAuthenticators?: ExternalAuthenticator[];
  isLoadingExternalAuthenticators?: boolean;
  password?: string;
  userName?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: theme.palette.grey[300],
      border: "none",
      height: 1,
      marginBottom: 24,
      marginTop: 24,
    },
  });

@observer
export class Logon extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private formRef = React.createRef<HTMLFormElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      externalAuthenticators: [],
      isLoadingExternalAuthenticators: true,
    };

    UserService.getExternalAuthenticators().then((response) => {
      this.setState(
        {
          externalAuthenticators: response,
          isLoadingExternalAuthenticators: false,
        },
        () => {
          if (this.props.autoFocus) {
            this.focusFirstElement();
          }
        }
      );
    });
  }

  private focusFirstElement(): void {
    if (this.formRef.current === null) {
      console.warn(
        "No form element exists when attempting to focus the first element " +
          "in the sign-in form."
      );
    } else {
      FocusManager.grabFocusForChild(
        this.formRef.current,
        FocusManager.selectors.focusable
      );
    }
  }

  private logon = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    ErrorsStore.clearErrors();
    if (!this.state.userName || !this.state.password) {
      ErrorsStore.showErrors([Localization.getBuiltInMessage("missingLogon")]);

      return;
    }

    RequestsStore.instance.processingStarted();

    if (Sys.getRouteToken("signin", 0) === "signin") {
      Sys.setHash("", false, true);
    }

    UserService.validateCredentials(this.state.userName, this.state.password)
      .then(() => {
        AppServer.clearState();
        Routing.processHash();

        setTimeout(() => RequestsStore.instance.processingStopped());
      })
      .catch((error) => {
        ErrorsStore.showErrors([error]);
        RequestsStore.instance.processingStopped();
      });
  };

  private onAuthenticatorClick = (name: string): void => {
    if (Sys.getRouteToken("signin", 0) === "signin") {
      Sys.setHash("", false, true);
    }

    Sys.setCookie("authenticatorProviderName", name);
    Sys.setCookie("authenticatorHref", window.location.href);
  };

  private onChangePassword = (value: string): void => {
    this.setState({ password: value });
  };

  private onChangeUserName = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ userName: event.target.value });
  };

  public render(): React.ReactNode {
    const enableEmailAuthentication: boolean =
      Sys.settings.enableEmailAuthentication;
    let emailAuthentication: React.ReactNode = null;
    let externalAuthentication: React.ReactNode = null;
    let title: React.ReactNode = null;

    title = (
      <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
        <Typography variant="h4">{this.props.title}</Typography>
      </GridItem>
    );

    if (enableEmailAuthentication) {
      let forgotPasswordLink: React.ReactNode = null;

      if (this.props.forgotPasswordUrl) {
        forgotPasswordLink = (
          <ButtonLink
            href={this.props.forgotPasswordUrl}
            style={{
              fontSize: 12,
              lineHeight: "16px",
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
            }}
          >
            {Localization.getBuiltInMessage("forgotPassword")}
          </ButtonLink>
        );
      }

      emailAuthentication = (
        <React.Fragment>
          <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
            <TextField
              autoComplete="username"
              icon="fas fa-user"
              inputProps={{ spellCheck: false }}
              label={Localization.getBuiltInMessage("email")}
              name="UserName"
              onChange={this.onChangeUserName}
              type="email"
              variant="filled"
            />
          </GridItem>
          <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
            <div>
              <PasswordField
                autoComplete="off"
                label={Localization.getBuiltInMessage("password")}
                name="Password"
                onChange={this.onChangePassword}
              />
              {forgotPasswordLink}
            </div>
          </GridItem>
          <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
            <Button color="dark" key="button" type="submit">
              {Localization.getBuiltInMessage("signIn")}
            </Button>
          </GridItem>
        </React.Fragment>
      );
    }

    if (this.state.externalAuthenticators!.length) {
      const authenticators = this.state.externalAuthenticators!;
      const buttons: [React.ReactNode] = [null];
      let ariaLabel = "";

      authenticators.forEach((authenticator, index: number) => {
        ariaLabel = Localization.getBuiltInMessage(
          "Logon.externalAuthenticatorLabel",
          { providerDescription: authenticator.description }
        );
        buttons.push(
          <Button
            aria-label={ariaLabel}
            color="dark"
            fullWidth={!enableEmailAuthentication}
            href={authenticator.url}
            key={authenticator.providerName}
            icon={authenticator.iconName}
            label={
              enableEmailAuthentication ? undefined : authenticator.description
            }
            onClick={() =>
              this.onAuthenticatorClick(authenticator.providerName)
            }
            style={{
              marginBottom:
                !enableEmailAuthentication && index < authenticators.length - 1
                  ? 24
                  : 0,
              marginRight:
                enableEmailAuthentication &&
                index < authenticators.length - 1 &&
                (index + 1) % 5 !== 0
                  ? 24
                  : 0,
              marginTop: enableEmailAuthentication && index >= 5 ? 24 : 0,
            }}
          />
        );
      });

      if (enableEmailAuthentication) {
        externalAuthentication = (
          <React.Fragment>
            <Divider style={{ marginBottom: 24, marginTop: 24 }} />
            <Typography style={{ marginBottom: 24, textAlign: "center" }}>
              {Localization.getBuiltInMessage("externalAuth")}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: 296 }}>{buttons}</div>
            </div>
          </React.Fragment>
        );
      } else {
        externalAuthentication = <div style={{ marginTop: 24 }}>{buttons}</div>;
      }
    }

    return (
      <form
        aria-label={this.props.title}
        onSubmit={this.logon}
        ref={this.formRef}
      >
        <div>
          <Grid grouping="Closely Related" xs={1}>
            {title}
            {emailAuthentication}
          </Grid>
          {externalAuthentication}
          {this.state.isLoadingExternalAuthenticators ? (
            <CircularProgress size="mini" style={{ marginTop: 24 }} />
          ) : null}
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(Logon);
