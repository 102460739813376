import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Layout, LayoutConfig } from "../config/Layout";
import Sys from "../core/Sys";
import KeyboardNavigationGroup from "../coreui/KeyboardNavigationGroup";
import muiTheme, { CustomTheme, ForegroundColorType } from "../muiTheme";
import { ValueByBreakpoint } from "../mustangui/Api";
import SessionMessageDisplay from "../mustangui/SessionMessageDisplay";
import BackgroundImage from "./components/BackgroundImage";
import ConfirmContinueDialog from "./components/ConfirmContinueDialog";
import EnvironmentBanner from "./components/EnvironmentBanner";
import Header from "./components/Header";
import MessagesSnackbar from "./components/MessagesSnackbar";
import PageProcessing from "./components/PageProcessing";
import CenteredContentClasses from "./styles/CenteredContentClasses";
import PaperClasses from "./styles/PaperClasses";

interface FooterConfig {
  backgroundColor: string;
  foreground: ForegroundColorType;
  layout: LayoutConfig;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  logoUrl: string;
  logoLabel: string;
  layout: LayoutConfig;
}

export interface BasePageTemplateConfig {
  backgroundImageUrl: string | null;
  footer: FooterConfig;
  header: HeaderConfig;
}

interface Props {
  backgroundImageUrl: string | null;
  footer: FooterConfig;
  header: HeaderConfig;
  paperWidth: ValueByBreakpoint<4 | 6 | null>;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    footer: {
      [theme.breakpoints.up("lg")]: {
        paddingBottom: theme.paper.padding.lg,
        paddingTop: theme.paper.padding.lg,
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: theme.paper.padding.md,
        paddingTop: theme.paper.padding.md,
      },
      [theme.breakpoints.only("sm")]: {
        paddingBottom: theme.paper.padding.sm,
        paddingTop: theme.paper.padding.sm,
      },
      [theme.breakpoints.only("xs")]: {
        paddingBottom: theme.paper.padding.xs,
        paddingTop: theme.paper.padding.xs,
      },
      "@media print": {
        display: "none",
      },
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    footerContent: {
      [theme.breakpoints.only("xs")]: {
        flexBasis: "100%",
      },
    },
    main: {
      flexGrow: 1,
      position: "relative",
    },
    mainContent: {
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.container.verticalSpacing.lg,
        marginTop: theme.container.verticalSpacing.lg,
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: theme.container.verticalSpacing.md,
        marginTop: theme.container.verticalSpacing.md,
      },
      [theme.breakpoints.only("sm")]: {
        marginBottom: theme.container.verticalSpacing.sm,
        marginTop: theme.container.verticalSpacing.sm,
      },
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.container.verticalSpacing.xs,
        marginTop: theme.container.verticalSpacing.xs,
      },
    },
    "paper-container-lg": {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    "paper-container-md": {
      [theme.breakpoints.only("md")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    "paper-container-sm": {
      [theme.breakpoints.only("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    "paper-container-xs": {
      [theme.breakpoints.only("xs")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    ...CenteredContentClasses.create(theme),
    ...PaperClasses.create(theme),
  });

class BasePageTemplate extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private readonly footerTheme: Theme;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.footerTheme = muiTheme(this.props.footer.foreground, true);
  }

  public render(): React.ReactNode {
    const footerContentClasses = [
      this.props.classes.footerContent,
      CenteredContentClasses.get(null, this.props.classes),
    ];

    let backgroundImage: React.ReactNode = null;
    if (this.props.backgroundImageUrl) {
      backgroundImage = (
        <BackgroundImage
          url={this.props.backgroundImageUrl}
          visible={
            {
              lg: this.props.paperWidth.lg !== null,
              md: this.props.paperWidth.md !== null,
              sm: this.props.paperWidth.sm !== null,
              xs: this.props.paperWidth.xs !== null,
            } as ValueByBreakpoint<boolean>
          }
        />
      );
    }

    const mainClasses = [this.props.classes.main];
    const mainContentClasses = [this.props.classes.mainContent];

    for (const breakPoint of ["lg", "md", "sm", "xs"] as Breakpoint[]) {
      const width = this.props.paperWidth[breakPoint];
      if (width === null) {
        mainContentClasses.push(
          CenteredContentClasses.get(breakPoint, this.props.classes)
        );
      } else {
        mainClasses.push(this.props.classes[`paper-container-${breakPoint}`]);

        const useDefaultColor = !this.props.backgroundImageUrl;
        mainContentClasses.push(
          PaperClasses.get(
            breakPoint,
            width,
            useDefaultColor,
            this.props.classes
          )
        );
      }
    }

    return (
      <div className={this.props.classes.root}>
        <ConfirmContinueDialog />
        <EnvironmentBanner />
        <PageProcessing />
        <Header
          backgroundColor={this.props.header.backgroundColor}
          bottomBorderColor={this.props.header.bottomBorderColor}
          foreground={this.props.header.foreground}
          layout={this.props.header.layout}
          logoImageUrl={this.props.header.logoUrl}
          logoLabel={this.props.header.logoLabel}
          logoNavigationUrl={`${Sys.settings.rootUrl}/#`}
        />
        <SessionMessageDisplay />
        <main className={mainClasses.join(" ")}>
          {backgroundImage}
          <div className={mainContentClasses.join(" ")}>
            {this.props.children}
          </div>
        </main>
        <MessagesSnackbar />
        <footer
          className={this.props.classes.footer}
          style={{
            backgroundColor: this.props.footer.backgroundColor,
          }}
        >
          <KeyboardNavigationGroup
            childSelector="a"
            className={footerContentClasses.join(" ")}
          >
            <ThemeProvider theme={this.footerTheme}>
              <Layout
                config={this.props.footer.layout}
                preventClear={true}
                propagated={{ isInPageFooter: true }}
              />
            </ThemeProvider>
          </KeyboardNavigationGroup>
        </footer>
      </div>
    );
  }
}

export default withStyles(styles)(BasePageTemplate);
