import * as muiBadge from "@material-ui/core/Badge";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import * as React from "react";
import Api from "../mustangui/Api";
import GridColumn from "../mustangui/Columns/GridColumn";
import Button from "./Button";
import ErrorTooltip from "./ErrorTooltip";

// This props does not extend badge properties because badgeContent is
// required and we don't want to have to pass a value for this type of badge.
interface Props {
  isShort?: boolean;
  message?: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  style?: React.CSSProperties;
  suppressEdit?: boolean;
}

interface State {
  open: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    badge: {
      right: 16,
      top: "calc(100% - 16px)",
    },
    button: {
      "&:hover": {
        backgroundColor: darken(Api.getSystemColor("danger"), 0.1),
      },
      backgroundColor: Api.getSystemColor("danger"),
      color: theme.palette.getContrastText(Api.getSystemColor("danger")),
    },
    error: {
      borderColor: Api.getSystemColor("danger"),
    },
    normal: {
      borderColor: "transparent",
    },
    ripple: {
      backgroundColor: darken(Api.getSystemColor("danger"), 0.4),
    },
    root: {
      alignItems: "center",
      borderStyle: "solid",
      borderWidth: 1,
      height: "calc(100% - 5px)",
      marginLeft: 1,
      marginTop: 2,
      width: "calc(100% - 3px)",
      zIndex: 1,
    },
    short: {
      borderColor: "transparent",
      height: "calc(100% - 7px)",
      marginTop: 2,
    },
    shortError: {
      borderColor: Api.getSystemColor("danger"),
      height: "calc(100% - 7px)",
      marginTop: 2,
    },
  });

export class ErrorBadge extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { open: false };
  }

  private onButtonClick = (): void => {
    // Fix for Safari not opening tooltip
    this.buttonRef.current!.focus();

    this.setState({ open: true });
  };

  private onButtonMouseDown = (): void => {
    if (this.props.suppressEdit) {
      GridColumn.suppressEdit = true;
    }
  };

  private onClickAway = (): void => {
    this.setState({ open: false });
  };

  public render(): React.ReactNode {
    let content: React.ReactNode = <div />;

    if (this.props.message) {
      content = (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={this.onClickAway}
        >
          <ErrorTooltip
            disableHoverListener
            enterTouchDelay={0}
            onClose={this.props.onClose}
            onOpen={this.props.onOpen}
            open={this.state.open || !!this.props.open}
            title={this.props.message}
          >
            <Button
              badge
              buttonRef={this.buttonRef}
              classes={{ root: this.props.classes.button }}
              icon="fas fa-exclamation"
              onClick={this.onButtonClick}
              onMouseDown={this.onButtonMouseDown}
              tabIndex={-1}
              TouchRippleProps={{
                classes: { child: this.props.classes.ripple },
              }}
            />
          </ErrorTooltip>
        </ClickAwayListener>
      );
    }

    const classes: string[] = [this.props.classes.root];
    if (this.props.isShort) {
      classes.push(
        this.props.message
          ? this.props.classes.shortError
          : this.props.classes.short
      );
    } else {
      classes.push(
        this.props.message
          ? this.props.classes.error
          : this.props.classes.normal
      );
    }

    return (
      <muiBadge.default
        badgeContent={content}
        classes={{ badge: this.props.classes.badge }}
        className={classes.join(" ")}
        style={this.props.style}
      >
        {this.props.children}
      </muiBadge.default>
    );
  }
}

export default withStyles(styles)(ErrorBadge);
