import * as muiHidden from "@material-ui/core/Hidden";
import * as React from "react";

export enum BreakpointRanges {
  smUp,
  xsDown,
}

interface Props extends muiHidden.HiddenProps {
  nestedIn?: BreakpointRanges;
}

export interface NestedInChild {
  nestedIn?: BreakpointRanges;
}

export class Hidden extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { nestedIn, ...hiddenProps } = this.props;

    // When nesting Hidden components sometimes the children will trigger
    // before the parent, which can make the children mount, unmount
    // and then re-mount. This reduces performace and can cause events
    // to fire for components which are no longer mounted
    if (nestedIn !== undefined) {
      switch (nestedIn) {
        case BreakpointRanges.smUp:
          return this.props.smUp ? this.props.children : null;

        case BreakpointRanges.xsDown:
          return this.props.xsDown ? this.props.children : null;

        default:
          return null;
      }
    }

    return <muiHidden.default {...hiddenProps} />;
  }
}

export default Hidden;
