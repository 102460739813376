import Paper from "@material-ui/core/Paper/Paper";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import {
  CellFocusedEvent,
  CellPosition,
  ColDef,
  Column,
  ColumnApi,
  ColumnResizedEvent,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  PaginationChangedEvent,
  RowNode,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { autorun, IReactionDisposer, toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "../agGridTheme/agGridTheme.scss";
import Localization from "../core/Localization";
import SubscribeEvent from "../core/SubscribeEvent";
import Sys from "../core/Sys";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel from "../core/TrackableModel";
import PaneRow from "../models/PaneRow";
import Api, { AccessLevel, ValueByBreakpoint } from "../mustangui/Api";
import GridNewRowChip from "../mustangui/GridNewRowChip";
import { Toolbar as ToolbarBase } from "../mustangui/Toolbar";
import { FileInfo, ProgressStatus } from "../stores/DocumentStore";
import ErrorsStore from "../stores/ErrorsStore";
import ComboBoxOption from "./ComboBoxOption";
import Fade from "./Fade";
import Hidden from "./Hidden";
import Icon from "./Icon";
import { KeyboardNavigationGroup as KeyboardNavigationGroupBase } from "./KeyboardNavigationGroup";
import Presentation from "./Presentation";
import RowErrorBadge, {
  RowErrorBadge as RowErrorBadgeBase,
} from "./RowErrorBadge";
import FullWidthRenderer, {
  Props as FullWidthProps,
  FullWidthRenderer as FullWidthRendererBase,
} from "./table/FullWidthRenderer";
import SelectionHeader from "./table/SelectionHeader";
import SelectionRenderer from "./table/SelectionRenderer";
import Typography from "./Typography";

export interface TableProps {
  "aria-label"?: string;
  getAccessibleDescription?: (rowCount: number) => string;
  cardDepth: number;
  cellEdit?: boolean;
  columns: ColDef[];
  contentDataId: string;
  dataId: string;
  disableScrollOnPageChange?: boolean;
  disableSelectAll?: boolean;
  dropAreaChild?: React.ReactNode;
  footerToolbarChild?: object;
  fullWidthChild?: object;
  getSelectedRowKeys?: () => string[];
  headerToolbarChild?: object;
  hideEmptyDocument?: boolean;
  ignoreBusinessErrors?: boolean;
  // FUTURE
  // It would be preferrable to move initial page size props into the Grid
  // Pager widget.  However layout only widgets are not set up to handle
  // props, so Table is the best place for now.
  initialPageSize?: ValueByBreakpoint<number>;
  isColumnFlex: (colId: string) => boolean;
  isColumnVisible: (colId: string, breakpoint: string) => boolean;
  isDocumentGrid?: boolean;
  isInDialog?: true;
  keepHeaderOnSelect?: boolean;
  minRowHeight: number;
  name: string;
  onRowSelected?: (rowKey: string) => void;
  onRowUnselected?: (rowKey: string) => void;
  propagated: TableChildProps;
  resetPageOnPopulate?: boolean;
  rowSelection?: "multiple" | "single";
  selectToolbarChild?: object;
  setPopulate: (populate: ((rows: TrackableModel[]) => void) | null) => void;
  setRestoreLostFocus?: (restoreLostFocus: (() => void) | null) => void;
  setScrollToRow?: (scrollToRow: ((rowKey: string) => void) | null) => void;
  showNoData?: boolean;
  summaryToolbarChild?: object;
  uploadFiles?: FileInfo[];
}

interface Props extends TableProps, WithWidth {
  tableKey: string;
}

interface State {
  accessibleDescription?: string;
  isEmpty?: boolean;
  isFocused?: boolean;
  isGridReady?: boolean;
  selectionToolbarVisible?: boolean;
}

interface ParentTableChildProps {
  cardDepth: number;
  columns: object[];
  configProps: {
    contentDataId: string;
    data?: object;
    dataId: string;
    name: string;
    tableKey: string;
  };
  getRelationshipComboBoxOptions: (
    widgetName: string,
    selectedOption: ComboBoxOption
  ) => ComboBoxOption[];
  hasRelatedEditDialog?: boolean;
  isDocumentGrid: boolean;
  isProjectGrid: boolean;
  openRowEditDialog: (
    rowKey: string,
    isFirstOpenOfNewRow: boolean,
    restoreFocusElement: HTMLElement | null
  ) => void;
  populateData: () => void;
  uploadFiles: (files: FileList) => Promise<void>;
  validExtensions: string[] | null;
}

export interface TableVerticalLayoutProps {
  parentTable: ParentTableChildProps;
  rowKey: string;
}

export interface TableChildProps {
  data?: object;
  parentRowKey?: string;
  parentTable: ParentTableChildProps & {
    description: string;
    getApi: () => GridApi;
    getTable: () => Table;
    initialPageSize: ValueByBreakpoint<number>;
    isTableReady: () => boolean;
    isVerticalLayout: boolean;
    scrollToRow: (rowKey: string) => void;
    scrollToTop: () => void;
    selection: {
      getSelectedCount: () => number;
      getSelectedRows: () => PaneRow[];
      isMultiSelect: boolean;
      isRowSelected: (row: RowNode) => boolean;
      isSelectAllEnabled: boolean;
      setAllSelected: (selected: boolean) => void;
      setRowsSelected: (rows: RowNode[], selected: boolean) => void;
    };
    setExternalFilter: (externalFilter: (row: PaneRow) => boolean) => void;
    setStopEditingWhenGridLosesFocus: (stopEditing: boolean) => void;
    updateRowHeight: () => void;
  };
}

export interface TableContext {
  getColumnApi: () => ColumnApi;
  hasHorizontalScrollBar: () => boolean;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

const styles = (theme: Theme) =>
  createStyles({
    print: {
      "@media print": {
        display: "none",
      },
    },
    root: {
      backgroundColor: theme.palette.common.white,
    },
    verticalLayout: {
      "& .ag-body-viewport.ag-layout-auto-height": {
        overflow: "hidden",
      },
    },
  });

@observer
export class Table extends React.Component<
  Props & WithTheme & WithStyles<typeof styles>,
  State
> {
  private readonly accessibleDescriptionId: string;
  private readonly allPageSize = 999999;
  private columnResizedTimeout: number;
  private componentDidUpdateTimeout: number;
  private readonly componentId: string;
  private readonly errorSummaryId: string;
  private externalFilter: ((row: PaneRow) => boolean) | null = null;
  private footerToolbar: React.ReactNode | null = null;
  private fullWidthChildByRowKey = new Map<string, FullWidthRendererBase>();
  private gridContainerRef: HTMLDivElement | null;
  private gridSizeChangedTimeout: number;
  private hadSelected: boolean = false;
  private headerToolbar: React.ReactNode | null = null;
  private headerToolbarRef: React.RefObject<HTMLDivElement>;
  private isKeyboardNavigatingCells = false;
  private isScrollingToTop = false;
  private isScrollToTopAbandoned = false;
  private lastRowErrors: string | null = null;
  private lastRowsData: string = "[]";
  private lastUploadFilesCount = 0;
  private noData: HTMLDivElement | null = null;
  private onTableReady = new SubscribeEvent();
  private populateDataTimeout: number;
  private populateDataWaitRenderTimeout: number;
  // Dictionary of upload progress components, keyed by upload file.
  private progressBars = new Map<object, HTMLElement>();
  private removeRowErrorsTimeout: number;
  // Dictionary of row error components, keyed by upload file.
  private rowErrors = new Map<object, HTMLElement>();
  private selectedRows = new Set<string>();
  private selectToolbar: React.ReactNode | null = null;
  private summaryToolbar: React.ReactNode | null = null;
  private updateLayoutTimeout: number;
  private updateRowHeightTimeout: number;
  private uploadFilesDisposer: IReactionDisposer;
  protected agProps: AgGridReactProps;
  protected gridWidth: number;
  protected lastSelectedRows: TrackableModel[] | null = null;
  protected minRowHeight: number = 0;
  protected trackableCollection: TrackableCollection;
  public columnApi: ColumnApi;
  public gridApi: GridApi;
  // Dictionary of row error messages, keyed by rowKey.
  public rowErrorMessages = new Map<string, string>();
  public rowErrorBadges = new Map<string, RowErrorBadgeBase>();
  public stopEditingWhenGridLosesFocus = true;

  public constructor(props: Props & WithTheme & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `table-${Sys.nextId}`;
    this.accessibleDescriptionId = `${this.componentId}-accessible-description`;
    this.errorSummaryId = `${this.componentId}-error-summary`;

    this.state = {
      isEmpty: true,
      isFocused: false,
      selectionToolbarVisible: false,
    };

    props.setPopulate((r) => this.populateData(r));

    if (props.uploadFiles) {
      this.uploadFilesDisposer = autorun(() => {
        const uploadFiles: FileInfo[] = props.uploadFiles!;
        for (const fileInfo of uploadFiles) {
          this.uploadProgress(fileInfo.file, fileInfo.status, fileInfo.event);
        }

        if (props.fullWidthChild) {
          const finalizedFiles: FileInfo[] = uploadFiles.filter(
            (f) => f.status === "Finalized"
          );

          if (finalizedFiles.length > this.lastUploadFilesCount) {
            const newestFile: FileInfo =
              finalizedFiles[finalizedFiles.length - 1];
            this.scrollToRow(newestFile.file["rowKey"]);
          }
          this.lastUploadFilesCount = finalizedFiles.length;
        } else {
          if (uploadFiles.length > this.lastUploadFilesCount) {
            const newestFile: FileInfo = uploadFiles[uploadFiles.length - 1];
            this.scrollToRow(newestFile.file["rowKey"]);
          }
          this.lastUploadFilesCount = uploadFiles.length;
        }
      });
    }

    if (props.setRestoreLostFocus) {
      props.setRestoreLostFocus(() => this.restoreLostFocus());
    }

    if (props.setScrollToRow) {
      props.setScrollToRow((rowKey) => this.scrollToRow(rowKey));
    }

    const initialPageSize = props.initialPageSize || {
      lg: 10,
      md: 10,
      sm: 5,
      xl: 10,
      xs: 1,
    };

    props.propagated.parentTable = {
      ...props.propagated.parentTable,
      getApi: () => this.gridApi,
      getTable: () => this,
      initialPageSize,
      isTableReady: () => !!this.gridApi,
      isVerticalLayout: !!props.fullWidthChild,
      scrollToRow: (rowKey) => this.scrollToRow(rowKey),
      scrollToTop: () => this.scrollToTop(),
      selection: {
        getSelectedCount: () => this.getSelectedCount(),
        getSelectedRows: () => this.getSelectedRows(),
        isMultiSelect: props.rowSelection === "multiple",
        isRowSelected: (r) => this.isRowSelected(r),
        isSelectAllEnabled:
          props.rowSelection === "multiple" && !props.disableSelectAll,
        setAllSelected: (s) => this.setAllSelected(s, true),
        setRowsSelected: (r, s) => this.setRowsSelected(r, s, true),
      },
      setExternalFilter: (e) => (this.externalFilter = e),
      setStopEditingWhenGridLosesFocus: (s) =>
        this.setStopEditingWhenGridLosesFocus(s),
      updateRowHeight: () => this.updateRowHeight(),
    };
    props.propagated.parentTable.configProps.tableKey = props.tableKey;

    this.agProps = {};

    this.agProps.animateRows = true;
    this.agProps.cacheQuickFilter = false;
    this.agProps.columnDefs = [];
    this.agProps.rowHeight = props.minRowHeight;
    this.agProps.context = {
      getColumnApi: () => this.columnApi,
      hasHorizontalScrollBar: () => {
        const body: Element = this.gridContainerRef!.querySelector(
          ".ag-body-viewport"
        )!;

        return body.clientWidth < body.scrollWidth;
      },
    } as TableContext;

    let hasRowEdit: boolean = false;

    if (!props.fullWidthChild) {
      if (props.columns.length) {
        for (const column of props.columns) {
          column.cellEditorParams = {
            ...column.cellEditorParams,
            propagated: props.propagated,
          };
          column.cellRendererParams = {
            ...column.cellRendererParams,
            propagated: props.propagated,
          };

          if (column.colId === "_rowEdit") {
            hasRowEdit = true;
          } else {
            this.agProps.columnDefs.push(column);
          }
        }

        // Add flex column to fill remaining space.
        this.agProps.columnDefs.push({
          cellClass: hasRowEdit ? "cx-cell" : "cx-cell cx-cell-last",
          colId: "_filler",
          headerClass: hasRowEdit
            ? "cx-header-filler"
            : "cx-header-filler cx-header-last",
          headerComponentFramework: () => (
            <div
              aria-label={Localization.getBuiltInMessage(
                "DataTable.emptyColumnHeaderLabel"
              )}
            ></div>
          ),
          hide: true,
          minWidth: 1,
          resizable: false,
          sortable: false,
          suppressMovable: true,
          suppressNavigable: true,
          suppressSizeToFit: false,
          width: 1,
        });

        if (hasRowEdit) {
          this.agProps.columnDefs.push(props.columns[props.columns.length - 1]);
        }
      }
    } else {
      // We can't remove the header so add a single column to focus on
      this.agProps.columnDefs.push({
        colId: "_fullWidthColumn",
        getQuickFilterText: (params) =>
          props.columns
            .filter((c) => c.getQuickFilterText !== undefined)
            .map((c) => c.getQuickFilterText!(params))
            .filter((t) => t.length > 0)
            .join(" "),
        resizable: false,
        sortable: false,
        suppressMovable: true,
        suppressNavigable: true,
        suppressSizeToFit: false,
      });
    }

    // FUTURE: delta row data mode has performance benefits
    // However, this requires the data store to be immutable which causes
    // problems with how updates are handled which presume in-place
    // mutable data
    // this.agProps.deltaRowDataMode = true;
    this.agProps.domLayout = "autoHeight";
    // https://www.ag-grid.com/javascript-grid-accessibility/#dom-order
    this.agProps.ensureDomOrder = true;
    this.agProps.getRowNodeId = (row) => {
      return row.rowKey;
    };
    this.agProps.headerHeight = 48;

    if (props.fullWidthChild) {
      this.agProps.animateRows = false;
      this.agProps.fullWidthCellRendererFramework = FullWidthRenderer;
      this.agProps.fullWidthCellRendererParams = {
        cardDepth: props.cardDepth,
        child: props.fullWidthChild,
        onTableReady: this.onTableReady,
        propagated: props.propagated,
        selectToolbarChild: props.selectToolbarChild,
      } as FullWidthProps;
      this.agProps.headerHeight = 0;
      this.agProps.isFullWidthCell = () => true;
      this.agProps.rowStyle = { border: "none" };
      this.agProps.scrollbarWidth = 0;
    }

    this.agProps.defaultColDef = {
      suppressHeaderKeyboardEvent: (params) => {
        const tab = 9;

        return params.event.which === tab;
      },

      suppressKeyboardEvent: (params) => {
        const tab = 9;

        return params.event.which === tab;
      },
    };

    this.agProps.pagination = true;
    // If paging is not configured we will display at most 999999 rows.
    this.agProps.paginationPageSize = this.allPageSize;
    this.agProps.rowClassRules = {
      "ag-row-selected": (row: RowNode) => {
        return this.isRowSelected(row);
      },
    };
    this.agProps.singleClickEdit = props.cellEdit;
    this.agProps.sortingOrder = ["asc", "desc"];
    this.agProps.stopEditingWhenGridLosesFocus = false;
    this.agProps.suppressClickEdit = !props.cellEdit;
    this.agProps.suppressColumnVirtualisation = true;
    this.agProps.suppressDragLeaveHidesColumns = true;
    this.agProps.suppressLoadingOverlay = true;
    this.agProps.suppressNoRowsOverlay = true;
    this.agProps.suppressPaginationPanel = true;
    this.agProps.suppressRowHoverHighlight = true;
    this.agProps.unSortIcon = true;

    if (props.columns.length && props.rowSelection) {
      if (!props.fullWidthChild) {
        const params = { propagated: props.propagated };

        this.agProps.columnDefs = ([
          {
            cellRendererFramework: SelectionRenderer,
            cellRendererParams: params,
            cellStyle: {
              "border-bottom-color": props.theme.palette.grey[300],
            },
            colId: "_checkboxSelection",
            headerComponentFramework: SelectionHeader,
            headerComponentParams: params,
            hide: true,
            lockPosition: true,
            resizable: false,
            suppressMovable: true,
            suppressNavigable: false,
            suppressSizeToFit: true,
            width: 72,
          },
        ] as ColDef[]).concat(this.agProps.columnDefs!);
      }

      this.agProps.rowDeselection = props.rowSelection === "multiple";
      this.agProps.rowSelection = props.rowSelection;
      this.agProps.rowMultiSelectWithClick = props.rowSelection === "multiple";
      this.agProps.suppressRowClickSelection = true;
    }

    this.agProps.doesExternalFilterPass = (node: RowNode): boolean => {
      if (!this.externalFilter) {
        throw new Error(
          "An external filter must be set if external " + "filtering is enabled"
        );
      }

      return this.externalFilter(node.data as PaneRow);
    };

    this.agProps.isExternalFilterPresent = (): boolean => {
      return this.externalFilter !== null;
    };

    this.agProps.onBodyScroll = () => {
      // Fix for bug where focus is lost on horizontal scroll
      if (this.isKeyboardNavigatingCells) {
        this.restoreLostFocus();
      }
    };

    this.agProps.onCellFocused = (event: CellFocusedEvent) => {
      if (event.forceBrowserFocus) {
        this.isKeyboardNavigatingCells = true;
        setTimeout(() => (this.isKeyboardNavigatingCells = false));
      }
    };

    this.agProps.onColumnResized = (event: ColumnResizedEvent) => {
      if (
        !event.column ||
        !event.column.getColDef().suppressSizeToFit ||
        !event.finished
      ) {
        return;
      }

      this.columnApi.sizeColumnsToFit(this.gridWidth);
    };

    this.agProps.onDragStopped = () => {
      this.gridApi.refreshCells({ force: true });
      this.gridApi.refreshHeader();
    };

    this.agProps.onFilterChanged = (event: FilterChangedEvent) => {
      const count: number = this.gridApi.getDisplayedRowCount();

      this.updateRowHeight();
      this.gridApi.refreshHeader();
      this.updateNoData(count);

      if (!!this.props.getAccessibleDescription) {
        const accessibleDescription = this.props.getAccessibleDescription(
          this.gridApi.paginationGetRowCount()
        );
        this.setState({ accessibleDescription });
      }

      Sys.debounceMethod(
        () =>
          Sys.announce(
            Localization.getBuiltInMessage(
              count === 0
                ? "DataTable.gridFilterAlertZero"
                : count === 1
                ? "DataTable.gridFilterAlertSingle"
                : "DataTable.gridFilterAlertMultiple",
              { count }
            )
          ),
        "DataTable.gridFilterAlert",
        1000
      );
    };

    this.agProps.onGridReady = (event: GridReadyEvent) => {
      this.columnApi = event.columnApi;
      this.gridApi = event.api;

      this.gridApi.setGridAriaProperty(
        "label",
        this.props["aria-label"] || null
      );
      this.createToolbars();

      this.setState({ isGridReady: true }, () => {
        this.updateClasses();

        // This must only run for card views with exactly one row.
        if (
          this.props.fullWidthChild &&
          this.gridApi.getDisplayedRowCount() === 1
        ) {
          this.updateRowHeightTimeout = window.setTimeout(
            () => this.updateRowHeight(),
            100
          );
        }

        this.gridApi.setGridAriaProperty(
          "describedBy",
          this.accessibleDescriptionId
        );

        if (!this.props.isInDialog) {
          this.gridApi["gridBodyComp"].eBodyViewport.classList.add(
            "cx-body-viewport"
          );

          this.gridApi["gridBodyCon"].eGridBody.classList.add("cx-root");

          this.gridApi["headerRootComp"].eHeaderContainer.classList.add(
            "cx-header-container"
          );
        }

        this.onTableReady.dispatchEvent();
      });
    };

    this.agProps.onGridSizeChanged = (event: GridSizeChangedEvent) => {
      // FUTURE
      // Event is sometimes fired after component is unmounted
      // Log a bug with ag-Grid
      if (!this.gridContainerRef) {
        return;
      }

      window.clearTimeout(this.gridSizeChangedTimeout);
      this.gridSizeChangedTimeout = window.setTimeout(() => {
        // Chrome sometimes is off by 1px due to OS scaling
        this.updateLayout(event.clientHeight, event.clientWidth - 1);
      }, 100);
    };

    this.agProps.onPaginationChanged = (event: PaginationChangedEvent) => {
      if (event.newPage) {
        this.updateRowHeight();

        // Fix for bug where focus is lost on page change
        this.restoreLostFocus();
      }
    };

    this.agProps.onSelectionChanged = (event: SelectionChangedEvent) => {
      this.updateSelection();
    };

    this.headerToolbarRef = React.createRef<HTMLDivElement>();
  }

  private abandonScrollToTop = (): void => {
    this.isScrollToTopAbandoned = true;
  };

  private addNewIndicator() {
    const pinnedTop = this.gridApi["gridBodyComp"].eTop;
    const count: number = this.gridApi.getPinnedTopRowCount();

    if (count && !pinnedTop.querySelector(".cx-new-container")) {
      const container: HTMLDivElement = document.createElement("div");
      const rowNode: RowNode = this.gridApi.getPinnedTopRow(0);
      const propagated = {
        contentDataId: this.props.contentDataId,
        data: rowNode.data,
        ...this.props.propagated,
      };
      const chip = Presentation.createWithTheme(
        <React.Fragment>
          <div className="cx-new-container-border" id={rowNode.data.rowKey} />
          <GridNewRowChip style={{ marginTop: -12 }} />
        </React.Fragment>,
        "grey",
        false
      );

      container.className = "cx-new-container";
      pinnedTop.appendChild(container);

      ReactDOM.render(chip, container);
    }
  }

  private addProgressBar(file: File) {
    if (this.props.fullWidthChild) {
      return;
    }

    const pinnedTop = this.gridContainerRef!.querySelector(
      ".cx-floating-top"
    )! as HTMLElement;
    const container: HTMLDivElement = document.createElement("div");
    container.className = "cx-progress-container";
    pinnedTop.appendChild(container);

    const content = Presentation.createWithTheme(
      <div className="cx-progress-bar" id={file["rowKey"]}>
        <div className="cx-progress" style={{ width: 0 }} />
        <Icon
          className="cx-progress-complete"
          icon="far fa-check-circle"
          style={{ height: 14 }}
        />
      </div>,
      "grey",
      false
    );

    ReactDOM.render(content, container, () => {
      const progressBar = container.querySelector(
        `[id='${file["rowKey"]}']`
      ) as HTMLElement;

      this.positionProgressBar(progressBar);
      this.progressBars.set(file, progressBar);
    });
  }

  private addRowError(rowNode: RowNode, messages: string[]) {
    if (this.props.fullWidthChild) {
      return;
    }

    const container: HTMLDivElement = document.createElement("div");
    const centerColumns = this.gridApi["gridBodyComp"].eBodyViewport;
    const pinnedTop = this.gridApi["gridBodyComp"].eTop;
    const rowKey = rowNode.data.rowKey;

    if (rowNode.data.isNew) {
      container.className = "cx-row-error-pinned-top-container";
      pinnedTop.appendChild(container);
    } else {
      container.className = "cx-row-error-container";
      centerColumns.appendChild(container);
    }

    const content = Presentation.createWithTheme(
      <div className="cx-row-error" id={rowKey}>
        <RowErrorBadge
          message={Api.getErrorMessages(messages)}
          propagated={this.props.propagated}
          rowKey={rowKey}
        />
      </div>,
      "grey",
      false
    );

    const rowMessages = [...messages];

    rowMessages.forEach((message: string, index: number) => {
      if (!message.endsWith(".")) {
        rowMessages[index] = `${message}. `;
      }
    });

    ReactDOM.render(content, container, () => {
      const rowError = container.querySelector(
        `[id='${rowKey}']`
      ) as HTMLElement;

      this.positionRowError(rowError);
      this.rowErrors.set(rowKey, rowError);
      this.rowErrorMessages.set(rowKey, rowMessages.join(" "));
    });
  }

  private createToolbars() {
    const propagatedProps: TableChildProps = this.props.propagated;

    if (this.props.headerToolbarChild) {
      this.headerToolbar = Presentation.create(
        this.props.headerToolbarChild,
        propagatedProps
      );
    }

    if (
      this.props.selectToolbarChild &&
      this.props.rowSelection &&
      !this.props.fullWidthChild
    ) {
      this.selectToolbar = Presentation.create(
        this.props.selectToolbarChild,
        propagatedProps
      );
    }

    if (this.props.footerToolbarChild) {
      this.footerToolbar = Presentation.create(
        this.props.footerToolbarChild,
        propagatedProps
      );
    }

    if (this.props.summaryToolbarChild) {
      this.summaryToolbar = Presentation.create(
        this.props.summaryToolbarChild,
        propagatedProps
      );
    }
  }

  private getSelectedCount() {
    return this.selectedRows.size;
  }

  private getSelectedRows(): PaneRow[] {
    if (!this.gridApi) {
      throw "Grid is not ready";
    }

    const rows: PaneRow[] = [];
    this.gridApi.getModel().forEachNode((node) => {
      if (this.selectedRows.has(node.data.rowKey)) {
        rows.push(node.data);
      }
    });

    for (let i = 0; i < this.gridApi.getPinnedTopRowCount(); i++) {
      const row = this.gridApi.getPinnedTopRow(i);
      if (this.selectedRows.has(row.data.rowKey)) {
        rows.push(row.data);
      }
    }

    return rows;
  }

  private getTableErrorDetails(): string[] {
    const result: string[] = [];

    if (this.gridApi) {
      ErrorsStore.getTableWidgetErrors(this.props.contentDataId).forEach(
        (businessErrorWidget) => {
          const rowNode = this.gridApi.getRowNode(businessErrorWidget.rowKey);

          if (rowNode) {
            const columnDescription:
              | string
              | null = this.props.propagated.parentTable.columns.find(
              (column) => column["name"] === businessErrorWidget.widgetName
            )!["header"];
            const rowNumber: number = rowNode.rowIndex!;

            result.push(
              Localization.getBuiltInMessage("DataTable.cellError", {
                columnDescription,
                rowNumber,
              })
            );
          }
        }
      );

      ErrorsStore.getTableRowErrors(this.props.contentDataId).forEach(
        (messages, rowKey) => {
          let rowNode = this.gridApi.getRowNode(rowKey);

          if (!rowNode) {
            for (
              let index = 0;
              index < this.gridApi.getPinnedTopRowCount();
              index++
            ) {
              const pinnedRow: RowNode = this.gridApi.getPinnedTopRow(index);

              if (pinnedRow.data.rowKey === rowKey) {
                rowNode = pinnedRow;
                break;
              }
            }
          }

          if (rowNode) {
            const rowNumber: number = rowNode.rowIndex!;

            result.push(
              ...messages.map((errorMessage) =>
                Localization.getBuiltInMessage("DataTable.rowError", {
                  errorMessage,
                  rowNumber,
                })
              )
            );
          }
        }
      );

      result.forEach((message: string, index: number) => {
        if (!message.endsWith(".")) {
          result[index] = `${message}. `;
        }
      });
    }

    return result;
  }

  private getTableErrors() {
    const widgetProperties = Api.getWidgetProperties(
      this.props
    ) as WidgetProperties;
    let result: React.ReactNode = null;

    // Required because businessErrors are not provided for all tables.
    if (!this.props.ignoreBusinessErrors && widgetProperties.businessErrors) {
      result = Api.getErrorMessages(widgetProperties.businessErrors);
    }

    return result;
  }

  private isRowSelected(row: RowNode) {
    return this.selectedRows.has(row.data.rowKey);
  }

  private onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      !event.currentTarget.contains(event.relatedTarget as Node) &&
      event.target instanceof HTMLElement &&
      event.currentTarget.contains(event.target as Node)
    ) {
      if (this.stopEditingWhenGridLosesFocus) {
        this.gridApi.stopEditing();
      }

      this.setState({ isFocused: false });
    }
  };

  private onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      !event.currentTarget.contains(event.relatedTarget as Node) &&
      event.currentTarget.contains(event.target as Node)
    ) {
      this.setState({ isFocused: true });
    }
  };

  private onFocusTabbableElement = () => {
    this.focus();
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !this.props.fullWidthChild &&
      event.key === " " &&
      this.gridApi.getEditingCells().length <= 0
    ) {
      event.preventDefault();

      const cell = this.gridApi.getFocusedCell();

      if (cell) {
        this.gridApi.setFocusedCell(
          cell.rowIndex,
          cell.column,
          cell.rowPinned || undefined
        );
      }
    }

    if (
      !this.props.fullWidthChild &&
      (event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowDown") &&
      this.gridApi.getEditingCells().length <= 0 &&
      this.rowErrorBadges.size > 0
    ) {
      const cell = this.gridApi.getFocusedCell();
      const columns = this.columnApi
        .getDisplayedCenterColumns()
        .filter((c) => c.getColId() !== "_filler");

      this.rowErrorBadges.forEach((rowErrorBadge: RowErrorBadgeBase) =>
        rowErrorBadge.close()
      );

      if (
        !!columns.length &&
        columns[columns.length - 1].getColId() === cell?.column.getColId()
      ) {
        let rowKey: string | null = null;

        if (cell.rowPinned === "top") {
          rowKey = this.gridApi.getPinnedTopRow(cell.rowIndex).data.rowKey;
        } else {
          rowKey = this.gridApi.getModel().getRow(cell.rowIndex)?.data.rowKey;
        }

        if (rowKey) {
          this.rowErrorBadges.get(rowKey!)?.open();
        }
      }
    }
  };

  private populateData(data: TrackableModel[]) {
    const errorCount: number = ErrorsStore.getTableErrorCount(
      this.props.contentDataId
    );
    const tableErrors: React.ReactNode = this.getTableErrors();

    window.clearTimeout(this.populateDataWaitRenderTimeout);

    // Always access the data to trigger any mobx observers
    const dataSerialized = JSON.stringify(toJS(data));

    if (!this.gridApi) {
      // Wait until rendering is complete to make sure any paging widget
      // is rendered
      this.populateDataWaitRenderTimeout = window.setTimeout(() =>
        this.populateData(data)
      );

      return;
    }

    if (this.props.showNoData) {
      this.updateNoData(data.length);
    }

    if (this.props.resetPageOnPopulate) {
      this.gridApi.paginationGoToFirstPage();
    }

    if (!!this.props.getAccessibleDescription) {
      const accessibleDescription = this.props.getAccessibleDescription(
        data.length
      );
      this.setState({ accessibleDescription });
    }

    // Only populate if the data has changed since the last time
    // we accessed it
    if (this.lastRowsData === dataSerialized) {
      return;
    }

    this.lastRowsData = dataSerialized;

    this.setState({ isEmpty: data.length === 0 });

    if (!!errorCount || tableErrors) {
      this.gridApi.setGridAriaProperty(
        "describedby",
        `${this.accessibleDescriptionId} ${this.errorSummaryId}`
      );
      this.gridApi.setGridAriaProperty("invalid", "true");
      this.gridApi.setGridAriaProperty("errormessage", this.errorSummaryId);
    } else {
      this.gridApi.setGridAriaProperty(
        "describedby",
        this.accessibleDescriptionId
      );
      this.gridApi.setGridAriaProperty("invalid", "false");
      this.gridApi.setGridAriaProperty("errormessage", "");
    }

    if (this.getSelectedRows().length > 0) {
      this.lastSelectedRows = this.getSelectedRows();
    }

    window.clearTimeout(this.updateRowHeightTimeout);
    if (this.props.fullWidthChild) {
      this.gridApi.setRowData(data);

      this.updateRowHeightTimeout = window.setTimeout(
        () => this.updateRowHeight(),
        100
      );
    } else {
      this.gridApi.setRowData(data.filter((value) => !value.isNew));
      this.gridApi.setPinnedTopRowData(data.filter((value) => value.isNew));

      if (this.props.showNoData) {
        this.updateNoData(data.length);
      }

      if (this.props.getSelectedRowKeys !== undefined) {
        this.selectedRows.clear();
        for (const rowId of this.props.getSelectedRowKeys()) {
          this.selectedRows.add(rowId);
        }
      } else {
        // Prune the selected state for any deleted rows
        const rowKeys = new Set<string>(data.map<string>((i) => i["rowKey"]));
        for (const key of this.selectedRows) {
          if (!rowKeys.has(key)) {
            this.selectedRows.delete(key);
          }
        }
      }

      if (data.length > 0 && this.lastSelectedRows) {
        const rows: RowNode[] = [];
        this.gridApi.getModel().forEachNode((node) => {
          if (
            this.lastSelectedRows!.some(
              (selected) =>
                node.data.getPrimaryKey() === selected.getPrimaryKey()
            )
          ) {
            rows.push(node);
          }
        });

        for (let i = 0; i < this.gridApi.getPinnedTopRowCount(); i++) {
          const row = this.gridApi.getPinnedTopRow(i);
          if (
            this.lastSelectedRows.some(
              (selected) =>
                row.data.getPrimaryKey() === selected.getPrimaryKey()
            )
          ) {
            rows.push(row);
          }
        }
        this.setRowsSelected(rows, true, false);
      }
      this.lastSelectedRows = null;

      this.updateClasses();

      // Must be deferred so it occurs after re-render.
      window.clearTimeout(this.populateDataTimeout);
      this.populateDataTimeout = window.setTimeout(() => {
        this.gridApi.refreshHeader();
        this.updateSelection();
        this.removeProgressBars();
        this.updateRowErrors();
        this.rowErrors.forEach((rowError: HTMLDivElement) => {
          this.positionRowError(rowError);
        });
        this.addNewIndicator();
      }, 10);
    }
  }

  private positionAllProgressBars() {
    this.progressBars.forEach((_progressBar: HTMLDivElement) => {
      this.positionProgressBar(_progressBar);
    });
  }

  private positionProgressBar(progressBar: HTMLElement) {
    for (let index = 0; index < this.gridApi.getPinnedTopRowCount(); index++) {
      const rowNode: RowNode = this.gridApi.getPinnedTopRow(index);

      if (rowNode.data.rowKey === progressBar.id && rowNode.rowHeight) {
        progressBar.style.top = `${(index + 1) * rowNode.rowHeight - 6}px`;
        break;
      }
    }
  }

  private positionRowError(rowError: HTMLElement) {
    for (let index = 0; index < this.gridApi.getPinnedTopRowCount(); index++) {
      const rowNode: RowNode = this.gridApi.getPinnedTopRow(index);

      if (rowNode.data.rowKey === rowError.id && rowNode.rowHeight) {
        rowError.style.top = `${index * rowNode.rowHeight - 3}px`;

        if (rowNode.data.isNew && this.props.isDocumentGrid) {
          rowError.style.height = `${rowNode.rowHeight - 7}px`;
        } else {
          rowError.style.height = `${rowNode.rowHeight - 5}px`;
        }
        break;
      }
    }

    this.gridApi.forEachNode((rowNode: RowNode) => {
      if (rowNode.data.rowKey === rowError.id && rowNode.rowHeight) {
        rowError.style.top = `${rowNode.childIndex * rowNode.rowHeight}px`;
        rowError.style.height = `${rowNode.rowHeight - 2}px`;
      }
    });
  }

  private refreshCellRenderer(column: string, rows?: RowNode[]) {
    this.gridApi
      .getCellRendererInstances({
        columns: [column],
        rowNodes: rows,
      })
      .forEach((cell) => cell.refresh({} as ICellRendererParams));
  }

  private removeProgressBars() {
    const pinnedRowCount = this.gridApi.getPinnedTopRowCount();
    if (this.props.uploadFiles === undefined) {
      return;
    }

    if (pinnedRowCount) {
      const filesToRemove = [];
      for (const [file, progressBar] of this.progressBars) {
        let documentRowExists: boolean = false;
        for (let index = 0; index < pinnedRowCount; index++) {
          if (
            this.gridApi.getPinnedTopRow(index).data.rowKey === progressBar.id
          ) {
            documentRowExists = true;
          }
        }

        if (!documentRowExists) {
          ReactDOM.unmountComponentAtNode(progressBar.parentElement!);

          const uploadFile: FileInfo | undefined = this.props.uploadFiles.find(
            (f) => f.file["rowKey"] === progressBar.id
          );

          if (uploadFile) {
            const uploadFileIndex = this.props.uploadFiles.indexOf(uploadFile);
            this.props.uploadFiles.splice(uploadFileIndex, 1);
          }

          filesToRemove.push(file);
        }
      }

      for (const fileToRemove of filesToRemove) {
        this.progressBars.delete(fileToRemove);
      }

      if (filesToRemove.length > 0) {
        this.positionAllProgressBars();
      }
    } else {
      this.progressBars.forEach((progressBar: HTMLDivElement) => {
        ReactDOM.unmountComponentAtNode(progressBar.parentElement!);
      });

      this.progressBars.clear();
      this.props.uploadFiles.length = 0;
    }
  }

  private removeRowErrors() {
    const errorContainers: HTMLElement[] = [];

    this.rowErrors.forEach((rowError: HTMLDivElement) => {
      errorContainers.push(rowError.parentElement!);

      ReactDOM.unmountComponentAtNode(rowError.parentElement!);
    });

    if (errorContainers.length > 0) {
      window.clearTimeout(this.removeRowErrorsTimeout);
      this.removeRowErrorsTimeout = window.setTimeout(() => {
        errorContainers.forEach((errorContainer: HTMLDivElement) => {
          const centerColumns = this.gridApi["gridBodyComp"].eBodyViewport;
          const pinnedTop = this.gridApi["gridBodyComp"].eTop;

          try {
            centerColumns.removeChild(errorContainer);
            pinnedTop.removeChild(errorContainer);
          } catch {
            // If the element does not exist anymore ignore it.
          }
        });
      });
    }

    this.rowErrors.clear();
  }

  private restoreLostFocus(): void {
    const cell = this.gridApi.getFocusedCell();
    if (cell) {
      this.gridApi.setFocusedCell(
        cell.rowIndex,
        cell.column,
        cell.rowPinned || undefined
      );
    }
  }

  private scrollToRow(rowKey: string): void {
    if (this.props.fullWidthChild) {
      this.scrollToRowVerticalLayout(rowKey);
    } else {
      this.scrollToRowHorizontalLayout(rowKey);
    }
  }

  private scrollToRowHorizontalLayout(rowKey: string): void {
    let rowNode: RowNode | null = null;
    const pinnedRowsCount: number = this.gridApi.getPinnedTopRowCount();
    let rowIndex = -1;
    for (let i = 0; i < pinnedRowsCount; i++) {
      rowNode = this.gridApi.getPinnedTopRow(i);
      if ((rowNode.data as PaneRow).rowKey === rowKey) {
        rowIndex = i;
        break;
      }
    }

    if (rowIndex < 0 || !rowNode) {
      throw new Error(`Row ${rowKey} not found`);
    }

    const newRowContainer: Element = this.gridContainerRef!.querySelector(
      ".ag-floating-top-container"
    )!;

    const containerOffset: number = newRowContainer.getBoundingClientRect().top;

    if (rowNode.rowHeight) {
      const rowBottomOffset: number =
        containerOffset + rowNode.rowHeight * (rowIndex + 1);
      const rowTopOffset: number =
        containerOffset + rowNode.rowHeight * rowIndex;

      if (rowBottomOffset + 10 > window.innerHeight) {
        window.scrollTo(
          0,
          window.scrollY + rowBottomOffset + 10 - window.innerHeight
        );
      } else if (rowTopOffset < 10) {
        window.scrollTo(0, window.scrollY + rowTopOffset - 10);
      }
    }
  }

  private scrollToRowVerticalLayout(rowKey: string): void {
    const newRowContainer: Element = this.gridContainerRef!.querySelector(
      ".ag-full-width-container"
    )!;
    const rowNode: RowNode | null = this.gridApi.getRowNode(rowKey);

    const containerOffset: number = newRowContainer.getBoundingClientRect().top;

    if (rowNode && rowNode.rowHeight) {
      const rowTopOffset: number =
        containerOffset + rowNode.rowHeight * rowNode.rowIndex!;

      window.scrollTo(0, window.scrollY + rowTopOffset - 20);
    }
  }

  private scrollToTop(): void {
    if (this.props.disableScrollOnPageChange) {
      return;
    }

    const tableOffset: number = document
      .getElementById(this.props.tableKey)!
      .getBoundingClientRect().top;

    if (tableOffset < 0 && !this.isScrollToTopAbandoned) {
      if (!this.isScrollingToTop) {
        this.isScrollingToTop = true;
        window.addEventListener("keydown", this.abandonScrollToTop);
        window.addEventListener("mousedown", this.abandonScrollToTop);
        window.addEventListener("touchstart", this.abandonScrollToTop);
        window.addEventListener("wheel", this.abandonScrollToTop);
      }

      window.scrollBy(0, -Math.max((-1 * tableOffset) / 10, 5));
      requestAnimationFrame(() => this.scrollToTop());
    } else if (this.isScrollingToTop) {
      this.isScrollingToTop = false;
      this.isScrollToTopAbandoned = false;
      window.removeEventListener("keydown", this.abandonScrollToTop);
      window.removeEventListener("mousedown", this.abandonScrollToTop);
      window.removeEventListener("touchstart", this.abandonScrollToTop);
      window.removeEventListener("wheel", this.abandonScrollToTop);
    }
  }

  private setAllSelected(selected: boolean, userInitiated: boolean) {
    if (this.props.rowSelection === "single") {
      throw "Cannot select more than one row when rowSelection is single";
    }

    // Use built-in row selection to update the row style. For pinned rows
    // force a full redraw to trigger the classRules.
    if (selected) {
      this.gridApi.forEachNodeAfterFilter((node) =>
        this.selectedRows.add(node.data["rowKey"])
      );
      this.gridApi.selectAllFiltered();
    } else {
      this.gridApi.forEachNodeAfterFilter((node) =>
        this.selectedRows.delete(node.data["rowKey"])
      );
      this.gridApi.deselectAllFiltered();
    }

    const pinnedRows = [];
    for (let i = 0; i < this.gridApi.getPinnedTopRowCount(); i++) {
      const row = this.gridApi.getPinnedTopRow(i);
      if (selected) {
        this.selectedRows.add(row.data["rowKey"]);
      } else {
        this.selectedRows.delete(row.data["rowKey"]);
      }
      pinnedRows.push(row);

      if (userInitiated) {
        if (selected && this.props.onRowSelected) {
          this.props.onRowSelected(row.data["rowKey"]);
        } else if (!selected && this.props.onRowUnselected) {
          this.props.onRowUnselected(row.data["rowKey"]);
        }
      }
    }

    // Force a redraw to trigger the classRules
    // This must be called for Select All even if there are no pinned rows, in
    // order to force a refresh (see B2C-14776).
    this.gridApi.redrawRows({ rowNodes: pinnedRows });

    this.refreshCellRenderer("_checkboxSelection");
    this.gridApi.refreshHeader();
  }

  private setColumns(): boolean {
    if (!this.columnApi || !this.props.columns) {
      return false;
    }

    const flexColumns: string[] = [];
    let columnsChanged = false;

    for (const column of this.props.columns) {
      const agColumn: Column | null = this.columnApi.getColumn(column.colId);

      if (agColumn) {
        const wasVisible: boolean = agColumn.isVisible();
        const isVisible: boolean = this.props.isColumnVisible(
          column.colId!,
          this.props.width
        );

        if (wasVisible !== isVisible) {
          this.columnApi.setColumnVisible(column.colId!, isVisible);
          columnsChanged = true;
        }

        if (!column.width && isVisible && !wasVisible) {
          this.columnApi.autoSizeColumn(column.colId!);

          // Remove extra space to the right of the header that
          // Ag-grid leaves for tools which we don't use
          const minWidth = agColumn.getMinWidth();
          let width = agColumn.getActualWidth();
          if (minWidth && width > minWidth) {
            width = Math.max(width * 0.89, minWidth);
            agColumn.setActualWidth(width);
          }
        }

        if (isVisible && this.props.isColumnFlex(column.colId!)) {
          flexColumns.push(column.colId!);
        }
      }
    }

    if (columnsChanged) {
      // Column is initially hidden and displayed here so that the first
      // load of the grid is cleaner.
      this.columnApi.setColumnVisible("_checkboxSelection", true);
      this.columnApi.setColumnVisible("_rowEdit", true);

      this.columnApi.setColumnVisible("_filler", !flexColumns.length);
    }

    this.columnApi.sizeColumnsToFit(this.gridWidth);

    if (flexColumns.length) {
      this.gridApi.refreshCells({ columns: flexColumns, force: true });
    }

    return columnsChanged;
  }

  private setRowsSelected(
    rows: RowNode[],
    selected: boolean,
    userInitiated: boolean
  ) {
    if (this.props.rowSelection === "single") {
      if (rows.length > 1) {
        throw new Error(
          "Cannot select more than one row when " + "rowSelection is single"
        );
      }

      if (selected && this.selectedRows.size > 0) {
        const rowId: string = this.selectedRows.values().next().value;
        if (userInitiated && this.props.onRowUnselected) {
          this.props.onRowUnselected(rowId);
        }

        const row = this.gridApi.getRowNode(rowId);
        if (row) {
          this.refreshCellRenderer("_checkboxSelection", [row]);
        }
        this.selectedRows.clear();
      }
    }

    const pinnedRows: RowNode[] = [];
    for (const row of rows) {
      const rowKey = row.data.rowKey;
      if (selected) {
        this.selectedRows.add(rowKey);
      } else {
        this.selectedRows.delete(rowKey);
      }
      // Use built-in row selection to update the row style. For pinned rows
      // force a full redraw to trigger the classRules.
      if (row.isRowPinned()) {
        pinnedRows.push(row);
      } else {
        row.setSelected(selected);
      }

      if (userInitiated) {
        if (selected && this.props.onRowSelected) {
          this.props.onRowSelected(rowKey);
        } else if (!selected && this.props.onRowUnselected) {
          this.props.onRowUnselected(rowKey);
        }
      }
    }

    this.refreshCellRenderer("_checkboxSelection", rows);
    this.gridApi.refreshHeader();

    // Only redraw rows if there are pinned rows. Otherwise redrawing rows on
    // selection causes problems with VoiceOver (see B2C-14639).
    if (!!pinnedRows.length) {
      this.gridApi.redrawRows({ rowNodes: pinnedRows });
      this.gridApi.dispatchEvent({ type: "selectionChanged" });
    }
  }

  private setStopEditingWhenGridLosesFocus(stopEditing: boolean): void {
    this.stopEditingWhenGridLosesFocus = stopEditing;
  }

  private updateClasses() {
    if (!this.gridApi || !this.gridApi["gridBodyComp"]) {
      return;
    }

    const body: Element = this.gridApi["gridBodyComp"].eBodyViewport;
    const header: Element = this.gridApi["headerRootComp"].eGui;
    const pinnedTop: Element = this.gridApi["gridBodyComp"].eTop;

    if (body && header) {
      if (pinnedTop) {
        if (this.gridApi.getPinnedTopRowCount()) {
          header.classList.add("cx-header");
          pinnedTop.classList.add("cx-floating-top");
        } else {
          header.classList.remove("cx-header");
          pinnedTop.classList.remove("cx-floating-top");
        }
      }
    }
  }

  private updateLayout(height: number, width: number) {
    this.gridWidth = width;

    if (this.props.columns.length) {
      const columnsChanged: boolean = this.setColumns();

      if (columnsChanged) {
        this.gridApi.refreshHeader();
        this.gridApi.refreshCells();
        this.updateRowErrors();

        // Must be deferred to calculate table height after the columns
        // are adjusted.
        window.clearTimeout(this.updateLayoutTimeout);
        this.updateLayoutTimeout = window.setTimeout(() => {
          this.rowErrors.forEach((rowError: HTMLDivElement) => {
            this.positionRowError(rowError);
          });
        }, 200);
      }
    }

    this.updateRowHeight();
  }

  private updateNoData(count: number) {
    const container: HTMLDivElement = !!this.props.fullWidthChild
      ? this.gridApi["gridBodyComp"].eBodyViewport.children.item(3)
      : this.gridApi["gridBodyComp"].eBodyViewport;

    if (!!count) {
      if (this.noData) {
        container.removeChild(this.noData);
        this.noData = null;
      }
    } else {
      if (!this.noData) {
        const depth: number = this.props.propagated.parentTable.cardDepth;

        this.noData = document.createElement("div");
        this.noData.className = !!this.props.fullWidthChild
          ? depth % 2 === 0
            ? "cx-nodata-fullwidth"
            : "cx-nodata-fullwidthtab"
          : "cx-nodata";
        this.noData.innerText = Localization.getBuiltInMessage("noResults");
        container.appendChild<HTMLDivElement>(this.noData);
      }
    }
  }

  private updateRowErrors() {
    if (
      this.gridApi.getDisplayedRowCount() === 0 &&
      this.gridApi.getPinnedTopRowCount() === 0
    ) {
      return;
    }

    const rowErrors: Map<string, string[]> = ErrorsStore.getTableRowErrors(
      this.props.contentDataId,
      (widget) =>
        this.props.isColumnVisible(widget.widgetName, this.props.width)
    );

    const errorsSerialized = JSON.stringify(Array.from(rowErrors.entries()));

    if (this.lastRowErrors !== errorsSerialized) {
      this.lastRowErrors = errorsSerialized;
      this.removeRowErrors();
      this.rowErrorBadges.clear();
      this.rowErrorMessages.clear();

      rowErrors.forEach((messages, rowKey) => {
        let rowNode = this.gridApi.getRowNode(rowKey);

        if (!rowNode) {
          for (
            let index = 0;
            index < this.gridApi.getPinnedTopRowCount();
            index++
          ) {
            const pinnedRow: RowNode = this.gridApi.getPinnedTopRow(index);

            if (pinnedRow.data.rowKey === rowKey) {
              rowNode = pinnedRow;
              break;
            }
          }
        }

        if (rowNode) {
          this.addRowError(rowNode, messages);
        }
      });
    }
  }

  private updateRowHeight() {
    if (!this.gridApi || !this.props.fullWidthChild) {
      return;
    }

    if (this.agProps.pinnedTopRowData) {
      // Required to force the pinned row height to be updated.
      this.gridApi.setPinnedTopRowData(this.agProps.pinnedTopRowData!);
    }

    this.gridApi.forEachNode((node: RowNode) => {
      if (
        node.rowIndex! >= this.gridApi.getFirstDisplayedRow() &&
        node.rowIndex! <= this.gridApi.getLastDisplayedRow()
      ) {
        const element: HTMLElement | null = document.getElementById(
          `${this.props.contentDataId}.${this.props.name}` +
            `.${node.data.getPrimaryKey()}`
        );
        let height = this.minRowHeight;

        if (element && element.clientHeight) {
          height = element.clientHeight + 24;

          this.minRowHeight = Math.min(this.minRowHeight || height, height);
        }

        node.setRowHeight(height);
      } else {
        node.setRowHeight(this.minRowHeight);
      }
    });

    this.gridApi.onRowHeightChanged();
  }

  private updateSelection() {
    if (!this.gridApi || !this.props.rowSelection) {
      return;
    }

    const count = this.getSelectedRows().length;

    this.setState({ selectionToolbarVisible: count > 0 });

    if (count === 0 && this.hadSelected) {
      this.hadSelected = false;
      Sys.announce(Localization.getBuiltInMessage("DataTable.noRowsSelected"));

      if (!this.props.isInDialog) {
        Sys.announce(
          Localization.getBuiltInMessage(
            "DataTable.headerToolbarShownInstructions"
          )
        );
      }
    } else if (count > 0 && !this.hadSelected) {
      this.hadSelected = true;
      Sys.announce(
        Localization.getBuiltInMessage("numberSelected", {
          numSelected: count,
        })
      );

      if (!this.props.isInDialog) {
        Sys.announce(
          Localization.getBuiltInMessage(
            "DataTable.selectionToolbarShownInstructions"
          )
        );
      }
    } else if (count > 0) {
      Sys.announce(
        Localization.getBuiltInMessage("numberSelected", {
          numSelected: count,
        })
      );
    }
  }

  private uploadProgress(
    file: File,
    status: ProgressStatus,
    event?: ProgressEvent
  ) {
    let progress: HTMLDivElement | undefined = undefined;
    if (this.progressBars.has(file)) {
      progress = this.progressBars.get(file)!.firstChild as HTMLDivElement;
    }

    switch (status) {
      case "Finalized":
        if (!progress) {
          return;
        }

        const progressBar = this.progressBars.get(file)!;
        const complete = progressBar.querySelector(
          ".cx-progress-complete"
        )! as HTMLDivElement;
        complete.style.opacity = "1";
        break;

      case "Ongoing":
        if (!progress || !event) {
          return;
        }

        if (event.loaded / event.total >= 1) {
          progress.classList.add("cx-progress-slow");
          progress.style.width = "98%";

          return;
        }

        // Only show 90% complete until the upload has finished
        const pct = Math.round((event.loaded / event.total) * 90);
        progress.style.width = `${pct}%`;
        break;

      case "Started":
        // Adjusts the position of any existing progress bars.
        this.positionAllProgressBars();
        this.addProgressBar(file);
        break;

      case "Uploaded":
        if (!progress) {
          return;
        }

        progress.classList.remove("cx-progress-slow");
        progress.style.width = "100%";
        break;

      default:
    }
  }

  public componentDidUpdate() {
    // Must give the grid a chance to render content.
    window.clearTimeout(this.componentDidUpdateTimeout);
    this.componentDidUpdateTimeout = window.setTimeout(() => {
      this.updateRowErrors();
      this.rowErrors.forEach((rowError: HTMLDivElement) => {
        this.positionRowError(rowError);
      });
    }, 100);
  }

  public componentWillUnmount() {
    window.clearTimeout(this.columnResizedTimeout);
    window.clearTimeout(this.componentDidUpdateTimeout);
    window.clearTimeout(this.gridSizeChangedTimeout);
    window.clearTimeout(this.populateDataTimeout);
    window.clearTimeout(this.populateDataWaitRenderTimeout);
    window.clearTimeout(this.removeRowErrorsTimeout);
    window.clearTimeout(this.updateLayoutTimeout);
    window.clearTimeout(this.updateRowHeightTimeout);

    this.props.setPopulate(null);
    if (this.props.setRestoreLostFocus) {
      this.props.setRestoreLostFocus(null);
    }
    if (this.props.setScrollToRow) {
      this.props.setScrollToRow(null);
    }

    if (this.gridApi) {
      // FUTURE: Log a bug with ag-Grid
      // Force removal of all custom column renderers
      this.gridApi.setColumnDefs([]);
    }

    if (this.uploadFilesDisposer) {
      this.uploadFilesDisposer();
    }
  }

  public focus = () => {
    const lastFocusedCell: CellPosition | null = this.gridApi.getFocusedCell();
    const pinnedRowCount: number = this.gridApi.getPinnedTopRowCount();

    let minRow = 0;
    let maxRow = pinnedRowCount - 1;
    if (lastFocusedCell && !lastFocusedCell.rowPinned) {
      minRow = this.gridApi.getFirstDisplayedRow();
      maxRow = this.gridApi.getLastDisplayedRow();
    }

    if (
      lastFocusedCell &&
      lastFocusedCell.rowIndex >= minRow &&
      lastFocusedCell.rowIndex <= maxRow
    ) {
      this.gridApi.setFocusedCell(
        lastFocusedCell.rowIndex,
        lastFocusedCell.column,
        lastFocusedCell.rowPinned || undefined
      );
    } else {
      const firstRowIndex: number =
        pinnedRowCount > 0 ? 0 : this.gridApi.getFirstDisplayedRow();
      const firstColumn: Column = this.columnApi.getAllDisplayedColumns()[0];

      this.gridApi.setFocusedCell(
        firstRowIndex,
        firstColumn,
        pinnedRowCount > 0 ? "top" : undefined
      );
    }
  };

  public focusHeaderToolbar() {
    if (this.headerToolbarRef.current) {
      const focusable = KeyboardNavigationGroupBase.getFocusableChildren(
        this.headerToolbarRef.current,
        ToolbarBase.childSelector
      );

      if (!!focusable.length) {
        (focusable[0] as HTMLElement).focus();
      }
    }
  }

  public focusNewRow = () => {
    if (!this.props.fullWidthChild) {
      this.gridApi.setFocusedCell(
        0,
        this.columnApi.getAllDisplayedColumns()[0],
        "top"
      );
    } else {
      const rowKey = this.gridApi.getDisplayedRowAtIndex(0)!.data.rowKey;
      const child = this.fullWidthChildByRowKey.get(rowKey);
      if (child) {
        child.focus();
      }
    }
  };

  public removeFullWidthChild(rowKey: string) {
    this.fullWidthChildByRowKey.delete(rowKey);
  }

  public setFullWidthChild(rowKey: string, child: FullWidthRendererBase) {
    this.fullWidthChildByRowKey.set(rowKey, child);
  }

  public render(): React.ReactNode {
    const errorCount: number = ErrorsStore.getTableErrorCount(
      this.props.contentDataId
    );
    const errorMessageDetails: string[] = this.getTableErrorDetails();
    const tableErrors: React.ReactNode = this.getTableErrors();
    const hideTable = this.props.hideEmptyDocument && this.state.isEmpty;
    const isFocusable: boolean =
      !this.state.isEmpty &&
      !this.state.isFocused &&
      !this.props.fullWidthChild;

    return (
      <div
        className={
          this.props.fullWidthChild
            ? this.props.classes.verticalLayout
            : this.props.classes.root
        }
        id={this.props.tableKey}
      >
        {!!errorCount || tableErrors ? (
          <Paper elevation={2} id={this.errorSummaryId} style={{ padding: 16 }}>
            <Typography
              component="div"
              style={{ color: Api.getSystemColor("danger") }}
            >
              {tableErrors}
              {!!errorCount
                ? Api.getErrorMessage(
                    // eslint-disable-next-line max-len
                    `${errorCount} ${
                      errorCount === 1
                        ? Localization.getBuiltInMessage("errorTable")
                        : Localization.getBuiltInMessage("errorsTable")
                    }`
                  )
                : null}
            </Typography>
            <div className="screenReaderOnly">
              {errorMessageDetails.join(" ")}
            </div>
          </Paper>
        ) : null}

        {this.headerToolbar ? (
          <Hidden
            xlDown={
              !this.props.keepHeaderOnSelect &&
              this.state.selectionToolbarVisible
            }
          >
            <Fade
              timeout={500}
              in={
                this.props.keepHeaderOnSelect ||
                !this.state.selectionToolbarVisible
              }
            >
              <div
                className={this.props.classes.print}
                ref={this.headerToolbarRef}
              >
                {this.headerToolbar}
              </div>
            </Fade>
          </Hidden>
        ) : null}

        {this.selectToolbar ? (
          <Hidden xlDown={!this.state.selectionToolbarVisible}>
            <Fade timeout={500} in={this.state.selectionToolbarVisible}>
              <div className={this.props.classes.print}>
                {this.selectToolbar}
              </div>
            </Fade>
          </Hidden>
        ) : null}

        {this.state.isGridReady ? (
          <div className={this.props.classes.print}>
            {this.props.dropAreaChild}
          </div>
        ) : null}

        <div style={{ display: hideTable ? "none" : "block" }}>
          <div
            className={
              "ag-theme-material" +
              ` ${this.state.isFocused ? "cx-focused" : ""}`
            }
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyDown={this.onKeyDown}
            ref={(r) => (this.gridContainerRef = r)}
          >
            <div
              onFocus={this.onFocusTabbableElement}
              tabIndex={isFocusable ? 0 : -1}
            />
            <AgGridReact {...this.agProps} />
            <div
              onFocus={this.onFocusTabbableElement}
              tabIndex={isFocusable ? 0 : -1}
            />
          </div>
          {this.summaryToolbar ? (
            <div
              style={{
                marginBottom:
                  this.footerToolbar && this.props.width === "xs" ? 24 : 0,
              }}
            >
              {this.summaryToolbar}
            </div>
          ) : null}
          <div className={this.props.classes.print}>
            {this.footerToolbar ? this.footerToolbar : null}
          </div>
        </div>
        <div id={this.accessibleDescriptionId} style={{ display: "none" }}>
          {this.state.accessibleDescription}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTheme(withWidth()(Table)));
