import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as muiMenu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import * as muiPopper from "@material-ui/core/Popper";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import MaskingStore from "../stores/MaskingStore";
import Grow from "./Grow";

interface Props extends WithWidth {
  anchorEl?: HTMLElement | null;
  "aria-label"?: string;
  children: React.ReactNode;
  labelId?: string;
  menuId?: string;
  modal?: boolean;
  offset?: number;
  onClose?: () => void;
  variant: "menu" | "selectedMenu";
}

const styles = (theme: Theme) =>
  createStyles({
    list: {
      flex: 1,
      minHeight: 0,
      outline: "none",
      overflow: "auto",
    },
    paper: {
      display: "flex",
      flex: 1,
      minHeight: 0,
    },
    popper: {
      display: "flex",
      flexDirection: "column",
      // Force marginTop to 0 to avoid inheriting the top margin that Grid.tsx
      // applies to the contents the nested GridItem divs. This happens when
      // disablePortal=true and the menu is inline.
      marginTop: "0 !important",
      zIndex: theme.zIndex.modal,
    },
  });

export class Menu extends React.PureComponent<
  Props & WithWidth & WithStyles<typeof styles>
> {
  private didClickAway: boolean = false;
  private isMasked: boolean = false;

  private onClickAway = (event: React.MouseEvent<EventTarget>): void => {
    if (this.props.anchorEl?.contains(event.target as HTMLElement)) {
      return;
    }

    this.didClickAway = true;
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private onClose = (event: React.KeyboardEvent | React.MouseEvent): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  // The MUI menu will disable scrolling, but we need to increment / decrement
  // the masking store to indicate when masking is applied, in order to
  // add or remove the body class that overrides overflow-y
  private onEntering = (node: HTMLElement, isAppearing: boolean) => {
    if (this.props.modal && !this.isMasked) {
      MaskingStore.maskOpened();
      this.isMasked = true;
    }
  };

  private onExit = (node: HTMLElement) => {
    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }
  };

  private onKeyDown = (event: React.KeyboardEvent): void => {
    event.stopPropagation();

    switch (event.key) {
      case "Escape":
        if (this.props.onClose) {
          this.props.onClose();
        }
        break;

      case "Tab":
        if (this.props.onClose) {
          this.props.onClose();
        }

        event.preventDefault();
        break;
      default:
    }
  };

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.anchorEl && !this.props.anchorEl) {
      if (!this.didClickAway) {
        prevProps.anchorEl.focus();
      }
    }

    if (prevProps.width !== this.props.width) {
      if (this.props.onClose) {
        this.props.onClose();
      }
    }

    this.didClickAway = false;
  }

  public componentWillUnmount() {
    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }
  }

  public render(): React.ReactNode {
    if (this.props.modal) {
      return (
        <muiMenu.default
          anchorEl={this.props.anchorEl}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className={this.props.classes.list}
          disableAutoFocusItem={this.props.variant === "menu"}
          getContentAnchorEl={undefined}
          MenuListProps={{
            "aria-label": this.props["aria-label"],
            "aria-labelledby": this.props.labelId,
            disableListWrap: true,
            id: this.props.menuId,
            role: this.props.variant === "selectedMenu" ? "listbox" : "menu",
            variant: this.props.variant,
          }}
          onClose={this.onClose}
          open={!!this.props.anchorEl}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          TransitionProps={{
            onEntering: this.onEntering,
            onExit: this.onExit,
          }}
          variant={this.props.variant}
        >
          {this.props.children}
        </muiMenu.default>
      );
    }

    const content: React.ReactNode = (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={this.onClickAway}
      >
        <MenuList
          aria-labelledby={this.props.labelId}
          role={this.props.variant === "selectedMenu" ? "listbox" : "menu"}
          id={this.props.menuId}
          autoFocusItem={true}
          className={this.props.classes.list}
          disableListWrap={true}
          variant={this.props.variant}
        >
          {this.props.children}
        </MenuList>
      </ClickAwayListener>
    );

    const rootAncestor: Element | null = this.props.anchorEl
      ? this.props.anchorEl.closest("#root")
      : null;

    // The root element is not an ancestor of the anchorEl if this component is
    // rendered in a dialog. In that case, the scroll parent is used to contain
    // the menu.
    const boundariesElement: Element | string = rootAncestor || "scrollParent";

    return (
      <muiPopper.default
        anchorEl={this.props.anchorEl}
        className={this.props.classes.popper}
        disablePortal={true}
        modifiers={{
          offset: { offset: `0, ${this.props.offset}` },
          preventOverflow: {
            boundariesElement,
          },
        }}
        onKeyDown={this.onKeyDown}
        open={this.props.anchorEl !== undefined}
        placement="bottom-start"
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
            timeout={300}
          >
            <Paper className={this.props.classes.paper} elevation={8}>
              {this.props.anchorEl ? content : null}
            </Paper>
          </Grow>
        )}
      </muiPopper.default>
    );
  }
}

export default withStyles(styles)(withWidth()(Menu));
