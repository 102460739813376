import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Dialog, { DialogChildProps, DialogLayoutConfig } from "../config/Dialog";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import TrackableModel from "../core/TrackableModel";
import { BreakPointColumn } from "../coreui/Dialog";
import FocusTracker from "../coreui/FocusTracker";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import ComplexGridControlService from "../services/ComplexGridControlService";
import DialogService from "../services/DialogService";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import Api, { AccessLevel } from "./Api";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import TableDisplayDialog from "./Columns/TableDisplayDialog";
import ErrorBoundary from "./ErrorBoundary";
import { GridRelatedEditButton } from "./GridRelatedEditButton";
import Panel from "./Panel";

interface ConfigProperties extends WithWidth {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  footerToolbar?: object;
  headerToolbar?: object;
  name: string;
  relatedEditLayoutIds: {
    RelatedEditLayoutId: number;
    RowTypeId: number;
  }[];
  rowSelection: boolean;
  selectionToolbar?: object;
  summaryToolbar?: object;
  tableKey: string;
  verticalLayout?: object;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  rowKeys: string[];
}

interface State {
  dialogRowKey?: string;
}

const styles = (theme: Theme) => createStyles({});

@observer
export class ComplexGridControl extends React.Component<
  ConfigProperties & WithWidth & WithStyles<typeof styles>,
  State
> {
  private asyncDataRef: React.RefObject<AsyncData>;
  private dialogLabelledById: string;
  @observable private dialogLayoutConfig: DialogLayoutConfig | null = null;
  private dialogPropagatedProps: DialogChildProps | undefined;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private populate: ((rows: TrackableModel[]) => void) | null = null;
  private propagated: TableChildProps;
  private restoreLostFocus: (() => void) | null;
  private tableProps: TableProps;

  public constructor(
    props: ConfigProperties & WithWidth & WithStyles<typeof styles>
  ) {
    super(props);

    this.state = {};
    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    const dialogId = Sys.nextId;
    this.dialogLabelledById = `dialog-${dialogId}-labelled-by`;

    this.propagated = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
        },
        description: props.description,
        hasRelatedEditDialog: props.relatedEditLayoutIds ? true : false,
        isDocumentGrid: false,
        openRowEditDialog: (r, i, e) => this.openRowDialog(r),
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.tableProps = {
      "aria-label": this.props.description,
      cardDepth: props.cardDepth,
      cellEdit: false,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      rowSelection: props.rowSelection ? "multiple" : undefined,
      selectToolbarChild: props.selectionToolbar,
      setPopulate: (populate) => (this.populate = populate),
      setRestoreLostFocus: (restoreFocus) =>
        (this.restoreLostFocus = restoreFocus),
      summaryToolbarChild: props.summaryToolbar,
    };
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.description,
      }),
      true
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private getData = () => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;

    return RoundTripService.partialDataRetrevial<GetDataResponse>(
      `ComplexGridControl/GetRowsData/${row.rowKey}` +
        `/${this.props.dataId}/${this.props.name}`
    );
  };

  private onDialogClose = (): void => {
    AppServer.recoverStateFromPoint();

    this.dialogPropagatedProps = undefined;
    this.dialogLayoutConfig = null;
    this.setState({ dialogRowKey: undefined });
  };

  private onDialogOpen = async (
    parentRowKey: string
  ): Promise<BreakPointColumn[]> => {
    // EmbeddedAddOn.roundTripStarting() is not called here because, though this
    // data request merges data changes, it is not a round trip. The app server
    // state is reverted as soon as the dialog closes, so the merged data
    // changes are transitory. They exist only to ensure the dialog contents
    // reflect what is entered on the main presentation.
    //
    // This presents an edge case where an EmbeddedAddOn OnChange script will
    // run when applying the data changes and queue a business error. In this
    // case, the error will be ignored, since it is not worth it at this time to
    // add complexity supporting such a corner case. Instead, the fact that the
    // error was raised and then ignored will be logged by the app server.
    AppServer.createStateRecoveryPoint();

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const dialogOpenResponse = await ComplexGridControlService.onDialogOpen(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      parentRowKey
    );

    if (dialogOpenResponse.validationErrors.length > 0) {
      ErrorsStore.showErrors(dialogOpenResponse.validationErrors);
      return Promise.reject();
    }

    const configResponse = await DialogService.getConfig(
      dialogOpenResponse.dialogLayoutId
    );

    AppServer.setState(dialogOpenResponse.appServerState);
    PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

    this.dialogPropagatedProps = {
      parentDialog: {
        rowKey: parentRowKey,
      },
    };
    this.dialogLayoutConfig = configResponse.dialogLayout;

    return configResponse.breakPoints;
  };

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState = this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private openRowDialog(rowKey: string) {
    this.setState({ dialogRowKey: rowKey });
  }

  private populateData = () => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.contentDataId
    );

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  public componentDidMount() {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    if (this.props.relatedEditLayoutIds) {
      this.tableProps.columns.push(
        GridRelatedEditButton.createColDef(this.propagated)
      );
    }
  }

  public componentDidUpdate(prevProps: ConfigProperties) {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public render() {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <div style={{ position: "relative" }}>
            <AsyncData
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              getData={this.getData}
              onIsLoadingChanged={this.onIsLoadingChanged}
              populateData={this.populateData}
              ref={this.asyncDataRef}
            />
            <Table
              {...this.tableProps}
              fullWidthChild={this.props.verticalLayout}
              tableKey={this.props.tableKey}
            />
            <TableDisplayDialog
              labelledById={this.dialogLabelledById}
              onClose={this.onDialogClose}
              onExited={() => this.restoreLostFocus!()}
              onOpen={this.onDialogOpen}
              parentRowKey={this.state.dialogRowKey}
            >
              {this.dialogLayoutConfig !== null ? (
                <Panel presentationId={this.dialogLayoutConfig.layoutId}>
                  <Dialog
                    config={this.dialogLayoutConfig}
                    labelledById={this.dialogLabelledById}
                    propagated={this.dialogPropagatedProps}
                  />
                </Panel>
              ) : null}
            </TableDisplayDialog>
          </div>
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

export default withStyles(styles)(withWidth()(ComplexGridControl));
