import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import { SearchChildProps } from "../config/SearchPresentation";
import { TableChildProps } from "../coreui/Table";
import ClearCriteriaButton from "./ActionButtons/ClearCriteriaButton";
import CustomActionButton, {
  CustomActionButton as CustomActionButtonBase,
} from "./ActionButtons/CustomActionButton";
import DeletePresentationButton, {
  DeletePresentationButton as DeletePresentationButtonBase,
} from "./ActionButtons/DeletePresentationButton";
import DeleteRowButton, {
  DeleteRowButton as DeleteRowButtonBase,
} from "./ActionButtons/DeleteRowButton";
import ExecuteSearchButton from "./ActionButtons/ExecuteSearchButton";
import NewRowButton, {
  NewRowButton as NewRowButtonBase,
} from "./ActionButtons/NewRowButton";
import SavePresentationButton, {
  SavePresentationButton as SavePresentationButtonBase,
} from "./ActionButtons/SavePresentationButton";
import SelectButton from "./ActionButtons/SelectButton";
import SignInButton, {
  SignInButton as SignInButtonBase,
} from "./ActionButtons/SignInButton";
import SignOutButton, {
  SignOutButton as SignOutButtonBase,
} from "./ActionButtons/SignOutButton";
import WizardNextButton, {
  WizardNextButton as WizardNextButtonBase,
} from "./ActionButtons/WizardNextButton";
import WizardPreviousButton, {
  WizardPreviousButton as WizardPreviousButtonBase,
} from "./ActionButtons/WizardPreviousButton";
import { AccessLevel, ValueByBreakpoint } from "./Api";
import { MenuChild } from "./MenuButton";
import { MenuItemProps } from "./MenuItem";
import { SelectChildProps } from "./SelectControl";
import { ToolbarChildProps } from "./Toolbar";

export interface ActionButtonProps {
  buttonColor: ButtonColor;
  dataId: string;
  disabledHelpText: string;
  iconName: string;
  name: string;
  propagated: DialogChildProps &
    MenuChild &
    SearchChildProps &
    SelectChildProps &
    TableChildProps &
    ToolbarChildProps;
  renderAsLink: boolean;
  size: ButtonSize;
  uploadOnNew?: boolean;
}

export type ButtonAction =
  | "Clear Criteria"
  | "Delete Presentation"
  | "Delete Row"
  | "Execute Search"
  | "New Row"
  | "Save Presentation"
  | "Select"
  | "Sign In"
  | "Sign Out"
  | "Wizard Next"
  | "Wizard Previous";

export type ButtonColor = "Danger" | "Dark" | "Default" | "Success";

export interface ButtonSize
  extends ValueByBreakpoint<"large" | "medium" | "small"> {}

export interface ActionButtonRuntimeProps {
  accessLevel: AccessLevel;
  alternateText: string;
  cancelButtonText: string | null;
  confirmMessage: string | null;
  confirmTitle: string | null;
  continueButtonText: string | null;
  label: string;
  showDisabledHelp: boolean;
  validateCaptcha: boolean;
}

interface Props extends ActionButtonProps {
  buttonAction?: ButtonAction;
}

export class ActionButton extends React.PureComponent<Props> {
  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const configProps = props.config as { buttonAction: ButtonAction };

    if (configProps.buttonAction === null) {
      return <CustomActionButtonBase.renderMenuItem {...props} />;
    }

    switch (configProps.buttonAction) {
      case "Delete Presentation":
        return <DeletePresentationButtonBase.renderMenuItem {...props} />;
      case "Delete Row":
        return <DeleteRowButtonBase.renderMenuItem {...props} />;
      case "New Row":
        return <NewRowButtonBase.renderMenuItem {...props} />;
      case "Save Presentation":
        return <SavePresentationButtonBase.renderMenuItem {...props} />;
      case "Select":
        return <SelectButton.renderMenuItem {...props} />;
      case "Sign In":
        return <SignInButtonBase.renderMenuItem {...props} />;
      case "Sign Out":
        return <SignOutButtonBase.renderMenuItem {...props} />;
      case "Wizard Next":
        return <WizardNextButtonBase.renderMenuItem {...props} />;
      case "Wizard Previous":
        return <WizardPreviousButtonBase.renderMenuItem {...props} />;
      default:
        throw new Error(`Unknown button action ${configProps.buttonAction}`);
    }
  }

  public render(): React.ReactNode {
    const { buttonAction, ...buttonProps } = this.props;

    if (this.props.buttonAction === null) {
      return <CustomActionButton {...buttonProps} />;
    }

    switch (this.props.buttonAction) {
      case "Clear Criteria":
        return <ClearCriteriaButton {...buttonProps} />;
      case "Delete Presentation":
        return <DeletePresentationButton {...buttonProps} />;
      case "Delete Row":
        return <DeleteRowButton {...buttonProps} />;
      case "Execute Search":
        return <ExecuteSearchButton {...buttonProps} />;
      case "New Row":
        return <NewRowButton {...buttonProps} />;
      case "Save Presentation":
        return <SavePresentationButton {...buttonProps} />;
      case "Select":
        return <SelectButton {...buttonProps} />;
      case "Sign In":
        return <SignInButton {...buttonProps} />;
      case "Sign Out":
        return <SignOutButton {...buttonProps} />;
      case "Wizard Next":
        return <WizardNextButton {...buttonProps} />;
      case "Wizard Previous":
        return <WizardPreviousButton {...buttonProps} />;
      default:
        throw new Error(`Unknown button action ${this.props.buttonAction}`);
    }
  }
}

export default ActionButton;
