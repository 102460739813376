import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTextField from "@material-ui/core/TextField";
import { observer } from "mobx-react";
import * as moment from "moment";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import CalendarDialog from "../coreui/CalendarDialog";
import getFieldHelperText from "../coreui/FieldHelperText";
import Presentation from "../coreui/Presentation";
import TextField from "../coreui/TextField";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface Props {
  dataId: string;
  dateFormatError: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  name: string;
}

interface State {
  isCalendarOpen: boolean;
  isFocused?: boolean;
  rawValue?: string | null;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    inputLabelRoot: {
      width: "calc(100% - 67px)",
    },

    inputLabelShrink: {
      width: "calc((100% - 67px) * 1.333)",
    },
  });

@observer
export class DateEdit extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private lastValidDate: Date | null = null;
  private readonly localeName = "current";
  private muiProps: muiTextField.TextFieldProps;

  public static formatValue(
    value: Date | null | string,
    userFormatted: boolean
  ) {
    if (value && typeof value !== "string") {
      return moment(value).format(DateEdit.getDateFormat(userFormatted));
    }

    return value;
  }

  public static getDateFormat(userFormatted: boolean) {
    let format = "";

    if (userFormatted) {
      format = Localization.dateFormat;
    } else {
      format = "yyyy-mm-dd";
    }

    // Set case of date formatter to match moment format
    // https://momentjs.com/docs/#/parsing/string-format/
    format = format.replace(/d/gi, "D");
    format = format.replace(/m/gi, "M");
    format = format.replace(/y/gi, "Y");

    return format;
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      isCalendarOpen: false,
      isFocused: false,
    };

    this.muiProps = {};

    this.muiProps.fullWidth = true;
    this.muiProps.id = `date-field-${Sys.nextId}`;
    this.muiProps.label = props.label;
    this.muiProps.name = props.name;
    this.muiProps.autoFocus = props["autoFocus"];
    this.muiProps.InputProps = props["InputProps"] || {};
    this.muiProps.InputProps!.placeholder = Localization.dateFormat;

    this.muiProps.onChange = (e) => {
      ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
      this.setState({ rawValue: e.target.value });
    };

    this.muiProps.onFocus = () => {
      const currentValue = this.getCurrentValueParsed(false);
      this.setState({
        isFocused: true,
        rawValue: DateEdit.formatValue(currentValue, true),
      });
      if (typeof currentValue !== "string") {
        this.lastValidDate = currentValue;
      }
    };

    this.muiProps.onKeyDown = (e) => {
      if (e.key === "Enter") {
        this.formatCurrentValueAndSetErrors(true);
        this.setState({
          rawValue: DateEdit.formatValue(
            this.getCurrentValueParsed(false),
            true
          ),
        });
      }
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private formatCurrentValueAndSetErrors(raw: boolean) {
    const widgetProperties = Api.getWidgetProperties(this.props);

    if (!widgetProperties) {
      return;
    }

    const businessErrors = widgetProperties["businessErrors"];
    const errorIndex = businessErrors.indexOf(this.props.dateFormatError);

    const date = this.getCurrentValueParsed(raw);
    if (typeof date === "string") {
      this.setValue(this.state.rawValue!);

      if (errorIndex < 0) {
        businessErrors.push(this.props.dateFormatError);
      }
    } else {
      this.setFormattedValue(date);

      if (errorIndex >= 0) {
        businessErrors.splice(errorIndex, 1);
      }
    }
  }

  private getCurrentValueParsed(raw: boolean) {
    const value = raw ? this.state.rawValue : Presentation.getValue(this.props);

    let parsed: Date | null | string = null;
    if (value) {
      const format = DateEdit.getDateFormat(raw);
      const date = moment(value, format, raw ? this.localeName : "en", true);
      if (date.isValid()) {
        parsed = date.toDate();
      } else {
        if (raw) {
          parsed = Localization.parseDate(value);
          if (!parsed) {
            parsed = value;
          }
        } else {
          parsed = value;
        }
      }
    }

    return parsed;
  }

  private onCalendarClose = (): void => {
    this.setState({ isCalendarOpen: false });
  };

  private onCalendarDateSelected = (date: Date): void => {
    this.setFormattedValue(date);
    this.formatCurrentValueAndSetErrors(false);
  };

  private openCalendar = () => {
    this.setState({ isCalendarOpen: true });
  };

  private setFormattedValue(value: Date | null) {
    this.setValue(DateEdit.formatValue(value, false));
  }

  private setValue(value: string | null) {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value);
  }

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const widgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    this.muiProps.InputProps!.endAdornment = undefined;
    this.muiProps.required = false;
    this.muiProps.style = undefined;
    this.muiProps.variant = "filled";

    if (widgetProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widgetProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widgetProperties.accessLevel === AccessLevel.readOnly) {
      const value = DateEdit.formatValue(
        this.getCurrentValueParsed(false),
        true
      );

      return (
        <TextField
          label={_props.label}
          name={_props.name}
          readOnly={true}
          value={value ? value : "-"}
          variant="filled"
        />
      );
    }

    this.muiProps.InputProps!.endAdornment = (
      <muiInputAdornment.default position="end" style={{ marginTop: -4 }}>
        <Button
          aria-label={Localization.getBuiltInMessage(
            "DateEdit.selectDateButtonLabel",
            {
              datePickerLabel: _props.label,
            }
          )}
          icon="fas fa-calendar-alt"
          size="small"
          onClick={this.openCalendar}
        />
      </muiInputAdornment.default>
    );

    this.muiProps.required = widgetProperties.showAsMandatory;

    const fieldHelperText = getFieldHelperText({
      getErrors: () => widgetProperties.businessErrors,
      helperText: this.props.helperText,
    });

    let formatted: string | null;
    if (this.state.isFocused) {
      formatted = this.state.rawValue!;
    } else {
      formatted = DateEdit.formatValue(this.getCurrentValueParsed(false), true);
    }
    this.muiProps.value = formatted === null ? "" : formatted;

    let currentDate = this.getCurrentValueParsed(false);
    if (typeof currentDate === "string") {
      currentDate = this.lastValidDate ? this.lastValidDate : null;
    }

    return (
      <React.Fragment>
        <muiTextField.default
          {...this.muiProps}
          error={fieldHelperText.hasErrors}
          FormHelperTextProps={{
            "aria-hidden": true,
            ...fieldHelperText.formHelperTextProps,
          }}
          helperText={fieldHelperText.helperText}
          InputLabelProps={{
            classes: {
              root: this.props.classes.inputLabelRoot,
              shrink: this.props.classes.inputLabelShrink,
            },
          }}
          onBlur={() => {
            this.setState({ isFocused: false });
            this.formatCurrentValueAndSetErrors(true);
            this.announceErrors(fieldHelperText.errors);
          }}
          role="group"
        />
        <CalendarDialog
          onClose={this.onCalendarClose}
          onDateSelected={this.onCalendarDateSelected}
          open={this.state.isCalendarOpen}
          title={Localization.getBuiltInMessage(
            "DateEdit.selectDateDialogLabel",
            {
              datePickerLabel: _props.label,
            }
          )}
          value={currentDate}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DateEdit);
