import { FormHelperTextProps } from "@material-ui/core/FormHelperText";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Api from "../mustangui/Api";
import Typography from "./Typography";

interface Props {
  getErrors?: (value?: string) => string[];
  helperText: React.ReactNode;
  value?: string;
}

interface HelperTextProps {
  errors: string[];
  helperText: React.ReactNode;
}

interface Result {
  formHelperTextProps: Partial<FormHelperTextProps> & { component?: string };
  errors: string[];
  hasErrors: boolean;
  helperText: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    errors: {
      overflow: "visible",
      whiteSpace: "normal",
    },
    helperText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  });

export class HelperText extends React.PureComponent<
  HelperTextProps & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    const errorMessages = (
      <div className={this.props.classes.errors}>
        {Api.getErrorMessages(this.props.errors)}
      </div>
    );

    if (this.props.helperText) {
      return (
        <div>
          <Typography
            className={this.props.classes.helperText}
            variant="caption"
          >
            {this.props.helperText}
          </Typography>
          {errorMessages}
        </div>
      );
    }

    return errorMessages;
  }
}

const FieldHelperText = withStyles(styles)(HelperText);

export default function getFieldHelperText(props: Props): Result {
  const result: Result = {
    errors: [],
    formHelperTextProps: {
      // Required so the DOM is still valid when error
      // messages are displayed in the helper text.
      component: "div",
      style: {
        marginLeft: 16,
        marginRight: 0,
      },
    },
    hasErrors: false,
    helperText: props.helperText,
  };

  if (!props.getErrors) {
    return result;
  }

  const errors: string[] = props.getErrors(props.value);
  if (errors.length === 0) {
    return result;
  }

  result.hasErrors = true;
  result.errors = errors;

  result.helperText = (
    <FieldHelperText errors={errors} helperText={props.helperText} />
  );

  return result;
}
