import * as muiDrawer from "@material-ui/core/Drawer";
import * as muiMenuList from "@material-ui/core/MenuList";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../core/Localization";
import MaskingStore from "../stores/MaskingStore";
import Button from "./Button";

interface Props {
  anchorEl?: HTMLElement;
  children: React.ReactNode;
  onClose?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "relative",
      top: "calc(50% - 20px)",
    },
    header: {
      borderBottom: 1,
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: "solid",
      marginBottom: 8,
      paddingLeft: 40,
      paddingRight: 40,
      [theme.breakpoints.up("xs")]: {
        minHeight: 72,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 88,
      },
      [theme.breakpoints.up("md")]: {
        minHeight: 108,
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: 140,
      },
    },
    menuList: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: 24,
        marginRight: 24,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: 16,
        marginRight: 16,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      outline: "none",
      padding: 0,
    },
    paper: {
      maxWidth: "100%",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  });

export class Drawer extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private isMasked: boolean = false;
  private onClose = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public componentDidUpdate(prevProps: Props): void {
    if (!prevProps.anchorEl && this.props.anchorEl) {
      setTimeout(() => {
        MaskingStore.maskOpened();
        this.isMasked = true;
      });
    } else if (prevProps.anchorEl && !this.props.anchorEl) {
      if (this.isMasked) {
        MaskingStore.maskClosed();
        this.isMasked = false;
      }

      prevProps.anchorEl.focus();
    }
  }

  public componentWillUnmount() {
    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }
  }

  public render(): React.ReactNode {
    return (
      <muiDrawer.default
        anchor="right"
        classes={{ paper: this.props.classes.paper }}
        className={this.props.classes.paper}
        onClose={this.onClose}
        open={this.props.anchorEl !== undefined}
        PaperProps={{
          "aria-label": Localization.getBuiltInMessage("menu"),
          role: "dialog",
        }}
      >
        <div className={this.props.classes.header}>
          <Button
            aria-label={Localization.getBuiltInMessage("close")}
            className={this.props.classes.closeButton}
            icon="fas fa-times"
            onClick={this.onClose}
            style={{ float: "right" }}
          />
        </div>
        <muiMenuList.default
          aria-label={Localization.getBuiltInMessage("menu")}
          autoFocusItem={true}
          className={this.props.classes.menuList}
          disableListWrap={true}
        >
          {this.props.children}
        </muiMenuList.default>
      </muiDrawer.default>
    );
  }
}

export default withStyles(styles)(Drawer);
