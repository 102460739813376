import * as muiBadge from "@material-ui/core/Badge";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTab from "@material-ui/core/Tab";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import Localization from "../core/Localization";
import Api from "../mustangui/Api";
import Button from "./Button";
import Icon from "./Icon";

interface Props extends muiTab.TabProps {
  businessErrorsCount: number;
  complete: boolean;
  last: boolean;
  step: number;
  stepCount: number;
}

const styles = (theme: Theme) =>
  createStyles({
    badge: {
      [theme.breakpoints.up("sm")]: {
        left: "50%",
      },
      backgroundColor: theme.palette.error.main,
      left: 20,
      top: 10,
      width: 20,
    },
    badgeRoot: {
      width: "100%",
    },
    disabled: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      opacity: "1 !important" as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pointerEvents: "auto !important" as any,
    },
    errorIcon: {
      color: theme.palette.common.white,
      marginTop: 8,
    },
    first: {
      [theme.breakpoints.only("xs")]: {
        height: "46px !important",
        marginTop: "10px !important",
      },
    },
    label: {
      // Required because Chrome can't do math.
      [theme.breakpoints.up("lg")]: { maxWidth: 134, minWidth: 133.5 },
      [theme.breakpoints.only("md")]: { maxWidth: 136, minWidth: 136 },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 8,
        paddingTop: 24,
        textAlign: "center",
      },
      [theme.breakpoints.only("sm")]: { maxWidth: 126, minWidth: 126 },
      [theme.breakpoints.only("xs")]: { maxWidth: "100%", minWidth: 300 },
      color: theme.palette.text.primary,
      overflow: "hidden",
      paddingLeft: 32,
      paddingRight: 8,
      textAlign: "left",
      textOverflow: "ellipsis",
    },
    labelDisabled: {
      color: theme.palette.grey[300],
    },
    last: {
      [theme.breakpoints.only("xs")]: {
        height: "46px !important",
        marginBottom: 10,
      },
    },
    root: {
      "&:focus-visible": { fontWeight: "bold" },
      alignItems: "flex-start",
      flex: "auto",
      height: "100%",
      maxWidth: "100%",
      overflow: "visible",
    },
    selected: {
      backgroundColor: "transparent !important",
    },
    step: {
      [theme.breakpoints.up("sm")]: {
        flexDirection: "column",
        paddingTop: 8,
      },
      alignItems: "center",
      display: "flex",
      flex: "auto",
      width: "100%",
    },
    stepbar: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.grey[300],
        height: 8,
        marginBottom: 26,
        marginLeft: 0,
        marginTop: -34,
        width: "100%",
      },
      backgroundColor: theme.palette.grey[300],
      height: 56,
      marginLeft: -24,
      minWidth: 8,
      width: 8,
    },
    stepbutton: {
      [theme.breakpoints.up("sm")]: {
        fontSize: 24,
        height: 60,
        width: 60,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
      },
      minWidth: 40,
    },
    stepComplete: {
      backgroundColor: Api.getSystemColor("success"),
      color: theme.palette.common.white,
    },
    stepCompleteSelected: {
      borderColor: Api.getSystemColor("success"),
      borderStyle: "solid",
      borderWidth: 4,
    },
    stepSelected: {
      backgroundColor: theme.palette.grey[800],
    },
    wrapped: {
      [theme.breakpoints.up("lg")]: { maxWidth: 150, minWidth: 150 },
      [theme.breakpoints.only("md")]: { maxWidth: 152, minWidth: 152 },
      [theme.breakpoints.only("sm")]: { maxWidth: 142, minWidth: 142 },
      [theme.breakpoints.only("xs")]: { maxWidth: "100%", minWidth: 300 },
      alignItems: "flex-start",
      flex: "auto",
      height: "100%",
      maxWidth: "100%",
      overflow: "visible",
    },
  });

export class Step extends React.PureComponent<
  Props & WithStyles<typeof styles> & WithWidth
> {
  private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(event);
      event.stopPropagation();
    }
  };

  public render() {
    const {
      businessErrorsCount,
      classes,
      className,
      complete,
      last,
      onChange,
      onClick,
      step,
      stepCount,
      ...tabProps
    } = this.props;

    let barClasses: string = classes.stepbar;
    let buttonClasses: string = classes.stepbutton;

    if (tabProps.selected) {
      barClasses += ` ${classes.stepSelected}`;

      if (complete) {
        buttonClasses += ` ${classes.stepCompleteSelected}`;
      }
    } else if (complete) {
      barClasses += ` ${classes.stepComplete}`;
      buttonClasses += ` ${classes.stepComplete}`;
    }

    if (step === 1) {
      barClasses += ` ${classes.first}`;
    }

    if (last) {
      barClasses += ` ${classes.last}`;
    }

    let labelClasses: string = classes.label;
    if (tabProps.disabled) {
      labelClasses += ` ${classes.labelDisabled}`;
    }

    const accessibleDescription: string = Localization.getBuiltInMessage(
      complete ? "Wizard.stepComplete" : "Wizard.stepIncomplete",
      {
        count: stepCount,
        step,
      }
    );

    tabProps.label = (
      <div className={classes.step}>
        <Button
          aria-hidden={true}
          className={buttonClasses}
          color={tabProps.selected ? "dark" : complete ? "success" : "default"}
          component="span"
          disabled={tabProps.disabled}
          disableRipple={true}
          icon={
            (complete && !tabProps.selected) || last
              ? "fas fa-check"
              : undefined
          }
          fab
          role={undefined}
          tabIndex={-1}
        >
          {(complete && !tabProps.selected) || last ? null : step}
        </Button>
        <div className={barClasses} />
        <span className="screenReaderOnly">{accessibleDescription}</span>
        <span className={labelClasses}>{tabProps.label}</span>
      </div>
    );

    if (businessErrorsCount > 0) {
      let accessibleErrorMessage: string;

      if (businessErrorsCount > 1) {
        accessibleErrorMessage = Localization.getBuiltInMessage(
          "Wizard.erroredFieldsCountMultiple",
          {
            widgetErrorCount: businessErrorsCount,
          }
        );
      } else {
        accessibleErrorMessage = Localization.getBuiltInMessage(
          "Wizard.erroredFieldsCountSingle"
        );
      }

      tabProps.label = (
        <muiBadge.default
          badgeContent={
            <Icon className={classes.errorIcon} icon="fas fa-exclamation" />
          }
          classes={{ badge: classes.badge, root: classes.badgeRoot }}
        >
          {tabProps.label}
          <span className="screenReaderOnly">{accessibleErrorMessage}</span>
        </muiBadge.default>
      );
    }

    return (
      <muiTab.default
        classes={{
          disabled: classes.disabled,
          root:
            ((this.props.width === "xl" || this.props.width === "lg") &&
              step > 8) ||
            (this.props.width === "md" && step > 6) ||
            (this.props.width === "sm" && step > 4)
              ? classes.wrapped
              : classes.root,
          selected: classes.selected,
        }}
        onClick={this.onClick}
        {...tabProps}
      />
    );
  }
}

export default withStyles(styles)(withWidth()(Step));
