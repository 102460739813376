import { observer } from "mobx-react";
import * as React from "react";
import Paper from "../coreui/Paper";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import Api, { AccessLevel } from "./Api";
import Grid from "./Grid";
import GridItem from "./GridItem";

interface Props {
  cardDepth: number;
  content: object;
  contentDataId: string;
  dataId: string;
  headerToolbar?: object;
  name: string;
  selectionToolbar?: object;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
}

@observer
export class CardControl extends React.Component<Props> {
  private headerToolbar: React.ReactNode = null;

  public constructor(props: Props) {
    super(props);

    if (props.headerToolbar) {
      this.headerToolbar = Presentation.create(this.props.headerToolbar, {
        verticalLayout: true,
      });
    }
  }

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const widgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.contentDataId
    );

    const cards: React.ReactNode[] = [];
    for (const model of rows) {
      const paneRow = model as PaneRow;
      cards.push(
        <GridItem key={paneRow.rowKey!} lg={1} md={1} sm={1} xs={1} xl={1}>
          <Paper card cardDepth={_props.cardDepth} margin elevation={0}>
            {this.props.selectionToolbar
              ? Presentation.create(this.props.selectionToolbar, {
                  contentDataId: this.props.contentDataId,
                  parentRowKey: paneRow.rowKey,
                })
              : null}
            {Presentation.create(this.props.content, {
              rowKey: paneRow.rowKey,
            })}
          </Paper>
        </GridItem>
      );
    }

    return (
      <div>
        {this.headerToolbar}
        <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
          {cards}
        </Grid>
      </div>
    );
  }
}

export default CardControl;
