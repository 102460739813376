import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config/SearchPresentation";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Autocomplete from "../coreui/Autocomplete";
import Button from "../coreui/Button";
import Presentation from "../coreui/Presentation";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import Api, { AccessLevel } from "./Api";
import { SelectChildProps } from "./SelectControl";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  name: string;
  propagated: SelectChildProps & SearchChildProps;
  searchButtonIsVisible: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  possibleValues: object[];
  showAsMandatory: boolean;
}

@observer
export class TextCriteria extends React.Component<ConfigProperties> {
  private initialRender: boolean = true;

  private getErrors = (value: string): string[] => {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return [];
    }

    return runtimeProperties.businessErrors;
  };

  private onClickSearchButton = (): void => {
    this.props.propagated.parentSelect.search(this.props.name);
  };

  private onInputChange = (value: string) => {
    // Event is fired on initial render with a blank value even if inputValue
    // has a value when rendered in a search dialog.  So it is ignored.
    if (!this.initialRender || value) {
      ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
      Presentation.setValue(this.props, value);
    }

    this.initialRender = false;
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.props.searchButtonIsVisible) {
      this.props.propagated.parentSelect.search(this.props.name);
    } else {
      this.props.propagated.parentSearch.search();
    }
  };

  private onValueChange = (value: object | string): void => {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value);
  };

  public render(): React.ReactNode {
    const { helperText, label, name, searchButtonIsVisible } = this.props;
    const componentId: string = `text-criteria-${Sys.nextId}`;
    const describedById: string = `${componentId}-described-by`;

    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      const parentSelect = this.props.propagated.parentSelect;
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            parentSelect.getRuntimeProps().showDisabledHelp
              ? parentSelect.configProps.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    const possibleValues = runtimeProperties.possibleValues;
    const value = Presentation.getValue(this.props) as string;

    const isIOS = Sys.isMobile && Sys.isSafari;
    if (possibleValues && possibleValues.length && !isIOS) {
      return (
        <Autocomplete
          autoComplete
          disableClearable
          forcePopupIcon={true}
          freeSolo
          getErrors={this.getErrors}
          helperText={helperText}
          endAdornment={
            searchButtonIsVisible ? (
              <muiInputAdornment.default
                position="end"
                style={{ marginTop: -4 }}
              >
                <Button
                  aria-label={Localization.getBuiltInMessage("search")}
                  icon="fas fa-search"
                  onClick={this.onClickSearchButton}
                  size="small"
                />
              </muiInputAdornment.default>
            ) : undefined
          }
          inputValue={
            runtimeProperties.accessLevel === AccessLevel.readOnly
              ? value
                ? value
                : "-"
              : value
          }
          label={label}
          name={name}
          onInputChange={this.onInputChange}
          onKeyPress={this.onKeyPress}
          onValueChange={(v: object) => this.onValueChange(v)}
          options={possibleValues}
          readOnly={runtimeProperties.accessLevel === AccessLevel.readOnly}
          renderOption={(
            option: object,
            state: AutocompleteRenderOptionState
          ) => {
            const text = (option as unknown) as string;
            let markup;

            if (text && state.inputValue) {
              const index = text
                .toLowerCase()
                .indexOf(state.inputValue.toLowerCase());
              if (index > -1) {
                markup = (
                  <React.Fragment>
                    {text.substr(0, index)}
                    <b>{text.substr(index, state.inputValue.length)}</b>
                    {text.substr(index + state.inputValue.length)}
                  </React.Fragment>
                );
              } else {
                markup = option;
              }
            } else {
              markup = option;
            }

            return (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  minHeight: 40,
                  width: "100%",
                }}
              >
                <Typography ellipsis>{markup}</Typography>
              </div>
            );
          }}
          required={runtimeProperties.showAsMandatory}
          role="group"
        />
      );
    }

    let inputDescribedByText: string = "";
    if (
      this.props.propagated.parentSelect &&
      (!this.props.propagated.parentSelect.isDialogOpen ||
        this.props.propagated.parentSelect.isDialogClosing)
    ) {
      if (helperText) {
        inputDescribedByText = helperText.endsWith(".")
          ? `${helperText} `
          : `${helperText}. `;
      }

      const selectGridRows: PaneRow[] = PaneDataStore.getPaneCollection(
        this.props.propagated.parentSelect.selectedDataId
      );

      if (selectGridRows.length === 1) {
        inputDescribedByText += Localization.getBuiltInMessage(
          "DataTable.selectCriteriaOneRowInTable"
        );
      } else if (selectGridRows.length > 1) {
        inputDescribedByText += Localization.getBuiltInMessage(
          "DataTable.selectCriteriaMultipleRowsInTable",
          { count: selectGridRows.length }
        );
      }
    } else {
      // This TextCriteria widget is on a Search Layout. Simply use the
      // configured helper text as the accessible description.
      inputDescribedByText = helperText;
    }

    return (
      <div>
        <TextField
          getErrors={this.getErrors}
          helperText={helperText}
          InputProps={{
            "aria-describedby": describedById,
            endAdornment: searchButtonIsVisible ? (
              <muiInputAdornment.default
                position="end"
                style={{ marginTop: -4 }}
              >
                <Button
                  aria-label={Localization.getBuiltInMessage("Search")}
                  icon="fas fa-search"
                  onClick={this.onClickSearchButton}
                  size="small"
                />
              </muiInputAdornment.default>
            ) : undefined,
          }}
          label={label}
          name={name}
          onKeyPress={this.onKeyPress}
          onValueChange={this.onValueChange}
          readOnly={runtimeProperties.accessLevel === AccessLevel.readOnly}
          required={runtimeProperties.showAsMandatory}
          value={
            runtimeProperties.accessLevel === AccessLevel.readOnly
              ? value
                ? value
                : "-"
              : value
          }
          variant="filled"
        />
        <span id={describedById} style={{ display: "none" }}>
          {inputDescribedByText}
        </span>
      </div>
    );
  }
}

export default TextCriteria;
