import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FormData from "../coreui/FormData";
import Thumbnail, { ThumbnailTypes } from "../coreui/Thumbnail";
import DocumentService from "../services/DocumentService";
import Api, { AccessLevel } from "./Api";

interface Props {
  dataId: string;
  imageHeight: number;
  imageWidth: number;
  name: string;
  parentColumnHeader?: string;
  thumbnailType: ThumbnailTypes;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  documentHandle: string | null;
  downloadToken: string;
  fileName: string;
  hasThumbnailImage: boolean;
  iconName: string;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class ThumbnailDisplay extends React.Component<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const _props = { ...this.props };
    const widgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      !widgetProperties.documentHandle &&
      !widgetProperties.pendingDocumentId
    ) {
      return null;
    }

    const imgSrc: string = DocumentService.getThumbnailUrl(
      widgetProperties.documentHandle,
      widgetProperties.pendingDocumentId,
      widgetProperties.pendingThumbnailId,
      _props.thumbnailType,
      widgetProperties.downloadToken
    );

    const downloadUrl: string = DocumentService.getDocumentUrl(
      widgetProperties.documentHandle,
      widgetProperties.pendingDocumentId,
      widgetProperties.fileName,
      widgetProperties.downloadToken
    );

    let content: React.ReactNode = (
      <Thumbnail
        alternateText={widgetProperties.alternateText}
        downloadUrl={downloadUrl}
        hasImage={widgetProperties.hasThumbnailImage}
        iconName={widgetProperties.iconName}
        imageHeight={_props.imageHeight}
        imageWidth={_props.imageWidth}
        imgSrc={imgSrc}
        thumbnailType={_props.thumbnailType}
      />
    );

    if (_props.parentColumnHeader) {
      content = (
        <FormData label={_props.parentColumnHeader}>{content}</FormData>
      );
    }

    return <div className={_props.classes.root}>{content}</div>;
  }
}

export default withStyles(styles)(ThumbnailDisplay);
