import TrackableCollection from "./TrackableCollection";

// Inteface for trackable objects and collections.
export interface ITrackable {
  // Id for the data.
  dataId: string | null;
  // Indicates if pending changes should be ignored.
  ignoreChanges: boolean;
  // Indicates if the model has been loaded.
  isLoaded: boolean;
  // Indicates if only the first load request should be processed.
  loadOnce: boolean;
  // Name of the model.
  modelName: string | null;

  // Reset all state flags, discard deleted models and set initial values to the
  // current value of each trackable property.
  acceptChanges(): void;

  // Clear the contents of each trackable.
  clear(): void;

  // Indicates if this model/collection has pending changes.
  hasChanges(): boolean;

  // Populates the model with the specified data.
  load(data: object): ITrackable;
}

export default abstract class TrackableModel implements ITrackable {
  // Dictionary of deleted models, keyed by model name.
  protected static deleted: Map<string | null, Set<TrackableModel>> = new Map<
    string | null,
    Set<TrackableModel>
  >();
  // Dictionary of models, keyed by dataId.
  public static models: Map<string | null, ITrackable> = new Map<
    string | null,
    ITrackable
  >();
  public static primaryKeyNames: Map<string | null, string | null> = new Map<
    string | null,
    string | null
  >();
  // Id for the data.
  public dataId: string | null = null;
  // Default property values.
  public defaultValues: object = Object.create(null);
  // Indicates if pending changes should be ignored.
  public ignoreChanges: boolean = false;
  // Initial property values.
  public initialValues: object = Object.create(null);
  // Indicates if the model has been loaded.
  public isLoaded: boolean = false;
  // Indicates if the model has been modified.
  public isModified: boolean = false;
  // Indicates if the model is new.
  public isNew: boolean = false;
  // Indicates if the model is new.
  public isTrackableNew: boolean = false;
  // Indicates if only the first load request should be processed.
  public loadOnce: boolean = false;
  // Name of the model.
  public modelName: string | null = null;
  // Indicates if the model should track changes that undo a property
  // value change.
  public trackUndo: boolean = true;

  // Reset all state flags, discard deleted models and set initial values to the
  // current value of each trackable property.
  public static acceptChanges(models: TrackableCollection): void {
    TrackableModel.clearDeleted(models.dataId);

    if (models) {
      models.forEach((model) => {
        if (model) {
          model.acceptChanges();
        }
      });
    }
  }

  // Discard any deleted models of the specified type.
  public static clearDeleted(modelName: string | null): void {
    if (TrackableModel.deleted.has(modelName)) {
      TrackableModel.deleted.delete(modelName);
    }
  }

  // Returns a new instance of the specified model populated with the given data.
  public static create(
    modelName: string | null,
    dataId: string | null,
    data?: object
  ): TrackableModel {
    const model: TrackableModel =
      // eslint-disable-next-line no-eval
      eval(`new mustang.${modelName}('${dataId}')`);

    if (data) {
      model.load(data);
    }

    return model;
  }

  // Returns a list of deleted models with the specified dataId.
  public static getDeleted(dataId: string | null): Set<TrackableModel> {
    let result: Set<TrackableModel>;

    if (TrackableModel.deleted.has(dataId)) {
      result = TrackableModel.deleted.get(dataId)!;
    } else {
      result = new Set<TrackableModel>();
    }

    return result;
  }

  public static getPrimaryKeyName(modelName: string): string {
    if (!TrackableModel.primaryKeyNames.has(modelName)) {
      throw Error(`${modelName} has no primary key`);
    }

    return TrackableModel.primaryKeyNames.get(modelName)!;
  }

  // Indicates if the specified collection contains models with pending changes.
  public static hasChanges(models: TrackableCollection): boolean {
    let result: boolean = false;

    if (
      models &&
      !(result = TrackableModel.getDeleted(models.dataId).size > 0)
    ) {
      result = models.some((model) => model && model.hasChanges());
    }

    return result;
  }

  public static register(trackable: ITrackable): ITrackable {
    if (
      !TrackableModel.models.has(trackable.dataId) ||
      TrackableModel.models.get(trackable.dataId) !== trackable
    ) {
      TrackableModel.models.set(trackable.dataId, trackable);
    }

    return trackable;
  }

  // Reset IsModified, and set the value of each trackable property to
  // it's initial value.
  public static revertValues(models: TrackableCollection): void {
    if (models) {
      models.forEach((model) => {
        if (model) {
          model.revertValues();
        }
      });
    }
  }

  public static setPrimaryKeyName(modelName: string, name: string | null) {
    TrackableModel.primaryKeyNames.set(modelName, name);
  }

  constructor(dataId: string) {
    this.modelName = this.constructor.name;
    this.dataId = dataId;
  }

  protected abstract getPropertyNames(): string[];

  protected abstract loadData(data: TrackableModel): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected abstract setPropertyValue(propName: string, value: any): void;

  // Reset all state flags, discard deleted models and set initial values to the
  // current value of each trackable property.
  public acceptChanges(): void {
    TrackableModel.clearDeleted(this.dataId);

    if (this.isModified) {
      for (const propertyName of this.getPropertyNames()) {
        if (propertyName in this.initialValues) {
          this.initialValues[propertyName] = this.getPropertyValue(
            propertyName
          );
        }
      }
    }

    this.isModified = false;
    this.isTrackableNew = false;
  }

  // Clear the contents of this model.
  public clear(revertValues: boolean = true): void {
    if (!this.loadOnce) {
      // If the model is registered then discard deletes.
      if (TrackableModel.models.has(this.dataId)) {
        TrackableModel.clearDeleted(this.dataId);
      }

      if (revertValues) {
        this.revertToDefaultValues();
      }
      this.initialValues = Object.create(null);
      this.isModified = false;
      this.isTrackableNew = false;
    }
  }

  // Track this model in the deleted collection for this model name.
  // optionally remove it from the specified list.
  public delete(models?: TrackableCollection): void {
    if (!this.isTrackableNew) {
      if (TrackableModel.deleted.has(this.dataId)) {
        if (!TrackableModel.deleted.get(this.dataId)!.has(this)) {
          TrackableModel.deleted.get(this.dataId)!.add(this);
        }
      } else {
        TrackableModel.deleted.set(
          this.dataId,
          new Set<TrackableModel>([this])
        );
      }
    }

    // Remove the model from the specified collection.
    if (models && models.indexOf(this) > -1) {
      models.splice(models.indexOf(this), 1);

      // Remove the model from the observable collection.
      if (
        models.observableCollection &&
        models.observableCollection.indexOf(this) > -1
      ) {
        models.observableCollection.splice(
          models.observableCollection.indexOf(this),
          1
        );
      }
    }
  }

  // Returns the default value for the specified property.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDefaultValue(propertyName: string): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = null;

    if (propertyName in this.defaultValues) {
      result = this.defaultValues[propertyName];
    }

    return result;
  }

  // Returns the initial value for the specified property.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getInitialValue(propertyName: string): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any;

    if (propertyName in this.initialValues) {
      result = this.initialValues[propertyName];
    } else {
      result = this.getPropertyValue(propertyName);
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getModifiedPropertyValues(): { [id: string]: any } {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const props: { [id: string]: any } = {};
    for (const propertyName of this.getPropertyNames()) {
      if (this.hasChanges(propertyName)) {
        props[propertyName] = this.getPropertyValue(propertyName);
      }
    }

    return props;
  }

  // Returns the primary key value for this model.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPrimaryKey(): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = null;
    const primaryKeyName = this.getPrimaryKeyName();

    if (primaryKeyName) {
      result = this[primaryKeyName];
    }

    return result;
  }

  // Returns the primary key name for this model.
  public getPrimaryKeyName() {
    let result: string | null = null;

    if (TrackableModel.primaryKeyNames.has(this.modelName)) {
      result = TrackableModel.primaryKeyNames.get(this.modelName)!;
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract getPropertyValue(propName: string): any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract getReadOnlyProperties(propName: string): any;

  // Indicates if this model has pending changes or
  // if the specified property has pending changes.
  public hasChanges(propertyName?: string): boolean {
    let result: boolean = false;

    if (propertyName) {
      // If there is no initial value then the property has never been
      // set before or it is not a tracked property.
      // Either way it would be considered unchanged.
      if (propertyName in this.initialValues) {
        result =
          this.getPropertyValue(propertyName) !==
          this.initialValues[propertyName];
      } else {
        const propertyNameUnderscore: string = `_${propertyName}`;

        if (propertyNameUnderscore in this.initialValues) {
          result =
            this.getPropertyValue(propertyNameUnderscore) !==
            this.initialValues[propertyNameUnderscore];
        }
      }
    } else {
      result =
        this.isTrackableNew ||
        this.isModified ||
        TrackableModel.getDeleted(this.dataId).has(this);
    }

    return result;
  }

  // Populates the model with the specified data.
  public load(data?: object): TrackableModel {
    if (!this.loadOnce || !this.isLoaded) {
      this.clear(false);

      if (data) {
        this.isLoaded = true;
        this.loadData(data as TrackableModel);

        for (const propertyName of this.getPropertyNames()) {
          this.initialValues[propertyName] = this.getPropertyValue(
            propertyName
          );
        }
      }
    }

    return this;
  }

  public revertToDefaultValues(): void {
    for (const property of Object.keys(this.defaultValues)) {
      this.setPropertyValue(property, this.defaultValues[property]);
    }
  }

  public revertValue(propertyName: string) {
    if (propertyName in this.initialValues) {
      this.setProperty(propertyName, this.initialValues[propertyName]);
    }
  }

  // Reset IsModified, and set the value of each trackable property to
  // it's initial value.
  public revertValues(): void {
    if (this.isModified) {
      for (const propertyName of this.getPropertyNames()) {
        if (propertyName in this.initialValues) {
          this.setPropertyValue(propertyName, this.initialValues[propertyName]);
        }
      }
    }

    this.isModified = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setDefaultValue(propertyName: string, value: any): void {
    this.defaultValues[propertyName] = value;
  }

  // Sets the name of the primary key for this model.
  public setPrimaryKeyName(name: string | null) {
    TrackableModel.primaryKeyNames.set(this.modelName, name);
  }

  // Sets the value of the specified property.
  // Tracks initial value and modified state.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setProperty(propertyName: string, value: any): boolean {
    let result: boolean = false;
    let isModified: boolean = false;
    let initialValue: boolean = false;

    if (!(propertyName in this.initialValues)) {
      this.setDefaultValue(propertyName, this.getPropertyValue(propertyName));
      this.initialValues[propertyName] = value;
      initialValue = true;
    }

    if (this.getPropertyValue(propertyName) !== value) {
      if (!initialValue) {
        if (this.initialValues[propertyName] === value && this.trackUndo) {
          // If this property has been reset to it's initial value,
          // check if any other property has been changed or is the
          // model now unmodified. If there is no initial value
          // then the property has never been
          // set before or it is not a tracked property.
          // Either way it would be considered unchanged.
          for (const propName of this.getPropertyNames()) {
            if (propName !== propertyName && propName in this.initialValues) {
              if (this.hasChanges(propName)) {
                isModified = true;
                break;
              }
            }
          }

          this.isModified = isModified;
        } else {
          this.isModified = true;
        }
      }

      this.setPropertyValue(propertyName, value);
      result = true;
    }

    return result;
  }
}
