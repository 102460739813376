import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow from "../../models/PaneRow";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

export class SLTextEditColumn {
  public static readonly widgetType: string = "SLTextEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidget(column.name);

    return widget.value ? (widget.value as string) : "";
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidget(props.column.name);

    if (!widget.value) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {widget.value as string}
      </Typography>
    );
  }
}
