import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer, useObserver } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../../config/Dialog";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import Sys from "../../core/Sys";
import MenuItem from "../../coreui/MenuItem";
import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
} from "../../services/ActionButtonService";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import Api, { AccessLevel } from "../Api";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ActionButtonProps {}

interface State {
  isConfirmDialogOpen?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class CustomActionButton extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private onClickPromise: RequestPromise<void>;

  private static getLabel = (
    runtimeProperties: ActionButtonRuntimeProps
  ): string => {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("Button.customActionLabel");

    return label;
  };

  private static onClick(config: {
    dataId: string;
    iconName?: string;
    name: string;
    propagated?: DialogChildProps & TableChildProps;
  }): RequestPromise<void> {
    let selectedRowKeys: string[] | null = null;
    if (config.propagated && config.propagated.parentTable) {
      selectedRowKeys = [];

      if (config.propagated.parentRowKey) {
        selectedRowKeys.push(config.propagated.parentRowKey);
      } else {
        const gridApi = config.propagated.parentTable.getApi();

        const rows = config.propagated.parentTable.selection.getSelectedRows();
        if (rows.length <= 0) {
          gridApi.forEachNode((r) => rows.push(r.data));
          for (let i = 0; i < gridApi.getPinnedTopRowCount(); i++) {
            rows.push(gridApi.getPinnedTopRow(i).data);
          }
        }

        for (const row of rows) {
          selectedRowKeys!.push(row.rowKey);
        }
      }
    }

    const paneRow: PaneRow = PaneRow.get(config.dataId)!;
    const runtimeProperties = Api.getWidgetProperties(
      config
    ) as ActionButtonRuntimeProps;
    const labelBeforeReload = CustomActionButton.getLabel(runtimeProperties);

    return ActionButtonService.onRoundTrip(
      paneRow,
      config.name,
      selectedRowKeys,
      config.propagated?.parentDialog?.rowKey
    )
      .then((response: OnRoundTripResponse) => {
        if (
          response.businessErrors.length === 0 &&
          response.validationErrors.length === 0
        ) {
          Sys.announce(
            Localization.getBuiltInMessage("Button.succeeded", {
              label: labelBeforeReload,
            })
          );
        }

        if (response.saved && !response.url) {
          ErrorsStore.clearBusinessErrors();
          RequestsStore.instance.setSaved(config.iconName);
        }

        if (runtimeProperties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState<
      boolean
    >(false);

    const onClick = () => {
      if (runtimeProps.accessLevel >= AccessLevel.actionable) {
        if (runtimeProps.confirmMessage) {
          setIsConfirmDialogOpen(true);
        } else {
          CustomActionButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }
      }
    };

    const onAcceptConfirm = () => {
      setIsConfirmDialogOpen(false);
      CustomActionButton.onClick(configProps);
      configProps.propagated.onItemClicked!();
    };

    const onCancelConfirm = () => {
      setIsConfirmDialogOpen(false);
    };

    return useObserver(() => (
      <React.Fragment>
        {runtimeProps.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={runtimeProps.cancelButtonText!}
            continueButtonAlternateText={
              runtimeProps.continueButtonText === null
                ? runtimeProps.alternateText
                : undefined
            }
            continueButtonColor={configProps.buttonColor}
            continueButtonIcon={configProps.iconName}
            continueButtonIsIconOnly={runtimeProps.continueButtonText === null}
            continueButtonText={
              runtimeProps.continueButtonText !== null
                ? runtimeProps.continueButtonText
                : Localization.getBuiltInMessage("Button.customActionLabel")
            }
            isOpen={isConfirmDialogOpen}
            message={runtimeProps.confirmMessage}
            onCancel={onCancelConfirm}
            onContinue={onAcceptConfirm}
            title={runtimeProps.confirmTitle!}
          />
        ) : null}
        <MenuItem
          disabled={
            runtimeProps.accessLevel === AccessLevel.disabled ||
            (props.runtime.validateCaptcha! && !CaptchaControlBase.isValid())
          }
          iconName={configProps.iconName}
          indent={props.config.propagated ? props.config.propagated.indent : 0}
          onClick={onClick}
          {...otherProps}
        >
          {runtimeProps.label}
        </MenuItem>
      </React.Fragment>
    ));
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { isConfirmDialogOpen: false };
  }

  private onAcceptConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
    CustomActionButton.onClick(this.props);
  };

  private onCancelConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private onClick = () => {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;

    if (runtimeProperties.confirmMessage) {
      this.setState({ isConfirmDialogOpen: true });
    } else {
      this.onClickPromise = CustomActionButton.onClick(this.props);
    }
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;
    const disabled: boolean =
      runtimeProperties.validateCaptcha && !CaptchaControlBase.isValid();

    return (
      <React.Fragment>
        {runtimeProperties.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={runtimeProperties.cancelButtonText!}
            continueButtonAlternateText={
              runtimeProperties.continueButtonText === null
                ? runtimeProperties.alternateText
                : undefined
            }
            continueButtonColor={this.props.buttonColor}
            continueButtonIcon={this.props.iconName}
            continueButtonIsIconOnly={
              runtimeProperties.continueButtonText === null
            }
            continueButtonText={
              runtimeProperties.continueButtonText !== null
                ? runtimeProperties.continueButtonText
                : Localization.getBuiltInMessage("Button.customActionLabel")
            }
            isOpen={this.state.isConfirmDialogOpen!}
            message={runtimeProperties.confirmMessage}
            onCancel={this.onCancelConfirm}
            onContinue={this.onAcceptConfirm}
            title={runtimeProperties.confirmTitle!}
          />
        ) : null}
        <ApiButton
          alternateText={runtimeProperties.alternateText}
          buttonColor={this.props.buttonColor}
          dataId={this.props.dataId}
          disabled={disabled}
          disabledHelpText={this.props.disabledHelpText}
          iconName={this.props.iconName}
          isIconOnly={!runtimeProperties.label}
          label={CustomActionButton.getLabel(runtimeProperties)}
          name={this.props.name}
          onClick={this.onClick}
          size={this.props.size}
          tabIndex={
            this.props.propagated && this.props.propagated.parentTable ? -1 : 0
          }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CustomActionButton);
