import * as muiFormControlLabel from "@material-ui/core/FormControlLabel";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";

interface Props extends muiFormControlLabel.FormControlLabelProps {
  refSetter?: (element: HTMLDivElement) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export class FormControlLabel extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const { refSetter, ..._props } = this.props;

    return <muiFormControlLabel.default ref={refSetter} {..._props} />;
  }
}

export default withStyles(styles)(FormControlLabel);
