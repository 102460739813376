import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import * as React from "react";
import Api from "../mustangui/Api";
import Button from "./Button";
import ErrorTooltip from "./ErrorTooltip";
import { TableChildProps } from "./Table";

// This props does not extend badge properties because badgeContent is
// required and we don't want to have to pass a value for this type of badge.
interface Props {
  message?: React.ReactNode;
  propagated: TableChildProps;
  rowKey: string;
}

interface State {
  open: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    ripple: {
      "& > span > span": {
        backgroundColor: Api.getSystemColor("danger"),
      },
    },
    root: {
      "&:hover": {
        backgroundColor: darken(Api.getSystemColor("danger"), 0.1),
      },
      backgroundColor: Api.getSystemColor("danger"),
      color: theme.palette.getContrastText(Api.getSystemColor("danger")),
      marginBottom: 8,
      marginRight: 8,
      pointerEvents: "auto",
    },
  });

export class RowErrorBadge extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buttonRef: any;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { open: false };

    props.propagated.parentTable
      .getTable()
      .rowErrorBadges.set(props.rowKey, this);
  }

  public close = () => {
    this.setState({ open: false });
  };

  public open = () => {
    this.setState({ open: true });
  };

  public render() {
    const _props = { ...this.props };
    const message = _props.message;
    let result: React.ReactNode = null;

    if (message) {
      result = (
        <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
          <ErrorTooltip
            // DisableHoverListener
            enterTouchDelay={0}
            open={this.state.open}
            title={message}
          >
            <Button
              aria-hidden={true}
              badge
              buttonRef={(r) => (this.buttonRef = r)}
              classes={{ root: this.props.classes.root }}
              icon="fas fa-exclamation"
              onClick={() => {
                // Fix for Safari not opening tooltip
                this.buttonRef.focus();
                this.setState({ open: true });
              }}
              tabIndex={-1}
              TouchRippleProps={{
                className: this.props.classes.ripple,
              }}
            />
          </ErrorTooltip>
        </ClickAwayListener>
      );
    }

    return result;
  }
}

export default withStyles(styles)(RowErrorBadge);
