import { observer } from "mobx-react";
import * as React from "react";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel from "../core/TrackableModel";
import Presentation from "../coreui/Presentation";

export interface LayoutConfig {
  dataIds: string[];
  layoutId: number;
  paneUse: object;
}

export interface LayoutChildProps {
  isInPageFooter?: boolean;
}

export interface Props {
  config: LayoutConfig;
  preventClear?: boolean;
  propagated?: LayoutChildProps;
}

@observer
export class Layout extends React.Component<Props> {
  public static initialize(config: LayoutConfig) {
    for (const dataId of config.dataIds) {
      if (!TrackableModel.models.has(dataId)) {
        new TrackableCollection("PaneRow", dataId);
      }
    }
  }

  public componentWillUnmount(): void {
    // FUTURE
    // This flag was added for the case when the same header or footer
    // layout is used between two different templates, such as the
    // BasePageTemplate and the LandingPageTemplate. If the clear occurs
    // when navigating between two templates which share a header or footer,
    // the new data ends up being removed because the clear will happen
    // after the new data is loaded. Simply preventing the clear from
    // occuring is acceptable because the point of clearing the data is to
    // reduce the memory footprint of the application, and the data involved
    // in a toolbar will be sufficiently small.
    if (this.props.preventClear) {
      return;
    }

    // Wait until presentation is fully unmounted before clearing data.
    // This prevents data changed handlers from firing during unmounting
    setTimeout(() => {
      for (const dataId of this.props.config.dataIds) {
        if (TrackableModel.models.has(dataId)) {
          TrackableModel.models.get(dataId)!.clear();
        }
      }
    });
  }

  public render(): React.ReactNode {
    return Presentation.create(
      this.props.config.paneUse,
      this.props.propagated
    );
  }
}
