import { useObserver } from "mobx-react-lite";
import * as React from "react";
import { PaneUse, PaneUseConfig } from "../config/PaneUse";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  description: string;
  projectGridPaneUses: PaneUseConfig[];
  name: string;
}

export interface ProjectGridControlChildProps {
  parentProjectGridControl: {
    description: string;
  };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  projectGridPaneUseKey: string;
}

export default function ProjectGridControl(
  props: ConfigProperties
): JSX.Element | null {
  return useObserver(() => {
    const runtimeProperties: RuntimeProperties = Api.getWidgetProperties(
      props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const paneUseKey = runtimeProperties.projectGridPaneUseKey;
    const projectGridPaneUse = props.projectGridPaneUses.find(
      (p) => p.paneUseKey === paneUseKey
    );

    if (!projectGridPaneUse) {
      return null;
    }

    const childPropagated: ProjectGridControlChildProps = {
      parentProjectGridControl: { description: props.description },
    };

    return <PaneUse config={projectGridPaneUse} propagated={childPropagated} />;
  });
}
