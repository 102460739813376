import AppServer, { State as AppServerState } from "../core/AppServer";
import Logging from "../core/Logging";
import RequestPromise from "../core/RequestPromise";
import Sys from "../core/Sys";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel from "../core/TrackableModel";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { CaptchaControl as CaptchaControlBase } from "../mustangui/CaptchaControl";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "../mustangui/EmbeddedAddOn";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import SessionMessagesStore, {
  SessionMessage,
} from "../stores/SessionMessagesStore";
import BaseService, { Response } from "./BaseService";
import NewObjectService from "./NewObjectService";

export interface OnRoundTripResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  layoutId: string | null;
  newObjectId: string | null;
  newSessionMessages: SessionMessage[];
  paneDataByDataId: PaneDataByDataId;
  saved: boolean;
  url: string | null;
  validationErrors: string[];
}

export interface OnNewRowResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  newRowPaneDataByDataId: PaneDataByDataId;
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export interface RoundTripProps {
  dataId: string;
  name: string;
}

export default class ActionButtonService {
  public static onNewRow(
    rowKey: string,
    dataId: string,
    widgetName: string,
    propagated: TableChildProps
  ): RequestPromise<OnNewRowResponse> {
    const restoreFocusElement: HTMLElement | null =
      document.activeElement instanceof HTMLElement
        ? document.activeElement
        : null;

    RequestsStore.instance.processingStarted();

    if (propagated.parentTable.hasRelatedEditDialog) {
      EmbeddedAddOnBase.roundTripStarting();
      AppServer.createStateRecoveryPoint();
    }

    return BaseService.requestObject<OnNewRowResponse>(
      `ActionButton/OnNewRow/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    )
      .then((response: OnNewRowResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.showErrors(response.validationErrors);
          if (propagated.parentTable.hasRelatedEditDialog) {
            AppServer.clearStateRecoveryPoint();
            EmbeddedAddOnBase.rejectRoundTrip();
          }

          return response;
        }

        AppServer.setState(response.appServerState);

        if (response.businessErrors.length > 0) {
          AppServer.setState(response.appServerState);
          ErrorsStore.clearBusinessErrors();
          PaneDataStore.loadResponse(response.paneDataByDataId);
          ErrorsStore.setBusinessErrors(response.businessErrors, false);
          if (propagated.parentTable.hasRelatedEditDialog) {
            AppServer.clearStateRecoveryPoint();
            EmbeddedAddOnBase.rejectRoundTrip();
          }

          return response;
        }

        const newRowData = response.newRowPaneDataByDataId;
        const newRowDataId = Object.keys(newRowData)[0];
        const row = newRowData[newRowDataId].rows[0];

        PaneDataStore.clearDeletedRows();
        const collection = TrackableModel.models.get(
          newRowDataId
        ) as TrackableCollection;
        collection.insert(row, false);
        propagated.parentTable.populateData();

        if (propagated.parentTable.hasRelatedEditDialog) {
          propagated.parentTable.openRowEditDialog(
            row["rowKey"],
            true,
            restoreFocusElement
          );
        } else {
          propagated.parentTable.scrollToRow(row["rowKey"]);
        }

        return response;
      })
      .finally(() => RequestsStore.instance.processingStopped());
  }

  public static onRoundTrip(
    row: PaneRow,
    widgetName: string,
    selectedRowKeys: string[] | null = null,
    dialogRowKey: string | null = null
  ): RequestPromise<OnRoundTripResponse> {
    RequestsStore.instance.processingStarted();
    EmbeddedAddOnBase.roundTripStarting();
    ErrorsStore.clearErrors();

    return BaseService.requestObject<OnRoundTripResponse>(
      `ActionButton/OnRoundTrip/${row.rowKey}/${row.dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        captchaResponseToken: CaptchaControlBase.responseToken,
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
        layoutState: LayoutStateStore.getCurrentState(),
        selectedRowKeys,
      },
      "POST"
    )
      .then((response: OnRoundTripResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.clearErrors();
          ErrorsStore.showErrors(response.validationErrors);
          EmbeddedAddOnBase.rejectRoundTrip();

          return Promise.reject();
        }

        AppServer.setState(response.appServerState);

        if (response.businessErrors.length > 0) {
          ErrorsStore.clearBusinessErrors();
        }

        PaneDataStore.loadResponse(response.paneDataByDataId);

        if (response.newSessionMessages.length > 0) {
          SessionMessagesStore.addMessages(response.newSessionMessages);
        }

        if (ErrorsStore.setBusinessErrors(response.businessErrors, false)) {
          EmbeddedAddOnBase.rejectRoundTrip();

          return response;
        }

        if (response.url) {
          Sys.ignoreChanges = true;

          if (window.location.hash === response.url) {
            window.location.reload();
          } else if (!response.url.startsWith("#")) {
            window.location.href = response.url;
          } else if (row.isNew) {
            Sys.setHash(response.url.substr(1));
          } else {
            window.location.assign(response.url);
          }
        }

        if (response.newObjectId) {
          NewObjectService.navigateToNewObject(
            response.newObjectId,
            response.layoutId!
          );
        }

        EmbeddedAddOnBase.resolveRoundTrip();

        return response;
      })
      .finally(() => {
        RequestsStore.instance.processingStopped();
      });
  }
}
