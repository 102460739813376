import AppServer, { State as AppServerState } from "../core/AppServer";
import Sys from "../core/Sys";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "../mustangui/EmbeddedAddOn";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import BaseService from "./BaseService";

interface AddressCandidate {
  attributes: object;
}

export interface AddressCandidatesResponse {
  candidates: AddressCandidate[];
  error: { code: number };
}

interface GetTokenResponse {
  accessToken: string;
}

export interface OnSelectResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export default class AddressSearchService {
  public static async findAddressCandidates(
    url: string,
    category: string,
    location: number[],
    magicKey: string,
    searchExtent: number[],
    singleLine: string,
    sourceCountry: string,
    token: string | null
  ): Promise<AddressCandidatesResponse> {
    const queryArgs = {
      category,
      f: "json",
      forStorage: true,
      location: location.join(","),
      magicKey,
      maxLocations: 1,
      outFields: "*",
      searchExtent: searchExtent.join(","),
      SingleLine: singleLine,
      sourceCountry,
      token,
    };

    return BaseService.requestObject<AddressCandidatesResponse>(
      `${url}?${Sys.objectToQueryString(queryArgs)}`,
      null,
      null,
      null,
      "POST"
    );
  }

  public static async getToken(
    rowKey: string,
    dataId: string,
    widgetName: string
  ): Promise<string> {
    return BaseService.requestObject<GetTokenResponse>(
      `AddressSearch/GetToken/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    ).then((response: GetTokenResponse) => {
      return response.accessToken;
    });
  }

  public static onSelect(
    rowKey: string,
    dataId: string,
    widgetName: string,
    selectedAddress: AddressCandidate
  ): Promise<void> {
    RequestsStore.instance.processingStarted();
    EmbeddedAddOnBase.roundTripStarting();
    ErrorsStore.clearErrors();

    return BaseService.requestObject<OnSelectResponse>(
      `AddressSearch/OnSelect/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
        selectedAddress: JSON.stringify(selectedAddress.attributes),
      },
      "POST"
    )
      .then((response: OnSelectResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.showErrors(response.validationErrors);
          EmbeddedAddOnBase.rejectRoundTrip();

          return;
        }

        AppServer.setState(response.appServerState);
        PaneDataStore.loadResponse(response.paneDataByDataId);

        if (response.businessErrors.length > 0) {
          ErrorsStore.setBusinessErrors(response.businessErrors, true);
          EmbeddedAddOnBase.rejectRoundTrip();

          return;
        }

        EmbeddedAddOnBase.resolveRoundTrip();
      })
      .finally(() => {
        RequestsStore.instance.processingStopped();
      });
  }
}
