import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import TrackableModel from "../core/TrackableModel";
import FocusTracker from "../coreui/FocusTracker";
import Presentation from "../coreui/Presentation";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import PaneDataStore from "../stores/PaneDataStore";
import Api, { AccessLevel, ValueByBreakpoint } from "./Api";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import ProjectCurrentJobColumn, {
  ProjectCurrentJobHeader,
} from "./Columns/ProjectCurrentJobColumn";
import ErrorBoundary from "./ErrorBoundary";
import { ProjectGridControlChildProps } from "./ProjectGridControl";
import ProjectGridVerticalHierarchy from "./ProjectGridVerticalHierarchy";

interface ConfigProperties extends WithWidth {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  footerToolbar?: object;
  headerToolbar?: object;
  hierarchyDepth: ValueByBreakpoint<"Full" | "Partial">;
  isVerticalLayout: boolean;
  name: string;
  propagated: ProjectGridControlChildProps;
  tableKey: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}

@observer
export class ProjectGrid extends React.Component<ConfigProperties & WithWidth> {
  private static currentJobColumnId: string = "_projectCurrentJobColumn";

  private asyncDataRef: React.RefObject<AsyncData>;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private populate: ((rows: TrackableModel[]) => void) | null = null;
  private propagated: TableChildProps;
  private tableProps: TableProps;

  public static getPartialHierarchyRows(rows: PaneRow[]): PaneRow[] {
    const currentRowIndex: number = rows.findIndex((row) => row.isCurrentJob!);

    if (currentRowIndex === -1) {
      // Occurs if the grid is not yet loaded
      return [];
    }

    let parentRowIndex: number = currentRowIndex;
    for (let i = currentRowIndex; i >= 0; i--) {
      if (rows[i].hierarchyLevel! < rows[currentRowIndex].hierarchyLevel!) {
        parentRowIndex = i;
        break;
      }
    }

    const currentRowLevel: number = rows[currentRowIndex].hierarchyLevel!;
    let state: string = "AddParent";
    const partialHierarchyRows: PaneRow[] = [];

    for (let i = parentRowIndex; i < rows.length; i++) {
      const row: PaneRow = rows[i];

      if (state === "AddParent") {
        if (row.hierarchyLevel! < currentRowLevel) {
          partialHierarchyRows.push(row);

          continue;
        }

        state = "AddSiblings";
      }

      if (state === "AddChildren") {
        if (row.hierarchyLevel! === currentRowLevel + 1) {
          partialHierarchyRows.push(row);

          continue;
        }

        if (row.hierarchyLevel! > currentRowLevel + 1) {
          continue;
        }

        state = "AddSiblings";
      }

      if (state === "AddSiblings") {
        if (row.hierarchyLevel! < currentRowLevel) {
          break;
        }

        if (row.hierarchyLevel! === currentRowLevel) {
          partialHierarchyRows.push(row);
        }

        if (row.isCurrentJob!) {
          state = "AddChildren";
        }
      }
    }

    return partialHierarchyRows;
  }

  public constructor(props: ConfigProperties & WithWidth) {
    super(props);

    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    this.propagated = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
          tableKey: props.tableKey,
        },
        hasRelatedEditDialog: false,
        isDocumentGrid: false,
        isProjectGrid: true,
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.tableProps = {
      cardDepth: props.cardDepth,
      cellEdit: false,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      isColumnFlex: this.isColumnFlex,
      isColumnVisible: this.isColumnVisible,
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      resetPageOnPopulate: true,
      setPopulate: (populate) => (this.populate = populate),
      showNoData: false,
    };
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.propagated.parentProjectGridControl
            .description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.propagated.parentProjectGridControl
          .description,
      }),
      true
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private getData = () => {
    const url =
      "ProjectGrid/GetRowsData" +
      `/${this.getRowKey()}` +
      `/${this.props.dataId}` +
      `/${this.props.name}`;

    return RoundTripService.partialDataRetrevial<GetDataResponse>(url);
  };

  private getRowKey(): string {
    const row = Presentation.getObservable(this.props)! as PaneRow;
    return row.rowKey;
  }

  private isColumnFlex = (colId: string) => {
    if (colId === ProjectGrid.currentJobColumnId) {
      return true;
    }

    return GridColumn.isColumnFlex(this.props.columns, colId);
  };

  private isColumnVisible = (colId: string, breakpoint: string) => {
    if (colId === ProjectGrid.currentJobColumnId) {
      return true;
    }

    return GridColumn.isColumnVisible(this.props.columns, colId, breakpoint);
  };

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState = this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private populateData = () => {
    let rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.contentDataId
    );

    if (this.props.hierarchyDepth[this.props.width] === "Partial") {
      rows = ProjectGrid.getPartialHierarchyRows(rows);
    }

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  public componentDidMount(): void {
    this.tableProps.columns.push({
      autoHeight: false,
      cellClass: "cx-cell",
      cellRendererFramework: ProjectCurrentJobColumn,
      cellStyle: {
        overflow: "visible",
        padding: "0px",
      },
      colId: ProjectGrid.currentJobColumnId,
      editable: false,
      headerComponentFramework: ProjectCurrentJobHeader,
      lockPinned: true,
      minWidth: 64,
      resizable: false,
      sortable: false,
      suppressSizeToFit: true,
      width: 64,
    });

    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }
  }

  public componentDidUpdate(prevProps: ConfigProperties): void {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props,
      this.props.data
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <AsyncData
            contentDataId={this.props.contentDataId}
            dataId={this.props.dataId}
            getData={this.getData}
            onIsLoadingChanged={this.onIsLoadingChanged}
            populateData={this.populateData}
            ref={this.asyncDataRef}
          />
          {this.props.isVerticalLayout ? (
            <ProjectGridVerticalHierarchy.render
              cardDepth={this.props.cardDepth}
              dataId={this.props.contentDataId}
              headerToolbarChild={this.props.headerToolbar}
              propagated={this.propagated}
            />
          ) : (
            <Table
              {...this.tableProps}
              aria-label={
                this.props.propagated.parentProjectGridControl.description
              }
              tableKey={this.props.tableKey}
            />
          )}
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

export default withWidth()(ProjectGrid);
