import { autorun, IReactionDisposer, observable } from "mobx";
import * as React from "react";
import Localization from "../core/Localization";
import RequestPromise from "../core/RequestPromise";
import Sys from "../core/Sys";
import NewObjectService from "../services/NewObjectService";
import RoundTripService, {
  RoundTripResponse,
} from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, {
  PaneData,
  PaneDataByDataId,
} from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import Api, { AccessLevel } from "./Api";
import ErrorBoundary from "./ErrorBoundary";
import WizardStepPane, { StepProps } from "./WizardStepPane";

interface GetDataResponse {
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

interface OnNavigateResponse extends RoundTripResponse {
  layoutId?: string;
  newObjectId?: string;
}

interface Props {
  dataId: string;
  name: string;
  steps: StepProps[];
}

interface State {
  selectedPaneUseKey?: string;
}

export interface StepWidgetProperties {
  accessLevel: AccessLevel;
  paneUseKey: string;
  rowKey: string;
  stepComplete: boolean;
}

export interface WidgetProperties {
  accessLevel: AccessLevel;
  selectedPaneUseKey: string | null;
  steps: StepWidgetProperties[];
}

export class WizardControl extends React.PureComponent<Props, State> {
  @observable
  private static widgetProperties: WidgetProperties | null = null;
  public static instance: WizardControl | null = null;
  private dataMonitorDisposer: IReactionDisposer;
  private lastRetrievedPaneDate: Date | undefined;
  private retrieveDataPromises: RequestPromise<GetDataResponse>[] = [];

  private static finishNavigate(
    response: RoundTripResponse,
    scrollToPageTop: boolean
  ) {
    PaneDataStore.clearAllDataChanges();

    if (WizardControl.isOnLastStep()) {
      if (response.newObjectId) {
        NewObjectService.navigateToNewObject(
          response.newObjectId,
          response.layoutId!
        );
      }

      ErrorsStore.clearBusinessErrors();
      RequestsStore.instance.setSaved("fas fa-save");
    }

    if (scrollToPageTop) {
      window.scrollTo(0, 0);
    }
  }

  public static announceNavigation(previousStepIndex: number) {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const stepCount = instance.props.steps.length;
    const complete =
      WizardControl.widgetProperties?.steps[previousStepIndex].stepComplete;

    const announcement: string = Localization.getBuiltInMessage(
      complete ? "Wizard.stepComplete" : "Wizard.stepIncomplete",
      {
        count: stepCount,
        step: previousStepIndex + 1,
      }
    );

    Sys.announce(announcement);
  }

  public static getStepCount(): number {
    let result: number = 0;

    if (WizardControl.widgetProperties) {
      result = WizardControl.widgetProperties.steps.length;

      for (const step of WizardControl.widgetProperties.steps) {
        if (step.accessLevel === AccessLevel.hidden) {
          result--;
        }
      }
    }

    return result;
  }

  public static getStepNumber(step: number): number {
    let result: number = step + 1;

    if (WizardControl.widgetProperties) {
      for (let index = 0; index <= step; index++) {
        if (
          WizardControl.widgetProperties.steps[index].accessLevel ===
          AccessLevel.hidden
        ) {
          result--;
        }
      }
    }

    return result;
  }

  public static getSteps(): StepProps[] | null {
    const instance = WizardControl.instance;
    let result: StepProps[] | null = null;

    if (instance) {
      result = instance.props.steps;
    }

    return result;
  }

  public static getWidgetProperties(): WidgetProperties | null {
    let result: WidgetProperties | null = null;

    if (WizardControl.widgetProperties) {
      result = WizardControl.widgetProperties;
    } else {
      result = {
        accessLevel: AccessLevel.enterable,
        selectedPaneUseKey: null,
        steps: [],
      };
    }

    return result;
  }

  public static async gotoNextStep(): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.props.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );
    RequestsStore.instance.processingStarted(null, false, true);

    try {
      const response: RoundTripResponse = await RoundTripService.standardRoundTrip(
        "WizardControl/OnNavigate",
        instance.props,
        { navigationDirection: "next" },
        true
      );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, true);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    } finally {
      RequestsStore.instance.processingStopped();
    }
  }

  public static async gotoPreviousStep(): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.props.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );
    RequestsStore.instance.processingStarted(null, false, true);

    try {
      const response: RoundTripResponse = await RoundTripService.standardRoundTrip(
        "WizardControl/OnNavigate",
        instance.props,
        { navigationDirection: "previous" },
        true
      );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, true);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    } finally {
      RequestsStore.instance.processingStopped();
    }
  }

  public static async gotoStep(navigationPaneUseKey: string): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.props.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );

    try {
      const response: RoundTripResponse = await RoundTripService.standardRoundTrip(
        "WizardControl/OnNavigate",
        instance.props,
        { navigationPaneUseKey }
      );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, false);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    }
  }

  public static isOnLastStep(): boolean {
    const instance = WizardControl.instance;

    if (instance) {
      const index = instance.props.steps.findIndex(
        (s) => s.paneUseKey === instance.state.selectedPaneUseKey
      );

      return index === instance.props.steps.length - 1;
    }

    return false;
  }

  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedPaneUseKey: undefined,
    };

    WizardControl.instance = this;
  }

  private dataMonitor = () => {
    const parentPane: PaneData = PaneDataStore.instance.getPane(
      this.props.dataId
    )!;

    if (!parentPane.lastRetrieved) {
      return;
    }

    if (
      this.lastRetrievedPaneDate &&
      this.lastRetrievedPaneDate >= parentPane.lastRetrieved
    ) {
      return;
    }

    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    WizardControl.widgetProperties = Api.getWidgetProperties(
      this.props
    ) as WidgetProperties;

    const paneUseKey: string | null =
      WizardControl.widgetProperties.selectedPaneUseKey;

    LayoutStateStore.setSelectedStep(paneUseKey);

    if (paneUseKey) {
      const activeStep:
        | StepWidgetProperties
        | undefined = WizardControl.widgetProperties.steps.find(
        (t) => t.paneUseKey === paneUseKey
      );

      if (!activeStep) {
        throw new Error(`No step with pane use key of ${paneUseKey} found`);
      }

      this.setState({
        selectedPaneUseKey: activeStep.paneUseKey,
      });
    } else {
      this.setState({
        selectedPaneUseKey: undefined,
      });
    }

    this.lastRetrievedPaneDate = parentPane.lastRetrieved;
  };

  public componentDidMount() {
    this.dataMonitorDisposer = autorun(this.dataMonitor);
  }

  public componentWillUnmount() {
    WizardControl.instance = null;

    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    this.dataMonitorDisposer();

    LayoutStateStore.setSelectedStep(null);
  }

  public render(): React.ReactNode {
    if (!this.state.selectedPaneUseKey || this.props.steps.length <= 0) {
      return null;
    }

    if (!WizardControl.widgetProperties) {
      return null;
    }

    if (WizardControl.widgetProperties.accessLevel <= AccessLevel.disabled) {
      return null;
    }

    const selectedStep: StepProps = this.props.steps.find(
      (s) => s.paneUseKey === this.state.selectedPaneUseKey
    )!;

    return (
      <ErrorBoundary title={this.props.name}>
        <WizardStepPane {...selectedStep} />
      </ErrorBoundary>
    );
  }
}

export default WizardControl;
