import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import { GridColumnConfigProperties } from "./GridColumn";

export class MLTextEditColumn {
  public static readonly widgetType: string = "MLTextEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidget(column.name);

    return widget.value ? (widget.value as string) : "";
  }
}
