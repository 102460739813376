import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { autorun, Lambda } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import Sys from "../core/Sys";
import ComboBoxOption from "../coreui/ComboBoxOption";
import getFieldHelperText from "../coreui/FieldHelperText";
import FormControl, { FormControlProps } from "../coreui/FormControl";
import FormLabel from "../coreui/FormLabel";
import Presentation from "../coreui/Presentation";
import RadioGroup from "../coreui/RadioGroup";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import Api, { AccessLevel } from "./Api";
import ApiRadio from "./ApiRadio";

interface Props {
  controlKey: string | null;
  controlledPaneKeysByValue: object | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  options: ComboBoxOption[];
  selectedDisplayValue: string;
  selectedValue: string | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "&:hover": { backgroundColor: "transparent" },
      backgroundColor: "transparent",
    },
  });

@observer
export class RadioSelect extends React.Component<
  Props & WithTheme & WithStyles<typeof styles> & WithWidth
> {
  private readonly componentId: string;
  private readonly componentName: string;
  private readonly helperTextId: string;
  private readonly labelId: string;

  protected disposeObserve: Lambda;
  protected muiProps: FormControlProps;

  public constructor(
    props: Props & WithTheme & WithStyles<typeof styles> & WithWidth
  ) {
    super(props);

    this.componentId = `radio-select-${Sys.nextId}`;
    this.componentName = `${props.name}-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-label`;

    this.muiProps = {};
    this.muiProps.fullWidth = true;
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  protected showSubPane(): void {
    if (!this.props.isPaneController) {
      return;
    }

    const controlKey: string = `${this.props.controlKey}_`;

    const value = Presentation.getValue(this.props, null);
    const controlledPaneName = this.props.controlledPaneKeysByValue![value];

    if (controlledPaneName) {
      SubPaneControlStore.showPane(controlKey, controlledPaneName);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.showSubPane();
    });
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
  }

  public render() {
    const _props = { ...this.props };
    const widgetProperties: WidgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    this.muiProps.className = `${_props.classes.root} `;

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widgetProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widgetProperties.accessLevel === AccessLevel.readOnly) {
      const value = Presentation.getValue(_props, null);
      const item = widgetProperties.options.find(
        (_item) => _item.value === value
      );
      return (
        <TextField
          label={_props.label}
          name={this.componentName}
          readOnly={true}
          value={item ? item.display : "-"}
          variant="filled"
        />
      );
    }

    this.muiProps.required = widgetProperties.showAsMandatory;
    const fieldHelperText = getFieldHelperText({
      getErrors: () => widgetProperties.businessErrors,
      helperText: this.props.helperText,
    });

    const options = [...widgetProperties.options];
    if (widgetProperties.selectedValue) {
      // Add the initially selected value as a historic option if is
      // not among the current candidates
      const optionInList = options.find(
        (o) => o.value === widgetProperties.selectedValue
      );

      if (optionInList === undefined) {
        const historicOption: ComboBoxOption = {
          display: widgetProperties.selectedDisplayValue,
          historic: true,
          value: widgetProperties.selectedValue,
        };

        options.push(historicOption);
      }
    }

    return (
      <FormControl
        {...this.muiProps}
        error={fieldHelperText.hasErrors}
        FormHelperTextProps={{
          "aria-hidden": false,
          style: { marginTop: 0 },
        }}
        helperText={fieldHelperText.helperText}
        helperTextId={this.helperTextId}
        onBlur={(event: React.FocusEvent<HTMLElement>) => {
          if (
            event.relatedTarget instanceof Element &&
            event.currentTarget.contains(event.relatedTarget)
          ) {
            // Focus is moving within the group
            return;
          }

          this.announceErrors(fieldHelperText.errors);
        }}
      >
        <FormLabel
          id={this.labelId}
          style={{ color: _props.theme.palette.grey[800] }}
        >
          {_props.label}
        </FormLabel>
        <RadioGroup
          aria-describedby={
            fieldHelperText.helperText ? this.helperTextId : undefined
          }
          aria-labelledby={this.labelId}
          aria-required={widgetProperties.showAsMandatory}
          id={this.componentId}
          name={this.componentName}
          row={_props.width !== "xs"}
        >
          {options.map((o, index) => (
            <ApiRadio
              {..._props}
              key={o.value ? o.value : ""}
              label={o.display}
              margin={index < options.length - 1}
              value={o.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withStyles(styles)(withTheme(withWidth()(RadioSelect)));
