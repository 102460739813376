import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer, useObserver } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
} from "../../services/ActionButtonService";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import Api, { AccessLevel } from "../Api";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";

interface Props extends ActionButtonProps {}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class SavePresentationButton extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private onClickPromise: RequestPromise<void>;

  private static onClick(props: {
    dataId: string;
    iconName?: string;
    name: string;
  }): RequestPromise<void> {
    const row: PaneRow = PaneRow.get(props.dataId)!;

    return ActionButtonService.onRoundTrip(row, props.name)
      .then((response: OnRoundTripResponse) => {
        const runtimeProperties = Api.getWidgetProperties(
          props
        ) as ActionButtonRuntimeProps;

        if (response.saved && !response.url) {
          ErrorsStore.clearBusinessErrors();
          RequestsStore.instance.setSaved(props.iconName);
        }

        if (runtimeProperties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;
    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        SavePresentationButton.onClick(configProps);
        configProps.propagated.onItemClicked!();
      }
    };

    return useObserver(() => (
      <MenuItem
        disabled={
          props.runtime.accessLevel === AccessLevel.disabled ||
          (props.runtime.validateCaptcha! && !CaptchaControlBase.isValid())
        }
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    ));
  }

  private onClick = () => {
    this.onClickPromise = SavePresentationButton.onClick(this.props);
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const _props = { ...this.props };
    const runtimeProperties = Api.getWidgetProperties(
      _props
    ) as ActionButtonRuntimeProps;
    const disabled: boolean =
      runtimeProperties.validateCaptcha && !CaptchaControlBase.isValid();

    const isIconOnly = !runtimeProperties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("Button.saveLabel")
      : runtimeProperties.label;

    return (
      <ApiButton
        alternateText={runtimeProperties.alternateText}
        buttonColor={this.props.buttonColor}
        dataId={this.props.dataId}
        disabled={disabled}
        disabledHelpText={this.props.disabledHelpText}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        name={this.props.name}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default withStyles(styles)(SavePresentationButton);
