import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config/SearchPresentation";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Presentation from "../coreui/Presentation";
import Select from "../coreui/Select";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  noSelectionDisplay: string;
  propagated: SearchChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

@observer
export class BooleanCriteria extends React.Component<ConfigProperties> {
  private getErrors = (value: string): string[] => {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return [];
    }

    return runtimeProperties.businessErrors;
  };

  private onValueChange = (value: ComboBoxOption) => {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value.value);
  };

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    const value = Presentation.getValue(this.props) as string;
    const options: ComboBoxOption[] = [
      {
        display: this.props.noSelectionDisplay,
        value: null,
      },
      {
        display: Localization.getBuiltInMessage("booleanYes"),
        value: "y",
      },
      {
        display: Localization.getBuiltInMessage("booleanNo"),
        value: "n",
      },
    ];

    return (
      <Select
        getErrors={() => runtimeProperties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={options}
        showAsMandatory={this.props.mandatory}
        value={value}
      />
    );
  }
}

export default BooleanCriteria;
