// VERSION_WARNING Material-UI 4.9.14
// This component implements the internal structure of the MUI TextField /
// Select components.  We are customizing this to be able to implement the
// ARIA 1.2 read-only combo box pattern, vs. the ARIA 1.1 popup button with
// menulist pattern that MUI supports.  This allows us to support features like
// aria-required, aria-invalid, separate label and value, the combobox role
// (vs. the button role), and the ability to use Popper for non-modal selects.
//
// In the future when MUI supports the ARIA 1.2 pattern, WebUI can migrate to
// using the built-in Select component vs. this customized version.
//
// See original source at:
// - https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Select/Select.js
// - https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/TextField/TextField.js
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../core/Sys";
import ComboBoxOption from "./ComboBoxOption";
import getFieldHelperText from "./FieldHelperText";
import FormControl from "./FormControl";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";
import SelectInput from "./SelectInput";
import TextField from "./TextField";

interface Props {
  "aria-label"?: string;
  className?: string;
  disableUnderline?: boolean;
  error?: boolean;
  getErrors?: (value: string) => string[];
  helperText?: React.ReactNode;
  iconClassName?: string;
  inputBaseStyle?: React.CSSProperties;
  label?: string;
  onClose?: () => void;
  onFocus?: () => void;
  onValueChange?: (value: ComboBoxOption) => void;
  openOnMount?: boolean;
  options: ComboBoxOption[];
  readOnly?: boolean;
  selectDisplayStyle?: React.CSSProperties;
  showAsMandatory?: boolean;
  value?: string | null;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      cursor: "pointer",
      fontSize: 16,
      height: "auto",
      pointerEvents: "none",
      position: "absolute",
      right: 12,
      top: "50%",
      transform: "translateY(-50%)",
    },
    inputLabelRoot: {
      width: "calc(100% - 33px)",
    },
    inputLabelShrink: {
      width: "calc((100% - 33px) * 1.333)",
    },
    label: {
      marginBottom: 8,
    },
    root: {},
  });

@observer
export class Select extends React.Component<Props & WithStyles<typeof styles>> {
  private readonly componentId: string;
  private readonly helperTextId: string;
  private readonly labelId: string;
  private readonly selectInputRef = React.createRef<HTMLElement>();
  private readonly selectRef = React.createRef<HTMLInputElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `select-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-label`;
  }

  public focus(): void {
    // Use a setTimeout to ensure the SelectInput component has enough time to
    // fully wire up its inputRef.
    // This is relevant if focus() is called as soon as the Select component is
    // mounted; in this case the SelectInput component still needs to run its
    // own effects on mount, which includes wiring up the inputRef. The
    // setTimeout() ensures the call to focus() the inputRef waits until the
    // next event cycle because by then the initial setup in SelectInput will be
    // done.
    setTimeout(() => {
      if (this.selectInputRef.current === null) {
        // This should never happen, but if it does it is not worth having the
        // application crash over it.
        console.warn(
          `No input element existed when attempting to focus select ${this.componentId}`
        );
      } else {
        this.selectInputRef.current.focus();
      }
    });
  }

  public render(): React.ReactNode {
    const classNames: string[] = [
      this.props.className ? this.props.className : "",
      this.props.classes.root,
    ];

    const inputLabelClasses = {
      root: this.props.classes.inputLabelRoot,
      shrink: this.props.classes.inputLabelShrink,
    };

    const selectedOption: ComboBoxOption = this.props.options.find(
      (o) => o.value === this.props.value
    )!;

    if (this.props.readOnly) {
      return (
        <TextField
          label={this.props.label}
          readOnly={true}
          value={selectedOption ? selectedOption.display : "-"}
          variant="filled"
        />
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: this.props.getErrors,
      helperText: this.props.helperText,
      value: this.props.value ? this.props.value : undefined,
    });

    return (
      <FormControl
        className={this.props.classes.root}
        error={fieldHelperText.hasErrors || this.props.error}
        FormHelperTextProps={fieldHelperText.formHelperTextProps}
        fullWidth={true}
        helperText={fieldHelperText.helperText}
        helperTextId={this.helperTextId}
        required={this.props.showAsMandatory}
        variant="filled"
      >
        {this.props.label && (
          <InputLabel
            aria-hidden={true}
            classes={inputLabelClasses}
            htmlFor={this.componentId}
            id={this.labelId}
          >
            {this.props.label}
          </InputLabel>
        )}
        <FilledInput
          className={classNames.join(" ")}
          disableUnderline={this.props.disableUnderline}
          fullWidth={true}
          inputComponent={SelectInput}
          inputProps={{
            "aria-label": this.props["aria-label"],
            errors: fieldHelperText.errors,
            IconComponent: () => (
              <Icon
                className={multiClassName(
                  this.props.classes.icon,
                  this.props.iconClassName
                )}
                icon="fas fa-caret-down"
              />
            ),
            labelId: this.props.label ? this.labelId : undefined,
            onClose: this.props.onClose,
            onFocus: this.props.onFocus,
            onValueChange: this.props.onValueChange,
            openOnMount: this.props.openOnMount,
            options: this.props.options,
            required: this.props.showAsMandatory,
            SelectDisplayProps: {
              "aria-describedby": fieldHelperText.helperText
                ? this.helperTextId
                : undefined,
              id: this.componentId,
              style: this.props.selectDisplayStyle,
            },
          }}
          inputRef={this.selectInputRef}
          ref={this.selectRef}
          style={this.props.inputBaseStyle}
          value={selectedOption?.value ?? ""}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(Select);
