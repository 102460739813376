import grey from "@material-ui/core/colors/grey";
import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import TrackableModel from "../../core/TrackableModel";
import Button from "../../coreui/Button";
import ErrorBadge from "../../coreui/ErrorBadge";
import Input, { InputProps } from "../../coreui/Input";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import ErrorsStore from "../../stores/ErrorsStore";
import Api from "../Api";
import { NumericEdit } from "../NumericEdit";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams {
  dataId: string;
  increment: number | null;
  justification: "Left" | "Right";
  maximum: number | null;
  maximumError: string | null;
  minimum: number | null;
  minimumError: string | null;
  name: string;
  propagated: TableChildProps;
  scale: number | null;
  scaleError: string | null;
}

interface State {
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

@observer
export class NumericEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  private updateValue: boolean;
  protected inputElement: HTMLInputElement;
  protected muiProps: InputProps;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static getCurrentValueParsed(value: any) {
    let parsed: number | null = null;

    if (value) {
      switch (typeof value) {
        case "string":
          const cleaned = value.replace(
            Sys.settings.decimalSeparator,
            NumericEdit.systemDecimalCharacter
          );

          const regex = new RegExp(`\\${Sys.settings.thousandsSeparator}`, "g");
          parsed = parseFloat(cleaned.replace(regex, ""));
          if (isNaN(parsed)) {
            parsed = null;
          }
          break;
        case "number":
          parsed = value as number;
          break;
        default:
          throw new Error(
            "Unexpected numeric edit value type " + `${typeof value}`
          );
      }
    }

    return parsed;
  }

  public static getErrors(
    props: ConfigProperties,
    data: TrackableModel,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): string[] {
    const widgetProperties = Api.getWidgetProperties(
      props,
      data
    ) as RuntimeProperties;
    let result: string[];

    if (!widgetProperties) {
      return [];
    }

    if (data.hasChanges(props.name)) {
      result = [];
    } else {
      result = [...widgetProperties.businessErrors];
    }

    const parsedValue = NumericEditColumnEdit.getCurrentValueParsed(value);

    if (parsedValue !== null) {
      if (props.minimum !== null && parsedValue < props.minimum) {
        result.push(props.minimumError!);
      }

      if (props.maximum !== null && parsedValue > props.maximum) {
        result.push(props.maximumError!);
      }

      if (props.increment !== null) {
        const scale = props.scale !== null ? Math.pow(10, props.scale) : 1;
        if (
          (Math.round(parsedValue * scale) % (props.increment * scale)) /
            scale !==
          0
        ) {
          result.push(props.scaleError!);
        }
      }
    }

    return result;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    // Backspace.
    if (props.keyPress === 8 && props.value) {
      let value = String(props.value);

      value = value.substr(0, value.length - 1);
      this.state = { value };
    } else {
      const regex = new RegExp(
        // eslint-disable-next-line max-len
        `[\\${Sys.settings.decimalSeparator}\\${Sys.settings.thousandsSeparator}\\-0-9]`,
        "g"
      );

      if (props.charPress && regex.test(props.charPress)) {
        if (props.value) {
          this.state = { value: props.value + props.charPress };
        } else {
          this.state = { value: props.charPress };
        }
      } else {
        this.state = { value: props.value };
      }
    }

    this.muiProps = {
      autoFocus: true,
      fullWidth: true,
      inputProps: {
        max: props.maximum,
        min: props.minimum,
        style: { marginLeft: "24px" },
      },
      justification: props.justification,
    };

    this.muiProps.inputRef = (element) => {
      this.inputElement = element;
    };

    this.muiProps.onChange = (e) => {
      ErrorsStore.clearBusinessErrorsForTableCell(
        this.props.dataId,
        this.props.name,
        this.props.data.rowKey
      );
      this.setState({ value: e.target.value });
    };
    this.muiProps.onKeyDown = (e) => this.validateKeyPress(e);
    // ArrowUp and ArrowDown are eaten by the onKeyDown event so
    // I am using onKeyUp for increment/decrement.
    this.muiProps.onKeyUp = (e) => this.onKeyUp(e);
    this.muiProps.style = { height: "calc(100% + 2px)", paddingLeft: 2 };
  }

  private formatValue(value: number | null, userFormatted: boolean) {
    return NumericEdit.formatNumericValue(
      value,
      userFormatted,
      this.props.scale
    );
  }

  private handleIncreaseOrDecreaseValue(increase: boolean) {
    if (this.updateValue) {
      this.increaseOrDecreaseValue(increase);

      setTimeout(() => {
        this.handleIncreaseOrDecreaseValue(increase);
      }, 150);
    }
  }

  private increaseOrDecreaseValue(increase: boolean) {
    if (this.props.increment === null) {
      return;
    }

    let currentValue = NumericEditColumnEdit.getCurrentValueParsed(
      this.muiProps.value
    );

    if (
      this.props.minimum !== null &&
      (currentValue === null || currentValue < this.props.minimum)
    ) {
      currentValue = this.props.minimum;
    } else if (
      this.props.maximum !== null &&
      (currentValue === null || currentValue > this.props.maximum)
    ) {
      currentValue = this.props.maximum;
    } else {
      currentValue = currentValue || 0;
      currentValue = currentValue + this.props.increment * (increase ? 1 : -1);
    }

    if (
      (increase ||
        this.props.minimum === null ||
        currentValue >= this.props.minimum) &&
      (!increase ||
        this.props.maximum === null ||
        currentValue <= this.props.maximum)
    ) {
      this.setFormattedValue(currentValue);
    }

    if (
      (this.props.minimum !== null &&
        currentValue !== undefined &&
        currentValue < this.props.minimum + this.props.increment!) ||
      (this.props.maximum !== null &&
        currentValue !== undefined &&
        currentValue > this.props.maximum - this.props.increment!)
    ) {
      this.updateValue = false;

      if (this.inputElement) {
        this.inputElement.focus();
      } else {
        this.props.api!.startEditingCell({
          colKey: this.props.column.getColId(),
          rowIndex: this.props.rowIndex,
        });
      }
    }
  }

  private onKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!event.key || !this.props.increment) {
      return;
    }

    if (event.key === "ArrowUp") {
      this.increaseOrDecreaseValue(false);
    }

    if (event.key === "ArrowDown") {
      this.increaseOrDecreaseValue(true);
    }
  };

  private onMouseUp = () => {
    this.updateValue = false;
    window.removeEventListener("mouseup", this.onMouseUp);
  };

  private setFormattedValue(value: number | null) {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value: this.formatValue(value, false) });
  }

  private startIncreaseOrDecreaseValue(increase: boolean) {
    this.updateValue = true;

    window.addEventListener("mouseup", this.onMouseUp);

    setTimeout(() => {
      this.handleIncreaseOrDecreaseValue(increase);
    }, 250);
  }

  private validateKeyPress(
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (!event.key) {
      return;
    }

    const validCharacters = [
      Sys.settings.thousandsSeparator,
      Sys.settings.decimalSeparator,
      "-",
    ];
    for (let i = 0; i <= 9; i++) {
      validCharacters.push(i.toString());
    }

    const isControlChar = event.key.length > 1;
    if (
      !event.ctrlKey &&
      !isControlChar &&
      validCharacters.indexOf(event.key) < 0
    ) {
      event.preventDefault();
    }
  }

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
  }

  public getValue(): string | null {
    this.updateValue = false;

    return this.formatValue(
      NumericEditColumnEdit.getCurrentValueParsed(this.state.value),
      false
    );
  }

  public render() {
    const _props = { ...this.props };
    const column: GridColumnConfigProperties | undefined = (this.props
      .propagated.parentTable.columns as GridColumnConfigProperties[]).find(
      (_column) => _column.name === this.props.colDef.colId
    );

    this.muiProps.value = this.state.value || "";

    const errors: string[] = NumericEditColumnEdit.getErrors(
      _props,
      _props.node.data,
      this.muiProps.value
    );

    this.muiProps.error = errors.length > 0;

    const parsedValue = NumericEditColumnEdit.getCurrentValueParsed(
      this.muiProps.value
    );

    if (_props.increment !== null) {
      const canDecrement =
        parsedValue === null ||
        _props.minimum === null ||
        parsedValue - _props.increment >= _props.minimum;
      const canIncrement =
        parsedValue === null ||
        _props.maximum === null ||
        parsedValue + _props.increment <= _props.maximum;

      this.muiProps.required = column?.required;
      this.muiProps.endAdornment = (
        <muiInputAdornment.default
          position="end"
          style={{ marginRight: 24, marginTop: -4 }}
        >
          <React.Fragment>
            <Button
              aria-label={Localization.getBuiltInMessage("decrement")}
              disabled={!canDecrement}
              icon="fas fa-minus"
              size="small"
              onClick={() => this.increaseOrDecreaseValue(false)}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(false)}
            />
            <Button
              aria-label={Localization.getBuiltInMessage("increment")}
              disabled={!canIncrement}
              icon="fas fa-plus"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={() => this.increaseOrDecreaseValue(true)}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(true)}
            />
          </React.Fragment>
        </muiInputAdornment.default>
      );
    }

    return (
      <ErrorBadge
        isShort={
          _props.node.data.isNew && _props.propagated.parentTable.isDocumentGrid
        }
        message={Api.getErrorMessages(errors)}
      >
        <Input {...this.muiProps} />
      </ErrorBadge>
    );
  }
}

export default NumericEditColumnEdit;
