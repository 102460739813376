import { reaction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import ProcessingMask from "../../coreui/ProcessingMask";
import RequestsStore, { ProcessingInfo } from "../../stores/RequestsStore";

interface Props {
  enableBackground?: boolean;
}

@observer
export class PageProcessing extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);

    reaction(
      () => RequestsStore.instance.processingInfo.isProcessing,
      (value: boolean) => {
        this.announceProcessing();
      }
    );
  }

  private announceProcessing = () => {
    const info: ProcessingInfo = RequestsStore.instance.processingInfo;

    if (info.isProcessing) {
      Sys.debounceMethod(
        () => {
          Sys.announce(Localization.getBuiltInMessage("Page.loadStarted"));
        },
        "Page.load",
        1000
      );
    } else {
      Sys.debounceMethod(
        () => {
          Sys.announce(Localization.getBuiltInMessage("Page.loadComplete"));
        },
        "Page.load",
        1000
      );
    }
  };

  public componentDidMount() {
    this.announceProcessing();
  }

  public render(): React.ReactNode {
    const info: ProcessingInfo = RequestsStore.instance.processingInfo;

    return (
      <ProcessingMask
        disableBackground={info.isNew && !this.props.enableBackground}
        disableRestoreFocus={info.disableRestoreFocus}
        isFixed={true}
        isOpen={info.isProcessing}
        message={info.message}
        shortFade={info.isNew}
        trapFocus={true}
      />
    );
  }
}

export default PageProcessing;
