import * as muiDialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import * as React from "react";
import * as DayPicker from "react-day-picker";
// @ts-ignore
import { LocaleUtils } from "react-day-picker/moment";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import multiClassName from "../coreui/MultiClassName";
import Button from "./Button";
import Dialog from "./Dialog";
import Typography from "./Typography";

type Digit = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

interface Props {
  onClose: () => void;
  onDateSelected: (date: Date) => void;
  onRestoreFocus?: () => void;
  open: boolean;
  title: string;
  value: Date | null;
}

interface State {
  selectedDate: Date | null;
  visibleMonth: Date;
  yearPickerOpen: boolean;
  yearPickerTypeAheadYear: number | null;
}

const styles = (theme: Theme) =>
  createStyles({
    calendar: {
      [theme.breakpoints.down("xs")]: {
        padding: 16,
      },

      "& .DayPicker-Body": {
        display: "table-row-group",
      },

      "& .DayPicker-Caption": {
        display: "table-caption",
        marginBottom: "0.5rem",
        maxHeight: 40,
        padding: "0 42px",
        textAlign: "center",
      },

      "& .DayPicker-Day": {
        display: "table-cell",
        outline: "none",
        textAlign: "center",
      },
      "& .DayPicker-Day button": {
        borderRadius: "50%",
        fontWeight: 400,
        letterSpacing: "normal",
        minWidth: "40px",
        padding: "0.5rem",
      },
      "& .DayPicker-Day--outside button": {
        color: theme.palette.grey["300"],
      },
      "& .DayPicker-Day--selected button": {
        background: `${theme.palette.grey["800"]} !important`,
        color: theme.palette.getContrastText(theme.palette.grey["800"]),
      },
      "& .DayPicker-Day--selected button:hover": {
        background: darken(theme.palette.grey["800"], 0.1),
      },
      "& .DayPicker-Day--selected:focus button": {
        background: theme.palette.grey["800"],
      },
      "& .DayPicker-Day--selected:focus button:hover": {
        background: darken(theme.palette.grey["800"], 0.1),
      },
      "& .DayPicker-Day:focus button": {
        background: theme.palette.grey["400"],
      },
      "& .DayPicker-Day:focus button:hover": {
        background: darken(theme.palette.grey["400"], 0.1),
      },

      "& .DayPicker-Month": {
        display: "table",
        width: "100%",
      },

      "& .DayPicker-NavButton": {
        position: "absolute",
        top: "5px",
      },
      "& .DayPicker-NavButton > button": {
        height: 24,
        padding: 0,
        width: 24,
      },
      "& .DayPicker-NavButton--next": {
        "& > button": { paddingLeft: 2 },
        right: "0.5rem",
      },
      "& .DayPicker-NavButton--prev": {
        "& > button": { paddingRight: 1 },
        left: "0.5rem",
      },

      "& .DayPicker-Week": {
        display: "table-row",
      },
      "& .DayPicker-Weekday": {
        color: theme.palette.grey["500"],
        display: "table-cell",
        fontSize: 12,
        lineHeight: "1.42857",
        padding: "0.5rem",
        textAlign: "center",
      },
      "& .DayPicker-Weekdays": {
        display: "table-header-group",
      },
      "& .DayPicker-WeekdaysRow": {
        display: "table-row",
      },

      "& .DayPicker-wrapper": {
        outline: "none",
        position: "relative",
      },

      padding: 40,
    },

    dialogContainer: {
      "& .CancelButton": {
        bottom: 16,
        display: "none",
        position: "absolute",
        right: 16,

        [theme.breakpoints.down("xs")]: {
          display: "inline-block",
        },
      },
      "&:first-child": {
        paddingTop: 0,
      },

      height: 405, // Ensure space for months that span 6 weeks
      margin: "0 auto",
      maxWidth: "100%",
      padding: 0,
      width: 380,
    },

    switchYearButton: {
      maxWidth: 215,
      minWidth: 150,
      paddingLeft: 0,
      paddingRight: 0,
      position: "static", // Fix for Safari not firing click event
      textTransform: "none",
    },
    switchYearButtonRipple: {
      background: "transparent",
      margin: "0 auto",
      maxHeight: 40,
      maxWidth: 150,
    },
    switchYearButtonText: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: "0.01071em",
      lineHeight: "20px",
      whiteSpace: "normal",
    },

    yearSelected: {
      color: theme.palette.getContrastText(theme.palette.grey["800"]),
    },
  });

export class CalendarDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private contentRef?: HTMLDivElement | null;
  private yearPickerTypeAheadDigits: string = "";

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      selectedDate: props.value,
      visibleMonth: props.value || new Date(),
      yearPickerOpen: false,
      yearPickerTypeAheadYear: null,
    };
  }

  private addDigitToYearPickerTypeAhead(digit: Digit): void {
    this.yearPickerTypeAheadDigits += digit;
    if (this.yearPickerTypeAheadDigits.length < 4) {
      return;
    }

    const candidateYear = this.yearPickerTypeAheadDigits.slice(-4);
    this.setState({ yearPickerTypeAheadYear: parseInt(candidateYear, 10) });
  }

  private focusSelectedDay = () => {
    let selected = this.contentRef!.querySelector(
      ".DayPicker-Day--selected"
    ) as HTMLLinkElement;

    if (!selected) {
      selected = this.contentRef!.querySelector(
        ".DayPicker-Day--today"
      ) as HTMLLinkElement;
    }

    if (!selected) {
      selected = this.contentRef!.querySelector(
        '.DayPicker-Day[tabindex="0"]'
      ) as HTMLLinkElement;
    }

    if (selected) {
      selected.focus();
    }
  };

  private navigateYears = (event: React.KeyboardEvent<HTMLElement>) => {
    const targetElement = event.target as HTMLElement;

    switch (event.key) {
      case "ArrowDown":
        if (targetElement.nextElementSibling) {
          (targetElement.nextElementSibling as HTMLElement).focus();
        }
        this.yearPickerTypeAheadDigits = "";
        break;
      case "ArrowUp":
        if (targetElement.previousElementSibling) {
          (targetElement.previousElementSibling as HTMLElement).focus();
        }
        this.yearPickerTypeAheadDigits = "";
        break;
      case "Escape":
        this.setState({ yearPickerOpen: false }, () => {
          const button = this.contentRef!.querySelector(
            `.${this.props.classes.switchYearButton}`
          ) as HTMLButtonElement;
          if (button) {
            button.focus();
          }
        });
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        this.addDigitToYearPickerTypeAhead(event.key);
        break;
      default:
        return;
    }

    event.preventDefault();
    event.stopPropagation();
  };

  private onExited = () => {
    if (this.props.onRestoreFocus) {
      this.props.onRestoreFocus();
    }
  };

  private renderCalendar(): React.ReactNode {
    return (
      <DayPicker.default
        className={this.props.classes.calendar}
        month={this.state.visibleMonth}
        locale={Localization.localeName}
        localeUtils={LocaleUtils}
        selectedDays={this.state.selectedDate || undefined}
        showOutsideDays
        onDayClick={(date: Date) => {
          this.props.onDateSelected(date);
          this.props.onClose();
        }}
        captionElement={(props) => {
          const selectedMonth = Localization.formatMonthTitle(props.date);

          return (
            <div className={props.classNames.caption}>
              <Button
                aria-label={Localization.getBuiltInMessage(
                  "DateEdit.switchYearButtonLabel",
                  { selectedMonth }
                )}
                classes={{
                  root: this.props.classes.switchYearButton,
                  text: this.props.classes.switchYearButtonText,
                }}
                onClick={() => this.switchToYearsView(props.date)}
                TouchRippleProps={{
                  className: this.props.classes.switchYearButtonRipple,
                }}
                variant="text"
              >
                {selectedMonth}
              </Button>
            </div>
          );
        }}
        navbarElement={(props) => {
          const selectedMonth = Localization.formatMonthTitle(props.month);

          return (
            <div className={props.className}>
              <span className={props.classNames.navButtonPrev}>
                <Button
                  aria-label={Localization.getBuiltInMessage(
                    "DateEdit.previousMonthButtonLabel",
                    { selectedMonth }
                  )}
                  icon="fas fa-chevron-left"
                  size="small"
                  onClick={() => props.onPreviousClick()}
                />
              </span>
              <span className={props.classNames.navButtonNext}>
                <Button
                  aria-label={Localization.getBuiltInMessage(
                    "DateEdit.nextMonthButtonLabel",
                    { selectedMonth }
                  )}
                  icon="fas fa-chevron-right"
                  size="small"
                  onClick={() => props.onNextClick()}
                />
              </span>
            </div>
          );
        }}
        renderDay={(day, modifiers) => (
          <Button
            aria-label={day.toLocaleDateString(
              Sys.settings.currentLanguageCode,
              {
                day: "numeric",
                month: "long",
                weekday: "long",
                year: "numeric",
              }
            )}
            tabIndex={-1}
            variant="text"
          >
            {day.getDate()}
          </Button>
        )}
        weekdayElement={(props) => (
          <Typography classes={{ root: props.className }} component="span">
            {Localization.formatWeekdayShort(props.weekday, props.locale)}
          </Typography>
        )}
      />
    );
  }

  private renderYearPicker(): React.ReactNode {
    const years: number[] = [];
    const currentYear = this.state.visibleMonth.getFullYear();
    for (let i = currentYear - 50; i <= currentYear + 50; i += 1) {
      years.push(i);
    }

    return (
      <div
        aria-label={Localization.getBuiltInMessage(
          "DateEdit.selectYearListLabel"
        )}
        role="listbox"
      >
        {years.map((year: number) => {
          const isSelected: boolean = year === currentYear;

          return (
            <MenuItem
              aria-selected={isSelected}
              autoFocus={
                this.state.yearPickerTypeAheadYear
                  ? year === this.state.yearPickerTypeAheadYear
                  : isSelected
              }
              classes={{ selected: this.props.classes.yearSelected }}
              component="div"
              key={year}
              onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                this.selectYear(year, event)
              }
              onKeyDown={this.navigateYears}
              role="option"
              selected={isSelected}
              tabIndex={isSelected ? 0 : -1}
            >
              {year}
            </MenuItem>
          );
        })}
      </div>
    );
  }

  private selectYear(year: number, event: React.MouseEvent<HTMLElement>) {
    const isUsingKeyboard: boolean = event.clientX === undefined;

    this.setState(
      (lastState) => {
        const currentMonth = lastState.visibleMonth.getMonth();

        return {
          visibleMonth: new Date(year, currentMonth, 1),
          yearPickerOpen: false,
        };
      },
      () => {
        if (isUsingKeyboard) {
          const button = this.contentRef!.querySelector(
            `.${this.props.classes.switchYearButton}`
          ) as HTMLButtonElement;
          if (button) {
            button.focus();
          }
        }
      }
    );
  }

  private switchToYearsView = (currentMonth: Date): void => {
    this.yearPickerTypeAheadDigits = "";
    this.setState({ visibleMonth: currentMonth, yearPickerOpen: true });
  };

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedDate: this.props.value,
        visibleMonth: this.props.value || new Date(),
      });
    }

    if (prevProps.open && !this.props.open) {
      this.setState({
        yearPickerOpen: false,
      });
    }
  }

  public render(): React.ReactNode {
    return (
      <Dialog
        onClose={this.props.onClose}
        onEntered={this.focusSelectedDay}
        onExited={this.onExited}
        open={this.props.open}
        PaperProps={{
          "aria-label": this.props.title,
        }}
      >
        <muiDialogContent.default
          className={this.props.classes.dialogContainer}
        >
          <div ref={(r) => (this.contentRef = r)} style={{ margin: "0 auto" }}>
            {this.state.yearPickerOpen
              ? this.renderYearPicker()
              : this.renderCalendar()}
            {!this.state.yearPickerOpen ? (
              <Button className="CancelButton" onClick={this.props.onClose}>
                {Localization.getBuiltInMessage("cancel")}
              </Button>
            ) : null}
          </div>
        </muiDialogContent.default>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CalendarDialog);
