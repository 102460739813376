import * as muiTypography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config/SearchPresentation";

interface ConfigProperties {
  dataId: string;
  name: string;
  propagated: SearchChildProps;
}

@observer
export class ResultsCountDisplay extends React.Component<ConfigProperties> {
  public render(): React.ReactNode {
    if (!this.props.propagated.parentSearch.succeeded) {
      return null;
    }

    return (
      <muiTypography.default
        style={{ display: "inline-block", fontWeight: 400 }}
        variant={"h3"}
        component={"p"}
      >
        {this.props.propagated.parentSearch.resultsCountMessage}
      </muiTypography.default>
    );
  }
}

export default ResultsCountDisplay;
