import { RowNode } from "ag-grid-community";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Icon from "../coreui/Icon";
import Presentation from "../coreui/Presentation";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import Api from "./Api";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import { ProjectGridVerticalHierarchyChildProps } from "./ProjectGridVerticalHierarchy";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps & ProjectGridVerticalHierarchyChildProps;
}

export class GridPrint extends React.PureComponent<Props> {
  private title: string | null = null;
  private onButtonClick = () => {
    const columns: GridColumnConfigProperties[] = [];
    const columnWidths: number[] = [];
    const headers: object[] = [];
    const tableProps = this.props.propagated.parentTable;
    const newRows: object[] = [];
    const printWindow = window.open(
      "printIndex.html",
      `${tableProps.configProps.contentDataId}.${this.props.propagated.parentTable.configProps.name}`
    );
    const columnConfigs: GridColumnConfigProperties[] = [];
    const rows: object[] = [];
    let content: JSX.Element;

    this.title =
      Presentation.objectDefDescription ||
      Presentation.objectTitle ||
      Sys.settings.siteName;

    if (tableProps.isProjectGrid) {
      columnConfigs.push({
        lg: true,
        name: "ProjectCurrentJobColumn",
        widgetType: "ProjectCurrentJobColumn",
        width: 16,
      } as GridColumnConfigProperties);
    }

    columnConfigs.push(...(tableProps.columns as GridColumnConfigProperties[]));

    for (const column of columnConfigs) {
      if (
        column.lg &&
        (!column.widgetProps ||
          column.widgetProps["thumbnailType"] !== "Download Link Only")
      ) {
        if (column.width) {
          columnWidths.push(column.width!);
        } else if (column.widgetProps["imageWidth"]) {
          columnWidths.push(column.widgetProps["imageWidth"]);
        } else {
          columnWidths.push(Presentation.measureText(column.header) || 16);
        }

        columns.push(column);
        headers.push(
          <div
            className="cxprintheadercell"
            key={column.name}
            style={{
              minWidth: columnWidths[columnWidths.length - 1],
              width: columnWidths[columnWidths.length - 1],
            }}
          >
            <div className="cxprintheader">{column.header}</div>
          </div>
        );
      }
    }

    tableProps.getApi().forEachNodeAfterFilterAndSort((rowNode) => {
      rows.push(this.renderRow(columns, columnWidths, rowNode));
    });

    const count = tableProps.getApi().getPinnedTopRowCount();

    if (!!count) {
      for (let index: number = 0; index < count; index++) {
        newRows.push(
          this.renderRow(
            columns,
            columnWidths,
            tableProps.getApi().getPinnedTopRow(index),
            index === count - 1
          )
        );
      }

      const width: number =
        columnWidths.reduce((a, b) => a + b) +
        columnWidths.length * 48 +
        (columnWidths.length - 1);

      content = (
        <React.Fragment>
          <div className="cxprinttitle">{this.title}</div>
          <div className="cxprintgrid">
            <div className="cxprintnewheaders">{headers}</div>
            <div className="cxprintnewcontainer" style={{ width }}>
              <div
                className="cxprintchip"
                style={{ borderColor: Api.getSystemColor("success") }}
              >
                {Localization.getBuiltInMessage("newRow")}
              </div>
              <div className="cxprintnewcontainerborders" style={{ width }}>
                {newRows}
              </div>
            </div>
            <div className="cxprintrowcontainer" style={{ width }}>
              {rows}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          <div className="cxprinttitle">{this.title}</div>
          <div className="cxprintgrid">
            <div className="cxprintheaders">{headers}</div>
            {rows}
          </div>
        </React.Fragment>
      );
    }

    if (printWindow) {
      this.renderGrid(printWindow, content);
    }
  };

  private renderGrid(printWindow: Window, grid: JSX.Element) {
    const gridDiv = printWindow.document.getElementById("grid");

    if (gridDiv && !gridDiv.innerHTML.length) {
      printWindow.document.documentElement.lang =
        Sys.settings.currentLanguageCode;
      printWindow.document.title = this.title!;

      ReactDOM.render(grid, gridDiv, () =>
        // Have to give the dom a chance to render.
        setTimeout(() => printWindow.print(), 100)
      );
    } else {
      setTimeout(() => {
        this.renderGrid(printWindow, grid);
      }, 100);
    }
  }

  private renderRow(
    columns: GridColumnConfigProperties[],
    columnWidths: number[],
    rowNode: RowNode,
    lastRow: boolean = false
  ) {
    const paneRow = rowNode.data as PaneRow;
    const values: object[] = [];

    columns.forEach((column: GridColumnConfigProperties, index: number) => {
      const widget = paneRow.getWidget(column.name);
      let value;

      if (widget) {
        value = GridColumn.getPrintValue(column, widget, paneRow);
      } else if (
        column.widgetType === "ProjectCurrentJobColumn" &&
        paneRow.isCurrentJob
      ) {
        value = <Icon icon="fas fa-eye" />;
      }

      values.push(
        <div
          className={lastRow ? "cxprintvaluecelllastrow" : "cxprintvaluecell"}
          key={column.name}
          style={{
            minWidth: columnWidths[index],
            width: columnWidths[index],
          }}
        >
          <div className="cxprintvalue">{value}</div>
        </div>
      );
    });

    return (
      <div className="cxprintrow" key={paneRow.rowKey}>
        {values}
      </div>
    );
  }

  public render() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          position: "relative",
        }}
      >
        <Button
          aria-label={Localization.getBuiltInMessage("print")}
          fab
          onClick={this.onButtonClick}
          size="small"
          tabIndex={-1}
        >
          <Icon icon={"fas fa-print"} style={{ marginTop: 2, width: "100%" }} />
        </Button>
      </div>
    );
  }
}

export default GridPrint;
