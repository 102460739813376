import * as muiPaper from "@material-ui/core/Paper";
import * as muiSnackbarContent from "@material-ui/core/SnackbarContent";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Collapse from "../coreui/Collapse";
import Icon from "../coreui/Icon";
import BaseService from "../services/BaseService";
import SessionMessagesStore, {
  SessionMessage,
} from "../stores/SessionMessagesStore";
import Api from "./Api";

const styles = (theme: Theme) =>
  createStyles({
    caution: {
      "& > svg": {
        top: "2px !important",
      },
    },
    content: {
      "& > div": {
        [theme.breakpoints.only("xs")]: { width: "100%" },
        [theme.breakpoints.only("sm")]: { width: 568 },
        [theme.breakpoints.only("md")]: { width: 912 },
        [theme.breakpoints.up("lg")]: { width: 1200 },
      },
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "none",
      },
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[800],
      justifyContent: "center",
    },
    message: {
      alignItems: "center",
      display: "flex",
    },
    root: {
      position: "sticky",
      top: 0,
      zIndex: theme.zIndex.modal - 1,
    },
    text: {
      flex: "auto",
      margin: "0px .4em",
    },
  });

@observer
export class SessionMessageDisplay extends React.Component<
  WithStyles<typeof styles>
> {
  private removeMessage(message: SessionMessage): void {
    SessionMessagesStore.removeMessage(message);

    const url: string = `${BaseService.baseUrl}User/SessionMessage/${message.messageId}`;

    fetch(url, { method: "DELETE" });
  }

  public render(): React.ReactNode {
    const content: React.ReactNode[] = [];
    const messages: SessionMessage[] = SessionMessagesStore.messages;

    for (const message of messages) {
      let icon: React.ReactNode = null;
      let messageText: string = message.message;

      switch (message.messageType) {
        case "Caution":
          icon = (
            <div
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.caution"
              )}
              className="fa-layers"
              role="img"
              style={{
                color: Api.getSystemColor("warning"),
                minWidth: "1em",
              }}
            >
              <Icon
                className={this.props.classes.caution}
                icon="fas fa-exclamation"
                style={{
                  color: Api.getSystemColor("dark"),
                  fontSize: ".5em",
                }}
              />
              <Icon icon="far fa-triangle" />
            </div>
          );

          messageText = `${Localization.getBuiltInMessage(
            "SessionMessage.warning"
          )} ${message.message}`;
          break;
        case "Danger":
          icon = (
            <div
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.warning"
              )}
              className="fa-layers"
              role="img"
              style={{
                color: Api.getSystemColor("danger"),
                minWidth: "1em",
              }}
            >
              <Icon icon="fas fa-exclamation" style={{ fontSize: ".6em" }} />
              <Icon icon="far fa-octagon" />
            </div>
          );

          messageText = `${Localization.getBuiltInMessage(
            "SessionMessage.warning"
          )} ${message.message}`;
          break;
        case "Info":
          icon = (
            <div
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.information"
              )}
              role="img"
            >
              <Icon
                fixedWidth
                icon="far fa-info-circle"
                style={{
                  color: Api.getSystemColor("information"),
                  float: "left",
                }}
              />
            </div>
          );
          break;
        case "Success":
          icon = (
            <div
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.success"
              )}
              role="img"
            >
              <Icon
                fixedWidth
                icon="far fa-check-circle"
                style={{
                  color: Api.getSystemColor("success"),
                  float: "left",
                }}
              />
            </div>
          );
          break;
        default:
          throw new Error(`Unknown message type ${message.messageType}`);
      }

      content.push(
        <muiSnackbarContent.default
          className={this.props.classes.content}
          elevation={0}
          key={message.messageId}
          message={
            <div className={this.props.classes.message}>
              {icon}
              <div className={this.props.classes.text}>{message.message}</div>
              <Button
                aria-label={Localization.getBuiltInMessage(
                  "SessionMessage.closeButton",
                  { messageText }
                )}
                fab
                icon="fas fa-times"
                onClick={() => this.removeMessage(message)}
                size="small"
              />
            </div>
          }
        />
      );

      setTimeout(() => {
        Sys.announce(messageText);
      }, 2000);
    }

    return (
      <Collapse className={this.props.classes.root} in={messages.length > 0}>
        <muiPaper.default elevation={6}>{content}</muiPaper.default>
      </Collapse>
    );
  }
}

export default withStyles(styles)(SessionMessageDisplay);
