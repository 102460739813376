import * as muiSnackbar from "@material-ui/core/Snackbar";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import Icon from "../../coreui/Icon";
import Typography from "../../coreui/Typography";
import Api from "../../mustangui/Api";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore, { SavedInfo } from "../../stores/RequestsStore";

@observer
export class MessagesSnackbar extends React.Component {
  private lastErrorMessages: string[] | null = null;
  private onClickDismiss = (): void => {
    ErrorsStore.clearErrors();
    RequestsStore.instance.clearSaved();
  };

  private onClose = (): void => {
    this.lastErrorMessages = null;
    ErrorsStore.clearErrors();
    RequestsStore.instance.clearSaved();
  };

  public render(): React.ReactNode {
    const errorMessages: string[] = ErrorsStore.getErrorMessages();
    const messagesToAnnounce: string[] = [];
    const savedInfo: SavedInfo = RequestsStore.instance.savedInfo;
    let autoHideDuration: number | undefined = undefined;
    let content: React.ReactNode = null;

    if (errorMessages.length === 0) {
      this.lastErrorMessages = null;
    }

    if (errorMessages.length > 0) {
      let errorText: string | null = null;

      if (this.lastErrorMessages) {
        messagesToAnnounce.push(
          ...errorMessages.filter(
            (message) => !this.lastErrorMessages?.includes(message)
          )
        );
      } else {
        messagesToAnnounce.push(...errorMessages);
      }

      messagesToAnnounce.forEach((message: string, index: number) => {
        if (!message.endsWith(".")) {
          messagesToAnnounce[index] = `${message}. `;
        }
      });

      // We only announce messages that were not previously announced to the
      // user, so messagesToAnnounce may not be populated
      if (messagesToAnnounce.length > 0) {
        errorText = messagesToAnnounce.join(" ");

        // Must delay call because page maybe performing a roundtrip.
        setTimeout(() => {
          Sys.announce(
            Localization.getBuiltInMessage("Snackbar.errorPrefix", {
              errorText,
            }),
            true
          );
        }, 2000);
      }

      this.lastErrorMessages = [...errorMessages];

      content = (
        <Typography color="error" component="div" variant="body2">
          {Api.getErrorMessages(errorMessages)}
        </Typography>
      );
    } else if (savedInfo.wasSaved) {
      const message: string = Localization.getBuiltInMessage("saved");

      autoHideDuration = 5000;

      content = (
        <div aria-hidden style={{ alignItems: "center", display: "flex" }}>
          {savedInfo.iconName ? (
            <Icon icon={savedInfo.iconName} style={{ marginRight: ".4em" }} />
          ) : null}
          {message}
        </div>
      );

      setTimeout(() => {
        Sys.announce(message);
      }, 2000);
    }

    return (
      <muiSnackbar.default
        action={
          content !== null && autoHideDuration === undefined ? (
            <Button
              aria-label={Localization.getBuiltInMessage(
                "Snackbar.closeButton"
              )}
              fab
              icon="fas fa-times"
              onClick={this.onClickDismiss}
              size="small"
            />
          ) : null
        }
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        autoHideDuration={autoHideDuration}
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: false,
        }}
        message={content}
        onClose={this.onClose}
        open={content !== null}
      />
    );
  }
}

export default MessagesSnackbar;
