import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import TrackableModel from "../core/TrackableModel";
import FocusTracker from "../coreui/FocusTracker";
import Presentation from "../coreui/Presentation";
import ProcessingMask from "../coreui/ProcessingMask";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  AsyncData as AsyncDataBase,
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import PaneDataStore from "../stores/PaneDataStore";
import Api, { AccessLevel } from "./Api";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import { DashboardCriteria as DashboardCriteriaBase } from "./DashboardCriteria";
import ErrorBoundary from "./ErrorBoundary";

interface ConfigProperties extends WithWidth {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  footerToolbar?: object;
  headerToolbar?: object;
  name: string;
  searches: { description: string; name: string }[];
  summaryToolbar?: object;
  tableKey: string;
  verticalLayout?: object;
}

export interface DashboardChildProps {
  parentDashboard: {
    initialSearch: string;
    search: (search: string) => Promise<void>;
    searches: Search[];
  };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  initialSearch: string;
}

export interface Search {
  description: string;
  name: string;
}

interface State {
  isSearching?: boolean;
}

const styles = (theme: Theme) => createStyles({});

@observer
export class DashboardGridControl extends React.Component<
  ConfigProperties & WithWidth & WithStyles<typeof styles>,
  State
> {
  private asyncDataRef: React.RefObject<AsyncData>;
  private currentSearchName: string;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private populate: ((rows: TrackableModel[]) => void) | null = null;
  private propagated: TableChildProps & DashboardChildProps;
  private tableProps: TableProps;

  public constructor(
    props: ConfigProperties & WithWidth & WithStyles<typeof styles>
  ) {
    super(props);

    this.state = { isSearching: false };
    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (DashboardCriteriaBase.values.has(this.props.contentDataId)) {
      this.currentSearchName = DashboardCriteriaBase.values.get(
        this.props.contentDataId
      )!;
    } else {
      this.currentSearchName = runtimeProperties.initialSearch;
    }

    const tableChildProps = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
          tableKey: props.tableKey,
        },
        description: props.description,
        hasRelatedEditDialog: false,
        isDocumentGrid: false,
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.propagated = {
      parentDashboard: {
        initialSearch: runtimeProperties.initialSearch,
        search: this.search,
        searches: props.searches,
      },
      ...tableChildProps,
    };

    this.tableProps = {
      "aria-label": this.props.description,
      cardDepth: props.cardDepth,
      cellEdit: false,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      resetPageOnPopulate: true,
      setPopulate: (populate) => (this.populate = populate),
      showNoData: true,
      summaryToolbarChild: props.summaryToolbar,
    };
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.description,
      }),
      true
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private getData = (searchName?: string) => {
    return RoundTripService.partialDataRetrevial<GetDataResponse>(
      `DashboardGridControl/GetRowsData/${this.getRowKey()}` +
        `/${this.props.dataId}/${this.props.name}`,
      undefined,
      { SearchName: searchName ? searchName : this.currentSearchName }
    );
  };

  private getRowKey(): string {
    const observable = Presentation.getObservable(this.props)! as PaneRow;
    return observable.rowKey;
  }

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState = this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private populateData = () => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.contentDataId
    );

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  private search = async (search: string) => {
    this.setState({ isSearching: true });

    try {
      await AsyncDataBase.processResponse(this.getData(search));
      const rows: PaneRow[] = PaneDataStore.getPaneCollection(
        this.props.contentDataId
      );

      this.currentSearchName = search;

      setTimeout(() => {
        Sys.announce(
          Localization.getBuiltInMessage(
            rows.length === 0
              ? "DataTable.tableRowCountZero"
              : rows.length === 1
              ? "DataTable.tableRowCountSingle"
              : "DataTable.tableRowCountMultiple",
            { count: rows.length }
          )
        );
      }, 2000);
    } finally {
      this.setState({ isSearching: false });
    }
  };

  public componentDidMount() {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }
  }

  public componentDidUpdate(prevProps: ConfigProperties) {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public render() {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <div style={{ position: "relative" }}>
            <AsyncData
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              getData={this.getData}
              onIsLoadingChanged={this.onIsLoadingChanged}
              populateData={this.populateData}
              ref={this.asyncDataRef}
            />
            <ProcessingMask isOpen={this.state.isSearching!} />
            <Table
              {...this.tableProps}
              fullWidthChild={this.props.verticalLayout}
              tableKey={this.props.tableKey}
            />
          </div>
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

export default withStyles(styles)(withWidth()(DashboardGridControl));
