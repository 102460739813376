import * as muiInput from "@material-ui/core/Input";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import InputAdornment from "./InputAdornment";

export interface InputProps extends muiInput.InputProps {
  icon?: string;
  iconColor?: string;
  justification?: "Left" | "Right";
}

const styles = (theme: Theme) =>
  createStyles({
    input: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    root: {},
  });

export class Input extends React.PureComponent<
  InputProps & WithStyles<typeof styles>
> {
  public render() {
    const _props = { ...this.props };

    if ("icon" in _props) {
      if (_props.icon) {
        _props.startAdornment = (
          <InputAdornment
            icon={_props.icon}
            position="start"
            style={{
              color: _props.iconColor,
              fontSize: 12,
              height: 12,
              marginLeft: 24,
              marginRight: ".4em",
            }}
          />
        );
      }

      const style = {
        height: 40,
        marginTop: 3,
        textAlign: _props.justification,
      } as React.CSSProperties;

      if (!_props.endAdornment) {
        style.marginRight = 24;
      }

      if (_props.inputProps) {
        if (_props.inputProps.style) {
          _props.inputProps.style = {
            ...style,
            ..._props.inputProps.style,
          };
        } else {
          _props.inputProps.style = style;
        }
      } else {
        _props.inputProps = { style };
      }

      delete _props.icon;
    }

    if ("iconColor" in _props) {
      delete _props.iconColor;
    }

    return <muiInput.default {..._props} />;
  }
}

export default withStyles(styles)(Input);
