import * as muiDialog from "@material-ui/core/Dialog";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as React from "react";
import Sys from "../core/Sys";
import {
  CustomTheme,
  getPanelMeasurements,
  PanelMeasurements,
} from "../muiTheme";
import MaskingStore from "../stores/MaskingStore";

export interface BreakPointColumn {
  columns: number;
  size: "lg" | "md" | "sm" | "xs";
}

interface Props extends muiDialog.DialogProps {
  breakPointColumns?: BreakPointColumn[];
}

const styles = (theme: CustomTheme) => {
  const minWidth = 345;
  const paperWidths = {};
  for (const breakPoint of theme.spacingBreakPoints.filter((b) => b !== "xs")) {
    const measurements: PanelMeasurements = getPanelMeasurements(
      theme,
      breakPoint
    );
    const maxColumns: number = theme.dialog.maxColumns[breakPoint];

    for (let size = 1; size <= maxColumns; size++) {
      const width: number =
        (size + 1) * measurements.gutterWidth + size * measurements.columnWidth;

      paperWidths[`dialogPaper-${breakPoint}-${size}`] = {
        [theme.breakpoints.up(breakPoint)]: {
          width: width < minWidth ? minWidth : width,
        },
      };
    }
  }

  return createStyles({
    paper: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.dialog.maxWidths.sm,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: theme.dialog.maxWidths.md,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: theme.dialog.maxWidths.lg,
      },
    },
    ...paperWidths,
  });
};

export class Dialog extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public static currentOpenInstance: Dialog | null;

  private isMasked: boolean = false;
  private rootRef: HTMLElement | null = null;

  private onEntered = (node: HTMLElement, isAppearing: boolean) => {
    if (this.props.onEntered) {
      this.props.onEntered(node, isAppearing);
    }
  };

  private onEntering = (node: HTMLElement, isAppearing: boolean) => {
    Dialog.currentOpenInstance = this;
    MaskingStore.maskOpened();
    this.isMasked = true;

    if (this.props.onEntering) {
      this.props.onEntering(node, isAppearing);
    }
  };

  private onExited(node: HTMLElement, forced: boolean) {
    Dialog.currentOpenInstance = null;

    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }

    if (this.props.onExited && !forced) {
      this.props.onExited(node);
    }
  }

  public componentWillUnmount() {
    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }

    this.forceClose();
  }

  public forceClose = () => {
    if (!this.props.open) {
      return;
    }

    if (this.props.onClose) {
      this.props.onClose({ forced: true }, "escapeKeyDown");
    }
    this.onExited(this.rootRef!, true);
  };

  public render() {
    const {
      classes,
      breakPointColumns,
      onEntered,
      onEntering,
      onExited,
      ...dialogProps
    } = this.props;

    const paperClasses = [classes.paper];

    if (breakPointColumns) {
      dialogProps.fullWidth = true;
      for (const breakPoint of breakPointColumns) {
        const className = this.props.classes[
          `dialogPaper-${breakPoint.size}-${breakPoint.columns}`
        ];
        paperClasses.push(className);
      }
    }

    const paperMargin: number = !dialogProps.fullScreen
      ? Sys.settings.baselineGridSize * 4
      : 0;

    return (
      <muiDialog.default
        classes={{ paper: paperClasses.join(" ") }}
        innerRef={(r) => (this.rootRef = r)}
        maxWidth={false}
        onEntered={this.onEntered}
        onEntering={this.onEntering}
        onExited={(n) => this.onExited(n, false)}
        PaperProps={{ style: { margin: paperMargin } }}
        {...dialogProps}
      />
    );
  }
}

export default withMobileDialog<Props>({ breakpoint: "xs" })(
  withStyles(styles)(Dialog)
);
