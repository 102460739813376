import grey from "@material-ui/core/colors/grey";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import TrackableModel from "../../core/TrackableModel";
import EditMask, { EditMask as EditMaskBase } from "../../coreui/EditMask";
import ErrorBadge from "../../coreui/ErrorBadge";
import Input from "../../coreui/Input";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import ErrorsStore from "../../stores/ErrorsStore";
import Api from "../Api";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams {
  dataCase: "lower" | "UPPER" | "Any";
  dataId: string;
  dataSize: number;
  editMask: string;
  maxSizeError: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

@observer
export class SLTextEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  private originalValue: string | null;
  protected inputElement: HTMLInputElement;

  public static getErrors(
    props: ConfigProperties,
    data: TrackableModel,
    value: string | null,
    editingValueHasChanged: boolean = false
  ): string[] {
    const widgetProperties = Api.getWidgetProperties(
      props,
      data
    ) as RuntimeProperties;
    let result: string[];

    if (!widgetProperties) {
      return [];
    }

    if (data.hasChanges(props.name)) {
      result = [];
    } else {
      result = [...widgetProperties.businessErrors];
    }

    if (props.dataSize && value && value.length > props.dataSize) {
      result.push(props.maxSizeError);
    }

    if (!editingValueHasChanged && props.editMask) {
      result = result.concat(EditMaskBase.getErrors(props.editMask, value));
    }

    return result;
  }

  public static getInitialValue(params: ICellEditorParams): string | null {
    let updatedValue: string | null = null;

    // Backspace.
    if (params.keyPress === 8 && params.value) {
      updatedValue = String(params.value);

      updatedValue = updatedValue.substr(0, updatedValue.length - 1);
    } else {
      if (params.charPress) {
        if (params.value) {
          updatedValue = params.value + params.charPress;
        } else {
          updatedValue = params.charPress;
        }
      } else {
        updatedValue = params.value;
      }
    }

    return updatedValue;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = { value: SLTextEditColumnEdit.getInitialValue(props) };

    this.originalValue = this.state.value!;
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value;

    switch (this.props.dataCase) {
      case "lower":
        value = value.toLowerCase();
        break;
      case "UPPER":
        value = value.toUpperCase();
        break;
      case "Any":
        break;
      default:
        break;
    }

    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  };

  private onMaskValueChange = (value: string) => {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  };

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
  }

  public getValue(): string | null {
    return this.state.value === "" ? null : this.state.value!;
  }

  public render() {
    const _props = { ...this.props };
    const column: GridColumnConfigProperties | undefined = (this.props
      .propagated.parentTable.columns as GridColumnConfigProperties[]).find(
      (_column) => _column.name === this.props.colDef.colId
    );
    const value = this.state.value || "";

    const errors: string[] = SLTextEditColumnEdit.getErrors(
      _props,
      _props.node.data,
      value,
      this.state.value !== this.originalValue
    );

    let input: React.ReactNode;
    if (_props.editMask) {
      input = (
        <EditMask
          dataCase={_props.dataCase}
          InputProps={{
            style: { height: "100%", margin: "0 24px", paddingLeft: 2 },
          }}
          inputRef={(r) => (this.inputElement = r)}
          mask={_props.editMask}
          name={_props.name}
          onChange={this.onMaskValueChange}
          required={column?.required}
          style={{ height: "calc(100% + 2px)" }}
          value={value}
        />
      );
    } else {
      input = (
        <Input
          autoFocus={true}
          error={errors.length > 0}
          fullWidth={true}
          inputProps={{ style: { margin: "0 24px" } }}
          inputRef={(r) => (this.inputElement = r)}
          onChange={this.onChange}
          required={column?.required}
          style={{ height: "calc(100% + 2px)", paddingLeft: 2 }}
          value={value}
        />
      );
    }

    return (
      <ErrorBadge
        isShort={
          _props.node.data.isNew && _props.propagated.parentTable.isDocumentGrid
        }
        message={Api.getErrorMessages(errors)}
      >
        {input}
      </ErrorBadge>
    );
  }
}

export default SLTextEditColumnEdit;
