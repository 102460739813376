import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer, useObserver } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import Sys from "../../core/Sys";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
  RoundTripProps,
} from "../../services/ActionButtonService";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import Api, { AccessLevel } from "../Api";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ActionButtonProps {}

interface State {
  isConfirmDialogOpen?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class DeletePresentationButton extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private onClickPromise: RequestPromise<void>;

  private static getLabel(runtimeProperties: ActionButtonRuntimeProps): string {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("Button.deleteLabel");

    return label;
  }

  private static onClick(props: RoundTripProps): RequestPromise<void> {
    const row: PaneRow = PaneRow.get(props.dataId)!;
    const runtimeProperties = Api.getWidgetProperties(
      props
    ) as ActionButtonRuntimeProps;

    const onClickPromise = ActionButtonService.onRoundTrip(row, props.name)
      .then((response: OnRoundTripResponse) => {
        if (
          response.businessErrors.length === 0 &&
          response.validationErrors.length === 0
        ) {
          Sys.announce(
            Localization.getBuiltInMessage("Button.succeeded", {
              label: DeletePresentationButton.getLabel(runtimeProperties),
            })
          );
        }

        if (runtimeProperties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });

    return onClickPromise;
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState<
      boolean
    >(false);

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        if (runtimeProps.confirmMessage) {
          setIsConfirmDialogOpen(true);
        } else {
          DeletePresentationButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }
      }
    };

    const onAcceptConfirm = () => {
      setIsConfirmDialogOpen(false);
      DeletePresentationButton.onClick(configProps);
      configProps.propagated.onItemClicked!();
    };

    const onCancelConfirm = () => {
      setIsConfirmDialogOpen(false);
    };

    return useObserver(() => (
      <React.Fragment>
        {runtimeProps.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={runtimeProps.cancelButtonText!}
            continueButtonColor={configProps.buttonColor}
            continueButtonIcon={configProps.iconName}
            continueButtonText={runtimeProps.continueButtonText!}
            isOpen={isConfirmDialogOpen}
            message={runtimeProps.confirmMessage}
            onCancel={onCancelConfirm}
            onContinue={onAcceptConfirm}
            title={runtimeProps.confirmTitle!}
          />
        ) : null}
        <MenuItem
          disabled={
            runtimeProps.accessLevel === AccessLevel.disabled ||
            (props.runtime.validateCaptcha! && !CaptchaControlBase.isValid())
          }
          iconName={configProps.iconName}
          indent={props.config.propagated ? props.config.propagated.indent : 0}
          onClick={onClick}
          {...otherProps}
        >
          {runtimeProps.label}
        </MenuItem>
      </React.Fragment>
    ));
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { isConfirmDialogOpen: false };
  }

  private onAcceptConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
    this.onClickPromise = DeletePresentationButton.onClick(this.props);
  };

  private onCancelConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private onClick = () => {
    const runtimeProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as ActionButtonRuntimeProps;

    if (runtimeProperties.confirmMessage) {
      this.setState({ isConfirmDialogOpen: true });
    } else {
      this.onClickPromise = DeletePresentationButton.onClick(this.props);
    }
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const _props = { ...this.props };
    const runtimeProperties = Api.getWidgetProperties(
      _props
    ) as ActionButtonRuntimeProps;
    const disabled: boolean =
      runtimeProperties.validateCaptcha && !CaptchaControlBase.isValid();

    return (
      <React.Fragment>
        {runtimeProperties.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={runtimeProperties.cancelButtonText!}
            continueButtonColor={this.props.buttonColor}
            continueButtonIcon={this.props.iconName}
            continueButtonText={runtimeProperties.continueButtonText!}
            isOpen={this.state.isConfirmDialogOpen!}
            message={runtimeProperties.confirmMessage}
            onCancel={this.onCancelConfirm}
            onContinue={this.onAcceptConfirm}
            title={runtimeProperties.confirmTitle!}
          />
        ) : null}
        <ApiButton
          alternateText={runtimeProperties.alternateText}
          buttonColor={this.props.buttonColor}
          dataId={this.props.dataId}
          disabled={disabled}
          disabledHelpText={this.props.disabledHelpText}
          iconName={this.props.iconName}
          isIconOnly={!runtimeProperties.label}
          label={DeletePresentationButton.getLabel(runtimeProperties)}
          name={this.props.name}
          onClick={this.onClick}
          size={this.props.size}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DeletePresentationButton);
