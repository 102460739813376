import * as muiMenuItem from "@material-ui/core/MenuItem";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import Sys from "../core/Sys";
import WithForwardedRef, {
  WithForwardedRefProps,
} from "../core/WithForwardedRef";
import Typography from "../coreui/Typography";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";

export interface MenuItemProps
  extends muiMenuItem.MenuItemProps,
    WithForwardedRefProps {
  button?: true;
  href?: string;
  iconName?: string;
  indent?: number;
  target?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    disabled: {
      "& > p": { color: theme.palette.grey[300] },
      "& > span": { color: theme.palette.grey[300] },
      "&:hover": { backgroundColor: "transparent" },
      cursor: "default",
    },
    link: {
      ...theme.overrides?.MuiMenuItem?.gutters,
      alignItems: "center",
      color: theme.palette.grey[800],
      display: "flex",
      flex: 1,
      minWidth: 0,
      textDecoration: "none",
    },
    root: {
      color: theme.palette.grey[800],
    },
    rootLink: {
      padding: 0,
    },
    typography: {
      color: theme.palette.grey[800],
    },
  });

export class MenuItem extends React.PureComponent<
  MenuItemProps & WithStyles<typeof styles>
> {
  private anchorRef = React.createRef<HTMLAnchorElement>();
  private readonly componentId: string;
  private readonly labelId: string;

  private static isKeyboardEvent(
    event: React.MouseEvent | React.KeyboardEvent
  ): event is React.KeyboardEvent {
    return (event as React.KeyboardEvent).key !== undefined;
  }

  public constructor(props: MenuItemProps & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `menu-item-${Sys.nextId}`;
    this.labelId = `${this.componentId}-label`;
  }

  private onClick = (
    event: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLLIElement>
  ) => {
    // The onClick event is triggered for mouse click and Enter key press, but
    // the key press isn't passed through to the anchor so we simulate a click
    // and then return.  The onClick event will then fire again.
    if (
      MenuItem.isKeyboardEvent(event) &&
      event.type === "keydown" &&
      event.key === "Enter" &&
      this.anchorRef.current
    ) {
      this.anchorRef.current.click();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(event as React.MouseEvent<HTMLLIElement>);
    }
  };

  private onKeyUp = (event: React.KeyboardEvent<HTMLLIElement>) => {
    // Suppress the Space key for menus
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  public render(): React.ReactNode {
    const {
      children,
      classes,
      className,
      href,
      iconName,
      forwardedRef,
      onClick,
      role,
      target,
      ...itemProps
    } = this.props;

    let content: React.ReactNode = children;
    let labelId: string | undefined = undefined;
    if (typeof content === "string") {
      labelId = this.labelId;
      content = (
        <Typography
          aria-hidden={true}
          className={classes.typography}
          ellipsis
          id={this.labelId}
        >
          {content}
        </Typography>
      );
    }

    if (iconName) {
      content = (
        <React.Fragment>
          <Icon icon={iconName} style={{ marginRight: ".4em" }} />
          {content}
        </React.Fragment>
      );
    }

    if (href) {
      content = (
        <a
          aria-hidden={true}
          className={classes.link}
          href={href}
          rel={target === "_blank" ? "noopener" : undefined}
          ref={this.anchorRef}
          target={target}
          tabIndex={-1}
        >
          {content}
        </a>
      );
    }

    return (
      <muiMenuItem.default
        {...itemProps}
        aria-labelledby={labelId}
        className={multiClassName(
          className,
          classes.root,
          itemProps.disabled ? classes.disabled : "",
          href ? classes.rootLink : ""
        )}
        onClick={this.onClick}
        onKeyUp={this.onKeyUp}
        ref={forwardedRef}
        role={role || "menuitem"}
        style={{ marginLeft: itemProps.indent }}
      >
        {content}
      </muiMenuItem.default>
    );
  }
}

export default withStyles(styles)(WithForwardedRef(MenuItem));
