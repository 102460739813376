import { makeStyles } from "@material-ui/core/styles";
import { useObserver } from "mobx-react-lite";
import * as React from "react";
import { SiteSearchInfo } from "../config/SearchPresentation";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import Icon from "../coreui/Icon";
import PaneRow from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import { HeaderChildProps } from "../templates/components/Header";
import { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  name: string;
  propagated: HeaderChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  siteSearches: SiteSearchInfo[];
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  iconAndLabel: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconOnly: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
}));

export default function SiteCriteriaLink(
  props: ConfigProperties
): JSX.Element | null {
  const classes = useStyles();

  const onClick = (): void => {
    if (props.propagated.parentHeader.siteCriteria.isExpanded) {
      props.propagated.parentHeader.siteCriteria.onCollapse();
    } else {
      const currentRuntimeProps = PaneRow.getWidgetProperties(
        props.dataId,
        props.name
      ) as RuntimeProperties;

      props.propagated.parentHeader.siteCriteria.onExpand(
        currentRuntimeProps.siteSearches
      );
    }
  };

  const runtimeProps = PaneRow.getWidgetProperties(
    props.dataId,
    props.name
  ) as RuntimeProperties;

  if (!runtimeProps) {
    return null;
  }

  if (runtimeProps.accessLevel <= AccessLevel.readOnly) {
    return null;
  }

  const label: string = Localization.getBuiltInMessage("search");
  const iconName: string = props.propagated.parentHeader.siteCriteria.isExpanded
    ? "fas fa-times"
    : "fas fa-search";

  return useObserver(() => (
    <React.Fragment>
      <div className={classes.iconAndLabel}>
        <ButtonLink
          aria-expanded={props.propagated.parentHeader.siteCriteria.isExpanded}
          aria-label={label}
          onClick={onClick}
          tabIndex={-1}
        >
          <Icon icon={iconName} style={{ marginRight: ".4em" }} />
          {label}
        </ButtonLink>
      </div>
      <div className={classes.iconOnly}>
        <Button
          aria-expanded={props.propagated.parentHeader.siteCriteria.isExpanded}
          aria-label={label}
          fab
          onClick={onClick}
          size="large"
          tabIndex={-1}
        >
          <Icon icon={iconName} />
        </Button>
      </div>
    </React.Fragment>
  ));
}
