import { observer } from "mobx-react";
import * as React from "react";
import MultilineTextField from "../coreui/MultilineTextField";
import Presentation from "../coreui/Presentation";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface Props {
  dataId: string;
  dataSize: number;
  disabledHelpText: string;
  helperText: string;
  label: string;
  maxSizeError: string;
  name: string;
}

interface WidgetProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

@observer
export class MLTextEdit extends React.Component<Props> {
  private getErrors = (value: string): string[] => {
    const widgetProperties: WidgetProperties = Api.getWidgetProperties(
      this.props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return [];
    }

    const errors: string[] = [...widgetProperties.businessErrors];
    if (this.props.dataSize && value && value.length > this.props.dataSize) {
      errors.push(this.props.maxSizeError);
    }

    return errors;
  };

  private onValueChange = (value: string) => {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value);
  };

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const widgetProperties: WidgetProperties = Api.getWidgetProperties(
      _props
    ) as WidgetProperties;

    if (!widgetProperties) {
      return null;
    }

    if (widgetProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const value = Presentation.getValue(_props) as string;

    return (
      <MultilineTextField
        disabled={widgetProperties.accessLevel === AccessLevel.disabled}
        disabledHelpText={
          widgetProperties.showDisabledHelp
            ? _props.disabledHelpText
            : undefined
        }
        getErrors={this.getErrors}
        helperText={_props.helperText}
        label={_props.label}
        name={_props.name}
        onValueChange={this.onValueChange}
        readOnly={widgetProperties.accessLevel === AccessLevel.readOnly}
        required={widgetProperties.showAsMandatory}
        value={value}
        variant="filled"
      />
    );
  }
}

export default MLTextEdit;
