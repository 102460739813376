import * as muiBadge from "@material-ui/core/Badge";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTab from "@material-ui/core/Tab";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Api from "../mustangui/Api";
import DisabledHelpBadge from "./DisabledHelpBadge";
import Icon from "./Icon";

interface Props extends muiTab.TabProps {
  badge?: "contents" | "errors";
  businessErrorsCount: number;
  disabledHelpText: string | null;
  showDisabledHelp?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    badge: {
      right: -12,
      top: 5,
    },
    disabled: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      opacity: "1 !important" as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pointerEvents: "auto !important" as any,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: "16px",
    },
    labelDisabled: {
      color: theme.palette.grey[300],
    },
    root: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      "&:focus-visible": { fontWeight: "bold" },
      boxSizing: "border-box",
      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      padding: 0,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  });

interface State {
  isDisabledHelpOpen: boolean;
  isFocusRippleVisible: boolean;
}

export class Tab extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private readonly describedById: string;
  private isDisabledHelpVisible: boolean;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `tab-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;
    this.isDisabledHelpVisible = false;

    this.state = { isDisabledHelpOpen: false, isFocusRippleVisible: false };
  }

  private onBlur = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (this.props.disabled) {
      this.setState({ isDisabledHelpOpen: false });
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    this.setState({ isFocusRippleVisible: false });
  };

  private onClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (this.props.disabled) {
      this.setState({
        isDisabledHelpOpen: !this.state.isDisabledHelpOpen,
      });

      return;
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.props.disabled) {
      if (event.key === "Escape") {
        this.setState({ isDisabledHelpOpen: false });
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  public render() {
    const {
      badge,
      businessErrorsCount,
      classes,
      className,
      disabled,
      disabledHelpText,
      onChange,
      onClick,
      showDisabledHelp,
      ...tabProps
    } = this.props;

    let labelClasses: string = "";
    if (disabled) {
      labelClasses += ` ${classes.labelDisabled}`;
    }

    // Only specify the describedById if its element is actually rendered.
    let describedById: string | undefined = undefined;

    if (disabled) {
      if (showDisabledHelp) {
        // DisabledHelpText is mandatory if a disabled condition is configured
        this.isDisabledHelpVisible = true;
        describedById = this.describedById;
        tabProps.label = (
          <DisabledHelpBadge
            classes={{ badge: classes.badge, root: labelClasses }}
            helpText={disabledHelpText!}
            isFocusRippleVisible={this.state.isFocusRippleVisible}
            isHelpOpen={this.state.isDisabledHelpOpen}
            onHelpOpenChange={(isHelpOpen: boolean): void =>
              this.setState({ isDisabledHelpOpen: isHelpOpen })
            }
          >
            {tabProps.label}
            <span id={this.describedById} style={{ display: "none" }}>
              {disabledHelpText}
            </span>
          </DisabledHelpBadge>
        );
      }
    } else if (badge === "contents") {
      describedById = this.describedById;
      tabProps.label = (
        <muiBadge.default
          badgeContent={
            <Icon
              fixedWidth
              icon="far fa-exclamation-circle"
              style={{
                color: Api.getSystemColor("information"),
                fontSize: 16,
                lineHeight: "24px",
              }}
            />
          }
          classes={{ badge: classes.badge, root: labelClasses }}
        >
          {tabProps.label}
          <span id={this.describedById} style={{ display: "none" }}>
            {Localization.getBuiltInMessage("Tab.hasContents")}
          </span>
        </muiBadge.default>
      );
    } else if (badge === "errors") {
      describedById = this.describedById;
      tabProps.label = (
        <muiBadge.default
          badgeContent={
            <span className="fa-layers fa-fw">
              <Icon
                className={classes.errorIcon}
                fixedWidth
                icon="fas fa-exclamation"
                style={{ fontSize: ".8em" }}
              />
              <Icon
                className={classes.errorIcon}
                fixedWidth
                icon="far fa-octagon"
              />
            </span>
          }
          classes={{ badge: classes.badge, root: labelClasses }}
        >
          {tabProps.label}
          <span id={this.describedById} style={{ display: "none" }}>
            {businessErrorsCount > 1
              ? Localization.getBuiltInMessage(
                  "Tab.erroredFieldsCountMultiple",
                  {
                    widgetErrorCount: businessErrorsCount,
                  }
                )
              : Localization.getBuiltInMessage("Tab.erroredFieldsCountSingle")}
          </span>
        </muiBadge.default>
      );
    } else {
      tabProps.label = <span className={labelClasses}>{tabProps.label}</span>;
    }

    return (
      <muiTab.default
        aria-describedby={describedById}
        aria-disabled={disabled}
        classes={{
          disabled: classes.disabled,
          root: classes.root,
        }}
        focusRipple={!this.isDisabledHelpVisible}
        onBlur={this.onBlur}
        onChange={disabled ? undefined : onChange}
        onClick={this.onClick}
        onFocusVisible={() =>
          this.setState({
            isFocusRippleVisible: true,
          })
        }
        onKeyDown={this.onKeyDown}
        {...tabProps}
      />
    );
  }
}

export default withStyles(styles)(Tab);
