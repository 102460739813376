import { observer } from "mobx-react";
import * as React from "react";
import PaneRow, { WidgetValue } from "../models/PaneRow";
import { ActionButton } from "./ActionButton";
import Api, { AccessLevel } from "./Api";
import { LanguageSelect } from "./LanguageSelect";
import { ManualLink } from "./ManualLink";
import { MenuButton } from "./MenuButton";
import { PresentationButton } from "./PresentationButton";

interface ConfigProperties {
  child: { props: MenuItemConfig; type: string };
  disabled: boolean; // Used by MUI MenuList for focus management
  propagated: object;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}

// VERSION_WARNING Material-UI 4.9.14
// Props injected by MenuList into children for focus management.  These will
// be forwarded to the MUI MenuItem.
// Since this interface is not specified in MUI docs, this will need to be
// validated with each version upgrade.
export interface FocusProps {
  autoFocus?: boolean;
  tabIndex?: number;
}

export interface MenuItemConfig {
  dataId: string;
  name: string;
  propagated: { indent?: number };
  type: string;
}

export interface MenuItemProps extends FocusProps {
  config: MenuItemConfig & object;
  runtime: { accessLevel: AccessLevel; validateCaptcha?: boolean } & object;
}

@observer
export class MenuItem extends React.Component<ConfigProperties & FocusProps> {
  public static isActionable(menuItemProps: MenuItemConfig): boolean {
    const row = PaneRow.get(menuItemProps.dataId);
    if (!row) {
      return false;
    }
    const widget = row.getWidgetT<WidgetValue, RuntimeProperties>(
      menuItemProps.name
    );

    return widget.properties.accessLevel >= AccessLevel.actionable;
  }

  public render(): React.ReactNode {
    const { child, propagated, ...otherProps } = this.props;

    const configProps = {
      ...child.props,
      propagated,
    };

    switch (this.props.child.type) {
      case "LanguageSelect":
        return (
          <LanguageSelect.renderMenuItem
            config={configProps}
            key={configProps.name}
            runtime={{ accessLevel: AccessLevel.readOnly }}
          />
        );
      default:
    }

    const runtimeProperties = Api.getWidgetProperties(
      this.props.child.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    switch (this.props.child.type) {
      case "ActionButton":
        return (
          <ActionButton.renderMenuItem
            config={configProps}
            key={configProps.name}
            runtime={runtimeProperties}
            {...otherProps}
          />
        );

      case "ManualLink":
        return (
          <ManualLink.renderMenuItem
            config={configProps}
            key={configProps.name}
            runtime={runtimeProperties}
            {...otherProps}
          />
        );

      case "MenuButton":
        return (
          <MenuButton.renderMenuItem
            config={configProps}
            key={configProps.name}
            runtime={runtimeProperties}
            {...otherProps}
          />
        );

      case "PresentationButton":
        return (
          <PresentationButton.renderMenuItem
            config={configProps}
            key={configProps.name}
            runtime={runtimeProperties}
            {...otherProps}
          />
        );

      default:
        throw new Error(`Unknown menu item type ${this.props.child.type}`);
    }
  }
}

export default MenuItem;
