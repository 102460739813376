import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import { AccessLevel } from "../mustangui/Api";

interface Props {
  childLayoutWidgetNames: string[];
  children: React.ReactNode;
  childWidgetNames: string[];
  dataId: string;
  justify: "start" | "end";
  propagated: TableChildProps;
  toolbarType: "header" | "footer" | "selection" | "summary";
  verticalLayout?: boolean;
}

interface WidgetProperties {
  accessLevel?: AccessLevel;
}

const styles = (theme: CustomTheme) => {
  const sectionSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const groupSpacing = theme.freeflow.group.spacing[breakPoint];
    const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

    sectionSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      marginLeft: -groupSpacing.horizontal * 0.5,
      marginRight: -groupSpacing.horizontal * 0.5,

      paddingLeft: sectionSpacing.horizontal * 0.5,
      paddingRight: sectionSpacing.horizontal * 0.5,
    };
  }

  return createStyles({
    container: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      ...sectionSpaceStyle,
    },
  });
};

@observer
export class ToolbarContainerSection extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private shouldRender(): boolean {
    if (this.props.childLayoutWidgetNames.length > 0) {
      return true;
    }

    for (const widgetName of this.props.childWidgetNames) {
      const widgetProperties = PaneRow.getWidgetProperties(
        this.props.dataId,
        widgetName
      ) as WidgetProperties;

      if (widgetProperties.accessLevel === undefined) {
        return true;
      }

      if (widgetProperties.accessLevel >= AccessLevel.disabled) {
        return true;
      }
    }

    return false;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    let justifyContent = {
      end: "flex-end",
      start: "flex-start",
    }[this.props.justify];

    // When table is in vertical layout the footer toolbar container will
    // render centered justified since there is only one full width section
    // instead of a start and end section.
    if (
      this.props.propagated.parentTable?.isVerticalLayout &&
      this.props.toolbarType === "footer"
    ) {
      justifyContent = "center";
    }

    return (
      <div
        className={this.props.classes.container}
        style={{
          justifyContent,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(ToolbarContainerSection);
