import * as React from "react";
import AppServer from "../core/AppServer";
import { Dialog } from "../coreui/Dialog";
import Presentation from "../coreui/Presentation";
import RequestsStore from "../stores/RequestsStore";
import ErrorPageTemplate, {
  ErrorPageTemplateConfig,
} from "../templates/ErrorPageTemplate";

export interface ErrorCodeConfig {
  backgroundImage?: string;
  message: string;
  solutions: string[];
}

export interface ErrorPageConfig {
  backgroundImage: string;
  errorCodes: object;
  message: string;
  solutionsTitle: string;
  solutions: string[];
  template: ErrorPageTemplateConfig;
}

export class ErrorPage {
  private static config: ErrorPageConfig | null = null;

  public static render(message: string | null, status: number) {
    if (Dialog.currentOpenInstance) {
      Dialog.currentOpenInstance.forceClose();
    }

    AppServer.clearState();

    RequestsStore.instance.clearAllProcessing();

    if (ErrorPage.config !== null) {
      Presentation.render(
        <ErrorPageTemplate
          backgroundImage={ErrorPage.config.backgroundImage}
          configMessage={ErrorPage.config.message}
          errorCodes={ErrorPage.config.errorCodes}
          footer={ErrorPage.config.template.footer}
          header={ErrorPage.config.template.header}
          message={message}
          solutionsTitle={ErrorPage.config.solutionsTitle}
          solutions={ErrorPage.config.solutions}
          status={status}
        />
      );
    } else {
      Presentation.render(
        <ErrorPageTemplate configMessage={message || ""} status={status} />
      );
    }
  }

  public static setConfig(config: ErrorPageConfig) {
    if (ErrorPage.config !== null) {
      throw new Error("Error page config is already set");
    }

    ErrorPage.config = config;
  }
}
