import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTypography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Layout, LayoutConfig } from "../config/Layout";
import KeyboardNavigationGroup from "../coreui/KeyboardNavigationGroup";
import muiTheme, {
  CustomTheme,
  ForegroundColorType,
  getPanelMeasurements,
} from "../muiTheme";
import SessionMessageDisplay from "../mustangui/SessionMessageDisplay";
import Logon from "../pages/Logon";
import BackgroundImage from "./components/BackgroundImage";
import EnvironmentBanner from "./components/EnvironmentBanner";
import Header from "./components/Header";
import MessagesSnackbar from "./components/MessagesSnackbar";
import PageProcessing from "./components/PageProcessing";
import CenteredContentClasses from "./styles/CenteredContentClasses";

interface FooterConfig {
  backgroundColor: string;
  foreground: ForegroundColorType;
  layout: LayoutConfig;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  logoUrl: string;
  logoLabel: string;
  layout: LayoutConfig;
}

export interface LandingPageTemplateConfig {
  backgroundImageUrl: string;
  footer: FooterConfig;
  header: HeaderConfig;
  signInTitle: string;
  welcomeTitle1: string;
  welcomeTitle2: string;
}

interface Props {
  backgroundImageUrl: string;
  footer: FooterConfig;
  forgotPasswordUrl: string | null;
  header: HeaderConfig;
  signInTitle: string;
  welcomeTitle1: string;
  welcomeTitle2: string;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    footer: {
      [theme.breakpoints.up("lg")]: {
        paddingBottom: theme.paper.padding.lg,
        paddingTop: theme.paper.padding.lg,
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: theme.paper.padding.md,
        paddingTop: theme.paper.padding.md,
      },
      [theme.breakpoints.only("sm")]: {
        paddingBottom: theme.paper.padding.sm,
        paddingTop: theme.paper.padding.sm,
      },
      [theme.breakpoints.only("xs")]: {
        paddingBottom: theme.paper.padding.xs,
        paddingTop: theme.paper.padding.xs,
      },
      "@media print": {
        display: "none",
      },
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    footerContent: {
      [theme.breakpoints.only("xs")]: {
        flexBasis: "100%",
      },
    },
    main: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    mainContent: {
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.container.verticalSpacing.xs,
        marginTop: theme.container.verticalSpacing.xs,
      },
      [theme.breakpoints.only("sm")]: {
        marginBottom: theme.container.verticalSpacing.sm,
        marginTop: theme.container.verticalSpacing.sm,
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: theme.container.verticalSpacing.md,
        marginTop: theme.container.verticalSpacing.md,
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.container.verticalSpacing.lg,
        marginTop: theme.container.verticalSpacing.lg,
      },
      flexGrow: 1,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    signIn: {
      [theme.breakpoints.up("lg")]: {
        display: "block",
        padding: theme.paper.padding.lg,
        width:
          getPanelMeasurements(theme, "lg").columnWidth * 4 +
          getPanelMeasurements(theme, "lg").gutterWidth * 3 -
          theme.paper.padding.lg * 2,
      },
      [theme.breakpoints.only("md")]: {
        display: "block",
        padding: theme.paper.padding.md,
        width:
          getPanelMeasurements(theme, "md").columnWidth * 5 +
          getPanelMeasurements(theme, "md").gutterWidth * 4 -
          theme.paper.padding.md * 2,
      },
      backgroundColor: theme.palette.common.white,
      display: "none",
    },
    titleLight: {
      fontWeight: 400,
    },
    top: {
      position: "relative",

      [theme.breakpoints.up("xs")]: {
        height: 212,
      },
      [theme.breakpoints.up("sm")]: {
        height: 300,
      },
      [theme.breakpoints.up("md")]: {
        height: "auto",
      },
    },
    topContent: {
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: 24,
        marginTop: 24,
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: 40,
        marginTop: 40,
      },
      alignItems: "center",
      display: "flex",
    },
    welcome: {
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.horizontalSpacing.related.lg,
      },
      [theme.breakpoints.only("md")]: {
        marginRight: theme.horizontalSpacing.related.md,
      },
      flex: 1,
    },
    welcomeTitle: {
      [theme.breakpoints.up("xs")]: {
        textAlign: "center",
      },
      [theme.breakpoints.up("lg")]: {
        textAlign: "left",
      },
      [theme.breakpoints.only("md")]: {
        textAlign: "left",
      },
      color: theme.palette.common.white,
    },
    ...CenteredContentClasses.create(theme),
  });

class LandingPageTemplate extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private readonly footerTheme: Theme;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.footerTheme = muiTheme(this.props.footer.foreground, true);
  }

  public render(): React.ReactNode {
    const footerContentClasses = [
      this.props.classes.footerContent,
      CenteredContentClasses.get(null, this.props.classes),
    ];

    const mainContentClasses = [
      this.props.classes.mainContent,
      CenteredContentClasses.get(null, this.props.classes),
    ];

    const topContentClasses = [
      this.props.classes.topContent,
      CenteredContentClasses.get(null, this.props.classes),
    ];

    const welcomeTitle1Classes = [
      this.props.classes.titleLight,
      this.props.classes.welcomeTitle,
    ];

    const welcomeMessage = `${this.props.welcomeTitle1} ${this.props.welcomeTitle2}`;

    return (
      <div className={this.props.classes.root}>
        <EnvironmentBanner />
        <PageProcessing />
        <Header
          backgroundColor={this.props.header.backgroundColor}
          bottomBorderColor={this.props.header.bottomBorderColor}
          foreground={this.props.header.foreground}
          layout={this.props.header.layout}
          logoImageUrl={this.props.header.logoUrl}
          logoLabel={this.props.header.logoLabel}
          logoNavigationUrl="#"
        />
        <SessionMessageDisplay />
        <main aria-label={welcomeMessage} className={this.props.classes.main}>
          <div className={this.props.classes.top}>
            <BackgroundImage url={this.props.backgroundImageUrl} />
            <div className={topContentClasses.join(" ")}>
              <div aria-hidden={true} className={this.props.classes.welcome}>
                <muiTypography.default
                  className={welcomeTitle1Classes.join(" ")}
                  variant="h3"
                >
                  {this.props.welcomeTitle1}
                </muiTypography.default>
                <muiTypography.default
                  className={this.props.classes.welcomeTitle}
                  variant="h1"
                >
                  {this.props.welcomeTitle2}
                </muiTypography.default>
              </div>
              <div className={this.props.classes.signIn}>
                <Logon
                  forgotPasswordUrl={this.props.forgotPasswordUrl}
                  title={this.props.signInTitle}
                />
              </div>
            </div>
          </div>
          <div className={mainContentClasses.join(" ")}>
            {this.props.children}
          </div>
        </main>
        <MessagesSnackbar />
        <footer
          className={this.props.classes.footer}
          style={{
            backgroundColor: this.props.footer.backgroundColor,
          }}
        >
          <KeyboardNavigationGroup
            childSelector="a"
            className={footerContentClasses.join(" ")}
          >
            <ThemeProvider theme={this.footerTheme}>
              <Layout
                config={this.props.footer.layout}
                preventClear={true}
                propagated={{ isInPageFooter: true }}
              />
            </ThemeProvider>
          </KeyboardNavigationGroup>
        </footer>
      </div>
    );
  }
}

export default withStyles(styles)(LandingPageTemplate);
