import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ICellRendererParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import { TableChildProps } from "../coreui/Table";
import { CellUtil } from "../coreui/table/CellUtil";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./Api";

interface ConfigProperties extends ICellRendererParams {
  data: PaneRow;
  propagated: TableChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  rowKeys?: string[];
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
    },
  });

@observer
export class GridRelatedEditButton extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  public static createColDef(propagated: TableChildProps) {
    return {
      cellClass: "cx-cell cx-cell-last",
      cellRendererFramework: withStyles(styles)(GridRelatedEditButton),
      cellRendererParams: { propagated },
      cellStyle: { textAlign: "center" },
      colId: "_rowEdit",
      headerClass: "cx-header-last",
      headerComponentFramework: () => (
        <div
          aria-label={Localization.getBuiltInMessage(
            "DataTable.rowActionColumnHeaderLabel"
          )}
        ></div>
      ),
      hide: true,
      resizable: false,
      sortable: false,
      suppressMovable: true,
      suppressNavigable: false,
      suppressSizeToFit: true,
      width: 50,
    };
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    if (props.eGridCell) {
      CellUtil.runOnAction(props.eGridCell, () => {
        this.openDialog();
      });
    }
  }

  private getParentTableWidgetProperties(): RuntimeProperties | null {
    const parentTable = this.props.propagated.parentTable;
    const row = PaneRow.get(parentTable.configProps.dataId);
    if (row === null) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(
      parentTable.configProps.name
    );

    const data = this.props.data || this.props.propagated.data;
    // RowKeys is populated by the App Server for only those rows which have a
    // related edit layout configured.
    const rowKeysWithEditLayout = widget.properties.rowKeys;
    const rowHasEditLayout =
      !rowKeysWithEditLayout || rowKeysWithEditLayout.indexOf(data.rowKey) > -1;

    if (!rowHasEditLayout) {
      return null;
    }

    return widget.properties;
  }

  public openDialog = () => {
    const parentTableWidgetProperties = this.getParentTableWidgetProperties();

    if (!parentTableWidgetProperties) {
      return;
    }

    const data = this.props.data || this.props.propagated.data;

    this.props.propagated.parentTable.openRowEditDialog(
      data.rowKey!,
      false,
      null
    );
  };

  public render() {
    const parentTableWidgetProperties = this.getParentTableWidgetProperties();

    if (!parentTableWidgetProperties) {
      return null;
    }

    const isEnterable =
      parentTableWidgetProperties.accessLevel >= AccessLevel.enterable;

    const altText = Localization.getBuiltInMessage(
      isEnterable ? "editDetails" : "viewDetails"
    );

    return (
      <div className={this.props.classes.root}>
        <Button
          aria-label={altText}
          color="default"
          icon={isEnterable ? "fas fa-edit" : "fas fa-search-plus"}
          onClick={this.openDialog}
          size="small"
          tabIndex={-1}
        />
      </div>
    );
  }
}

export default withStyles(styles)(GridRelatedEditButton);
