import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as muiTooltip from "@material-ui/core/Tooltip";
import * as React from "react";

interface Props extends muiTooltip.TooltipProps {}

const styles = (theme: Theme) =>
  createStyles({
    tooltip: {},
  });

export class Tooltip extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const _props = { ...this.props };

    return <muiTooltip.default {..._props} />;
  }
}

export default withStyles(styles)(Tooltip);
