import { observer } from "mobx-react";
import * as React from "react";
import ButtonLink from "../coreui/ButtonLink";
import FormData from "../coreui/FormData";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  label: string;
  name: string;
  presentationId?: number;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  anchorText: string;
  objectId: number | null;
  presentationId?: number;
}

@observer
export class DataLink extends React.Component<ConfigProperties> {
  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      runtimeProperties.accessLevel >= AccessLevel.actionable &&
      runtimeProperties.objectId !== null
    ) {
      const presentationId: number = this.props.presentationId
        ? this.props.presentationId
        : // Used when in a data grid vertical layout for DataLinkColumns
          runtimeProperties.presentationId!;

      return (
        <FormData label={this.props.label}>
          <ButtonLink
            href={`#/object/${runtimeProperties.objectId}/${presentationId}`}
            style={{ height: 20, minHeight: 20 }}
            target="_self"
          >
            {runtimeProperties.anchorText}
          </ButtonLink>
        </FormData>
      );
    }

    return (
      <FormData label={this.props.label}>
        {runtimeProperties.anchorText || "-"}
      </FormData>
    );
  }
}

export default DataLink;
