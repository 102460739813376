import * as muiCheckbox from "@material-ui/core/Checkbox";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import getFieldHelperText from "../coreui/FieldHelperText";
import FormControl, { FormControlProps } from "../coreui/FormControl";
import FormControlLabel from "../coreui/FormControlLabel";
import Icon from "../coreui/Icon";
import Presentation from "../coreui/Presentation";
import { TableChildProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import Api, { AccessLevel } from "./Api";

interface Props {
  controlKey: string | null;
  controlledPaneName: string | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  propagated?: DialogChildProps & TableChildProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  roundTripOnChange: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    ripple: {
      overflow: "visible",
    },
    root: {
      "&:hover": { backgroundColor: "transparent" },
      backgroundColor: "transparent",
      display: "inline-block",
      outline: "none",
    },
  });

@observer
export class Checkbox extends React.Component<
  Props & WithTheme & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private formControlElement: HTMLDivElement;
  private readonly helperTextId: string;
  private readonly labelId: string;

  protected checkboxProps: muiCheckbox.CheckboxProps;
  protected muiProps: FormControlProps;

  public constructor(props: Props & WithTheme & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `checkbox-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-labelled-by`;

    this.muiProps = {};

    this.muiProps.fullWidth = true;

    this.checkboxProps = {
      checkedIcon: <Icon icon="fas fa-check-square" />,
      color: "default",
      icon: <Icon icon="far fa-square" />,
      TouchRippleProps: { className: props.classes.ripple },
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  protected showSubPane(): void {
    if (!this.props.isPaneController) {
      return;
    }

    const propagated: object | undefined = this.props.propagated;
    const rowKey: string =
      propagated && propagated["rowKey"] ? propagated["rowKey"] : "";
    const controlKey: string = `${this.props.controlKey}_${rowKey}`;

    if (Presentation.getValue(this.props, false)) {
      SubPaneControlStore.showPane(controlKey, this.props.controlledPaneName!);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.showSubPane();
  }

  public componentDidUpdate(): void {
    this.showSubPane();
  }

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const runtimeProperties = Api.getWidgetProperties(
      _props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    this.muiProps.className = `${_props.classes.root} `;

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            runtimeProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (runtimeProperties.accessLevel === AccessLevel.readOnly) {
      Presentation.setBinding(this, this.checkboxProps);

      return (
        <TextField
          label={_props.label}
          name={_props.name}
          readOnly={true}
          value={
            this.checkboxProps.checked
              ? Localization.getBuiltInMessage("booleanYes")
              : "-"
          }
          variant="filled"
        />
      );
    }

    Presentation.setBinding(this, this.checkboxProps);

    if (_props.roundTripOnChange) {
      this.checkboxProps.onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => {
        ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
        Presentation.setValue(this.props, checked);

        RoundTripService.standardRoundTrip("CheckBox/OnChange", this.props, {
          dialogRowKey: this.props.propagated?.parentDialog?.rowKey,
        }).catch((reason) => {
          if (reason) {
            throw reason;
          } else {
            // If the round trip fails, undo the value change.
            Presentation.setValue(this.props, !checked);
          }
        });
        if (Sys.isMobile && Sys.isSafari) {
          this.formControlElement.focus();
        }
      };
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: () => runtimeProperties.businessErrors,
      helperText: this.props.helperText,
    });

    // VERSION_WARNING iOS 15
    // There is a bug in Safari on iOS where input elements that are nested in
    // a label get trapped in voiceover navigation after a roundtrip if focus
    // returns back to the input element.
    // The work-around implemented here is to force voice-over's focus to be
    // on the element that contains both the input and the label, and to make
    // the browser's focus be on that same element after roundtrip.
    // This condition seems to circumvent the bug.
    let labelId: string | undefined;
    let label: React.ReactNode;
    if (Sys.isMobile && Sys.isSafari) {
      labelId = this.labelId;
      label = (
        <span aria-hidden="true" id={this.labelId} tabIndex={-1}>
          {_props.label}
        </span>
      );
    } else {
      labelId = undefined;
      label = _props.label;
    }

    return (
      <FormControl
        error={fieldHelperText.hasErrors}
        FormHelperTextProps={{ style: { marginTop: 0 } }}
        helperText={fieldHelperText.helperText}
        helperTextId={this.helperTextId}
        refSetter={(element) => (this.formControlElement = element)}
        tabIndex={Sys.isMobile && Sys.isSafari ? -1 : undefined}
        {...this.muiProps}
      >
        <FormControlLabel
          aria-labelledby={labelId}
          control={
            <muiCheckbox.default
              inputProps={{
                "aria-describedby": fieldHelperText.helperText
                  ? this.helperTextId
                  : undefined,
              }}
              onBlur={() => {
                this.announceErrors(fieldHelperText.errors);
              }}
              {...this.checkboxProps}
            />
          }
          label={label}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(withTheme(Checkbox));
