import { GridApi, GridSizeChangedEvent } from "ag-grid-community";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import Grow from "../coreui/Grow";
import Icon from "../coreui/Icon";
import { TableChildProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import { FilterStandardization } from "./FilterStandardization";
import { ProjectGridVerticalHierarchyChildProps } from "./ProjectGridVerticalHierarchy";
import ToolbarInputWrapper from "./ToolbarInputWrapper";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps & ProjectGridVerticalHierarchyChildProps;
}

interface State {
  filterValue: string;
  filterVisible: boolean;
  inputFieldFocused: boolean;
}

export class GridFilter extends React.PureComponent<Props, State> {
  private buttonRef: HTMLButtonElement;
  private readonly gridApi: GridApi;
  private inputElement: HTMLInputElement;
  private lastTableWidth: number = 0;
  private setFilterTimeout: number;

  private static rowMatchesFilter(
    row: PaneRow,
    filterWords: string[],
    columns: GridColumnConfigProperties[],
    propagated: TableChildProps
  ): boolean {
    const foundWords = new Map<string, boolean>();
    for (const filterWord of filterWords) {
      foundWords.set(filterWord, false);
    }

    for (const column of columns) {
      const filterText = GridColumn.getFilterText(
        column,
        propagated,
        row
      ).toLowerCase();

      for (const filterWord of filterWords) {
        if (filterText.includes(filterWord)) {
          foundWords.set(filterWord, true);
        }
      }
    }

    for (const foundWord of foundWords.values()) {
      if (!foundWord) {
        return false;
      }
    }

    return true;
  }

  public constructor(props: Props) {
    super(props);

    if (
      props.propagated.parentTable.isProjectGrid &&
      !props.propagated.parentTable.isVerticalLayout
    ) {
      props.propagated.parentTable.setExternalFilter(this.externalFilter);
    }

    if (
      !props.propagated.parentTable.isProjectGrid ||
      !props.propagated.parentTable.isVerticalLayout
    ) {
      this.gridApi = props.propagated.parentTable.getApi();
    }

    this.state = {
      filterValue: "",
      filterVisible: false,
      inputFieldFocused: false,
    };

    if (!props.propagated.parentTable.isVerticalLayout) {
      this.gridApi.addEventListener("gridSizeChanged", this.onGridSizeChanged);
    }
  }

  private buttonOnClick = () => {
    if (this.state.filterVisible) {
      this.close();
    } else {
      this.setState({ filterVisible: true });
    }
  };

  private close() {
    this.setState(
      {
        filterValue: "",
        filterVisible: false,
        inputFieldFocused: false,
      },
      () => {
        if (this.props.propagated.parentTable.isProjectGrid) {
          if (this.props.propagated.parentTable.isVerticalLayout) {
            this.props.propagated.parentProjectGridVerticalHierarchy.onFilterChanged(
              this.externalFilter
            );
          } else {
            this.gridApi.onFilterChanged();
          }
        } else {
          this.gridApi.setQuickFilter("");
        }
      }
    );
  }

  private externalFilter = (row: PaneRow): boolean => {
    const translatedFilterValue = FilterStandardization.Standardize(
      this.state.filterValue
    );
    const filterWords: string[] = translatedFilterValue
      .split(" ")
      .filter((v) => v.length > 0);

    if (filterWords.length === 0) {
      return true;
    }

    const propagated: TableChildProps = this.props.propagated;
    const columns: GridColumnConfigProperties[] = propagated.parentTable
      .columns as GridColumnConfigProperties[];

    if (GridFilter.rowMatchesFilter(row, filterWords, columns, propagated)) {
      return true;
    }

    // Row does not match filter, check for any children that match so the
    // hierarchy is preserved during filtering
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(row.dataId!);
    const currentRowIndex: number = rows.findIndex(
      (r) => r.rowKey === row.rowKey
    );

    if (currentRowIndex < 0) {
      throw new Error(
        "GridFilter.externalFilter: Cannot find row " + "in collection"
      );
    }

    for (let i = currentRowIndex + 1; i < rows.length; ++i) {
      const candidateRow: PaneRow = rows[i];
      if (candidateRow.hierarchyLevel! <= row.hierarchyLevel!) {
        // No longer looping through the descendents of the current row
        break;
      }

      const match = GridFilter.rowMatchesFilter(
        candidateRow,
        filterWords,
        columns,
        propagated
      );

      if (match) {
        // One of the descendents matches, so include the current row in
        // the filtered set. This ensures that all the ancestors are
        // visible for any row that is included by the filter.
        return true;
      }
    }

    return false;
  };

  private onGridSizeChanged = (event: GridSizeChangedEvent) => {
    if (
      !this.state.filterVisible ||
      this.lastTableWidth === event.clientWidth
    ) {
      return;
    }

    this.lastTableWidth = event.clientWidth;

    // Filter processing is deferred so that the visible columns are up to
    // date before the filter is reapplied.
    setTimeout(() => {
      if (this.props.propagated.parentTable.isProjectGrid) {
        this.gridApi.onFilterChanged();
      } else {
        const translatedFilterValue = FilterStandardization.Standardize(
          this.state.filterValue
        );
        // Must filter on a different value to force the filter to
        // reevaluate the value.  Using a value to exclude
        // all rows for a cleaner display.
        this.gridApi.setQuickFilter("$does_not_exist$");
        this.gridApi.setQuickFilter(translatedFilterValue);
      }
    });
  };

  private onGrowEnd = (node: HTMLElement): void => {
    this.inputElement.focus();
  };

  private onGrowStart = (node: HTMLElement, isAppearing?: boolean): void => {
    node.style.visibility = "visible";
    this.inputElement.focus();
  };

  private onShrinkEnd = (node: HTMLElement): void => {
    node.style.visibility = "hidden";
  };

  private textFieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value;

    this.setState({ filterValue: value });

    window.clearTimeout(this.setFilterTimeout);
    this.setFilterTimeout = window.setTimeout(() => {
      if (this.props.propagated.parentTable.isProjectGrid) {
        if (this.props.propagated.parentTable.isVerticalLayout) {
          this.props.propagated.parentProjectGridVerticalHierarchy.onFilterChanged(
            this.externalFilter
          );
        } else {
          this.gridApi.onFilterChanged();
        }
      } else {
        const translatedFilterValue = FilterStandardization.Standardize(value);
        this.gridApi.setQuickFilter(translatedFilterValue);
      }
    }, 300);
  };

  public componentWillUnmount() {
    if (!this.props.propagated.parentTable.isVerticalLayout) {
      this.gridApi.removeEventListener(
        "gridSizeChanged",
        this.onGridSizeChanged
      );
    }
  }

  public render() {
    const depth: number = this.props.propagated.parentTable.cardDepth;

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          position: "relative",
        }}
      >
        <Grow
          in={this.state.filterVisible}
          onEnter={this.onGrowStart}
          onEntered={this.onGrowEnd}
          onExited={this.onShrinkEnd}
          style={{ visibility: "hidden" }}
          timeout={500}
        >
          <ToolbarInputWrapper
            inputElement={this.inputElement}
            onClose={() => {
              this.close();

              if (this.buttonRef) {
                this.buttonRef.focus();
              }
            }}
          >
            <TextField
              disabled={!this.state.filterVisible}
              icon="fas fa-filter"
              inputProps={{ spellCheck: false, tabIndex: -1 }}
              inputRef={(element) => (this.inputElement = element)}
              label={Localization.getBuiltInMessage("filter")}
              onBlur={() => this.setState({ inputFieldFocused: false })}
              onChange={this.textFieldOnChange}
              onFocus={() => this.setState({ inputFieldFocused: true })}
              style={{
                width: this.props.propagated.parentTable.isVerticalLayout
                  ? `calc(100vw - ${depth * 32 + 64}px)`
                  : 272,
              }}
              value={this.state.filterValue}
              variant="filled"
            />
          </ToolbarInputWrapper>
        </Grow>
        <Button
          aria-label={
            this.state.filterVisible
              ? Localization.getBuiltInMessage("close")
              : Localization.getBuiltInMessage("filter")
          }
          buttonRef={(r) => (this.buttonRef = r as HTMLButtonElement)}
          fab
          onClick={this.buttonOnClick}
          size="small"
          tabIndex={this.state.inputFieldFocused ? 0 : -1}
        >
          <Icon
            icon={this.state.filterVisible ? "fas fa-times" : "fas fa-filter"}
            style={{ marginTop: 2, width: "100%" }}
          />
        </Button>
      </div>
    );
  }
}

export default GridFilter;
