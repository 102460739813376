import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import Sys from "../core/Sys";
import Autocomplete from "../coreui/Autocomplete";
import Icon from "../coreui/Icon";
import Presentation from "../coreui/Presentation";
import Typography from "../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import BaseService from "../services/BaseService";
import LayoutStateStore from "../stores/LayoutStateStore";
import Api, { AccessLevel } from "./Api";

export interface AddressSearchCriteriaValue {
  addressValue: object | null;
  userValue: string | null;
}

export interface Props {
  dataId: string;
  geocodeApiUrlFindAddressCandidates: string;
  geocodeApiUrlSuggest: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  noResultsMessage: string;
  propagated?: object;
}

interface Option {
  isCollection?: boolean;
  magicKey: string;
  text: string;
}

interface State {
  options: Option[];
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  category: string;
  countryCode: string;
  location: number[];
  searchExtent: number[];
}

@observer
export class AddressSearchCriteria extends React.PureComponent<Props, State> {
  public static readonly widgetTypeId: number = 83;

  private accessToken: string | null = null;

  public static clear(widgetName: string, row: PaneRow): void {
    row.setProperty(widgetName, {
      addressValue: null,
      userValue: null,
    } as AddressSearchCriteriaValue);
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget: RuntimeWidget = row.getWidget(widgetName)!;
    const value = widget.value as AddressSearchCriteriaValue;

    return value.addressValue !== null;
  }

  public constructor(props: Props) {
    super(props);

    this.state = { options: [] };
  }

  private getCandidate(option: Option) {
    const paneRow = Presentation.getObservable(this.props)! as PaneRow;
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as AddressSearchCriteriaValue;

    const queryArgs = {
      category: runtimeProperties.category,
      f: "json",
      forStorage: true,
      location: runtimeProperties.location
        ? runtimeProperties.location.join(",")
        : "",
      magicKey: option.magicKey,
      maxLocations: 1,
      outFields: "*",
      searchExtent: runtimeProperties.searchExtent
        ? runtimeProperties.searchExtent.join(",")
        : "",
      SingleLine: option.text,
      sourceCountry: runtimeProperties.countryCode,
      token: this.accessToken,
    };
    const esriServiceUrl: string = `${
      this.props.geocodeApiUrlFindAddressCandidates
    }?${Sys.objectToQueryString(queryArgs)}`;

    BaseService.requestObject<object>(
      esriServiceUrl,
      {},
      {},
      {},
      "POST",
      false
    ).then((response) => {
      if (response["candidates"]) {
        Presentation.setValue(this.props, {
          addressValue: response["candidates"][0].attributes,
          userValue: value.userValue,
        } as AddressSearchCriteriaValue);
      } else if (response["error"] && response["error"].code === 498) {
        // If the token is invalid get a new one.
        this.getToken().then(() => this.getCandidate(option));
      }
    });
  }

  private getSuggestions(userValue: string) {
    const options: Option[] = [];
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as AddressSearchCriteriaValue;

    const queryArgs = {
      category: runtimeProperties.category,
      countryCode: runtimeProperties.countryCode,
      f: "json",
      location: runtimeProperties.location
        ? runtimeProperties.location.join(",")
        : "",
      maxSuggestions: 8,
      searchExtent: runtimeProperties.searchExtent
        ? runtimeProperties.searchExtent.join(",")
        : "",
      text: userValue,
      token: this.accessToken,
    };
    const serviceUrl: string = `${
      this.props.geocodeApiUrlSuggest
    }?${Sys.objectToQueryString(queryArgs)}`;

    Presentation.setValue(this.props, {
      addressValue: value.addressValue,
      userValue,
    } as AddressSearchCriteriaValue);

    BaseService.requestObject<object>(serviceUrl, {}, {}, {}, "POST", false)
      .then((response) => {
        if (response["suggestions"]) {
          for (const suggestion of response["suggestions"]) {
            if (!suggestion.isCollection) {
              options.push({
                magicKey: suggestion.magicKey,
                text: suggestion.text,
              });
            }
          }

          this.setState({ options });
        } else {
          this.setState({ options });

          if (response["error"] && response["error"].code === 498) {
            // If the token is invalid get a new one.
            this.getToken().then(() => this.getSuggestions(userValue));
          }
        }
      })
      .catch(() => this.setState({ options }));
  }

  private getToken(): RequestPromise<void | object> {
    const paneRow = Presentation.getObservable(this.props)! as PaneRow;
    const serviceUrl: string = `AddressSearch/GetToken/${paneRow.rowKey}/${this.props.dataId}/${this.props.name}`;

    return BaseService.requestObject<object>(
      serviceUrl,
      {},
      {},
      {
        appServerState: AppServer.getState(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST",
      false
    ).then((response) => {
      this.accessToken = response["accessToken"];
    });
  }

  private onInputChange = (value: string) => {
    this.getSuggestions(value);
  };

  private onValueChange = (option: Option | null) => {
    if (option) {
      this.getCandidate(option);
    } else {
      Presentation.setValue(this.props, {
        addressValue: null,
        userValue: null,
      } as AddressSearchCriteriaValue);
    }
  };

  public render() {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    if (!row) {
      return null;
    }

    const widget = row.getWidget(this.props.name);
    const value = widget.value as AddressSearchCriteriaValue;

    const { helperText, label, mandatory, name, noResultsMessage } = this.props;
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.accessToken) {
      this.getToken();
    }

    return (
      <Autocomplete
        autoComplete
        autoSelect
        filterOptions={(options: Option[]) => options}
        forcePopupIcon={false}
        getOptionLabel={(option: Option) => option.text}
        getOptionSelected={(option: Option, optionValue: Option) =>
          option.text === optionValue.text
        }
        helperText={helperText}
        inputValue={value.userValue || ""}
        label={label}
        name={name}
        noOptionsText={noResultsMessage}
        onInputChange={this.onInputChange}
        onValueChange={this.onValueChange}
        options={this.state.options}
        renderOption={(option: Option) => {
          return (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                minHeight: 40,
                width: "100%",
              }}
            >
              <Icon
                icon="fas fa-map-marker-alt"
                style={{ marginRight: ".4em" }}
              />
              <Typography ellipsis>{option.text}</Typography>
            </div>
          );
        }}
        required={mandatory}
      />
    );
  }
}

export default AddressSearchCriteria;
