import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import {
  CustomTheme,
  getPanelMeasurements,
  PanelMeasurements,
} from "../../muiTheme";

export default class PaperClasses {
  private static readonly supportedColumnWidths = [3, 4, 5, 6, 8];

  public static create(theme: CustomTheme): {} {
    const paperClasses = {
      "paper-alt-color-lg": {
        [theme.breakpoints.up("lg")]: {
          backgroundColor: theme.palette.common.white,
        },
      },
      "paper-color-lg": {
        [theme.breakpoints.up("lg")]: {
          backgroundColor: theme.palette.grey[100],
        },
      },
      "paper-lg": {
        [theme.breakpoints.up("lg")]: {
          marginLeft: "auto",
          marginRight: "auto",
          padding: theme.paper.padding.lg,
        },
      },
      "paper-md": {
        [theme.breakpoints.only("md")]: {
          marginLeft: "auto",
          marginRight: "auto",
          padding: theme.paper.padding.md,
        },
      },
      "paper-sm": {
        [theme.breakpoints.only("sm")]: {
          marginLeft: "auto",
          marginRight: "auto",
          padding: theme.paper.padding.sm,
        },
      },
      "paper-xs": {
        [theme.breakpoints.only("xs")]: {
          marginLeft: theme.horizontalSpacing.related.xs,
          marginRight: theme.horizontalSpacing.related.xs,
          padding: theme.paper.padding.xs,
        },
      },
    };

    for (const breakPoint of ["md", "sm", "xs"] as Breakpoint[]) {
      paperClasses[`paper-color-${breakPoint}`] = {
        [theme.breakpoints.only(breakPoint)]: {
          backgroundColor: theme.palette.grey[100],
        },
      };
      paperClasses[`paper-alt-color-${breakPoint}`] = {
        [theme.breakpoints.only(breakPoint)]: {
          backgroundColor: theme.palette.common.white,
        },
      };
    }

    for (const breakPoint of theme.widthBreakPoints) {
      const measurements: PanelMeasurements = getPanelMeasurements(
        theme,
        breakPoint
      );

      for (const columns of PaperClasses.supportedColumnWidths) {
        const gutters = columns - 1;
        const gutterWidth = gutters * measurements.gutterWidth;
        const columnWidth = columns * measurements.columnWidth;
        const width: number = columnWidth + gutterWidth;

        if (breakPoint === "lg") {
          paperClasses[`paper-lg-${columns}`] = {
            [theme.breakpoints.up("lg")]: { width },
          };
        } else {
          paperClasses[`paper-${breakPoint}-${columns}`] = {
            [theme.breakpoints.only(breakPoint)]: { width },
          };
        }
      }
    }

    return paperClasses;
  }

  public static get(
    breakPoint: Breakpoint,
    columns: 4 | 5 | 6,
    useDefaultColor: boolean,
    classes: {}
  ): string {
    const breakPointToUse = breakPoint === "xl" ? "lg" : breakPoint;
    const paperClasses = [
      `paper-${breakPointToUse}`,
      `paper-${breakPointToUse}-${columns}`,
    ];

    if (useDefaultColor) {
      paperClasses.push(`paper-color-${breakPointToUse}`);
    } else {
      paperClasses.push(`paper-alt-color-${breakPointToUse}`);
    }

    return paperClasses.map((c) => classes[c]).join(" ");
  }
}
