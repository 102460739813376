import { WithTheme, withTheme } from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import Icon from "../coreui/Icon";
import Menu from "../coreui/Menu";
import MenuItem from "../coreui/MenuItem";
import { TableChildProps } from "../coreui/Table";
import { GridColumnConfigProperties } from "./Columns/GridColumn";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  buttonElement?: HTMLElement;
  sortModel?: { colId: string; sort: string };
}

export class TableSort extends React.PureComponent<Props & WithTheme, State> {
  private readonly sortColumns: GridColumnConfigProperties[];

  public constructor(props: Props & WithTheme) {
    super(props);

    this.state = {
      sortModel: { colId: "", sort: "" },
    };

    this.sortColumns = [];
    for (const column of this.props.propagated.parentTable.columns) {
      const gridColumn = column as GridColumnConfigProperties;
      if (gridColumn.xs) {
        this.sortColumns.push(gridColumn);
      }
    }
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        buttonElement: prevState.buttonElement ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ buttonElement: undefined });
  };

  private sort(colId: string, direction: string) {
    const sortModel = { colId, sort: direction };

    this.props.propagated.parentTable.getApi().setSortModel([sortModel]);
    this.setState({ sortModel });
    this.props.propagated.parentTable.updateRowHeight();

    this.setState({ buttonElement: undefined });
  }

  public render(): React.ReactNode {
    const menuItems: React.ReactNode[] = [];
    for (const column of this.sortColumns) {
      const sortModel = this.state.sortModel;
      const isSelected = sortModel!.colId === column.name;

      let direction = column.sortDescending ? "desc" : "asc";
      let iconColor = this.props.theme.palette.grey[200];
      let icon = "fas fa-sort";

      if (isSelected) {
        direction = sortModel!.sort === "asc" ? "desc" : "asc";
        iconColor = this.props.theme.palette.grey[800];
        icon =
          sortModel!.sort === "asc" ? "fas fa-sort-up" : "fas fa-sort-down";
      }

      menuItems.push(
        <MenuItem
          key={column.name}
          onClick={() => this.sort(column.name, direction)}
          selected={false}
        >
          <Icon
            fixedWidth
            icon={icon}
            style={{ color: iconColor, marginRight: ".4em" }}
          />
          {column.header}
        </MenuItem>
      );
    }

    return (
      <React.Fragment>
        <Button
          aria-label={Localization.getBuiltInMessage("sort")}
          icon="fas fa-sort"
          size="small"
          onClick={this.onClick}
          tabIndex={-1}
        />
        <Menu
          anchorEl={this.state.buttonElement}
          onClose={this.onCloseMenu}
          variant="menu"
        >
          {menuItems}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withTheme(TableSort);
