import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../core/Sys";
import Grid from "../coreui/Grid";
import GridItem from "../coreui/GridItem";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import { AccessLevel, ValueByBreakpoint } from "./Api";
import MustangGrid from "./Grid";

interface Props {
  contentsGridWidth: ValueByBreakpoint<number>;
  items: object[];
  media: MediaWidgetProps;
  mediaGrouping: MediaGrouping;
  orientation: Orientation;
  propagated: object;
}

interface Orientation {
  lg: "Left" | "Top" | null;
  md: "Left" | "Top" | null;
  sm: "Left" | "Top" | null;
  xs: "Left" | "Top" | null;
}

export interface MediaChild {
  parentMediaOrientation?: "Left" | "Top" | null;
}

interface MediaGrouping {
  lg: "Normal" | "Tight" | null;
  md: "Normal" | "Tight" | null;
  sm: "Normal" | "Tight" | null;
  xs: "Normal" | "Tight" | null;
}

interface MediaWidgetProps {
  props: RuntimeProperties;
  type: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  dataId: string;
  name: string;
}

const styles = (theme: Theme) =>
  createStyles({
    content: {
      minWidth: "100px",
    },
    lgleft: {
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        flexWrap: "nowrap",
      },
    },
    lgleftcontent: {
      [theme.breakpoints.up("lg")]: {
        alignSelf: "center",
        marginLeft: Sys.settings.baselineGridSize * 10,
      },
    },
    lgtop: {
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        flexDirection: "column",
      },
    },
    lgtopcontent: {
      [theme.breakpoints.up("lg")]: {
        "& > div": {
          justifyItems: "center",
        },
        alignSelf: "stretch",
        marginTop: Sys.settings.baselineGridSize * 6,
      },
    },
    mdleft: {
      [theme.breakpoints.only("md")]: {
        flexDirection: "row",
        flexWrap: "nowrap",
      },
    },
    mdleftcontent: {
      [theme.breakpoints.only("md")]: {
        alignSelf: "center",
        marginLeft: Sys.settings.baselineGridSize * 6,
      },
    },
    mdtop: {
      [theme.breakpoints.only("md")]: {
        alignItems: "center",
        flexDirection: "column",
      },
    },
    mdtopcontent: {
      [theme.breakpoints.only("md")]: {
        "& > div": {
          justifyItems: "center",
        },
        alignSelf: "stretch",
        marginTop: Sys.settings.baselineGridSize * 6,
      },
    },
    root: {
      maxWidth: "100%",
    },
    smleft: {
      [theme.breakpoints.only("sm")]: {
        flexDirection: "row",
        flexWrap: "nowrap",
      },
    },
    smleftcontent: {
      [theme.breakpoints.only("sm")]: {
        alignSelf: "center",
        marginLeft: Sys.settings.baselineGridSize * 6,
      },
    },
    smtop: {
      [theme.breakpoints.only("sm")]: {
        alignItems: "center",
        flexDirection: "column",
      },
    },
    smtopcontent: {
      [theme.breakpoints.only("sm")]: {
        "& > div": {
          justifyItems: "center",
        },
        alignSelf: "stretch",
        marginTop: Sys.settings.baselineGridSize * 6,
      },
    },
    xsleft: {
      [theme.breakpoints.only("xs")]: {
        flexDirection: "row",
        flexWrap: "nowrap",
      },
    },
    xsleftcontent: {
      [theme.breakpoints.only("xs")]: {
        alignSelf: "center",
        marginLeft: Sys.settings.baselineGridSize * 4,
      },
    },
    xstop: {
      [theme.breakpoints.only("xs")]: {
        alignItems: "center",
        flexDirection: "column",
      },
    },
    xstopcontent: {
      [theme.breakpoints.only("xs")]: {
        "& > div": {
          justifyItems: "center",
        },
        alignSelf: "stretch",
        marginTop: Sys.settings.baselineGridSize * 4,
      },
    },
  });

@observer
export class Media extends React.PureComponent<
  Props & WithStyles<typeof styles> & WithWidth
> {
  private shouldRender(): boolean {
    const mediaWidgetProperties = this.props.media;

    const runtimeProperties = PaneRow.getWidgetProperties(
      mediaWidgetProperties.props.dataId,
      mediaWidgetProperties.props.name
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return true;
    }

    if (runtimeProperties.accessLevel >= AccessLevel.disabled) {
      return true;
    }

    return false;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    const rootClasses: string[] = [this.props.classes.root];
    const contentClasses: string[] = [this.props.classes.content];

    const breakPoints: Breakpoint[] = ["xs", "sm", "md", "lg"];
    for (const breakPoint of breakPoints) {
      const orientation = this.props.orientation[breakPoint];
      if (orientation) {
        const className = `${breakPoint}${orientation.toLowerCase()}`;

        rootClasses.push(this.props.classes[className]);
        contentClasses.push(this.props.classes[`${className}content`]);
      }
    }

    const gridGrouping =
      this.props.mediaGrouping[this.props.width] === "Normal"
        ? "Closely Related"
        : this.props.mediaGrouping[this.props.width];

    const propagated: MediaChild = {
      ...this.props.propagated,
      parentMediaOrientation: this.props.orientation[this.props.width],
    };

    return (
      <Grid className={rootClasses.join(" ")}>
        <GridItem>{Presentation.create(this.props.media, propagated)}</GridItem>
        {this.props.items.length > 0 ? (
          <GridItem
            className={contentClasses.join(" ")}
            style={{ flex: "1 1 0%" }}
          >
            <MustangGrid
              grouping={gridGrouping}
              lg={this.props.contentsGridWidth["lg"]}
              md={this.props.contentsGridWidth["md"]}
              sm={this.props.contentsGridWidth["sm"]}
              xs={this.props.contentsGridWidth["xs"]}
            >
              {this.props.items.map((item, index) => (
                <React.Fragment key={index}>
                  {Presentation.create(item, propagated)}
                </React.Fragment>
              ))}
            </MustangGrid>
          </GridItem>
        ) : null}
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(Media));
