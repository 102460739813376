import grey from "@material-ui/core/colors/grey";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import { EditMask as EditMaskBase } from "../../coreui/EditMask";
import ErrorBadge from "../../coreui/ErrorBadge";
import Icon from "../../coreui/Icon";
import MultilineTextFieldDialog from "../../coreui/MultilineTextFieldDialog";
import Presentation from "../../coreui/Presentation";
import { TableChildProps } from "../../coreui/Table";
import {
  CellFocusUtil,
  FocusCellRendererParams,
} from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import { RuntimeWidget } from "../../models/PaneRow";
import Api, { AccessLevel } from "../Api";
import { FunctionName } from "../TableSummary";

interface ConfigProperties extends FocusCellRendererParams {
  dataId: string;
  editMask?: string;
  justification?: "Left" | "Right";
  name: string;
  propagated: TableChildProps;
  showEllipsis?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  bodyText: string[];
}

interface State {
  isDialogOpen: boolean;
  isErrorBadgeOpen?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    edit: {
      color: theme.palette.grey[300],
      textAlign: "left",
    },
    expandButton: {
      backgroundColor: theme.palette.common.white,
    },
  });

@observer
export class TextColumn extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private readonly errorMessageId: string;
  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      if (data.value !== null && typeof data.value !== "string") {
        throw new Error(`Unexpected data type ${typeof data.value}`);
      }

      const runtimeProperties = data.properties as RuntimeProperties;

      const value: string | null = runtimeProperties.bodyText
        ? runtimeProperties.bodyText.join("\n")
        : data.value;

      if (value) {
        result += 1;
      }
    }

    return result.toString();
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = { isDialogOpen: false, isErrorBadgeOpen: false };

    CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
      props,
      this.onCellFocus,
      this.onCellBlur
    );

    this.componentId = `text-column-${Sys.nextId}`;
    this.errorMessageId = `${this.componentId}-error-message`;

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellBlur = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onCellFocus = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    if (
      (event.key === "Enter" || event.key === " ") &&
      !this.props.column!.isCellEditable(this.props.node)
    ) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({ isDialogOpen: true });

      return;
    }

    if (event.key === " " && !this.props.api.getEditingCells().length) {
      this.props.api.startEditingCell({
        colKey: this.props.column!.getColId(),
        rowIndex: this.props.rowIndex,
      });

      event.preventDefault();
      event.stopPropagation();
    }

    if (
      event.key === "Tab" &&
      !!this.props.api.getEditingCells().length &&
      this.props.colDef?.cellEditorFramework.widgetType !== "DateEditColumn" &&
      this.props.colDef?.cellEditorFramework.widgetType !== "MLTextEditColumn"
    ) {
      this.props.api.stopEditing();

      if (event.shiftKey) {
        this.props.api.tabToPreviousCell();
      } else {
        this.props.api.tabToNextCell();
      }

      event.preventDefault();
      event.stopPropagation();
    }

    CellUtil.customizeGridNavigation(event, this.props);
  };

  private onCloseErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onDialogCancel = (): void => {
    this.setState({ isDialogOpen: false });
  };

  private onOpenErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  private showDialog = (): void => {
    this.setState({ isDialogOpen: true });
  };

  public componentWillUnmount(): void {
    CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
      this.props,
      this.onCellFocus,
      this.onCellBlur
    );
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const _props = { ...this.props };
    const runtimeProperties = Api.getWidgetProperties(
      _props,
      _props.data
    ) as RuntimeProperties;
    let rowErrors:
      | string
      | undefined = _props.propagated.parentTable
      .getTable()
      .rowErrorMessages.get(_props.data.rowKey);

    if (!runtimeProperties) {
      return null;
    }

    CellUtil.setReadOnlyAttribute(
      _props.eGridCell,
      runtimeProperties.accessLevel <= AccessLevel.actionable
    );

    let expandButton: React.ReactNode = null;
    let expandButtonDialog: React.ReactNode = null;
    let value: string | null = runtimeProperties.bodyText
      ? runtimeProperties.bodyText.join("\n")
      : _props.value;
    const errors: string[] = _props.colDef!.cellEditorFramework
      ? _props.colDef!.cellEditorFramework.getErrors(
          _props.colDef!.cellEditorParams,
          _props.data,
          value
        )
      : [];

    value =
      _props.editMask && value
        ? EditMaskBase.formatValue(_props.editMask, value)
        : value;

    if (value && !_props.showEllipsis) {
      const columnWidth = _props.eGridCell.getBoundingClientRect().width;
      const textWidth: number = Presentation.measureText(value);

      // If its enterable we need to ajust the size for the pencil.
      if (
        (runtimeProperties.accessLevel >= AccessLevel.enterable &&
          textWidth > columnWidth - 67) ||
        (runtimeProperties.accessLevel < AccessLevel.enterable &&
          textWidth > columnWidth - 50)
      ) {
        expandButton = (
          <Button
            aria-hidden
            className={_props.classes.expandButton}
            customSize="tiny"
            icon="fas fa-ellipsis-h"
            onClick={this.showDialog}
            size="small"
            tabIndex={-1}
          />
        );

        expandButtonDialog = (
          <MultilineTextFieldDialog
            label={_props.colDef!.headerName}
            onCancel={this.onDialogCancel}
            open={this.state.isDialogOpen}
            readOnly={true}
            value={value}
          />
        );
      }
    }

    let result: React.ReactNode = null;

    if (!_props.justification) {
      _props.justification = "Left";
    }

    if (runtimeProperties.accessLevel >= AccessLevel.enterable) {
      const entryHint: React.ReactNode = value ? null : (
        <div className={_props.classes.edit}>
          {Localization.getBuiltInMessage("editDetailsEllipses")}
        </div>
      );

      if (errors.length) {
        CellUtil.setAriaAttributes(
          this.props.eGridCell,
          this.errorMessageId,
          true
        );

        rowErrors = `${errors.join(". ")}. ${rowErrors || ""}`;

        result = (
          <ErrorBadge
            isShort={
              _props.data.isNew && _props.propagated.parentTable.isDocumentGrid
            }
            message={Api.getErrorMessages(errors)}
            onClose={this.onCloseErrorBadge}
            onOpen={this.onOpenErrorBadge}
            open={this.state.isErrorBadgeOpen}
            suppressEdit={true}
          >
            <div
              aria-describedby={this.errorMessageId}
              aria-errormessage={this.errorMessageId}
              aria-invalid="true"
              role="textbox"
              style={{
                alignItems: "center",
                display: "flex",
                flex: "auto",
                height: "100%",
                margin: "0 24px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flex: "auto",
                  lineHeight: "normal",
                  overflow: "hidden",
                  textAlign: Api.getAlignment(_props.justification),
                  textOverflow: _props.showEllipsis ? "ellipsis" : "clip",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
                {entryHint}
              </div>
              {expandButton}
              {expandButtonDialog}
              <div className="cx-print">
                <Icon
                  fixedWidth
                  icon="fas fa-pencil"
                  style={{
                    color: grey[800],
                    fontSize: 12,
                    marginLeft: 8,
                  }}
                />
              </div>
            </div>
            <div id={this.errorMessageId} style={{ display: "none" }}>
              {rowErrors}
            </div>
          </ErrorBadge>
        );
      } else {
        CellUtil.setAriaAttributes(
          this.props.eGridCell,
          this.errorMessageId,
          !!rowErrors
        );

        result = (
          <div
            aria-describedby={rowErrors ? this.errorMessageId : undefined}
            aria-errormessage={rowErrors ? this.errorMessageId : undefined}
            aria-invalid={rowErrors ? true : false}
            role="textbox"
            style={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              margin: "0 24px",
            }}
          >
            <div
              style={{
                flex: "auto",
                lineHeight: "normal",
                overflow: "hidden",
                textAlign: Api.getAlignment(_props.justification),
                textOverflow: _props.showEllipsis ? "ellipsis" : "clip",
                whiteSpace: "nowrap",
              }}
            >
              {value}
              {entryHint}
            </div>
            {expandButton}
            {expandButtonDialog}
            <div className="cx-print">
              <Icon
                fixedWidth
                icon="fas fa-pencil"
                style={{
                  color: grey[800],
                  fontSize: 12,
                  height: "auto",
                  marginLeft: 8,
                }}
              />
            </div>
            <div id={this.errorMessageId} style={{ display: "none" }}>
              {rowErrors}
            </div>
          </div>
        );
      }
    } else {
      CellUtil.setAriaAttributes(
        this.props.eGridCell,
        this.errorMessageId,
        !!rowErrors
      );

      result = (
        <div
          aria-describedby={rowErrors ? this.errorMessageId : undefined}
          aria-errormessage={rowErrors ? this.errorMessageId : undefined}
          aria-invalid={rowErrors ? true : false}
          role="textbox"
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            margin: "0px 24px",
          }}
        >
          <div
            style={{
              lineHeight: "normal",
              overflow: "hidden",
              textAlign: Api.getAlignment(_props.justification),
              textOverflow: _props.showEllipsis ? "ellipsis" : "clip",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            {value}
          </div>
          {expandButton}
          {expandButtonDialog}
          <div id={this.errorMessageId} style={{ display: "none" }}>
            {rowErrors}
          </div>
        </div>
      );
    }

    return result;
  }
}

export default withStyles(styles)(TextColumn);
