import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import ButtonLink from "../../coreui/ButtonLink";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import Typography from "../../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import Api, { AccessLevel } from "../Api";
import { FunctionName } from "../TableSummary";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface ConfigProperties extends WidgetProperties, ICellRendererParams {}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  anchorText: string;
  sortIndex: number | null;
  url: string;
}

export interface WidgetProperties {
  alternateText: string;
  dataId: string;
  header: string;
  iconName: string;
  linkType: "External" | "Internal";
  name: string;
  sortDescending: boolean;
  sortEnabled: boolean;
  width: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      margin: "0 24px",
    },
    text: {
      fontSize: 14,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
  });

export class ManualLinkColumn extends React.PureComponent<
  ConfigProperties & WithStyles
> {
  public static readonly widgetType = "ManualLinkColumn";

  private linkRef = React.createRef<HTMLButtonElement>();

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidget(column.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    return runtimeProps.anchorText;
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      const runtimeProperties = data.properties as RuntimeProperties;

      if (runtimeProperties.accessLevel >= AccessLevel.actionable) {
        result += 1;
      }
    }

    return result.toString();
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidget(props.column.name);
    const runtimeProps = widget.properties as RuntimeProperties;
    const configProps = props.column.widgetProps as WidgetProperties;

    if (runtimeProps.accessLevel <= AccessLevel.disabled) {
      return null;
    }

    if (runtimeProps.accessLevel === AccessLevel.readOnly) {
      return (
        <Typography className={props.className} variant="body1">
          {runtimeProps.anchorText}
        </Typography>
      );
    }

    return (
      <ButtonLink
        aria-label={configProps.alternateText}
        className={props.className}
        href={runtimeProps.url}
        iconName={configProps.iconName}
        onClick={(e) => e.stopPropagation()}
        target={configProps.linkType === "Internal" ? "_self" : "_blank"}
      >
        {runtimeProps.anchorText}
      </ButtonLink>
    );
  }

  public constructor(props: ConfigProperties & WithStyles) {
    super(props);

    CellUtil.setReadOnlyAttribute(props.eGridCell, true);
    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
    props.eGridCell.addEventListener("focus", this.onCellFocus);
  }

  private onCellFocus = (): void => {
    if (this.linkRef.current) {
      this.linkRef.current.focus();
    }
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("focus", this.onCellFocus);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props,
      this.props.data
    ) as RuntimeProperties;
    let text: React.ReactNode = null;
    let result: React.ReactNode = null;

    if (runtimeProperties.anchorText) {
      text = (
        <div className={this.props.classes.text}>
          {runtimeProperties.anchorText}
        </div>
      );
    }

    switch (runtimeProperties.accessLevel) {
      case AccessLevel.actionable:
        result = (
          <ButtonLink
            aria-label={this.props.alternateText}
            buttonRef={this.linkRef}
            focusRipple={false}
            href={runtimeProperties.url}
            iconName={this.props.iconName}
            tabIndex={-1}
            target={this.props.linkType === "Internal" ? "_self" : "_blank"}
          >
            {text}
          </ButtonLink>
        );
        break;
      case AccessLevel.readOnly:
        result = text;
        break;
      default:
        break;
    }

    if (result) {
      result = <div className={this.props.classes.root}>{result}</div>;
    }

    return result;
  }
}

export default withStyles(styles)(ManualLinkColumn);
