import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Zoom from "@material-ui/core/Zoom";
import { useObserver } from "mobx-react-lite";
import * as React from "react";
import { Layout, LayoutChildProps, LayoutConfig } from "../../config/Layout";
import { SiteSearchInfo } from "../../config/SearchPresentation";
import Sys from "../../core/Sys";
import multiClassName from "../../coreui/MultiClassName";
import muiTheme, { CustomTheme, ForegroundColorType } from "../../muiTheme";
import CenteredContentClasses from "../styles/CenteredContentClasses";
import Logo from "./Logo";
import * as SiteCriteria from "./SiteCriteria";

export interface HeaderChildProps extends LayoutChildProps {
  parentHeader: {
    siteCriteria: {
      isExpanded: boolean;
      onCollapse: () => void;
      onExpand: (siteSearches: SiteSearchInfo[]) => void;
    };
  };
}

interface Props {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  layout: LayoutConfig;
  logoImageUrl: string;
  logoNavigationUrl: string;
  logoLabel: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  content: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
  },
  root: {
    [theme.breakpoints.only("xs")]: {
      height: 72,
    },
    [theme.breakpoints.only("sm")]: {
      height: 88,
    },
    [theme.breakpoints.only("md")]: {
      height: 108,
    },
    [theme.breakpoints.up("lg")]: {
      height: 140,
    },
    "@media print": {
      display: "none",
    },
    borderBottomStyle: "solid",
    borderBottomWidth: 4,
  },
  toolbar: {
    flex: "auto",
  },
  toolbarSiteCriteria: {
    alignItems: "center",
    display: "grid",
    gridColumnGap: theme.horizontalSpacing.widget.related,
    gridTemplateColumns: "270px 1fr auto",
  },
  ...CenteredContentClasses.create(theme),
}));

export default function Header(props: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const renderSiteCriteriaInToolbar = useMediaQuery(theme.breakpoints.up("md"));

  const [searchCriteria, setSearchCriteria] = React.useState<string>("");
  const [
    selectedSearch,
    setSelectedSearch,
  ] = React.useState<SiteSearchInfo | null>(null);
  const [
    siteCriteriaInToolbarIsVisible,
    setSiteCriteriaInToolbarIsVisible,
  ] = React.useState<boolean>(false);
  const [siteCriteriaIsExpanded, setSiteCriteriaIsExpanded] = React.useState<
    boolean
  >(false);
  const [siteSearches, setSiteSearches] = React.useState<SiteSearchInfo[]>([]);

  const onSiteCriteriaCollapse = (): void => {
    setSiteCriteriaIsExpanded(false);
  };

  const onSiteCriteriaExpand = (newSiteSearches: SiteSearchInfo[]): void => {
    setSiteCriteriaIsExpanded(true);
    setSiteSearches(newSiteSearches);

    let searchIsSelected: boolean = false;
    if (selectedSearch !== null) {
      const candidateSearches: SiteSearchInfo[] = newSiteSearches.filter(
        (s) => s.url === selectedSearch.url
      );
      if (candidateSearches.length > 0) {
        setSelectedSearch(candidateSearches[0]);
        searchIsSelected = true;
      }
    }

    if (!searchIsSelected) {
      const defaultSearches: SiteSearchInfo[] = newSiteSearches.filter(
        (s) => s.isDefault
      );
      if (defaultSearches.length > 0) {
        setSelectedSearch(defaultSearches[0]);
      } else {
        setSelectedSearch(newSiteSearches[0]);
      }
    }

    setSearchCriteria("");
  };

  const onSiteCriteriaSearch = (): void => {
    if (selectedSearch === null) {
      return;
    }

    const baseUrl: string = selectedSearch.url.substr(1);
    const queryString = Sys.objectToQueryString({
      PosseAutoExecute: "Y",
      SiteCriteria: searchCriteria,
    });

    Sys.setHash(`${baseUrl}?${queryString}`);
    setSiteCriteriaIsExpanded(false);
  };

  const propagated: HeaderChildProps = {
    parentHeader: {
      siteCriteria: {
        isExpanded: siteCriteriaIsExpanded,
        onCollapse: onSiteCriteriaCollapse,
        onExpand: onSiteCriteriaExpand,
      },
    },
  };

  return useObserver(() => (
    <React.Fragment>
      <header
        className={classes.root}
        style={{
          backgroundColor: props.backgroundColor,
          borderColor: props.bottomBorderColor,
        }}
      >
        <div
          className={multiClassName(
            classes.content,
            CenteredContentClasses.get(null, classes)
          )}
        >
          <ThemeProvider theme={muiTheme(props.foreground, true)}>
            <Logo
              href={props.logoNavigationUrl}
              imageSrc={props.logoImageUrl}
              label={props.logoLabel}
            />
            <div className={classes.toolbar}>
              <Zoom
                in={
                  renderSiteCriteriaInToolbar &&
                  siteCriteriaIsExpanded &&
                  siteCriteriaInToolbarIsVisible
                }
                mountOnEnter={true}
                onExited={() => setSiteCriteriaInToolbarIsVisible(false)}
                unmountOnExit={true}
              >
                <div className={classes.toolbarSiteCriteria}>
                  <SiteCriteria.SearchSelect
                    onSelect={setSelectedSearch}
                    selectedSearch={selectedSearch}
                    siteSearches={siteSearches}
                  />
                  <SiteCriteria.SearchCriteriaField
                    helperText={selectedSearch?.helperText}
                    onCriteriaChange={setSearchCriteria}
                    onSearch={onSiteCriteriaSearch}
                    mandatory={selectedSearch?.mandatory}
                    value={searchCriteria}
                  />
                  <SiteCriteria.CollapseButton
                    onCollapse={onSiteCriteriaCollapse}
                  />
                </div>
              </Zoom>
              <Fade
                in={
                  !(
                    renderSiteCriteriaInToolbar &&
                    (siteCriteriaIsExpanded || siteCriteriaInToolbarIsVisible)
                  )
                }
                mountOnEnter={true}
                onExited={() => setSiteCriteriaInToolbarIsVisible(true)}
                unmountOnExit={true}
              >
                <div>
                  <Layout
                    config={props.layout}
                    preventClear={true}
                    propagated={propagated}
                  />
                </div>
              </Fade>
            </div>
          </ThemeProvider>
        </div>
      </header>
      {!renderSiteCriteriaInToolbar && siteCriteriaIsExpanded && (
        <Collapse in={siteCriteriaIsExpanded}>
          <SiteCriteria.SearchSelect
            onSelect={setSelectedSearch}
            selectedSearch={selectedSearch}
            siteSearches={siteSearches}
          />
          <SiteCriteria.SearchCriteriaField
            helperText={selectedSearch?.helperText}
            onCriteriaChange={setSearchCriteria}
            onSearch={onSiteCriteriaSearch}
            mandatory={selectedSearch?.mandatory}
            value={searchCriteria}
          />
        </Collapse>
      )}
    </React.Fragment>
  ));
}
