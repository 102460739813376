import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "./Button";
import CalendarDialog from "./CalendarDialog";
import TextField from "./TextField";

interface Props {
  dateFormatError: string;
  error?: boolean;
  label: string;
  onChange: (value: Date | null) => void;
  onEnterKeyPress?: () => void;
  onInvalidEntry?: (entry: string) => void;
  value: Date | null;
}

interface State {
  calendarOpen: boolean;
  hasInvalidInput: boolean;
  pendingValue: string;
}

export class DatePicker extends React.PureComponent<Props, State> {
  private calendarHasFocus: boolean;

  private static datesAreEquivalent(a: Date | null, b: Date | null): boolean {
    if (a === null && b === null) {
      return true;
    }

    if (a === null || b === null) {
      return false;
    }

    return a.valueOf() === b.valueOf();
  }

  public constructor(props: Props) {
    super(props);

    this.calendarHasFocus = false;

    let dateString: string = "";
    if (this.props.value !== null) {
      dateString = Localization.formatDate(this.props.value);
    }

    this.state = {
      calendarOpen: false,
      hasInvalidInput: false,
      pendingValue: dateString,
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private flushInput(): void {
    let date: Date | null = null;
    const dateString: string = this.state.pendingValue;
    if (dateString.length > 0) {
      date = Localization.parseDate(dateString);
      if (date === null) {
        this.setState({ hasInvalidInput: true });

        if (this.props.onInvalidEntry) {
          this.props.onInvalidEntry(dateString);
        }

        return;
      }
    }

    if (!DatePicker.datesAreEquivalent(this.props.value, date)) {
      this.props.onChange(date);
    }
  }

  private getErrors(): string[] {
    const errors = [];

    if (this.state.hasInvalidInput) {
      errors.push(this.props.dateFormatError);
    }

    return errors;
  }

  private onCalendarClose = (): void => {
    this.setState({ calendarOpen: false });
  };

  private onCalendarDateSelected = (date: Date): void => {
    this.setState({ hasInvalidInput: false });

    if (!DatePicker.datesAreEquivalent(this.props.value, date)) {
      this.props.onChange(date);
    }
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      hasInvalidInput: false,
      pendingValue: event.target.value,
    });
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter" || this.calendarHasFocus) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.props.onEnterKeyPress) {
      this.flushInput();
      this.props.onEnterKeyPress();
    }
  };

  private onOpenCalendar = (): void => {
    this.setState({ calendarOpen: true });
  };

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (!DatePicker.datesAreEquivalent(prevProps.value, this.props.value)) {
      let dateString: string = "";
      if (this.props.value !== null) {
        dateString = Localization.formatDate(this.props.value);
      }

      this.setState({ pendingValue: dateString });
    }
  }

  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <TextField
          endAdornment={
            <Button
              aria-label={Localization.getBuiltInMessage(
                "DateEdit.selectDateButtonLabel",
                {
                  datePickerLabel: this.props.label,
                }
              )}
              icon="fas fa-calendar-alt"
              onBlur={() => {
                this.calendarHasFocus = false;
              }}
              onClick={this.onOpenCalendar}
              onFocus={() => {
                this.calendarHasFocus = true;
              }}
              size="small"
            />
          }
          error={this.props.error}
          getErrors={(v) => this.getErrors()}
          InputProps={{
            placeholder: Localization.dateFormat,
          }}
          label={this.props.label}
          onBlur={() => {
            this.flushInput();
            this.announceErrors(this.getErrors());
          }}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          value={this.state.pendingValue}
          variant="filled"
        />
        <CalendarDialog
          onClose={this.onCalendarClose}
          onDateSelected={this.onCalendarDateSelected}
          open={this.state.calendarOpen}
          title={Localization.getBuiltInMessage(
            "DateEdit.selectDateDialogLabel",
            {
              datePickerLabel: this.props.label,
            }
          )}
          value={this.props.value}
        />
      </React.Fragment>
    );
  }
}

export default DatePicker;
