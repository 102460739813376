import * as muiFade from "@material-ui/core/Fade";
import * as React from "react";

interface Props extends muiFade.FadeProps {}

export class Fade extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return <muiFade.default {...this.props} />;
  }
}

export default Fade;
