import grey from "@material-ui/core/colors/grey";
import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as moment from "moment";
import * as React from "react";
import Localization from "../../core/Localization";
import TrackableModel from "../../core/TrackableModel";
import Button from "../../coreui/Button";
import CalendarDialog from "../../coreui/CalendarDialog";
import ErrorBadge from "../../coreui/ErrorBadge";
import Input, { InputProps } from "../../coreui/Input";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import Api from "../../mustangui/Api";
import ErrorsStore from "../../stores/ErrorsStore";
import { DateEdit } from "../DateEdit";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams {
  dataId: string;
  dateFormatError: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  isCalendarOpen: boolean;
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

@observer
export class DateEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  public static readonly localeName = "current";
  public static readonly widgetType: string = "DateEditColumn";
  private inputElement: HTMLInputElement;
  private lastValidDate: Date | null = null;
  protected muiProps: InputProps;

  private static getErrors(
    props: ConfigProperties,
    data: TrackableModel,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): string[] {
    const widgetProperties = Api.getWidgetProperties(
      props,
      data
    ) as RuntimeProperties;
    let result: string[];

    if (!widgetProperties) {
      return [];
    }

    if (data.hasChanges(props.name)) {
      result = [];
    } else {
      result = [...widgetProperties.businessErrors];
    }

    const parsedValue = DateEditColumnEdit.getCurrentValueParsed(true, value);

    if (typeof parsedValue === "string") {
      result.push(props.dateFormatError);
    }

    return result;
  }

  public static getCurrentValueParsed(
    userFormatted: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): Date | null {
    let parsed: Date | null = null;

    if (value) {
      const format = DateEdit.getDateFormat(userFormatted);
      const date = moment(
        value,
        format,
        userFormatted ? DateEditColumnEdit.localeName : "en",
        true
      );

      if (date.isValid()) {
        parsed = date.toDate();
      } else {
        if (userFormatted) {
          parsed = Localization.parseDate(value);
          if (!parsed) {
            parsed = value;
          }
        } else {
          parsed = value;
        }
      }
    }

    return parsed;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    const currentDate = DateEditColumnEdit.getCurrentValueParsed(
      false,
      props.value
    );
    let formattedValue = DateEdit.formatValue(currentDate, true);

    // Backspace.
    if (props.keyPress === 8 && formattedValue) {
      formattedValue = formattedValue.substr(0, formattedValue.length - 1);
    } else if (props.charPress) {
      if (formattedValue) {
        formattedValue += props.charPress;
      } else {
        formattedValue = props.charPress;
      }
    }

    this.state = {
      isCalendarOpen: false,
      value: formattedValue,
    };

    if (typeof currentDate !== "string") {
      this.lastValidDate = currentDate;
    }

    this.muiProps = {
      autoFocus: true,
      fullWidth: true,
      inputProps: {
        style: { marginLeft: "24px" },
      },
      inputRef: (element) => {
        this.inputElement = element;
      },
      onChange: (e) => this.setValue(e.target.value),
      style: { height: "calc(100% + 2px)", paddingLeft: 2 },
    };

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCalendarClose = (): void => {
    this.setState({ isCalendarOpen: false });
  };

  private onCalendarDateSelected = (date: Date): void => {
    this.setValue(DateEdit.formatValue(date, true));
  };

  private onCalendarRestoreFocus = (): void => {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    // If the button has focus do not stop editing.
    if (
      event.key === "Enter" &&
      document.activeElement &&
      document.activeElement.tagName === "BUTTON"
    ) {
      event.preventDefault();
    }
  };

  private openCalendar = () => {
    this.setState({ isCalendarOpen: true });
  };

  private setValue(value: string | null) {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  }

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public getValue(): string | null {
    return DateEdit.formatValue(
      DateEditColumnEdit.getCurrentValueParsed(true, this.state.value),
      false
    );
  }

  public render() {
    const _props = { ...this.props };
    const column: GridColumnConfigProperties | undefined = (this.props
      .propagated.parentTable.columns as GridColumnConfigProperties[]).find(
      (_column) => _column.name === this.props.colDef.colId
    );
    let currentDate: string | Date | null;
    let errors: string[] = [];

    const parentTable = this.props.propagated.parentTable;
    if (this.state.isCalendarOpen) {
      parentTable.setStopEditingWhenGridLosesFocus(false);
    } else {
      parentTable.setStopEditingWhenGridLosesFocus(true);
    }

    this.muiProps.value = this.state.value || "";

    currentDate = DateEditColumnEdit.getCurrentValueParsed(
      true,
      this.muiProps.value
    );

    if (typeof currentDate === "string") {
      currentDate = this.lastValidDate ? this.lastValidDate : null;
    }

    errors = DateEditColumnEdit.getErrors(
      _props,
      _props.node.data,
      this.muiProps.value
    );

    this.muiProps.error = errors.length > 0;
    this.muiProps.required = column?.required;
    this.muiProps.endAdornment = (
      <muiInputAdornment.default
        position="end"
        style={{ marginRight: 24, marginTop: -4 }}
      >
        <Button
          aria-label={Localization.getBuiltInMessage(
            "DateEdit.selectDateButtonLabel",
            {
              datePickerLabel: _props.colDef.headerName,
            }
          )}
          icon="fas fa-calendar-alt"
          size="small"
          onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === "Tab" && !event.shiftKey && this.props.api) {
              this.props.api.stopEditing();
              this.props.api.tabToNextCell();

              event.preventDefault();
              event.stopPropagation();
            } else if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
              this.openCalendar();
            }
          }}
          onClick={this.openCalendar}
        />
      </muiInputAdornment.default>
    );
    this.muiProps.onKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === "Tab" && event.shiftKey && this.props.api) {
        this.props.api.stopEditing();
        this.props.api.tabToPreviousCell();

        event.preventDefault();
        event.stopPropagation();
      }
    };

    return (
      <ErrorBadge
        isShort={
          _props.node.data.isNew && _props.propagated.parentTable.isDocumentGrid
        }
        message={Api.getErrorMessages(errors)}
      >
        <Input {...this.muiProps} />
        <CalendarDialog
          onClose={this.onCalendarClose}
          onDateSelected={this.onCalendarDateSelected}
          onRestoreFocus={this.onCalendarRestoreFocus}
          open={this.state.isCalendarOpen}
          title={Localization.getBuiltInMessage(
            "DateEdit.selectDateDialogLabel",
            {
              datePickerLabel: _props.colDef.headerName,
            }
          )}
          value={currentDate}
        />
      </ErrorBadge>
    );
  }
}

export default DateEditColumnEdit;
