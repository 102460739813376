import * as muiFormLabel from "@material-ui/core/FormLabel";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Presentation from "./Presentation";

// VERSION_WARNING Material-UI 4.9.1
// MaterialUI's new "generic" component prop injection infrastructure seems to
// break typescript. Specifically, it does not appear to exist on the Props
// exposed by MaterialUI even though TypeScript thinks the prop is valid when
// using the raw MUI component. The work around to this is to simply re-expose
// the component prop here and then manually pass it on to the MUI component.
interface Props extends muiFormLabel.FormLabelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ElementType<any>;
  dataId?: string;
  name?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

@observer
export class FormLabel extends React.Component<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const {
      children: childrenProp,
      component,
      dataId,
      name,
      ...otherProps
    } = this.props;

    let children = childrenProp;

    if (dataId && name) {
      children = Presentation.getValue(this.props);
    }

    return (
      <muiFormLabel.default component={component!} {...otherProps}>
        {children}
      </muiFormLabel.default>
    );
  }
}

export default withStyles(styles)(FormLabel);
