import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import Localization from "../../core/Localization";
import SubscribeEvent from "../../core/SubscribeEvent";
import PaneRow from "../../models/PaneRow";
import { CustomTheme } from "../../muiTheme";
import GridNewRowChip from "../../mustangui/GridNewRowChip";
import Paper from "../Paper";
import Presentation from "../Presentation";
import { TableChildProps, TableVerticalLayoutProps } from "../Table";

export interface Props {
  cardDepth: number;
  child: object;
  data: PaneRow;
  onTableReady: SubscribeEvent;
  propagated: TableChildProps;
  selectToolbarChild?: object | null;
}

interface State {
  isTableReady?: boolean;
}

const styles = (theme: CustomTheme) =>
  createStyles({
    print: {
      "@media print": {
        display: "none",
      },
    },
    root: {
      "&:focus": {
        outline: "none",
      },
      "&:focus-visible": {
        border: `2px solid ${theme.palette.grey[800]}`,
        padding: theme.paper.padding.xs - 2,
      },
      whiteSpace: "normal",
    },
  });

export class FullWidthRenderer extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private cardRef = React.createRef<HTMLDivElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      isTableReady: props.propagated.parentTable.isTableReady(),
    };

    props.onTableReady.addEventListener(this.onTableReady);
  }

  private onTableReady = (): void => {
    this.setState({ isTableReady: true });
  };

  public componentDidMount(): void {
    this.props.propagated.parentTable
      .getTable()
      .setFullWidthChild(this.props.data.rowKey, this);
  }

  public componentWillUnmount(): void {
    this.props.onTableReady.removeEventListener(this.onTableReady);
    this.props.propagated.parentTable
      .getTable()
      .removeFullWidthChild(this.props.data.rowKey);
  }

  public focus(): void {
    if (this.cardRef.current) {
      this.cardRef.current.focus();
    }
  }

  public render(): React.ReactNode {
    const tableProps = this.props.propagated.parentTable.configProps;

    let newChip: React.ReactNode = null;

    if (this.props.data.isNew) {
      newChip = (
        <React.Fragment>
          <div className="cx-new-container-border-xs" />
          <div className="cx-new-container-xs">
            <GridNewRowChip />
          </div>
        </React.Fragment>
      );
    }

    return (
      <Paper
        aria-label={
          this.props.data.isNew
            ? Localization.getBuiltInMessage("DataTable.newRowAccessibleLabel")
            : Localization.getBuiltInMessage("DataTable.rowAccessibleLabel")
        }
        card
        cardDepth={this.props.cardDepth}
        className={this.props.classes.root}
        margin
        elevation={0}
        id={
          `${tableProps.contentDataId}.${tableProps.name}.` +
          `${this.props.data.getPrimaryKey()}`
        }
        ref={this.cardRef}
        role="gridcell"
        tabIndex={0}
      >
        {newChip}
        <div className={this.props.classes.print}>
          {this.state.isTableReady && this.props.selectToolbarChild
            ? Presentation.create(this.props.selectToolbarChild, {
                ...this.props.propagated,
                data: this.props.data,
                parentRowKey: this.props.data.rowKey,
                parentRowObjectHandle: this.props.data.objectHandle,
              })
            : null}
        </div>
        {Presentation.create(this.props.child, {
          parentTable: this.props.propagated.parentTable,
          rowKey: this.props.data.rowKey,
        } as TableVerticalLayoutProps)}
      </Paper>
    );
  }
}

export default withStyles(styles)(FullWidthRenderer);
