import { makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import Paper from "../coreui/Paper";
import Presentation from "../coreui/Presentation";

export interface StepProps {
  dataId: string;
  label: string;
  paneUse: object;
  paneUseKey: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: "border-box",
    minHeight: 80 + 8 * 2, // Loading animation + padding
    position: "relative",
  },
}));

export default function WizardStepPane(props: StepProps): JSX.Element {
  const styles = useStyles();
  const isPresentationNavigation = React.useRef<boolean>(true);
  const paneRef = React.useRef<HTMLElement | null>(null);

  const focusFirstElement = (): void => {
    if (paneRef.current === null) {
      console.warn(
        "No step pane element exists when attempting to focus the first " +
          "element in the wizard step."
      );
    } else {
      FocusManager.grabFocusForChild(
        paneRef.current,
        [
          FocusManager.selectors.headings,
          FocusManager.selectors.focusable,
        ].join(", ")
      );
    }
  };

  React.useEffect(() => {
    // Focus must only be set when navigating between wizard steps on the same
    // presentation, so as not to interfere with focus being set to the main
    // heading when navigating to a new presentation.
    if (isPresentationNavigation.current) {
      isPresentationNavigation.current = false;
    } else {
      focusFirstElement();
    }
  }, [props.paneUseKey]);

  // The key attribute tells React to recreate the component from scratch when
  // the selected step changes. Without this, child components are reused and
  // constructors are not called.
  return (
    <Paper
      aria-label={props.label}
      card={false}
      cardDepth={0}
      className={styles.root}
      elevation={0}
      margin={false}
      ref={paneRef}
      role="tabpanel"
    >
      <div key={props.paneUseKey}>{Presentation.create(props.paneUse)}</div>
    </Paper>
  );
}
