import { autorun, Lambda } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Presentation from "../coreui/Presentation";
import Select from "../coreui/Select";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import { AccessLevel } from "./Api";

interface Props {
  controlKey: string | null;
  controlledPaneKeysByValue: object | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  options: ComboBoxOption[];
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  selectedDisplayValue: string;
  selectedValue: string | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

@observer
export class DomainComboBox extends React.Component<Props> {
  protected disposeObserve: Lambda;

  public static getOptions(
    propOptions: ComboBoxOption[],
    selectedValue: string | null,
    selectedDisplayValue: string
  ): ComboBoxOption[] {
    const options = [...propOptions];

    if (selectedValue) {
      // Add the initially selected value as a historic option if is not
      // among the current candidates
      const optionInList = options.find((o) => o.value === selectedValue);

      if (optionInList === undefined) {
        const historicOption: ComboBoxOption = {
          display: selectedDisplayValue,
          historic: true,
          value: selectedValue,
        };

        options.push(historicOption);
      }
    }

    return options;
  }

  private onValueChange = (value: ComboBoxOption | null) => {
    const oldValue = Presentation.getValue(this.props);

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value?.value);

    if (!this.props.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip("DomainComboBox/OnChange", this.props, {
      dialogRowKey: this.props.propagated?.parentDialog?.rowKey,
    }).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round trip fails, undo the value change.
        Presentation.setValue(this.props, oldValue);
      }
    });
  };

  protected showSubPane(): void {
    if (!this.props.isPaneController) {
      return;
    }

    const propagated: object | undefined = this.props.propagated;
    const rowKey: string =
      propagated && propagated["rowKey"] ? propagated["rowKey"] : "";
    const controlKey: string = `${this.props.controlKey}_${rowKey}`;

    const value = Presentation.getValue(this.props, null);
    const controlledPaneName = this.props.controlledPaneKeysByValue![value];

    if (controlledPaneName) {
      SubPaneControlStore.showPane(controlKey, controlledPaneName);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.showSubPane();
    });
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
  }

  public render(): React.ReactNode {
    let rowKey = null;
    if (this.props.propagated?.parentTable) {
      // Widget is rendered in the vertical layout of a grid.
      rowKey = this.props.propagated.rowKey;
    }

    const row = PaneRow.get(this.props.dataId, rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidget(this.props.name);
    const runtimeProperties = widget.properties as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            runtimeProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    const options: ComboBoxOption[] = DomainComboBox.getOptions(
      this.props.options,
      runtimeProperties.selectedValue,
      runtimeProperties.selectedDisplayValue
    );

    const value = Presentation.getValue(this.props) as string | null;

    return (
      <Select
        getErrors={() => runtimeProperties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={options}
        readOnly={runtimeProperties.accessLevel === AccessLevel.readOnly}
        showAsMandatory={runtimeProperties.showAsMandatory}
        value={value}
      />
    );
  }
}

export default DomainComboBox;
