import * as muiInputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps, SiteSearchInfo } from "../config/SearchPresentation";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Presentation from "../coreui/Presentation";
import Select from "../coreui/Select";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  mandatory: boolean;
  name: string;
  propagated: SearchChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  siteSearches: SiteSearchInfo[];
}

const styles = (theme: CustomTheme) =>
  createStyles({
    root: {
      display: "grid",
      gridColumnGap: theme.horizontalSpacing.widget.related,

      [theme.breakpoints.up("xs")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.xs,
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.up("sm")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.sm,
      },
      [theme.breakpoints.up("md")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.md,
        gridTemplateColumns: "270px 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.lg,
      },
    },
  });

@observer
export class SiteCriteria extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private getErrors = (value: string): string[] => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;

    if (!row) {
      return [];
    }

    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    return runtimeProps.businessErrors;
  };

  private onClickSearchButton = (): void => {
    this.props.propagated.parentSearch.search();
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.props.propagated.parentSearch.search();
  };

  private onSearchForChange = (option: ComboBoxOption): void => {
    const baseUrl: string | undefined = option.value?.substr(1);

    if (baseUrl) {
      let searchCriteria: string | null = null;
      const row: PaneRow = PaneRow.get(this.props.dataId)!;

      if (row) {
        const widget = row.getWidget(this.props.name);
        searchCriteria = widget.value as string | null;
      }

      if (searchCriteria !== null) {
        const queryString = Sys.objectToQueryString({
          SiteCriteria: searchCriteria || undefined,
        });
        Sys.setHash(`${baseUrl}?${queryString}`);
      } else {
        Sys.setHash(baseUrl);
      }
    }
  };

  private onValueChange = (value: string): void => {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value);
  };

  public render(): React.ReactNode {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;

    if (!row) {
      return null;
    }

    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    let defaultSearch: string | null = null;
    const options: ComboBoxOption[] = [];
    for (const siteSearch of runtimeProps.siteSearches) {
      if (siteSearch.isDefault) {
        defaultSearch = siteSearch.url;
      }
      options.push({
        display: siteSearch.description,
        value: siteSearch.url,
      });
    }

    return (
      <div className={this.props.classes.root}>
        <Select
          label={Localization.getBuiltInMessage("siteCriteriaSearchFor")}
          onValueChange={this.onSearchForChange}
          options={options}
          value={defaultSearch}
        />
        <TextField
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          InputProps={{
            endAdornment: (
              <muiInputAdornment.default
                position="end"
                style={{ marginTop: -4 }}
              >
                <Button
                  aria-label={Localization.getBuiltInMessage("search")}
                  color="dark"
                  icon="fas fa-search"
                  onClick={this.onClickSearchButton}
                  size="small"
                />
              </muiInputAdornment.default>
            ),
          }}
          label={Localization.getBuiltInMessage("search")}
          name={this.props.name}
          onKeyPress={this.onKeyPress}
          onValueChange={this.onValueChange}
          required={this.props.mandatory}
          role="group"
          value={(widget.value || "") as string}
          variant="filled"
        />
      </div>
    );
  }
}

export default withStyles(styles)(SiteCriteria);
