import { DialogLayoutConfig } from "../config/Dialog";
import { Layout } from "../config/Layout";
import RequestPromise from "../core/RequestPromise";
import { BreakPointColumn } from "../coreui/Dialog";
import BaseService from "./BaseService";

export interface DialogConfigResponse {
  breakPoints: BreakPointColumn[];
  dialogLayout: DialogLayoutConfig;
}

export default class DialogService {
  public static getConfig(
    layoutId: number
  ): RequestPromise<DialogConfigResponse> {
    const request = BaseService.requestObject<DialogConfigResponse>(
      `Dialog/Config/${layoutId}`,
      null,
      null,
      null,
      "GET"
    );

    return request.then((config: DialogConfigResponse) => {
      Layout.initialize(config.dialogLayout);

      return config;
    });
  }
}
