import { observer, useObserver } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Routing from "../../core/Routing";
import MenuItem from "../../coreui/MenuItem";
import UserService from "../../services/UserService";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import Api from "../Api";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";

interface Props extends ActionButtonProps {}

@observer
export class SignOutButton extends React.Component<Props> {
  private static signOutError(): void {
    Routing.goToErrorPage("Only signed in users can sign out");
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = (config as unknown) as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;
    let url: string | undefined;
    if (!UserService.isGuest) {
      url = "#/signout";
    }

    return useObserver(() => (
      <MenuItem
        href={url}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={url ? undefined : SignOutButton.signOutError}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    ));
  }

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as ActionButtonRuntimeProps;
    let url: string | undefined;
    if (!UserService.isGuest) {
      url = "#/signout";
    }

    const isIconOnly = !runtimeProperties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("signOut")
      : runtimeProperties.label;

    return (
      <ApiButton
        alternateText={runtimeProperties.alternateText}
        buttonColor={this.props.buttonColor}
        dataId={this.props.dataId}
        disabled={false}
        disabledHelpText={this.props.disabledHelpText}
        href={url}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        name={this.props.name}
        onClick={url ? undefined : SignOutButton.signOutError}
        renderAsLink={this.props.renderAsLink}
        size={this.props.size}
      />
    );
  }
}

export default SignOutButton;
