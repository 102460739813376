import { observable } from "mobx";

export default class SubPaneControlStore {
  @observable static currentPaneKeyByControlKey: {
    [controlKey: string]: string | undefined;
  } = {};

  public static getCurrentPaneKeyForControlKey(
    controlKey: string
  ): string | undefined {
    if (!(controlKey in this.currentPaneKeyByControlKey)) {
      this.currentPaneKeyByControlKey[controlKey] = undefined;
    }

    return this.currentPaneKeyByControlKey[controlKey];
  }

  public static hidePane(controlKey: string): void {
    this.currentPaneKeyByControlKey[controlKey] = undefined;
  }

  public static showPane(controlKey: string, paneKey: string): void {
    this.currentPaneKeyByControlKey[controlKey] = paneKey;
  }
}
