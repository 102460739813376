// FUTURE
// This component currently implements its own focus trap. Since the Material-UI
// TrapFocus had to be lifted to add a focus trap for config.AddOnHost, it is
// now available and could probably be used here too. This isn't being done at
// the moment to contain scope and minimize the changes being made at the end of
// the 7.4.2 project.
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../../core/AppServer";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import TrackableCollection from "../../core/TrackableCollection";
import TrackableModel from "../../core/TrackableModel";
import Button from "../../coreui/Button";
import Dialog, { BreakPointColumn } from "../../coreui/Dialog";
import DialogActions from "../../coreui/DialogActions";
import DialogContent from "../../coreui/DialogContent";
import ProcessingMask from "../../coreui/ProcessingMask";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { AccessLevel } from "../Api";

interface ConfigProperties {
  contentDataId: string;
  dataId: string;
  isFirstOpenOfNewRow?: boolean;
  labelledById: string;
  name: string;
  onAccept: (parentRowKey: string) => Promise<void>;
  onClose: (accepted: boolean) => void;
  onDeleteRow: () => void;
  onExited?: () => void;
  onOpen: (parentRowKey: string) => Promise<BreakPointColumn[]>;
  parentRowKey?: string;
}

interface State {
  breakPointColumns?: BreakPointColumn[];
  isDialogOpen?: boolean;
  isProcessing?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      "& > div": {
        [theme.breakpoints.only("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  });

@observer
export class TableEditDialog extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private static instances: TableEditDialog[] = [];
  private content: HTMLElement | null = null;
  private isDialogClosing: boolean = false;
  private sentinelEnd: HTMLElement | null = null;
  private sentinelStart: HTMLElement | null = null;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = { isDialogOpen: false, isProcessing: false };
  }

  private isTopDialog = () => {
    return (
      TableEditDialog.instances[TableEditDialog.instances.length - 1] === this
    );
  };

  private loopFocus = (event: KeyboardEvent) => {
    // 9 = Tab
    const srcElement = event.srcElement as Node;
    if (
      event.keyCode === 9 &&
      this.isTopDialog() &&
      this.content &&
      (srcElement === this.sentinelEnd ||
        srcElement === this.sentinelStart ||
        this.content.contains(srcElement)) &&
      !this.content.contains(document.activeElement)
    ) {
      if (event.shiftKey) {
        this.sentinelEnd?.focus();
      } else {
        this.sentinelStart?.focus();
      }
    }
  };

  private onAccept = () => {
    if (this.isDialogClosing) {
      return;
    }
    this.isDialogClosing = true;

    this.setState({ isProcessing: true });

    this.props
      .onAccept(this.props.parentRowKey!)
      .then(() => this.closeDialog(true))
      .catch((reason) => {
        this.isDialogClosing = false;
        if (reason) {
          throw reason;
        }
      })
      .finally(() => this.setState({ isProcessing: false }));
  };

  private onCancel = () => {
    if (this.isDialogClosing) {
      return;
    }
    this.isDialogClosing = true;

    if (this.props.isFirstOpenOfNewRow) {
      const collection = TrackableModel.models.get(
        this.props.contentDataId
      ) as TrackableCollection;

      const model: TrackableModel = collection.find(
        (m) => m.getPrimaryKey() === this.props.parentRowKey
      )!;

      collection.delete(model);

      // Remove from the deleted collection as we're restoring the
      // app server state to before the new row was added
      collection.getDeleted().delete(model);
      this.props.onDeleteRow();
    }

    ErrorsStore.clearBusinessErrors(
      this.props.contentDataId,
      undefined,
      this.props.parentRowKey
    );

    AppServer.recoverStateFromPoint();
    this.closeDialog(false);
  };

  private onClose = (event: object) => {
    if (event["forced"]) {
      this.setState({ isDialogOpen: false });
    } else {
      this.onCancel();
    }
  };

  private onEntered = (node: HTMLElement, isAppearing: boolean) => {
    this.content = node;

    if (this.content) {
      FocusManager.grabFocusForChild(
        this.content,
        FocusManager.selectors.focusable
      );

      this.sentinelStart = this.content.previousElementSibling as HTMLElement;
      this.sentinelEnd = this.content.nextElementSibling as HTMLElement;

      document.addEventListener("keydown", this.loopFocus, true);
    }
  };

  private onEntering = (node: HTMLElement, isAppearing: boolean) => {
    TableEditDialog.instances.push(this);
  };

  private onExited = () => {
    TableEditDialog.instances.pop();
    document.removeEventListener("keydown", this.loopFocus, true);

    if (this.props.onExited) {
      this.props.onExited();
    }
  };

  private openDialog = () => {
    RequestsStore.instance.processingStarted();

    this.props
      .onOpen(this.props.parentRowKey!)
      .then((breakPointColumns: BreakPointColumn[]) => {
        this.setState({
          breakPointColumns,
          isDialogOpen: true,
        });

        this.isDialogClosing = false;
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
        this.props.onClose(false);
      })
      .finally(() => RequestsStore.instance.processingStopped());
  };

  public closeDialog = (accepted: boolean) => {
    this.setState({ isDialogOpen: false });
    this.props.onClose(accepted);
  };

  public componentDidUpdate(prevProps: ConfigProperties) {
    if (
      this.props.parentRowKey !== prevProps.parentRowKey &&
      this.props.parentRowKey &&
      !this.state.isDialogOpen
    ) {
      this.openDialog();
    }
  }

  public componentWillUnmount() {
    if (this.state.isDialogOpen) {
      AppServer.clearStateRecoveryPoint();
    }

    if (this.isTopDialog()) {
      TableEditDialog.instances.pop();
    }

    document.removeEventListener("keydown", this.loopFocus, true);
  }

  public render() {
    const parentTableWidgetProperties = PaneRow.getWidgetProperties(
      this.props.dataId,
      this.props.name
    ) as RuntimeProperties;

    if (!parentTableWidgetProperties) {
      return null;
    }

    const isEnterable =
      parentTableWidgetProperties.accessLevel >= AccessLevel.enterable;

    return (
      <Dialog
        aria-labelledby={this.props.labelledById}
        breakPointColumns={this.state.breakPointColumns}
        disableBackdropClick={true}
        disableEnforceFocus={true}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onClose={this.onClose}
        onExited={this.onExited}
        open={this.state.isDialogOpen! && !!this.props.parentRowKey}
      >
        <DialogContent classes={{ root: this.props.classes.dialogContent }}>
          {this.props.children}
        </DialogContent>
        <DialogActions>
          {isEnterable ? (
            <Button onClick={this.onAccept}>
              {Localization.getBuiltInMessage("ok")}
            </Button>
          ) : null}
          <Button onClick={this.onCancel} style={{ marginLeft: 40 }}>
            {isEnterable
              ? Localization.getBuiltInMessage("cancel")
              : Localization.getBuiltInMessage("close")}
          </Button>
        </DialogActions>
        <ProcessingMask
          disableRestoreFocus={false}
          isOpen={this.state.isProcessing!}
          trapFocus={true}
        />
      </Dialog>
    );
  }
}

export default withStyles(styles)(TableEditDialog);
