import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config/SearchPresentation";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Presentation from "../coreui/Presentation";
import Select from "../coreui/Select";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  options: ComboBoxOption[];
  propagated: SearchChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

@observer
export class DomainComboBoxCriteria extends React.Component<ConfigProperties> {
  private onValueChange = (value: ComboBoxOption) => {
    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value.value);
  };

  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    const value = Presentation.getValue(this.props) as string;

    return (
      <Select
        getErrors={() => runtimeProperties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={this.props.options}
        showAsMandatory={this.props.mandatory}
        value={value}
      />
    );
  }
}

export default DomainComboBoxCriteria;
