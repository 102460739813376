import * as muiDialogActions from "@material-ui/core/DialogActions";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { CustomTheme } from "../muiTheme";

interface Props extends muiDialogActions.DialogActionsProps {}

const styles = (theme: CustomTheme) =>
  createStyles({
    root: {
      "& > *": {
        "&:first-child": {
          marginLeft: 0,
        },

        marginRight: 0,

        [theme.breakpoints.up("xs")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.xs,
        },
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.sm,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.md,
        },
        [theme.breakpoints.up("lg")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.lg,
        },
      },
      [theme.breakpoints.up("xs")]: {
        marginBottom: theme.dialog.padding.xs,
        marginLeft: theme.dialog.padding.xs,
        marginRight: theme.dialog.padding.xs,
        // Space for button shadow
        marginTop: theme.verticalSpacing.related.xs - 4,
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.dialog.padding.sm,
        marginLeft: theme.dialog.padding.sm,
        marginRight: theme.dialog.padding.sm,
        marginTop: theme.verticalSpacing.related.sm - 4,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.verticalSpacing.related.md - 4,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.verticalSpacing.related.lg - 4,
      },
    },
  });

export class DialogActions extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const _props = { ...this.props };

    return <muiDialogActions.default {..._props} />;
  }
}

export default withStyles(styles)(DialogActions);
