import * as muiCircularProgress from "@material-ui/core/CircularProgress";
import lightGreen from "@material-ui/core/colors/lightGreen";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";

interface Props extends muiCircularProgress.CircularProgressProps {
  size?: "standard" | "mini";
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: lightGreen[500],
    },
  });

export class CircularProgress extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const { classes, size, ...other } = this.props;

    return (
      <muiCircularProgress.default
        classes={{ root: classes.root, ...classes }}
        size={size === "mini" ? 24 : 80}
        thickness={size === "mini" ? 3.6 : 2.6}
        {...other}
      />
    );
  }
}

export default withStyles(styles)(CircularProgress);
