export default class RequestPromise<T> extends Promise<T> {
  private onAbort: () => void;

  public constructor(
    executor: (
      resolve: (value?: T | PromiseLike<T>) => void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reject: (reason?: any) => void
    ) => void,
    onAbort: () => void
  ) {
    super(executor);
    this.onAbort = onAbort;
  }

  public abort() {
    this.onAbort();
  }

  public catch<TResult = never>(
    onrejected?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null
  ): RequestPromise<T | TResult> {
    const promise = super.catch(onrejected) as RequestPromise<T | TResult>;
    promise.onAbort = this.onAbort;
    return promise;
  }

  public finally(
    onfinally?: (() => void) | undefined | null
  ): RequestPromise<T> {
    const promise = super.finally(onfinally) as RequestPromise<T>;
    promise.onAbort = this.onAbort;
    return promise;
  }

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null
  ): RequestPromise<TResult1 | TResult2> {
    const promise = super.then(onfulfilled, onrejected) as RequestPromise<
      TResult1 | TResult2
    >;

    promise.onAbort = this.onAbort;
    return promise;
  }
}
