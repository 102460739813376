import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FormData from "../coreui/FormData";
import Icon from "../coreui/Icon";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  label?: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  iconName: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      alignItems: "left",
      display: "flex",
      width: "16px",
    },
    icon: {
      fontSize: 16,
      height: "auto",
    },
  });

@observer
export class IconDisplayVerticalLayout extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    const icon = (
      <div className={this.props.classes.container}>
        <Icon
          aria-label={runtimeProperties.alternateText}
          className={this.props.classes.icon}
          icon={runtimeProperties.iconName}
        />
      </div>
    );

    if (this.props.label) {
      return <FormData label={this.props.label}>{icon}</FormData>;
    }

    return icon;
  }
}

export default withStyles(styles)(IconDisplayVerticalLayout);
