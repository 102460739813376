import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config/Dialog";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Presentation from "../coreui/Presentation";
import Select from "../coreui/Select";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./Api";

interface Props {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  name: string;
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  options: ComboBoxOption[];
  selectedDisplayValue: string;
  selectedValue: string | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

@observer
export class RelationshipComboBox extends React.Component<Props> {
  private getOptions(runtimeProps: RuntimeProperties) {
    // Check if Widget is rendered in the vertical layout of a grid
    if (this.props.propagated?.parentTable) {
      const selectedOption: ComboBoxOption = {
        display: runtimeProps.selectedDisplayValue,
        value: runtimeProps.selectedValue,
      };

      const table = this.props.propagated.parentTable;
      return table.getRelationshipComboBoxOptions(
        this.props.name,
        selectedOption
      );
    }

    const options = [...runtimeProps.options];

    if (runtimeProps.selectedValue) {
      // Add the initially selected value as a historic option if is not
      // among the current candidates
      const optionInList = options.find(
        (o) => o.value === runtimeProps.selectedValue
      );

      if (optionInList === undefined) {
        const historicOption: ComboBoxOption = {
          display: runtimeProps.selectedDisplayValue,
          historic: true,
          value: runtimeProps.selectedValue,
        };

        options.push(historicOption);
      }
    }

    return options;
  }

  private onValueChange = (value: ComboBoxOption | null) => {
    const oldValue = Presentation.getValue(this.props);

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, value?.value);

    if (!this.props.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip(
      "RelationshipComboBox/OnChange",
      this.props,
      {
        dialogRowKey: this.props.propagated?.parentDialog?.rowKey,
      }
    ).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round trip fails, undo the value change.
        Presentation.setValue(this.props, oldValue);
      }
    });
  };

  public render(): React.ReactNode {
    let rowKey = null;
    if (this.props.propagated?.parentTable) {
      // Widget is rendered in the vertical layout of a grid.
      rowKey = this.props.propagated.rowKey;
    }

    const row = PaneRow.get(this.props.dataId, rowKey);
    if (!row) {
      return null;
    }

    const widget: RuntimeWidget = row.getWidget(this.props.name);
    const runtimeProperties = widget.properties as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            runtimeProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    const options: ComboBoxOption[] = this.getOptions(runtimeProperties);

    const value = Presentation.getValue(this.props) as string | null;

    return (
      <Select
        getErrors={() => runtimeProperties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={options}
        readOnly={runtimeProperties.accessLevel === AccessLevel.readOnly}
        showAsMandatory={runtimeProperties.showAsMandatory}
        value={value}
      />
    );
  }
}

export default RelationshipComboBox;
