import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config/SearchPresentation";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Collapse from "../coreui/Collapse";
import ComboBoxOption from "../coreui/ComboBoxOption";
import DatePicker from "../coreui/DatePicker";
import getFieldHelperText from "../coreui/FieldHelperText";
import FormControl from "../coreui/FormControl";
import FormLabel from "../coreui/FormLabel";
import multiClassName from "../coreui/MultiClassName";
import Select from "../coreui/Select";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import { CustomTheme } from "../muiTheme";
import { AccessLevel } from "./Api";

interface ConfigProperties {
  dataId: string;
  dateFormatError: string;
  dateRangeError: string;
  fromLabel: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  propagated: SearchChildProps;
  toLabel: string;
}

export interface DateRangeCriteriaValue {
  fromDate: string | null;
  fromDateIsValid: boolean;
  selectedCriteriaName: string | null;
  toDate: string | null;
  toDateIsValid: boolean;
}

interface PredefinedCriteria {
  description: string;
  fromDate: string | null;
  name: string;
  toDate: string | null;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  predefinedCriteria: PredefinedCriteria[];
}

interface State {
  hasInvalidRange: boolean;
  showSelectHelperText: boolean;
}

const styles = (theme: CustomTheme) => {
  const collapseStyle = {};
  const spacingStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const horizontalSpacing = theme.horizontalSpacing.related[breakPoint];
    const verticalSpacing = theme.verticalSpacing.closelyRelated[breakPoint];

    collapseStyle[theme.breakpoints.up(breakPoint)] = {
      marginTop: `${verticalSpacing}px`,
    };

    spacingStyle[theme.breakpoints.up(breakPoint)] = {
      gridColumnGap: `${horizontalSpacing}px`,
      gridRowGap: `${verticalSpacing}px`,
    };
  }

  const result = {
    collapse: collapseStyle,
    label: {
      marginBottom: 8,
    },
    rangeContainer: {
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.only("xs")]: {
        gridTemplateColumns: "1fr",
      },

      display: "grid",
    },
    spacing: spacingStyle,
  };

  return createStyles(result);
};

@observer
export class DateRangeCriteria extends React.PureComponent<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private static readonly customRangeSentinel: string = "custom-range";
  public static readonly widgetTypeId: number = 81;

  private readonly componentId: string;
  private readonly labelId: string;

  public static clear(widgetName: string, row: PaneRow): void {
    row.setProperty(widgetName, {
      fromDate: null,
      fromDateIsValid: true,
      selectedCriteriaName: null,
      toDate: null,
      toDateIsValid: true,
    } as DateRangeCriteriaValue);
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget: RuntimeWidget = row.getWidget(widgetName)!;
    const value = widget.value as DateRangeCriteriaValue;

    if (value.selectedCriteriaName === DateRangeCriteria.customRangeSentinel) {
      return value.fromDate !== null || value.toDate !== null;
    }

    return value.selectedCriteriaName !== null;
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `date-range-criteria-${Sys.nextId}`;
    this.labelId = `${this.componentId}-label`;

    this.state = {
      hasInvalidRange: false,
      showSelectHelperText: true,
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private checkForErrors(fromDate: Date | null, toDate: Date | null): void {
    this.setState({ hasInvalidRange: false });

    if (fromDate !== null && toDate !== null) {
      if (fromDate.valueOf() > toDate.valueOf()) {
        this.setState({ hasInvalidRange: true });
      }
    }
  }

  private getErrors(): string[] {
    const errors = [];

    if (this.state.hasInvalidRange) {
      errors.push(this.props.dateRangeError);
    }

    return errors;
  }

  private onCollapseEnd = (node: HTMLElement): void => {
    this.setState({ showSelectHelperText: true });
  };

  private onEnterKeyPress = (): void => {
    this.props.propagated.parentSearch.search();
  };

  private onExpandStart = (node: HTMLElement, isAppearing?: boolean): void => {
    this.setState({ showSelectHelperText: false });
  };

  private onFromDateChange = (fromDate: Date | null): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as DateRangeCriteriaValue;

    if (value.toDateIsValid) {
      this.checkForErrors(fromDate, PaneRow.deserializeDateValue(value.toDate));
    }

    value.fromDate = PaneRow.serializeDateValue(fromDate);
    value.fromDateIsValid = true;
    value.selectedCriteriaName = DateRangeCriteria.customRangeSentinel;

    row.setProperty(this.props.name, value);
  };

  private onFromDateInvalid = (entry: string): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as DateRangeCriteriaValue;

    value.fromDateIsValid = false;

    row.setProperty(this.props.name, value);
  };

  private onPredefinedCriteriaChange = (option: ComboBoxOption): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;

    this.setState({
      hasInvalidRange: false,
    });

    row.setProperty(this.props.name, {
      fromDate: null,
      fromDateIsValid: true,
      selectedCriteriaName: option.value,
      toDate: null,
      toDateIsValid: true,
    });
  };

  private onToDateChange = (toDate: Date | null): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as DateRangeCriteriaValue;

    if (value.fromDateIsValid) {
      this.checkForErrors(PaneRow.deserializeDateValue(value.fromDate), toDate);
    }

    value.selectedCriteriaName = DateRangeCriteria.customRangeSentinel;
    value.toDate = PaneRow.serializeDateValue(toDate);
    value.toDateIsValid = true;

    row.setProperty(this.props.name, value);
  };

  private onToDateInvalid = (entry: string): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidget(this.props.name);
    const value = widget.value as DateRangeCriteriaValue;

    value.toDateIsValid = false;

    row.setProperty(this.props.name, value);
  };

  public render(): React.ReactNode {
    const row: PaneRow | null = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidget(this.props.name);
    const runtimeProps = widget.properties as RuntimeProperties;

    if (runtimeProps.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: (v) => this.getErrors(),
      helperText: this.props.helperText,
    });

    const value = widget.value as DateRangeCriteriaValue;

    const fromDatePicker: React.ReactNode = (
      <DatePicker
        dateFormatError={this.props.dateFormatError}
        error={fieldHelperText.hasErrors}
        label={this.props.fromLabel}
        onChange={this.onFromDateChange}
        onEnterKeyPress={this.onEnterKeyPress}
        onInvalidEntry={this.onFromDateInvalid}
        value={PaneRow.deserializeDateValue(value.fromDate)}
      />
    );

    const toDatePicker: React.ReactNode = (
      <DatePicker
        dateFormatError={this.props.dateFormatError}
        error={fieldHelperText.hasErrors}
        label={this.props.toLabel}
        onChange={this.onToDateChange}
        onEnterKeyPress={this.onEnterKeyPress}
        onInvalidEntry={this.onToDateInvalid}
        value={PaneRow.deserializeDateValue(value.toDate)}
      />
    );

    if (runtimeProps.predefinedCriteria.length === 0) {
      return (
        <FormControl
          error={fieldHelperText.hasErrors}
          fullWidth={true}
          helperText={fieldHelperText.helperText}
        >
          <FormLabel className={this.props.classes.label} id={this.labelId}>
            {this.props.label}
          </FormLabel>
          <div
            aria-labelledby={this.labelId}
            className={multiClassName(
              this.props.classes.rangeContainer,
              this.props.classes.spacing
            )}
          >
            {fromDatePicker}
            {toDatePicker}
          </div>
        </FormControl>
      );
    }

    const options: ComboBoxOption[] = runtimeProps.predefinedCriteria.map(
      (v) => ({
        display: v.description,
        value: v.name,
      })
    );

    options.unshift({
      display: Localization.getBuiltInMessage("comboNoSelection"),
      value: null,
    });

    options.push({
      display: Localization.getBuiltInMessage("customDate"),
      value: DateRangeCriteria.customRangeSentinel,
    });

    return (
      <div>
        <Select
          error={fieldHelperText.hasErrors}
          helperText={
            this.state.showSelectHelperText
              ? fieldHelperText.helperText
              : undefined
          }
          label={this.props.label}
          onValueChange={this.onPredefinedCriteriaChange}
          options={options}
          showAsMandatory={this.props.mandatory}
          value={value.selectedCriteriaName}
        />
        <Collapse
          className={this.props.classes.collapse}
          in={
            value.selectedCriteriaName === DateRangeCriteria.customRangeSentinel
          }
          onEnter={this.onExpandStart}
          onExited={this.onCollapseEnd}
        >
          <FormControl
            error={fieldHelperText.hasErrors}
            fullWidth={true}
            helperText={fieldHelperText.helperText}
            onBlur={() => {
              this.announceErrors(fieldHelperText.errors);
            }}
          >
            <div
              className={multiClassName(
                this.props.classes.rangeContainer,
                this.props.classes.spacing
              )}
            >
              {fromDatePicker}
              {toDatePicker}
            </div>
          </FormControl>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(DateRangeCriteria);
