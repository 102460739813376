import * as muiBadge from "@material-ui/core/Badge";
import { createStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { CustomTheme } from "../muiTheme";
import Api from "../mustangui/Api";

const StyledBadge = withStyles((theme: CustomTheme) =>
  createStyles({
    badge: {
      backgroundColor: theme.palette.common.white,
      borderColor: Api.getSystemColor("information"),
      borderStyle: "solid",
      borderWidth: 1,
      color: Api.getSystemColor("information"),
      fontSize: 11,
      height: 16,
      minWidth: 16,
      padding: 0,
      width: 16,
    },
  })
)(muiBadge.default);

export function ContentsExistBadge(props: muiBadge.BadgeProps): JSX.Element {
  const { max, ...otherProps } = props;

  return <StyledBadge max={max || 9} {...otherProps} />;
}
