import * as muiMenuItem from "@material-ui/core/MenuItem";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import * as React from "react";
import WithForwardedRef, {
  WithForwardedRefProps,
} from "../core/WithForwardedRef";
import multiClassName from "./MultiClassName";

interface Props extends WithForwardedRefProps {
  className?: string;
  disabled: boolean;
  indent?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.overrides?.MuiMenuItem?.gutters,
      boxSizing: "border-box",
    },
  });

export class MenuItemDisplay extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const {
      children,
      classes,
      className,
      disabled,
      forwardedRef,
      indent,
      ...otherProps
    } = this.props;

    return (
      <muiMenuItem.default
        className={multiClassName(className, classes.root)}
        disabled={disabled}
        ref={forwardedRef}
        style={{ marginLeft: indent ?? 0 }}
        {...otherProps}
      >
        {children}
      </muiMenuItem.default>
    );
  }
}

export default withStyles(styles)(WithForwardedRef(MenuItemDisplay));
