import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { observer } from "mobx-react";
import * as React from "react";
import EditMask, { EditMask as EditMaskBase } from "../coreui/EditMask";
import PasswordField from "../coreui/PasswordField";
import Presentation from "../coreui/Presentation";
import TextField from "../coreui/TextField";
import ErrorsStore from "../stores/ErrorsStore";
import Api, { AccessLevel } from "./Api";

interface ConfigProperties {
  dataCase: "lower" | "UPPER" | "Any";
  dataId: string;
  dataSize: number;
  disabledHelpText: string;
  editMask?: string;
  helperText: string;
  isPassword: boolean;
  label: string;
  maxSizeError: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) => createStyles({});

@observer
export class SLTextEdit extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private getErrors = (value: string): string[] => {
    const runtimeProperties: RuntimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return [];
    }

    const errors: string[] = [...runtimeProperties.businessErrors];
    if (this.props.dataSize && value && value.length > this.props.dataSize) {
      errors.push(this.props.maxSizeError);
    }

    return errors;
  };

  private onValueChange = (value: string) => {
    let transformedValue: string = value;

    switch (this.props.dataCase) {
      case "lower":
        transformedValue = value.toLowerCase();
        break;
      case "UPPER":
        transformedValue = value.toUpperCase();
        break;
      case "Any":
        break;
      default:
        throw new Error(`Unknown case ${this.props.dataCase}`);
    }

    ErrorsStore.clearBusinessErrors(this.props.dataId, this.props.name);
    Presentation.setValue(this.props, transformedValue);
  };

  public render() {
    const runtimeProperties = Api.getWidgetProperties(
      this.props
    ) as RuntimeProperties;

    if (!runtimeProperties) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (runtimeProperties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            runtimeProperties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    if (runtimeProperties.accessLevel === AccessLevel.readOnly) {
      let value = Presentation.getValue(this.props);

      value =
        this.props.editMask && value
          ? EditMaskBase.formatValue(this.props.editMask, value)
          : value;

      return (
        <TextField
          label={this.props.label}
          name={this.props.name}
          readOnly
          value={value ? value : "-"}
          variant="filled"
        />
      );
    }

    if (this.props.editMask) {
      return (
        <EditMask
          dataCase={this.props.dataCase}
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          label={this.props.label}
          mask={this.props.editMask}
          name={this.props.name}
          onChange={this.onValueChange}
          required={runtimeProperties.showAsMandatory}
          value={Presentation.getValue(this.props)}
          variant="filled"
        />
      );
    }

    if (this.props.isPassword) {
      return (
        <PasswordField
          autoComplete="new-password"
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          label={this.props.label}
          name={this.props.name}
          onChange={this.onValueChange}
          required={runtimeProperties.showAsMandatory}
        />
      );
    }

    return (
      <TextField
        getErrors={this.getErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        name={this.props.name}
        onValueChange={this.onValueChange}
        required={runtimeProperties.showAsMandatory}
        value={Presentation.getValue(this.props) as string}
        variant="filled"
      />
    );
  }
}

export default withStyles(styles)(SLTextEdit);
