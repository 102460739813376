import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import { DateEdit } from "../DateEdit";
import { FunctionName } from "../TableSummary";
import { DateEditColumnEdit } from "./DateEditColumnEdit";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";
import TextColumn from "./TextColumn";

interface ConfigProperties extends ICellRendererParams {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

export class DateEditColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "DateEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidget(column.name);

    if (!widget.value) {
      return "";
    }

    const parsed = DateEditColumnEdit.getCurrentValueParsed(
      false,
      widget.value
    );
    const formatted = DateEdit.formatValue(parsed, true);

    return formatted ? formatted : "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result: Date | null = null;
    let count: number = 0;
    for (const data of runtimeData) {
      if (data.value === null) {
        continue;
      }
      if (typeof data.value !== "string") {
        throw new Error(`Unexpected data type ${typeof data.value}`);
      }

      const value: Date = DateEditColumnEdit.getCurrentValueParsed(
        false,
        data.value
      )!;

      switch (functionName) {
        case "Count":
          count += 1;
          break;
        case "Maximum":
          result = result === null || value > result ? value : result;
          break;
        case "Minimum":
          result = result === null || value < result ? value : result;
          break;
        default:
          throw new Error(`Unknown summary function ${functionName}`);
      }
    }

    if (functionName === "Count") {
      return count.toString();
    }

    return DateEdit.formatValue(result, true);
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidget(props.column.name);
    const date: Date | null = DateEditColumnEdit.getCurrentValueParsed(
      false,
      widget.value
    );

    if (date === null) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {DateEdit.formatValue(date, true)}
      </Typography>
    );
  }

  public constructor(props: ConfigProperties) {
    super(props);

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    if (event.key === " " && !this.props.api.getEditingCells().length) {
      this.props.api.startEditingCell({
        colKey: this.props.column!.getColId(),
        rowIndex: this.props.rowIndex,
      });

      event.preventDefault();
      event.stopPropagation();
    }
  };

  public render() {
    const _props = { ...this.props };

    _props.value = DateEdit.formatValue(
      DateEditColumnEdit.getCurrentValueParsed(false, _props.value),
      true
    );

    return <TextColumn showEllipsis {..._props} />;
  }
}

export default DateEditColumn;
