// VERSION_WARNING Material-UI 4.9.14
// This component is taken from the internal TrapFocus component that ships with
// Material-UI. It is required by config.AddOnHost, which cannot use the Modal
// component from Material-UI because the add-on must still be mounted and
// visible when collapsed. The Material-UI Modal assumes that the component is
// either "open" and modal, or "closed" and no longer visible / interactive.
//
// Minimal changes were made:
// - Remove getDoc prop to simplify the interface, in our use-case referencing
//   document directly is sufficient.
// - Add type annotations so this component works with TypeScript.
//
// See original source at:
// - https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Modal/TrapFocus.js
/* eslint-disable consistent-return, jsx-a11y/no-noninteractive-tabindex */
import ownerDocument from "@material-ui/core/utils/ownerDocument";
import useForkRef from "@material-ui/core/utils/useForkRef";
import * as React from "react";
import * as ReactDOM from "react-dom";

interface Props {
  children: React.ReactElement;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  disableRestoreFocus?: boolean;
  isEnabled: () => boolean;
  open: boolean;
}

function TrapFocus(props: Props): JSX.Element {
  const {
    children,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    isEnabled,
    open,
  } = props;
  const ignoreNextEnforceFocus = React.useRef<boolean | undefined>();
  const sentinelStart = React.useRef<HTMLDivElement>(null);
  const sentinelEnd = React.useRef<HTMLDivElement>(null);
  const nodeToRestore = React.useRef<Element | null>(null);

  const rootRef = React.useRef<Element | null>(null);
  const handleOwnRef = React.useCallback((instance) => {
    rootRef.current = ReactDOM.findDOMNode(instance) as Element | null;
  }, []);
  const handleRef = useForkRef(children["ref"], handleOwnRef);

  const prevOpenRef = React.useRef<boolean | undefined>();
  React.useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  if (!prevOpenRef.current && open && typeof window !== "undefined") {
    // WARNING: Potentially unsafe in concurrent mode.
    // The way the read on `nodeToRestore` is setup could make this actually safe.
    // Say we render `open={false}` -> `open={true}` but never commit.
    // We have now written a state that wasn't committed. But no committed effect
    // will read this wrong value. We only read from `nodeToRestore` in effects
    // that were committed on `open={true}`
    // WARNING: Prevents the instance from being garbage collected. Should only
    // hold a weak ref.
    nodeToRestore.current = document.activeElement;
  }

  React.useEffect(() => {
    if (!open) {
      return;
    }

    const doc = ownerDocument(rootRef.current || undefined);

    // We might render an empty child.
    if (
      !disableAutoFocus &&
      rootRef.current &&
      !rootRef.current.contains(doc.activeElement)
    ) {
      if (!rootRef.current.hasAttribute("tabIndex")) {
        if (process.env.NODE_ENV !== "production") {
          console.error(
            [
              "Material-UI: The modal content node does not accept focus.",
              "For the benefit of assistive technologies, " +
                'the tabIndex of the node is being set to "-1".',
            ].join("\n")
          );
        }
        rootRef.current.setAttribute("tabIndex", "-1");
      }

      (rootRef.current! as HTMLElement).focus();
    }

    const contain = () => {
      if (
        !doc.hasFocus() ||
        disableEnforceFocus ||
        !isEnabled() ||
        ignoreNextEnforceFocus.current
      ) {
        ignoreNextEnforceFocus.current = false;
        return;
      }

      if (rootRef.current && !rootRef.current.contains(doc.activeElement)) {
        (rootRef.current! as HTMLElement).focus();
      }
    };

    const loopFocus = (event: KeyboardEvent) => {
      // 9 = Tab
      if (disableEnforceFocus || !isEnabled() || event.keyCode !== 9) {
        return;
      }

      // Make sure the next tab starts from the right place.
      if (doc.activeElement === rootRef.current) {
        // We need to ignore the next contain as
        // it will try to move the focus back to the rootRef element.
        ignoreNextEnforceFocus.current = true;
        if (event.shiftKey) {
          sentinelEnd.current!.focus();
        } else {
          sentinelStart.current!.focus();
        }
      }
    };

    doc.addEventListener("focus", contain, true);
    doc.addEventListener("keydown", loopFocus, true);

    // With Edge, Safari and Firefox, no focus related events are fired when the focused area stops being a focused area
    // e.g. https://bugzilla.mozilla.org/show_bug.cgi?id=559561.
    //
    // The whatwg spec defines how the browser should behave but does not explicitly mention any events:
    // https://html.spec.whatwg.org/multipage/interaction.html#focus-fixup-rule.
    const interval = setInterval(() => {
      contain();
    }, 50);

    return () => {
      clearInterval(interval);

      doc.removeEventListener("focus", contain, true);
      doc.removeEventListener("keydown", loopFocus, true);

      if (!disableRestoreFocus) {
        // In IE 11 it is possible for document.activeElement to be null resulting
        // in nodeToRestore.current being null.
        // Not all elements in IE 11 have a focus method.
        // Once IE 11 support is dropped the focus() call can be unconditional.
        if (
          nodeToRestore.current &&
          (nodeToRestore.current as HTMLElement).focus
        ) {
          (nodeToRestore.current as HTMLElement).focus();
        }

        nodeToRestore.current = null;
      }
    };
  }, [
    disableAutoFocus,
    disableEnforceFocus,
    disableRestoreFocus,
    isEnabled,
    open,
  ]);

  return (
    <React.Fragment>
      <div tabIndex={0} ref={sentinelStart} data-test="sentinelStart" />
      {React.cloneElement(children, { ref: handleRef })}
      <div tabIndex={0} ref={sentinelEnd} data-test="sentinelEnd" />
    </React.Fragment>
  );
}

export default TrapFocus;
