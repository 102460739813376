import { action, computed, observable } from "mobx";

export interface ProcessingInfo {
  disableRestoreFocus: boolean;
  isNew: boolean;
  isProcessing: boolean;
  message: string | null;
}

export interface SavedInfo {
  iconName?: string;
  wasSaved: boolean;
}

export default class RequestsStore {
  private static staticInstance: RequestsStore;

  @observable private processing = {
    disableRestoreFocus: false,
    isNew: false,
    message: null as string | null,
    pendingRequestCount: 0,
  };

  @observable private saved: SavedInfo = { wasSaved: false };

  public static get instance() {
    if (!RequestsStore.staticInstance) {
      RequestsStore.staticInstance = new RequestsStore();
    }

    return RequestsStore.staticInstance;
  }

  @computed public get processingInfo(): ProcessingInfo {
    return {
      disableRestoreFocus: this.processing.disableRestoreFocus,
      isNew: this.processing.isNew,
      isProcessing: this.processing.pendingRequestCount > 0,
      message: this.processing.message,
    };
  }

  public get savedInfo(): SavedInfo {
    return this.saved;
  }

  public clearAllProcessing(): void {
    this.processing.pendingRequestCount = 0;
  }

  public clearSaved(): void {
    this.saved.wasSaved = false;
  }

  @action
  public processingStarted(
    message: string | null = null,
    isNewPage: boolean = false,
    disableRestoreFocus: boolean = false
  ): void {
    if (this.processing.pendingRequestCount === 0) {
      this.processing.disableRestoreFocus = disableRestoreFocus;
      this.processing.isNew = isNewPage;
      this.processing.message = message;
    }
    this.processing.pendingRequestCount++;
  }

  @action
  public processingStopped(): void {
    this.processing.pendingRequestCount--;

    if (this.processing.pendingRequestCount < 0) {
      // Reset count so the requests store does not enter an invalid
      // state if the thrown exception is handled.
      this.processing.pendingRequestCount = 0;

      if (process.env.NODE_ENV !== "production") {
        throw "Processing stopped when it was never started";
      }
    }
  }

  @action
  public setSaved(iconName?: string): void {
    this.saved.wasSaved = true;
    this.saved.iconName = iconName;
  }
}
