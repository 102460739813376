import * as muiTypography from "@material-ui/core/Typography";
import * as React from "react";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import Dialog from "../../coreui/Dialog";
import DialogActions from "../../coreui/DialogActions";
import DialogContent from "../../coreui/DialogContent";
import Icon from "../../coreui/Icon";
import Grid from "../../mustangui/Grid";
import GridItem from "../../mustangui/GridItem";
import { ButtonColor } from "../ActionButton";
import Api from "../Api";

interface Props {
  cancelButtonText: string;
  continueButtonAlternateText?: string;
  continueButtonColor: ButtonColor;
  continueButtonIcon?: string;
  continueButtonIsIconOnly?: boolean;
  continueButtonText: string;
  isOpen: boolean;
  message: string;
  onCancel: () => void;
  onContinue: () => void;
  title: string;
}

export class ConfirmationDialog extends React.Component<Props> {
  private readonly componentId: string;
  private readonly continueButtonDescriptionId: string;
  private readonly dangerColor: string;
  private readonly messageId: string;
  private readonly titleId: string;

  public constructor(props: Props) {
    super(props);

    this.dangerColor = Api.getSystemColor("danger");
    this.componentId = `confirmation-dialog-${Sys.nextId}`;
    this.continueButtonDescriptionId = `${this.componentId}-continue-button-description`;
    this.messageId = `${this.componentId}-message`;
    this.titleId = `${this.componentId}-title`;
  }

  public render() {
    const isDanger: boolean = this.props.continueButtonColor === "Danger";

    return (
      <Dialog
        aria-describedby={this.messageId}
        aria-labelledby={this.titleId}
        fullScreen={false}
        id={this.componentId}
        onClose={() => this.props.onCancel()}
        open={this.props.isOpen}
        role="alertdialog"
      >
        <DialogContent>
          <Grid grouping="Closely Related" lg={1} md={1} sm={1} xs={1}>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <muiTypography.default
                id={this.titleId}
                style={{
                  color: isDanger ? this.dangerColor : undefined,
                }}
                variant="h3"
              >
                {isDanger ? (
                  <div className="fa-layers" style={{ marginRight: ".4em" }}>
                    <Icon
                      fixedWidth
                      icon="fas fa-exclamation"
                      style={{ fontSize: ".6em" }}
                    />
                    <Icon fixedWidth icon="far fa-octagon" />
                  </div>
                ) : null}
                {this.props.title}
              </muiTypography.default>
            </GridItem>
            <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
              <muiTypography.default id={this.messageId}>
                {this.props.message}
              </muiTypography.default>
            </GridItem>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            aria-describedby={
              this.props.continueButtonIsIconOnly
                ? this.continueButtonDescriptionId
                : undefined
            }
            aria-label={
              this.props.continueButtonIsIconOnly
                ? this.props.continueButtonText
                : undefined
            }
            autoFocus
            color={this.props.continueButtonColor.toLowerCase()}
            icon={this.props.continueButtonIcon}
            label={
              !this.props.continueButtonIsIconOnly
                ? this.props.continueButtonText
                : undefined
            }
            onClick={() => this.props.onContinue()}
          ></Button>
          {this.props.continueButtonAlternateText ? (
            <div
              id={this.continueButtonDescriptionId}
              style={{ display: "none" }}
            >
              {this.props.continueButtonAlternateText}
            </div>
          ) : null}
          <Button
            onClick={() => this.props.onCancel()}
            style={{ marginLeft: 40 }}
          >
            {this.props.cancelButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
