import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { observer } from "mobx-react";
import * as React from "react";
import Presentation from "../coreui/Presentation";

interface ConfigProperties {
  horizontalLayout: object;
  propagated: object;
  verticalLayout: object;
}

@observer
export class ResponsiveGrid extends React.Component<
  ConfigProperties & WithWidth
> {
  public render() {
    // The key attribute tells React to recreate the component from
    // scratch when the break point changes. Without this child
    // components are reused and constructors are not called

    if (this.props.verticalLayout && this.props.width === "xs") {
      return (
        <React.Fragment key="vertical">
          {Presentation.create(
            this.props.verticalLayout,
            this.props.propagated
          )}
        </React.Fragment>
      );
    }
    if (this.props.horizontalLayout && this.props.width !== "xs") {
      return (
        <React.Fragment key="horizontal">
          {Presentation.create(
            this.props.horizontalLayout,
            this.props.propagated
          )}
        </React.Fragment>
      );
    }

    // If a grid is moved between break points so the presentation server
    // can't combine them, multiple responsive grids will be rendered
    // causing them to trigger this code path at some break points
    return null;
  }
}

export default withWidth()(ResponsiveGrid);
