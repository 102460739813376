import { Theme } from "@material-ui/core/styles";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/styles";
import { GenerateId } from "jss";
import * as React from "react";
import Presentation from "../../coreui/Presentation";
import muiTheme from "../../muiTheme";

interface Props {
  config: object;
  containerName: string;
}

class ThemeWrapper extends React.PureComponent<Props> {
  private readonly generateClassName: GenerateId;
  private readonly rootTheme: Theme;

  public constructor(props: Props) {
    super(props);

    this.generateClassName = createGenerateClassName({
      disableGlobal: true,
      seed: this.props.containerName,
    });
    this.rootTheme = muiTheme("grey", false);
  }

  public render(): React.ReactNode {
    return (
      <StylesProvider generateClassName={this.generateClassName}>
        <ThemeProvider theme={this.rootTheme}>
          {React.isValidElement(this.props.config)
            ? this.props.config
            : Presentation.create(this.props.config)}
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

export default ThemeWrapper;
